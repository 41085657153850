import { useEffect, useState } from 'react'
import { Header, Input, Button, Icon, Select } from '@thryvlabs/maverick'
import { useForm, Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { setBusinessLocation } from './slices/businessInfoSlice'
import { UPDATE_BUSINESS_INFO_BY_LOCATION } from '../../graphql/mutations/business-info-mutations'
import { useAuth0 } from '@auth0/auth0-react'
import { MaskedInput } from '@thryvlabs/maverick'
import { GET_BUSINESS_INFO_BY_LOCATION } from '../../graphql/queries/business-info-queries'
// import { parsePhoneNumber } from 'libphonenumber-js'
import { getLocalPhone } from '../../modules/settings/MyAccount/BusinessCardForms/my-account-helpers'
import {
  convertAlphaToInputValue,
  convertAlphaToNumeric,
  getZip,
} from '../../modules/settings/MyAccount/BusinessCardForms/my-account-helpers'
import {
  getCountryStates,
  getStateObj,
  getStateValue,
} from './StateProvinceSelectDropdown'

const LocationModal = ({
  setOpenLocationModal,
  setRemote,
  remoteToggled,
  setRemoteToggled,
}) => {
  const businessLocation = useSelector((state) => state.businessInfo.location)
  // const countryAlpha2Code = useSelector((state) => state.countryCode.country)
  const [maskPattern, setMaskPattern] = useState('(###) ###-####')
  const dispatch = useDispatch()
  // const { countryIso2 } = useSelector((state) => state.countryCode)

  // const renderParsedPhoneNumber = () => {
  //   if (countryIso2 === 'US' || countryIso2 === 'CA') {
  //     return parsePhoneNumber('+1' + businessLocation.phone).formatNational()
  //   } else if (countryIso2 === 'AU') {
  //     return parsePhoneNumber('+61' + businessLocation.phone).formatNational()
  //   } else if (countryIso2 === 'NZ') {
  //     return parsePhoneNumber('+64' + businessLocation.phone).formatNational()
  //   } else {
  //     return businessLocation.phone
  //   }
  // }
  const {
    control,
    setValue,
    getValues,
    reset,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      address: businessLocation.address,
      city: businessLocation.city,
      zip: businessLocation.zip,
      state: businessLocation.state,
      country: businessLocation.country,
      callingCode: businessLocation.country,
      phoneNumber: getLocalPhone(businessLocation.phone),
    },
  })

  const { user } = useAuth0()

  useEffect(() => {
    switch (businessLocation.countryCode) {
      case 'US':
        return setMaskPattern('(###) ###-####')
      case 'CA':
        return setMaskPattern('(###) ###-####')
      case 'AU':
        if (
          getValues('phoneNumber')[1] === '4' ||
          businessLocation.phone[1] === '4' ||
          getValues('phoneNumber')[1] === '5' ||
          businessLocation.phone[1] === '5'
          // checks if mobile numbber and formats accordingly
        ) {
          return setMaskPattern('#### ### ###')
        } else {
          return setMaskPattern('(##) #### ####')
        }

      case 'NZ':
        return setMaskPattern('(##) ###-####')
    }
    return '(###) ###-####'
  }, [businessLocation])

  useEffect(() => {
    reset({
      address: businessLocation.address,
      city: businessLocation.city,
      zip: businessLocation.zip,
      state: businessLocation.state,
      country: businessLocation.country,
      countryCode: businessLocation.countryCode,
      phoneNumber: businessLocation.phone,
    })
  }, [businessLocation])

  // const API_KEY = import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY

  // const setAddressFromAutocomplete = (selected) => {
  //   setValue(
  //     'address',
  //     `${selected.address_components[0]?.long_name} ${selected.address_components[1]?.long_name}`,
  //   )
  //   setValue('city', selected.address_components[3]?.long_name)
  //   setValue('zip', selected.address_components[7]?.long_name)
  //   setValue('country', selected.address_components[6]?.long_name)
  //   setValue('state', selected.address_components[5]?.short_name)
  // }

  const validateForm = () => {
    setOpenLocationModal(false)
  }

  const [updateBusinessInfo] = useMutation(UPDATE_BUSINESS_INFO_BY_LOCATION, {
    variables: {
      name: businessLocation.name || '',
      category: businessLocation.category || '',
      address: getValues('address').trim() || '',
      city: getValues('city').trim() || '',
      zip: getValues('zip') || '',
      state: getStateValue(getValues('state')) || '',
      country: businessLocation.countryCode || '',
      phone:
        `${convertAlphaToNumeric(businessLocation.countryCode)}${getValues(
          'phoneNumber',
        )}` || '',
      email: businessLocation.email || '',
      website: businessLocation.website || '',
      directoryCode: user.dirCode,
      locationId: user.businessId,
    },
    onCompleted: () => {},
    refetchQueries: [GET_BUSINESS_INFO_BY_LOCATION],
    onError: () => {},
  })

  const handleFormSubmit = (e) => {
    e.preventDefault()
    const name = businessLocation.name || ''
    const category = businessLocation.category || ''
    const address = getValues('address').trim() || ''
    const city = getValues('city').trim() || ''
    const zip = getValues('zip') || ''
    const state = getStateValue(getValues('state')) || ''
    const phone = getValues('phoneNumber') || ''
    const email = businessLocation.email || ''
    const website = businessLocation.website || ''

    const updatedValues = {
      name,
      category,
      address,
      city,
      zip,
      state,
      country: businessLocation.countryCode,
      countryCode: businessLocation.countryCode,
      phone,
      email,
      website,
    }
    dispatch(setBusinessLocation(updatedValues))
    updateBusinessInfo()
  }

  return (
    <div>
      <div className="flex items-center mb-[15px] ml-4 w-50px] sm:ml-1">
        <div className="flex whitespace-nowrap justify-start w-1/2 sm:justify-center sm:w-full sm:mt-4">
          <Header variant="h4" fontWeight="semibold">
            Business Information
          </Header>
        </div>
        <div className="flex w-1/2 justify-end sm:invisible">
          <Icon
            className="cursor-pointer"
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={() => {
              setOpenLocationModal(false)
              setRemoteToggled(false)
            }}
          />
        </div>
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className="flex flex-col items-start gap-[4px] h-[194px] relative ml-4 sm:ml-1.5">
          <div className="relative h-[62px] w-[436px] sm:w-[286px] flex flex-col justify-center mb-4 sm:mb-1">
            <Controller
              control={control}
              name="address"
              render={({ field }) => (
                // <Autocomplete
                //   className="w-[100%] peer apperance-none px-0 border-b border-thryv-gray-light-500 text-thryv-black-500 font-open-sans text-form-text font-normal focus:border-b-2 focus:border-thryv-black-500 focus:outline-none focus:ring-0 transition duration-300 ease-in-out focus:placeholder-transparent disabled:placeholder-thryv-gray-medium-500 disabled:border-b disabled:border-thryv-gray-light-500 disabled:text-thryv-gray-medium-500 mt-1 bg-transparent placeholder-opacity-0 placeholder-transparent pb-1 valid:border-b invalid:border-b bg-none false"
                //   type="text"
                //   customplaceholder="Address"
                //   {...field}
                //   variant="default"
                //   apiKey={API_KEY}
                //   onPlaceSelected={(selected) =>
                //     setAddressFromAutocomplete(selected)
                //   }
                //   options={{
                //     types: ['geocode', 'establishment'],
                //   }}
                // />
                <Input
                  {...field}
                  type="text"
                  placeholder="Address"
                  name="address"
                  withLabel
                  labelType="floating"
                  variant="default"
                  disabled={remoteToggled}
                  className="w-[100%] peer apperance-none px-0 border-b border-thryv-gray-light-500 text-thryv-black-500 font-open-sans text-form-text 
                  font-normal focus:border-b-2 focus:border-thryv-black-500 focus:outline-none focus:ring-0 transition duration-300 ease-in-out focus:placeholder-transparent 
                  disabled:placeholder-thryv-gray-medium-500 disabled:border-b disabled:border-thryv-gray-light-500 disabled:text-thryv-gray-medium-500 mt-1 
                  bg-transparent placeholder-opacity-0 placeholder-transparent pb-1 valid:border-b invalid:border-b bg-none false"
                />
              )}
            />
            {/* <label
              htmlFor="address"
              className='pointer-events-none absolute left-0 -top-0 capitalize 
                font-open-sans text-label-text peer-placeholder-shown:text-form-text peer-placeholder-shown:text-thryv-steel 
                peer-placeholder-shown:top-6 transition-all peer-focus:-top-0 peer-focus:text-label-text 
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989] undefined ">Country</label>'
            >
              Address
            </label> */}
          </div>
          <p className="text-notification-red text-xs absolute top-[45px] sm:top-[44px] z-50">
            {errors.address?.message}
          </p>
          <div className="flex items-start gap-4 h-[62px] w-[436px] sm:flex-col sm:h-[66px] sm:gap-5">
            <div className="flex">
              <Controller
                control={control}
                name="city"
                render={({ field }) => (
                  <Input
                    className="w-[210px] sm:w-[286px]"
                    type="text"
                    placeholder="City"
                    name="city"
                    {...field}
                    withLabel
                    labelType="floating"
                    variant="default"
                    disabled={remoteToggled}
                  />
                )}
              />
            </div>
            <div className="flex flex-col">
              <Controller
                control={control}
                name="zip"
                render={({ field }) => (
                  <Input
                    {...register('zip', {
                      pattern: getZip.pattern(businessLocation.countryCode),
                      maxLength: getZip.max(businessLocation.countryCode),
                      minLength: getZip.min(businessLocation.countryCode),
                    })}
                    className="w-[210px] sm:w-[286px]"
                    type="tel"
                    placeholder="Postal Code"
                    {...field}
                    withLabel
                    labelType="floating"
                    variant="default"
                    disabled={remoteToggled}
                  />
                )}
              />
              {errors?.zip && (
                <p className="flex text-notification-red text-xs sm:top-[140px] sm:left-0">
                  Please enter a valid Postal Code
                </p>
              )}
            </div>
          </div>
          <div className="flex items-start gap-4 h-[62px] w-[436px] sm:w-[286px] sm:items-end sm:mb-2">
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <Input
                  className="w-[210px] sm:w-[135px]"
                  type="text"
                  placeholder="Country"
                  {...field}
                  withLabel
                  labelType="floating"
                  variant="default"
                  disabled={remoteToggled}
                />
              )}
            />
            <Controller
              control={control}
              name="state"
              render={({ field: { ...rest } }) => (
                <Select
                  options={getCountryStates(businessLocation.countryCode)}
                  className="w-[210px] sm:w-[135px] mt-[-7px] "
                  selectLabel="State/Province"
                  labelType="static"
                  withLabel
                  selectedOption={getStateObj(
                    getValues('state'),
                    getCountryStates(businessLocation.countryCode),
                  )}
                  setSelectedOption={(e) => setValue('state', e.target?.value)}
                  {...rest}
                />
              )}
            />
          </div>
          <p className="text-notification-red text-xs absolute bottom-[10px] right-[155px] sm:-bottom-[20px] sm:left-[150px]">
            {errors.state?.message}
          </p>
          <p className="text-notification-red text-xs absolute bottom-[10px] sm:-bottom-[20px]">
            {errors.country?.message}
          </p>
        </div>
        <div className="w-[436px] max-w-[436px] h-[46px] ml-4 flex gap-[16px] items-end sm:ml-2">
          <div className="relative">
            <div className="w-[110px] sm:w-[135px]">
              <Input
                className="w-[110px] sm:w-[135px]"
                type="tel"
                customPlaceholder={convertAlphaToInputValue(
                  businessLocation.countryCode,
                )}
                name="countryCode"
                withLabel
                labelType="static"
                variant="default"
                disabled={true}
              />
            </div>
            <label
              htmlFor="countryCode"
              className='pointer-events-none absolute left-0 -top-[16px] capitalize 
                font-open-sans text-label-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text 
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
            >
              Country Code
            </label>
          </div>
          <Controller
            control={control}
            // rules={{
            //   minLength: phoneErrorMessage(),
            //   maxLength: phoneErrorMessage(),
            // }}
            name="phoneNumber"
            render={({ field: { ...rest }, fieldState: { error } }) => (
              <MaskedInput
                className="w-[310px] sm:w-[135px]"
                type="tel"
                mask={maskPattern}
                placeholder={error ? '' : 'Phone Number'}
                withLabel
                labelType="floating"
                variant="default"
                error={error}
                {...rest}
              />
            )}
          />
          <p className="text-notification-red text-xs absolute bottom-[165px] left-[163px]">
            {errors.phoneNumber?.message}
          </p>
        </div>
        <div className="flex w-[450px] mt-[80px] ml-4 justify-end items-center gap-4 sm:w-[286px] sm:justify-center sm:mt-[50px] sm:mb-[20px]">
          <Button
            onClick={() => {
              setOpenLocationModal(false)
              setRemoteToggled(false)
            }}
            variant="text"
            level={2}
            textVariant="light"
          >
            Cancel
          </Button>
          {remoteToggled ? (
            <Button
              disabled={Object.keys(errors).length > 0}
              variant="primary"
              onClick={() => {
                setOpenLocationModal(false)
                setRemote(true)
              }}
            >
              Save
            </Button>
          ) : (
            <Button
              disabled={Object.keys(errors).length > 0}
              variant="primary"
              type="submit"
              onClick={validateForm}
            >
              Save
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}

export default LocationModal
