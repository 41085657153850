import moment from 'moment'
import { ParagraphText } from '@thryvlabs/maverick'

const HorizontalLine = () => {
  return <hr className="h-px text-thryv-gray-light-400 w-full" />
}

export const dateMethods = function (date) {
  const providedDate = moment(date)
  const todayDate = moment().startOf('day')
  if (providedDate.isSame(todayDate, 'Day')) {
    return 'Today'
  } else if (providedDate.isSame(todayDate.clone().subtract(1, 'day'), 'day')) {
    return 'Yesterday'
  } else {
    return moment(date).format('dddd, MMMM Do')
  }
}

export const DaySeparator = ({ className, date, title = null, subTitle }) => {
  const init = () => {
    const text = subTitle ? dateMethods(subTitle) : dateMethods(date)
    return text
  }

  const text = init()
  const renderDaySeparator = () => (
    <div className="flex items-center gap-4 ">
      <HorizontalLine />
      <ParagraphText
        variant="sm"
        className="font-[Montserrat] font-semibold whitespace-nowrap "
        color="thryv-steel text-[11px]"
      >
        {text}
      </ParagraphText>
      <HorizontalLine />
    </div>
  )

  const renderTitleContent = () => (
    <div className="flex flex-col items-center mt-2">
      <ParagraphText className="font-montserrat font-semibold" variant="lg">
        {title}
      </ParagraphText>
      <ParagraphText
        className="!text-[11px] leading-[13px] font-semibold"
        variant="sm"
        color="thryv-gray-medium-500"
      >
        {moment(date).format('dddd, MMM D, YYYY [at] LT')}
      </ParagraphText>
    </div>
  )

  return (
    <div className={className} data-testid="day-separator">
      {/* checks to make sure we dont render the day and line for the first separator on the conversation */}
      {date && title ? null : renderDaySeparator()}
      {title && renderTitleContent()}
    </div>
  )
}
