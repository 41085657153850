import { LoadingSpinner } from '../../../../components/common/loading-spinner'
import { Button } from '@thryvlabs/maverick'
import { useMutation } from '@apollo/client'
import { DELETE_BILLING_INFO } from '../../../../graphql'
import { useState } from 'react'

const RemovePaymentMethod = ({
  paymentMethod,
  handleClose,
  onComplete,
  isLastPaymentMethod,
}) => {
  const [error, setError] = useState()
  const handleDelete = () => {
    if (isLastPaymentMethod) {
      setError('Please set a new primary billing option before deleting this one.')
    } else {
      deleteBillingInfo({ variables: { CCID: cc_id, billingInfoId } })
    }
  }
  const [deleteBillingInfo, { loading }] = useMutation(DELETE_BILLING_INFO, {
    onCompleted: () => {
      setError()
      onComplete()
    },
    onError: (err) => {
      const errorMessage = JSON.parse(err.message)?.message
      if (
        errorMessage.includes(
          'Please set a new primary billing info before deleting this billing info',
        )
      ) {
        setError('Please set a new primary billing option before deleting this one.')
      } else {
        setError(errorMessage ? errorMessage : 'Error Deleting Billing Info')
      }
      // eslint-disable-next-line no-console
      console.log('Error Deleting Billing info')
    },
  })

  const cc_id = localStorage.getItem('cc_id')
  const { CC_LAST_4: lastFour, BILLING_INFO_ID: billingInfoId } = paymentMethod

  return (
    <>
      <p>Are you sure you want to delete the payment method ending in {lastFour}?</p>
      <div className="flex w-full mt-5 mb-5 justify-end items-center gap-4 sm:w-[286px] sm:justify-center sm:mt-[50px] sm:mb-[20px]">
        <Button onClick={handleClose} variant="text" level={2} textVariant="light">
          Cancel
        </Button>

        {loading ? (
          <LoadingSpinner widthAndHeight={32} />
        ) : (
          <Button
            variant="primary"
            type="submit"
            disabled={loading || error}
            onClick={() => {
              setError()
              handleDelete()
            }}
          >
            Delete
          </Button>
        )}
      </div>
      <p className="text-[12px] text-[#FF0000]">{error}</p>
    </>
  )
}

export default RemovePaymentMethod
