import { useState, useEffect } from 'react'
import { Header, ParagraphText, Button, Icon } from '@thryvlabs/maverick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth0 } from '@auth0/auth0-react'
import { faX } from '@fortawesome/pro-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  setOpenVideoCallLinkModal,
  setCopyInvitationClicked,
} from '../slices/callSlice'
import { LoadingSpinner } from '../../common/loading-spinner'
import { CREATE_VONAGE_MEETING_ROOM } from '../../../graphql/mutations/vonage-mutations'
import {
  setRoomInfo,
  setRoomData,
} from '../../../redux-toolkit/slices/meetings/meetings-slice'
import { Buffer } from 'buffer'
import { Notification } from '../../common/notification'
import {
  setModalContent,
  setShowModal,
} from '../../common/modals/slices/connectChannelsSlice'
import './style.css'
window.Buffer = Buffer

const VideoCallLinkModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useAuth0()
  const copyInvitationClicked = useSelector(
    (state) => state.calls.copyInvitationClicked,
  )
  const isMobile = localStorage.getItem('IS_MOBILE')

  const [meetingRoomLink, setMeetingRoomLink] = useState({})
  const [, setRoomID] = useState('')

  const [showMinutesLimitError, setShowMinutesLimitError] = useState(false)

  const [createVonageMeetingRoom, { loading }] = useMutation(
    CREATE_VONAGE_MEETING_ROOM,
    {
      variables: {
        CC_UserID: user.cc_uid,
      },
      onCompleted: (data) => {
        setMeetingRoomLink(data.createVonageMeetingRoom)
        dispatch(setRoomInfo(meetingRoomLink))
        dispatch(setRoomData(data.createVonageMeetingRoom))
        setRoomID(data.createVonageMeetingRoom.RoomID)
        // setShowMinutesLimitError(false)//making sure error gets set back to false
      },
      onError: (error) => {
        const errObj = { error }
        const errorMessage = errObj?.error?.message
        if (errorMessage.includes('has no vonage time.')) {
          setShowMinutesLimitError(true)
        }
      },
    },
  )

  useEffect(() => {
    createVonageMeetingRoom()
  }, [])

  const copyToClipboard = () => {
    const copyText = meetingRoomLink.guest_url
    navigator.clipboard.writeText(copyText)
  }

  const handleCopyInvitation = () => {
    dispatch(setCopyInvitationClicked(true))
    copyToClipboard()
  }

  const handleClose = () => {
    dispatch(setOpenVideoCallLinkModal(false))
    dispatch(setCopyInvitationClicked(false))
  }

  const renderVonageLink = !loading ? (
    <div
      onClick={() => {
        if (isMobile) {
          window.open(meetingRoomLink.host_url)
        } else {
          navigate('/meetings')
        }
        dispatch(setOpenVideoCallLinkModal(false))
      }}
      className="w-10/12 font-open-sans text-[14px] font-bold leading-5 text-[#057AFF] cursor-pointer truncate disable-touch-callout"
    >
      {meetingRoomLink.host_url}
    </div>
  ) : null

  const renderVonageLinkOnLoading = loading ? (
    <LoadingSpinner widthAndHeight={36} />
  ) : null

  const openUpgradeModal = () => {
    dispatch(setModalContent(4))
    dispatch(setShowModal(true))
    handleClose()
  }

  return (
    <div
      className={` ${
        !loading && !meetingRoomLink.host_url
          ? `h-[315px] max-h-[315px]`
          : `h-[386px] max-h-[386px]`
      }  flex flex-col items-start px-[20px] py-[10px] gap-[24px] sm:w-[300px] x-sm:w-[280px] sm:px-0 sm:h-[unset] sm:max-h-[unset]`}
    >
      <div className="flex justify-center items-center gap-[32px] w-[436px] h-[34px] sm:w-[300px] x-sm:w-[280px]">
        <div className="w-[386px] h-[30px] sm:w-[300px] x-sm:w-[280px]">
          <Header
            fontWeight="semibold"
            variant="h4"
            className="sm:!text-[19px] x-sm:!text-[17px]"
          >
            Connect over Video Calls
          </Header>
        </div>

        <FontAwesomeIcon
          onClick={handleClose}
          icon={faX}
          className="w-[18px] h-[18px] text-[#A3A5A7] cursor-pointer"
        />
      </div>
      {meetingRoomLink.host_url || loading ? (
        <>
          <div className="w-[400px] sm:w-[300px] x-sm:w-[280px]">
            <ParagraphText variant="lg" className="text-[#000000]">
              Share this link to connect instantly with your clients. <br /> <br />{' '}
              Simply copy the link below and send it via your inbox.
            </ParagraphText>
          </div>
          <div className="w-[400px] sm:w-[300px] x-sm:w-[280px]">
            <ParagraphText variant="sm" className="text-[#808080]">
              Use the link below to join your video call:
            </ParagraphText>
          </div>
          <div className="w-[450px] mt-[-20px] sm:w-[300px] x-sm:w-[280px]">
            {renderVonageLinkOnLoading}
            {renderVonageLink}
          </div>
        </>
      ) : (
        <div className="flex max-w-[436px] py-2 px-3 justify-center items-start gap-[52px] rounded border border-[#EF9595] bg-[#FCEAEA]">
          <div className="flex items-start gap-2 grow shrink-0 basis-0">
            <div className="flex w-5 h-5 px-[3px] py-0 justify-center items-center gap-[10px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g>
                  <path
                    d="M8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16ZM8 4C8.41562 4 8.75 4.33437 8.75 4.75V8.25C8.75 8.66562 8.41562 9 8 9C7.58437 9 7.25 8.66562 7.25 8.25V4.75C7.25 4.33437 7.58437 4 8 4ZM9 11C9 11.5531 8.55313 12 8 12C7.44688 12 7 11.5531 7 11C7 10.4469 7.44688 10 8 10C8.55313 10 9 10.4469 9 11Z"
                    fill="#DF2A2A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_20806_289226">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="text-[14px] font-open-sans font-normal leading-5">
              An error occurred with your meeting link. Please try again later or
              contact support:{' '}
              <span className="text-[#057AFF]">support@thryv.com</span>.
            </div>
          </div>
        </div>
      )}
      {showMinutesLimitError ? (
        <Notification
          variant="warning"
          iconType="solid"
          className={'w-[450px]'}
          textToRender={
            'You have used all your minutes. Please upgrade to continue to make Video Calls'
          }
          // close={() => setShowMinutesLimitError(false)}
        />
      ) : null}
      <div className="flex gap-4 ">
        {showMinutesLimitError ? (
          <Button variant="secondary" onClick={() => openUpgradeModal()}>
            get more minutes
          </Button>
        ) : copyInvitationClicked ? (
          <button className="flex items-center justify-center gap-2 w-[135px] h-[28px] bg-[#D0ECE7] border-[2px] border-[#16A085] rounded font-open-sans leading-5 text-[14px] font-normal text-[#231F20]">
            <Icon
              type="solid"
              variant="circleCheck"
              color="#16A085"
              className="h-[16px] w-[16px]"
            />
            Link Copied!
          </button>
        ) : (
          <button
            className={`w-[124px] h-[28px] rounded py-[7px]  ${
              meetingRoomLink?.host_url ? `bg-[#F2F4F6]` : `bg-[#CCC] text-[#808080]`
            } font-open-sans font-normal text-[11px] leading-[14px] ${
              meetingRoomLink?.host_url && `hover:bg-thryv-gray-medium-200`
            }`}
            onClick={handleCopyInvitation}
            disabled={!meetingRoomLink?.host_url}
          >
            COPY INVITATION
          </button>
        )}

        {/* <button
          className="w-[144px] h-[28px] rounded py-[7px] px-[16px] bg-[#F2F4F6] font-open-sans font-normal text-[11px] leading-[14px] hover:bg-thryv-gray-medium-200"
          onClick={() => navigate('/inbox')}
        >
          SHARE LINK IN INBOX
        </button> */}
      </div>

      <div className="w-[400px] sm:w-[300px] x-sm:w-[280px] ">
        <ParagraphText variant="sm" className="text-[#808080]">
          Video calls without any activity for 10 minutes will automatically be
          closed. Simply generate a new video call link to start again.
        </ParagraphText>
      </div>
      <div className="w-[436px] h-[32px] flex justify-end sm:w-[300px] x-sm:w-[280px]">
        <Button onClick={handleClose} variant="primary">
          Close
        </Button>
      </div>
    </div>
  )
}

export default VideoCallLinkModal
