import styled from 'styled-components'

const StyledEmojiMenuWrapper = styled.div`
  /* #      Emoji picker container     # */

  aside.EmojiPickerReact.epr-main {
    position: absolute;
    z-index: 10;
    ${({ styles }) => styles}
  }

  /* #     Header w/ emoji types and search     # */

  aside.EmojiPickerReact.epr-main > .epr-header {
    margin-bottom: -12px;
  }

  /* #     Search bar container     # */

  .EmojiPickerReact .epr-header .epr-header-overlay {
    transform: translateY(40px);
  }

  /* #     Search bar input element     # */

  .EmojiPickerReact .epr-search-container input.epr-search {
    border-radius: 25px;
    border-color: #626364;
    background-color: #fff;
  }

  /* #     Emoji Types     # */

  aside.EmojiPickerReact.epr-main .epr-category-nav,
  aside.EmojiPickerReact.epr-search-active .epr-category-nav {
    background-color: #f8f8f8;
    transform: translateY(-70px);
    height: 46px;
    padding: 8px 8px;
    border-bottom: 1px solid #eae7e6;
  }

  /* #     Recommended emoji text     # */

  .EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
    display: none;
  }

  /* #     Recommended emojis     # */

  .epr-emoji-category.epr-hidden-on-search {
    display: none;
  }

  /* #     Preview footer     # */

  aside.EmojiPickerReact > div.epr-preview {
    margin-bottom: -2px;
    height: 46px;
  }

  /* #     Preview (hovered emoji)     # */

  aside.EmojiPickerReact > div.epr-preview > div > span.__EmojiPicker__ {
    display: inline-block;
    font-size: 30px !important;
    height: 30px !important;
    width: 30px !important;
  }

  /* #     Skintone Selector     # */

  aside.EmojiPickerReact .epr-skin-tones .epr-tone,
  aside.EmojiPickerReact .epr-skin-tones .epr-tone.epr-active {
    border-radius: 100%;
    border: 1px solid #eae7e6;
  }

  /* #     Add border around skintone selector     # */

  aside.EmojiPickerReact .epr-skin-tones .epr-tone:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 2px solid #ffffff;
    background: transparent;
    border-radius: 100%;
  }
`
export default StyledEmojiMenuWrapper
