import { InvoicesCoupons } from './InvoicesCoupons'
import { PaymentMethods } from './PaymentMethods'
import { PersonalInformationCard } from './PersonalInformationCard'
import ProductServices from './ProductServices'
import { useMediaQuery } from 'react-responsive'
import BusinessForm from './BusinessForm'
import { Routes, Route, Link, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { GET_STAFF } from '../../../graphql'

export const GeneralTab = () => {
  const location = useLocation()
  const activeTab = location.pathname.split('/')[3]
  const { user } = useAuth0()
  const userId = user.cc_uid
  const { data: staffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: userId },
      },
    },
  })
  const userRole = staffData?.staff[0]?.Role || user.cc_role

  const isMobile = useMediaQuery({ maxWidth: 835 })

  const MyAccountSubTab = () => {
    if (userRole === '2') {
      return [
        { label: 'Personal Information', path: 'personal-information' },
        { label: 'Business Information', path: 'business-form' },
      ]
    } else {
      return [
        { label: 'Personal Information', path: 'personal-information' },
        { label: 'Business Information', path: 'business-form' },
        { label: 'Active Subscriptions', path: 'product-services' },
        { label: 'Payment Methods', path: 'payment-methods' },
        { label: 'Payment History', path: 'invoice-coupons' },
      ]
    }
  }

  const myAccountSubTab = MyAccountSubTab()

  return (
    <div className="grid grid-cols-6 gap-3 ">
      {!isMobile && (
        <div className="sm:col-span-6 col-span-1 flex flex-col font-open-sans p-3 gap-[18px] items-start">
          {myAccountSubTab.map((data, idx) => (
            <p className="text-[14px] leading-5" key={idx}>
              {data.path === activeTab ? (
                <span
                  className={`cursor-default font-semibold font-montserrat text-sm  mb-5`}
                >
                  {data.label}
                </span>
              ) : (
                <Link to={data.path}>
                  <span
                    className={`cursor-pointer font-normal font-montserrat hover:text-[#717171] text-sm  mb-5`}
                  >
                    {data.label}
                  </span>
                </Link>
              )}
            </p>
          ))}
        </div>
      )}

      <div className="sm:col-span-6 col-span-5">
        <div className="sm:bg-[#fff] border-[#EFEFEF] rounded-[3px]">
          <Routes>
            <Route
              path="/personal-information"
              element={<PersonalInformationCard />}
            />
            <Route path="/business-form" element={<BusinessForm />} />
            <Route path="/product-services" element={<ProductServices />} />
            <Route path="/payment-methods" element={<PaymentMethods />} />
            <Route path="/invoice-coupons" element={<InvoicesCoupons />} />
            <Route path="/" element={<PersonalInformationCard />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}
