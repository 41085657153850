import { useEffect } from 'react'
import styled from 'styled-components'

// Components
import { CustomButtonGroup } from '../../../../components/common/CustomButtonGroupDropDown/CustomButtonGroup'
import { StatusPill } from '../../../../components/common/status-pill/status-pill'
import CustomModal from '../../../../components/common/CustomModal/Modal'
import { CancelPlanModal } from '../../../../components/common/modals/cancel-plan-modal/CancelPlanModal'
import {
  MenuChevron,
  TableContent,
  TableRow,
  ParagraphText,
  TableMenu,
  Icon,
} from '@thryvlabs/maverick'
import { useMediaQuery } from 'react-responsive'
import { CommandCenterIcon } from '../../../../components/command-center-icon'
import { useSortableData } from '../../../../hooks/use-sortable-data'

const StyledTable = styled.div`
  border-radius: 4px;
  overflow: hidden;
`
const PLAN_NAME_ICONS = [
  { name: 'Command Center Plus', icon: 'cc-subscription' },
  { name: 'Marketing Center', icon: 'marketingCenterSubscription' },
  { name: 'Business Center', icon: 'businessCenterSubscription' },
  { name: 'ThryvPay Center', icon: 'thryvPaySubscription' },
  { name: 'Thryv Marketplace', icon: 'thryvMarketplaceSubscription' },
]

export const SubscriptionTableHead = ({
  children,
  className,
  width,
  onClick,
  sortDirection,
}) => {
  return (
    <button
      type="button"
      className={`${className} flex justify-start items-center pl-[16px] py-[26px] 
      ${!sortDirection && 'cursor-default'}`}
      style={{
        width: width, // Recommended to be a percentage value. Ex: 35%
      }}
      onClick={onClick}
    >
      <h6 className="text-thryv-steel font-semibold font-montserrat text-[14px] capitalize">
        {children}
      </h6>
      {sortDirection ? (
        <MenuChevron
          style={{
            marginLeft: '16px',
            ...(sortDirection === 'ascending' && { transform: 'rotate(180deg)' }),
          }}
        />
      ) : null}
    </button>
  )
}

const SubscriptionTableCell = ({ children, className, width }) => {
  return (
    <div
      className={`${className} flex justify-start items-center pl-[16px]`}
      style={{
        width: width,
      }}
    >
      {children}
    </div>
  )
}

const renderPlanName = (plan) => {
  if (
    plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Plus' ||
    plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Pro'
  ) {
    return plan?.subscription?.SUBSCRIPTION_PLAN_NAME.substring(14)
  } else if (plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Basic') {
    return 'Free Forever'
  } else {
    return plan?.subscription?.SUBSCRIPTION_PLAN_NAME
  }
}

const renderCommandCenter = (plan) => {
  if (plan?.subscription?.SUBSCRIPTION_PLAN_NAME.includes('Command Center')) {
    return plan?.subscription?.SUBSCRIPTION_PLAN_NAME.substring(0, 14)
  } else {
    return plan?.subscription?.SUBSCRIPTION_PLAN_NAME
  }
}

const renderStatus = (plan) => {
  return plan?.subscription?.__typename === 'ActiveSubscription'
    ? 'ACTIVE'
    : 'INACTIVE'
}

const renderUsers = (plan) => {
  return plan.subscription.SUBSCRIPTION_QUANTITY
}

const renderPrice = (plan) => {
  return plan?.subscription?.QUANTITY_TIER_PRICE ?? null
}

export const SubscriptionWeb = ({
  tableData,
  selectedItem,
  setSelectedItem,
  loading,
  activeSubscriptions,
  handleClose,
  latestPayment,
  openCancelModal,
  setOpenCancelModal,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const { items, requestSort, sortConfig } = useSortableData(tableData)

  useEffect(() => {
    if (selectedItem === 'Cancel') setOpenCancelModal(true)
  }, [selectedItem])

  const renderSubscriptionIcon = (subscription) => {
    const { subscription: subscriptionInfo } = subscription
    const { SUBSCRIPTION_PLAN_NAME: planName } = subscriptionInfo
    let icon = 'cc-subscription'

    if (!planName.includes('Command Center')) {
      const newPlanObj = PLAN_NAME_ICONS.find((plan) => plan.name === planName)
      if (newPlanObj) {
        icon = newPlanObj.icon
      }
    }
    return <CommandCenterIcon variant={icon} type="solid" width={35} height={35} />
  }

  const getSortDirectionForKey = (key) => {
    return sortConfig?.key === key && sortConfig?.direction
  }

  return (
    <div>
      <StyledTable>
        <TableMenu className="w-full border-b border-thryv-gray-light-400">
          <div className="flex justify-start items-center w-full">
            <SubscriptionTableHead
              width="20%"
              onClick={() => requestSort('servicesAndPlans', renderCommandCenter)}
              sortDirection={getSortDirectionForKey('servicesAndPlans')}
            >
              Services and Plans
            </SubscriptionTableHead>
            <SubscriptionTableHead
              width="16%"
              onClick={() => requestSort('planName', renderPlanName)}
              sortDirection={getSortDirectionForKey('planName')}
            >
              Plan Name
            </SubscriptionTableHead>
            <SubscriptionTableHead
              width="16%"
              onClick={() => requestSort('status', renderStatus)}
              sortDirection={getSortDirectionForKey('status')}
            >
              Status
            </SubscriptionTableHead>

            <SubscriptionTableHead
              width="16%"
              onClick={() => requestSort('users', renderUsers)}
              sortDirection={getSortDirectionForKey('users')}
            >
              Users
            </SubscriptionTableHead>
            <SubscriptionTableHead
              width="16%"
              onClick={() => requestSort('price', renderPrice)}
              sortDirection={getSortDirectionForKey('price')}
            >
              Price
            </SubscriptionTableHead>
            <SubscriptionTableHead width="16%">Actions</SubscriptionTableHead>
          </div>
        </TableMenu>
        {loading ? (
          <div className="flex justify-center h-[60px]">
            <span className="w-[38px] h-[38px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] box-border animate-spin relative top-5"></span>
          </div>
        ) : (
          <TableContent>
            {items.map((subscription, i) => {
              // const centerNames = center ? center.name?.split(' ') : []

              const servicesAndPlans = renderCommandCenter(subscription)
              const servicesAndPlansIcon = renderSubscriptionIcon(subscription)
              const planName = renderPlanName(subscription)
              const status = renderStatus(subscription)
              const users = renderUsers(subscription)
              const price = renderPrice(subscription)

              return (
                <TableRow key={i} variant="accordion" compact>
                  {({ show, setShow, compact }) => (
                    <div className="flex flex-col justify-between gap-[30px] ]">
                      <div
                        className={`
                      ${compact ? 'py-[16px]' : 'py-[30px]'} 
                       w-full flex items-center 
                      ${show ? 'bg-thryv-white-200' : 'bg-white'} 
                      hover:bg-thryv-white-200 transition-all duration-300 ease-in`}
                      >
                        {/* ----- Services and Plans Cell -----*/}
                        <SubscriptionTableCell width="20%">
                          <div className="flex gap-2 items-center">
                            {servicesAndPlansIcon}
                            <ParagraphText
                              key={i}
                              variant="sm"
                              className="!font-semibold !font-[Montserrat] leading-[15px] !text-[#4D4D4D]"
                            >
                              {servicesAndPlans}
                            </ParagraphText>
                          </div>

                          <SubscriptionTableCell>
                            {subscription.addons.length > 0 && (
                              <Icon
                                type="solid"
                                width="8"
                                variant="caretDown"
                                className={`${
                                  show ? 'rotate-0' : '-rotate-90'
                                } mt-[2px]  cursor-pointer transition-all duration-300 `}
                                onClick={() => setShow(!show)}
                              />
                            )}
                          </SubscriptionTableCell>
                        </SubscriptionTableCell>
                        {/* ----- Plan Name Cell -----*/}
                        <SubscriptionTableCell width="16%">
                          <span className="text-btn-links font-montserrat text-[#231F20]">
                            {planName}
                          </span>
                        </SubscriptionTableCell>
                        {/* ----- Status Cell -----*/}{' '}
                        <SubscriptionTableCell width="16%">
                          <StatusPill
                            color={status === 'ACTIVE' ? '#16A085' : '#a3a5a7'}
                          >
                            {status}
                          </StatusPill>
                        </SubscriptionTableCell>
                        {/* ----- Users Cell -----*/}
                        <SubscriptionTableCell
                          width="16%"
                          className={' flex justify-start'}
                        >
                          <span className="text-btn-links  font-montserrat text-[#231F20]">
                            {users}
                          </span>
                        </SubscriptionTableCell>
                        {/* ----- Monthly Price Cell -----*/}
                        <SubscriptionTableCell width="16%">
                          {price === null ? null : (
                            <span className="text-[#231F20] text-[14px]">
                              ${price} per user/month
                            </span>
                          )}
                        </SubscriptionTableCell>
                        {/* ----- Actions Cell -----*/}
                        <SubscriptionTableCell width="16%">
                          {subscription.subscription.SUBSCRIPTION_PLAN_NAME.includes(
                            'Command Center',
                          ) ? (
                            <>
                              <CustomButtonGroup
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                                subscription={
                                  subscription.subscription.SUBSCRIPTION_PLAN_NAME
                                }
                              />
                              <CustomModal
                                isModalForm={true}
                                heading=""
                                hasCrossIcon={false}
                                isOpen={openCancelModal}
                                setIsOpen={setOpenCancelModal}
                                close={handleClose}
                                width={isMobile ? '94%' : '500px'}
                              >
                                <CancelPlanModal
                                  handleClose={handleClose}
                                  activeSubscriptions={activeSubscriptions}
                                  latestPayment={latestPayment}
                                  planName={
                                    subscription.subscription.SUBSCRIPTION_PLAN_NAME
                                  }
                                  subscription={subscription}
                                  renderPlanName={renderPlanName}
                                />
                              </CustomModal>
                            </>
                          ) : null}
                        </SubscriptionTableCell>
                      </div>

                      {show && (
                        <div className="w-full pl-[2rem] mb-[1rem] bg-white mt-[-1%] flex">
                          <div className="w-[13px] h-[18px] mb-[05px] mt-[-10px] border-l border-b border-[#CCC]"></div>
                          <div>
                            <ParagraphText
                              variant="sm"
                              className="!font-[Montserrat] !font-medium !text-[#4D4D4D] !leading-[15px] mb-3 ml-[10px]"
                            >
                              Entitlements ({subscription.addons.length})
                            </ParagraphText>
                            <div className="flex flex-wrap gap-2.5">
                              {subscription.addons.map((addon, index) => (
                                <StatusPill
                                  className="h-[22px] flex items-center justify-center py-auto sm:absolute sm:bottom-3 sm:left-3"
                                  color={'#808080'}
                                  key={index}
                                >
                                  <ParagraphText
                                    variant="sm"
                                    className="leading-[16.6px]"
                                    color="white"
                                  >
                                    {addon.ADD_ON_NAME}
                                  </ParagraphText>
                                </StatusPill>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </TableRow>
              )
            })}
          </TableContent>
        )}
      </StyledTable>
    </div>
  )
}
