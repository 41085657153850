import { useRef, useState } from 'react'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { CREATE_CONTACT, GET_STAFF } from '../../../../../graphql'
import { setSelectedThread } from '../../../slices/inboxThreadsSlice'
import { handleStartNewMessage, setRecipient } from '../../../slices/inboxSlice'
import { useContactSuggestions } from './hooks/use-contact-suggestions'
import { isValidEmail } from './utils/utils'
import { updateInboxNotif } from '../../../slices/inbox-notifications/inbox-notifications-slice'
import commandCenterPhoneValidator from '../../../../../utils/commandCenterPhoneValidator'
import formatContactData from '../../../hooks/formatContactData'
import countries from './utils/countries'

// Components
import { UserSearchBar } from '../user-search-bar'
import { UsersDropdown } from '../users-dropdown/users-dropdown'
import { CreateNewConversationModal } from './create-new-conversation-modal'
import { ParagraphText } from '@thryvlabs/maverick'
import { useInboxMenuConversations } from '../../../inbox-menu/hooks/use-inbox-menu-conversations'
import { LoadingSpinner } from '../../../../common/loading-spinner'
import { FETCH_THREADS } from '../../../../../graphql'

export const ContactComposeSubheader = () => {
  const searchBarRef = useRef(null)
  const { data: staffData } = useQuery(GET_STAFF)

  const dispatch = useDispatch()

  const [postContact, { loading: contactCreationLoading }] =
    useMutation(CREATE_CONTACT)

  const [showModal, setShowModal] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)

  const [search, setSearch] = useState('')
  const [showSearchError, setShowSearchError] = useState(false)

  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const {
    contactSuggestions,
    updateContactSuggestions,
    loading: suggestionsLoading,
  } = useContactSuggestions(search)

  const ccCountryCode = staffData?.staff?.find(
    (staff) => staff?.Role === '3',
  )?.CountryCode

  const isValidPhone = (searchTerm) => {
    return commandCenterPhoneValidator(searchTerm || search, ccCountryCode).isValid()
  }

  const createContact = async ({ email, phone }) => {
    if (!email && !phone) return

    const contact = {
      given_name: '', // first name
      middle_name: '', // middle name
      surname: '', // last name
      // picture_url: '', // ONLY PASS IF WE HAVE
      im_addresses: [],
    }

    if (email) contact.emails = [{ type: '', email: email }]
    if (phone) {
      const formattedPhone = commandCenterPhoneValidator(
        search,
        ccCountryCode,
      ).number?.slice(1)

      contact.phone_numbers = [{ type: '', number: formattedPhone }]
    }

    const jsonedContact = JSON.stringify(contact)

    try {
      const { data: contactData } = await postContact({
        variables: {
          body: jsonedContact,
        },
        onCompleted: () => {
          getThreads()
        },
      })

      return contactData.contact
    } catch (err) {
      //
    }
  }

  const createRecipient = ({ contactsk1, email, phone, fullName }) => {
    if (!fullName && !email && !phone) return

    const recipient = {
      id: contactsk1,
      imgUrl: '',
    }

    if (fullName) recipient.fullName = fullName
    if (email) recipient.email = email
    if (phone) recipient.phoneNumber = phone

    dispatch(setRecipient(recipient))
  }

  const openConversation = (threadId, recipientName) => {
    dispatch(handleStartNewMessage(false))
    dispatch(
      setSelectedThread({
        id: threadId,
        name: recipientName,
      }),
    )
  }

  const handleModalSubmit = async () => {
    if (isValidEmail(search)) {
      const contactData = await createContact({ email: search })
      createRecipient({
        threadId: contactData.contactsk1,
        email: contactData.contactsk2,
        fullName: contactData.contactsk2,
      })
      openConversation(contactData.contactsk1, contactData.contactsk2)
      return
    }

    if (isValidPhone(search)) {
      const contactData = await createContact({ phone: search })
      createRecipient({
        threadId: contactData.contactsk1,
        phone: contactData.contactsk3,
        fullName: contactData.contactsk3,
      })
      openConversation(contactData.contactsk1, contactData.contactsk3)
    }
  }

  const handleSearchSubmit = async (e) => {
    e.preventDefault()
    let searchTerm = search

    const isExistingContactName = () => {
      if (contactSuggestions.length) {
        const contactsFullName = contactSuggestions[0]?.full_name
          ?.trim()
          .toLowerCase()

        if (contactsFullName === search.toLowerCase()) return true
      }
      return false
    }

    if (isExistingContactName()) {
      const contact = contactSuggestions[0]

      createRecipient({
        threadId: contact.contactsk1,
        email: contact.contactsk2,
        phone: contact.contactsk3,
        fullName: contact.full_name,
      })
      openConversation(contact.contactsk1, contact.full_name)
      return
    }

    if (!isValidEmail(searchTerm) && !isValidPhone(searchTerm)) {
      const isNum = /^\d+$/.test(searchTerm)

      if (isNum) {
        dispatch(
          updateInboxNotif({
            show: true,
            name: 'contactCreateNotifError',
            customText: `Please enter a valid phone number originating from ${countries[ccCountryCode]}.`,
          }),
        )
      } else {
        dispatch(
          updateInboxNotif({
            show: true,
            name: 'contactCreateNotifError',
            customText:
              'Please enter a valid email, phone number, or contact name to start a conversation.',
          }),
        )
      }

      setShowSearchError(true)
      return
    }

    if (isValidEmail(searchTerm)) {
      const contact = contactSuggestions[0]
      const contactEmail = contact?.contactsk2 || ''
      const newName = contact?.full_name || contactEmail

      if (contactEmail.toLowerCase() === searchTerm.toLowerCase()) {
        createRecipient({
          threadId: contact.contactsk1,
          email: contactEmail,
          fullName: newName,
        })
        openConversation(contact.contactsk1, newName)
        return
      } else {
        setShowModal(true)
        return
      }
    }

    if (isValidPhone(searchTerm)) {
      const contact = contactSuggestions[0]
      const contactPhone = contact?.contactsk3 || ''
      const newName = contact?.full_name || contactPhone

      if (contactPhone === search) {
        createRecipient({
          threadId: contact.contactsk1,
          phone: contactPhone,
          fullName: newName,
        })
        openConversation(contact.contactsk1, newName)
      } else {
        setShowModal(true)
        return
      }
    }
  }

  const handleSearchChange = (e) => {
    updateContactSuggestions(e.target.value)
    setShowSearchError(false)
  }

  const handleDropdownContactClick = (user) => {
    const clickedUser = user.originalUser
    const newName =
      clickedUser.full_name || clickedUser.contactsk2 || clickedUser.contactsk3

    createRecipient({
      threadId: clickedUser.contactsk1,
      phone: search,
      fullName: newName,
    })
    openConversation(clickedUser.contactsk1, newName)
  }

  const handleSearchKeyDown = (e) => {
    const { key } = e

    const keyClickHandlers = {
      tab: () => {
        e.preventDefault()
        handleSearchSubmit(e)
      },
    }

    switch (key) {
      case 'Enter':
        return
      case 'Tab':
        keyClickHandlers.tab()
        break
      default:
        break
    }
  }

  return (
    <>
      <CreateNewConversationModal
        show={showModal}
        setShow={setShowModal}
        value={
          isValidPhone()
            ? formatContactData(
                commandCenterPhoneValidator(search, ccCountryCode).number.slice(1),
              )
            : search
        }
        onSubmit={handleModalSubmit}
      />

      <div className="relative flex items-center w-full h-[35px]">
        {contactCreationLoading ? (
          <div>
            <LoadingSpinner widthAndHeight={28} className={'pt-2'} />
          </div>
        ) : (
          <>
            <ParagraphText variant="reg" color="thryv-night" className="mr-2">
              To:
            </ParagraphText>

            <UserSearchBar
              ref={searchBarRef}
              showError={showSearchError}
              search={search}
              setSearch={setSearch}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => setShowDropdown(false)}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
              onSubmit={handleSearchSubmit}
            />

            <UsersDropdown
              className="absolute bottom-0 left-2.5 w-[500px]"
              users={contactSuggestions?.map((user) => {
                return {
                  id: user.sk1,
                  imgUrl: '', // FIXME: NOT AVAILABLE IN CLOUDSEARCH
                  name: user.full_name,
                  email: user.contactsk2?.toLowerCase() || '',
                  phoneNumber: user.contactsk3 || '',
                  originalUser: user,
                  isThryvSupport:
                    user.contactsk2?.toLowerCase() === 'support@thryv.com',
                }
              })}
              setSelectedUser={handleDropdownContactClick}
              show={
                !suggestionsLoading && showDropdown && contactSuggestions.length > 0
              }
              searchTerm={search}
            />
          </>
        )}
      </div>
    </>
  )
}
