export const convertAlphaToInputValue = (alphaCode) => {
  if (alphaCode === 'US') {
    return '🇺🇸   +1'
  } else if (alphaCode === 'CA') {
    return '🇨🇦   +1'
  } else if (alphaCode === 'AU') {
    return '🇦🇺   +61'
  } else if (alphaCode === 'NZ') {
    return '🇳🇿   +64'
  }
}

export const convertAlphaToNumeric = (alphaCode) => {
  if (alphaCode === 'US') {
    return '1'
  } else if (alphaCode === 'CA') {
    return '1'
  } else if (alphaCode === 'AU') {
    return '61'
  } else if (alphaCode === 'NZ') {
    return '64'
  }
}

export const getRole = (role) => {
  switch (role) {
    case '1':
      return 'Admin'
    case '2':
      return 'Staff'
    case '3':
      return 'Owner'
  }
  return 'Staff'
}

export const convertAlphaToLong = (alphaCode) => {
  switch (alphaCode) {
    case 'US':
      return 'United States'
    case 'CA':
      return 'Canada'
    case 'NZ':
      return 'New Zealand'
    case 'AU':
      return '+61'
  }
  return ''
}

export const getLocalPhone = (countryCode, phone) => {
  return phone?.slice(-10) || ''
}

export const getZip = {
  pattern: (countryCode) => {
    switch (countryCode) {
      case 'US':
        return /(^\d{5}$)|(^\d{5}-\d{4}$)/
      case 'CA':
        return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][- ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
      case 'AU':
        return /^[0-9]+$/
      case 'NZ':
        return /^[0-9]+$/
    }
    return /^[a-zA-Z0-9-\s]+$/
  },
  max: (countryCode) => {
    switch (countryCode) {
      case 'US':
        return 10
      case 'CA':
        return 7
      case 'AU':
        return 4
      case 'NZ':
        return 4
    }
    return 10
  },
  min: (countryCode) => {
    switch (countryCode) {
      case 'US':
        return 5
      case 'CA':
        return 3
      case 'AU':
        return 4
      case 'NZ':
        return 4
    }
    return 3
  },
}
