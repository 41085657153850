import { useQuery } from '@apollo/client'
import { Button, Header } from '@thryvlabs/maverick'
import { ParagraphText as Text } from '@thryvlabs/maverick'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import RecordVoiceModal from '../../../components/calls/recent-and-voicemail/recent-and-voicemail-content/record-voicemail/recordVoicemailModal/recordVoiceModal'
import CustomModal from '../../../components/common/CustomModal/Modal'
import { GET_VOICEMAIL_AUDIO } from '../../../graphql/queries/calls-queries'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../../../graphql'
import { setVoicemailMetaData } from '../../../redux-toolkit/slices/calls'
import { secondsToTime } from '../../../utils/voicemail'
import { useMediaQuery } from 'react-responsive'
import { useAuth0 } from '@auth0/auth0-react'
import {
  setModalContent,
  setShowModal,
} from '../../../components/common/modals/slices/connectChannelsSlice'

export const VoicemailSettings = () => {
  const dispatch = useDispatch()
  const [openRecordNew, setOpenRecordNew] = useState(false)
  const [hasPhoneChannel, setHasPhoneChannel] = useState(false)
  const { phoneNumber } = useSelector((state) => state.contacts.contacts)
  const voicemailMetaData = useSelector((state) => state.contacts.voicemailMetaData)
  const isMobile = useMediaQuery({ maxWidth: 640 })
  const { user } = useAuth0()
  const { data } = useQuery(GET_VOICEMAIL_AUDIO, {
    variables: { phone_number: phoneNumber },
    onCompleted: () => {
      const seconds = data?.queryVoicemailGreetingMetaData?.length
      const name = data?.queryVoicemailGreetingMetaData?.name
      const metaData = {
        length: secondsToTime(seconds),
        name: name,
      }
      dispatch(setVoicemailMetaData(metaData))
    },
  })

  const { data: channelData } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: {
      loc_id: user.businessId,
      user_id: user.cc_uid,
    },
    onCompleted: () => {
      const phoneChannel = channelData.queryConnectedChannelsByUser.filter(
        (chan) => chan.Provider === 'phone',
      )
      if (phoneChannel.length > 0) setHasPhoneChannel(true)
    },
  })

  const openConnectModal = () => {
    dispatch(setShowModal(true))
    dispatch(setModalContent(7))
  }

  useEffect(() => {
    if (phoneNumber) {
      setHasPhoneChannel(true)
    }
  }, [phoneNumber])

  return (
    <div className="px-[24px] grid grid-row mt-[1%] h-[114px] sm:h-[150px] bg-[white] rounded border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A]">
      <div className="flex flex-row pt-[24px] h-[118px] justify-between">
        <div>
          <Header fontWeight="semibold" variant="h6">
            Voicemail
          </Header>
          {!hasPhoneChannel ? (
            <Text
              variant="reg"
              className="pt-[21px] sm:max-w-[250px] flex flex-wrap"
            >
              <span
                onClick={openConnectModal}
                className="text-utility/text-link-info font-bold cursor-pointer"
              >
                Get a phone number
              </span>{' '}
              <span className="text-[#808080]">
                in order to record a custom voicemail.
              </span>
            </Text>
          ) : voicemailMetaData ? (
            <div className="flex gap-6 sm:gap-2">
              <Text
                variant="reg"
                className="pt-[21px] truncate max-w-[300px] sm:max-w-[100px] !text-[#16A085] !font-semibold"
              >
                {voicemailMetaData.name}
              </Text>
              <Text
                variant="reg"
                className="pt-[21px] !text-[#16A085] !font-semibold"
              >
                {voicemailMetaData.length}
              </Text>
            </div>
          ) : (
            <span className="text-[#808080] font-open-sans font-normal">
              Record a custom voicemail.
            </span>
          )}
        </div>
        <div className="pt-[40px] mr-[-5px]">
          <Button
            disabled={!hasPhoneChannel}
            variant="secondary"
            className="flex px-2 py-3px"
            onClick={() => setOpenRecordNew(true)}
          >
            <span className="my-auto font-open-sans text-[#4D4D4D] font-semibold text-[13px] sm:text-[10px] leading-[14px]">
              Record a new greeting
            </span>
          </Button>
        </div>
      </div>

      <CustomModal
        isOpen={openRecordNew}
        showTitle={true}
        close={() => setOpenRecordNew(false)}
        heading="Record a new greeting"
        width={isMobile ? '325px' : '616px'}
        hasCrossIcon={true}
      >
        <RecordVoiceModal setOpenRecordNew={setOpenRecordNew} />
      </CustomModal>
    </div>
  )
}
