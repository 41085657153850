import { useApolloClient } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { GET_S3_DOWNLOAD_LINK } from '../../../../graphql'
import {
  handleClickAttachment,
  handleShowAttachmentPreview,
} from '../../../inbox/slices/messageComposeSlice'
import JSZip from 'jszip'
import saveAs from 'save-as'
import { useCallback } from 'react'

const useHandleImageClick = ({ imageProcessing, setImageProcessing }) => {
  const dispatch = useDispatch()
  const client = useApolloClient()

  // Function to handle image click
  const handleClickImage = useCallback(
    async (attachment, index) => {
      const updatedImageProcessing = [...imageProcessing]
      updatedImageProcessing[index] = true
      setImageProcessing(updatedImageProcessing)

      try {
        const formattedLink = await handleAttachmentLinkDownload(attachment)
        if (formattedLink) {
          dispatch(handleClickAttachment(formattedLink))
          dispatch(handleShowAttachmentPreview(true))
        }
      } finally {
        setImageProcessing((prevState) => {
          const newState = [...prevState]
          newState[index] = false
          return newState
        })
      }
    },
    [imageProcessing],
  )

  // Function to download attachment link
  const handleAttachmentLinkDownload = useCallback(async (attachment) => {
    if (attachment && attachment.pk1 && attachment.sk1) {
      const { pk1, sk1 } = attachment
      const finalString = `${pk1
        .split('#')
        .slice(0, -1)
        .join('/')
        .replace(/#/g, '/')}/${sk1}`

      try {
        // Use GET_S3_DOWNLOAD_LINK for full-res image
        const { data } = await client.query({
          query: GET_S3_DOWNLOAD_LINK,
          variables: {
            filename: finalString,
          },
          fetchPolicy: 'no-cache',
        })
        const downloadLink = data.getSafeS3DownloadURL

        const formattedUrl = downloadLink.substring(
          downloadLink.indexOf('=') + 1,
          downloadLink.length - 1,
        )
        return formattedUrl
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching download link:', error.message)
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('Attachment or its properties are undefined')
    }
  }, [])

  const handleAttachmentDownloadBulk = useCallback(
    async (attachments, zipFilename) => {
      const zip = new JSZip()

      for (let attachment of attachments) {
        const filename = JSON.parse(attachment.body).filename
        const formattedLink = await handleAttachmentLinkDownload(attachment)

        try {
          const file = await fetch(formattedLink)
            .then((resp) => resp.blob())
            .then((blob) => {
              return blob
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error('An error occurred while downloading the file:', error)
            })
          zip.file(filename, file)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }

      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `${zipFilename}.zip`)
      })
    },
    [],
  )

  // Function to initiate attachment download
  const handlePhotoAttachmentDownload = useCallback(
    async (attachment, index) => {
      const updatedImageProcessing = [...imageProcessing]
      updatedImageProcessing[index] = true
      setImageProcessing(updatedImageProcessing)

      try {
        const formattedLink = await handleAttachmentLinkDownload(attachment)
        if (formattedLink) {
          await fetch(formattedLink)
            .then((resp) => resp.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = JSON.parse(attachment.body).filename
              document.body.appendChild(a)
              a.click()
              window.URL.revokeObjectURL(url)
              return blob
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error('An error occurred while downloading the file:', error)
            })
        }
      } finally {
        setImageProcessing((prevState) => {
          const newState = [...prevState]
          newState[index] = false
          return newState
        })
      }
    },
    [imageProcessing],
  )

  return {
    handleAttachmentLinkDownload,
    handleClickImage,
    handlePhotoAttachmentDownload,
    handleAttachmentDownloadBulk,
  }
}

export default useHandleImageClick
