import { useRedux } from '../hooks'
import { useEffect, useState, useRef } from 'react'
import VonageClient from '@vonage/client-sdk'
import {
  initVonageClient,
  clearVonageClient,
} from '../components/calls/slices/callSlice'
import { generateJwtToken } from './vonageAPI'
import { useAuth0 } from '@auth0/auth0-react'
import { useMediaQuery } from 'react-responsive'

export const useVonageClient = () => {
  const [dispatch, useSelector] = useRedux()
  const [isConnecting, setIsConnecting] = useState(false)
  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const { countryIso2 } = useSelector((state) => state.countryCode)
  const {
    onOutgoingCall,
    vonageClient: { isInit, session_id },
    vonageClient,
  } = useSelector((state) => state.calls)
  const {
    contacts: { phoneNumber, phoneChannelID },
  } = useSelector((state) => state.contacts)
  const {
    desktop: desktopNotifications,
    mobile: mobileNotifications,
    doNotDisturb,
  } = useSelector((state) => state.userPreferences.notificationPreferences)
  const incomingWeb = desktopNotifications[0].value
  const incomingMobile = mobileNotifications[0].value
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const { getAccessTokenSilently } = useAuth0()
  const prevValueRef = useRef()

  const connectVonageClient = async () => {
    if (isInit && isConnecting && session_id) return null

    setIsConnecting(true)
    const token = await getAccessTokenSilently()
    const jwt = await generateJwtToken(countryIso2, phoneChannelID, token)
    const jwt2 = await generateJwtToken(countryIso2, null, token)

    // config data center
    const dcObj = { US: 'us-3', CA: 'us-3', NZ: 'us-3', AU: 'us-3' }
    const config = {
      api_url: `https://api-${dcObj[countryIso2]}.vonage.com`,
    }

    // create vonage client
    const client = new VonageClient(config)
    let res = await client.createSession(jwt).catch(() => {
      // console.log(`❌session error: ${err}`)
    })
    dispatch(
      initVonageClient({
        app: client,
        isInit: true,
        session_id: res,
        jwt_sub: jwt,
        jwt_no_sub: jwt2,
      }),
    )
    setIsConnecting(false)
  }

  const disconnectVonageClient = async () => {
    if (!isInit && isDisconnecting && !session_id) return null
    setIsDisconnecting(true)
    // await deleteSession(session_id, jwt_sub)
    await vonageClient.app.deleteSession()
    dispatch(clearVonageClient())
    setIsDisconnecting(false)
  }

  useEffect(() => {
    // prevent doNotDisturb updated multiple time but with the same value by using useRef
    if (prevValueRef.current !== doNotDisturb) {
      prevValueRef.current = doNotDisturb
    }
  }, [doNotDisturb])

  // based on dnd and whether users are on outgoing call, toggle clientSDK status
  useEffect(() => {
    // phoneNumber exist, mean they have phone channel
    if (phoneNumber && phoneChannelID && typeof prevValueRef.current === 'boolean') {
      // if dnd is on, close the session
      // also checking if user is on outgoing call
      if (
        doNotDisturb ||
        (!isMobile && !incomingWeb) ||
        (isMobile && !incomingMobile)
      ) {
        if (!onOutgoingCall) {
          if (isInit && !isDisconnecting && session_id) disconnectVonageClient()
        } else {
          if (!isInit && !isConnecting && !session_id) connectVonageClient()
        }
      } else if (!isInit && !isConnecting && !session_id) {
        connectVonageClient()
      }
    }
  }, [
    onOutgoingCall,
    phoneNumber,
    countryIso2,
    phoneChannelID,
    isInit,
    session_id,
    prevValueRef.current,
  ])
  return [vonageClient, disconnectVonageClient]
}
