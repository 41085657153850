import { useState, forwardRef, useEffect } from 'react'
import { Icon } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../command-center-icon'
import { TooltipWithCenteredArrow } from '../../../common/tooltip-with-centered-arrow'

/*
###########################
##     EXAMPLE USAGE     ##
###########################

<RoundedActionButton
  className="!w-[30px] !h-[30px]"
  type="regular"
  icon={{
    variant: 'fileImport',
    type: 'regular',
    width: '20',
    height: '15',
    color: '#808080',
    hoverColor: '#057AFF',
  }}
  isMavIcon
  tooltipTitle="Go to message"
/>

*/

const validate = {
  icon: (isCCIcon, isMavIcon) => {
    if (
      (isCCIcon === undefined && isMavIcon === undefined) ||
      (isCCIcon === false && isMavIcon === false)
    ) {
      throw new Error(
        "Please specify if you want to use Maverick's 'Icon' component or Command Center's 'CommandCenterIcon' component by using either 'isMavIcon' or 'isCCIcon' as a prop to this component.",
      )
    } else if (isCCIcon === true && isMavIcon === true) {
      throw new Error("Please only use one: 'isCCIcon' or 'isMavIcon'.")
    }
  },
}

const RoundedActionButton = forwardRef(
  (
    {
      dataTestId,
      className,
      icon = {
        variant: '',
        type: '',
        width: '',
        height: '',
        color: '',
        hoverColor: '',
      },
      active,
      disable,
      onClick,
      tooltipTitle,
      isCCIcon,
      isMavIcon,
    },
    ref,
  ) => {
    validate.icon(isCCIcon, isMavIcon)

    const [isHovering, setIsHovering] = useState(false)
    const [iconColor, setIconColor] = useState(icon.color || '#92929D')

    const initIconColor = () => {
      if (disable) {
        setIconColor('#CCCCCC')
        return
      }

      const hoverColor = icon.hoverColor ? icon.hoverColor : '#808080'
      const defaultColor = icon.color ? icon.color : '#92929D'
      isHovering ? setIconColor(hoverColor) : setIconColor(defaultColor)
    }

    useEffect(() => {
      initIconColor()
    }, [isHovering, disable, active, icon])

    const renderCommandCenterIcon = (
      <CommandCenterIcon
        type={icon.type}
        variant={icon.variant}
        color={iconColor}
        width={icon.width || '16'}
        height={icon.height || '16'}
      />
    )

    const renderMaverickIcon = (
      <Icon
        type={icon.type}
        variant={icon.variant}
        color={iconColor}
        width={icon.width || '16'}
        height={icon.height || '16'}
      />
    )

    return (
      <TooltipWithCenteredArrow
        className={(disable || tooltipTitle === undefined) && 'hidden'}
        positioning={{
          offsetY: '-9px',
        }}
        variant="top"
        width="max-content"
        title={tooltipTitle}
        disableVisibleOnClick
      >
        <button
          data-testid={dataTestId}
          type="button"
          className={`
          flex justify-center items-center h-8 w-8 hover:bg-thryv-white-200 rounded-full
          ${className}
          ${disable && 'pointer-events-none'}
          ${active && 'bg-thryv-white-200'}`}
          onClick={onClick}
          ref={ref}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {isCCIcon && renderCommandCenterIcon}
          {isMavIcon && renderMaverickIcon}
        </button>
      </TooltipWithCenteredArrow>
    )
  },
)

export default RoundedActionButton
