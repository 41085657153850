import { useRef, useState, useLayoutEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faAnglesLeft,
  faAnglesRight,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons'
import Marketplace from './marketplace-section/Marketplace'
import { StyledAccordion } from './LeftNavBar.styles'
import SupportCenterAndSettings from './support-center-and-settings-section/SupportCenterAndSettings'
import MenuSection from './menu-section/MenuSection'
import { Droppable } from 'react-beautiful-dnd'
import { useEffect } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import CustomModal from '../common/CustomModal/Modal'
import { AddOtherApps } from '../AddOther-Apps/AddOtherApps'
import { useSelector, useDispatch } from 'react-redux'
import {
  setNavigationPreferences,
  setLeftNavigationCollapsed,
} from '../../redux-toolkit/slices/user-preferences/user-preferences'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'
import IncomingCallsWidget from './incoming-calls-widget/IncomingCallsWidget'
import { useAuth0 } from '@auth0/auth0-react'
import { UPDATE_USER_PREFERENCES } from '../../graphql/mutations/user-preferences-mutation'
import { useMutation } from '@apollo/client'
import { ThryvCommandCenterIcon } from '../command-center-icon/icons'

const LeftNavBar = () => {
  /* Local Component State */
  const [isEditing, setIsEditing] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [sectionIsEditing, setSectionIsEditing] = useState('')
  const [sectionOrder, setSectionOrder] = useState({})
  const [openEditMenuId, setOpenEditMenuId] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)
  const [draggableIdState, setDraggableIdState] = useState('')
  const [destination, setDestination] = useState()
  const [source, setSource] = useState()
  const [allowAnimate, setAllowAnimate] = useState(true)
  const [outOfRangeNum, setOutOfRangeNum] = useState(0)
  const [lastCheckedIndex, setLastCheckedIndex] = useState(0)
  const [editedId, setEditedId] = useState('')
  const [mobileSectionsOpen, setMobileSectionsOpen] = useState([])
  const [, setOtherAppAdded] = useState(false)
  const [disableIncomingCalMobileOrWeb, setDisableIncomingCalMobileOrWeb] =
    useState(true)
  const animateDragId = (draggableId) => {
    setDraggableIdState(draggableId)
  }

  /* Redux State */
  const sideNavData = useSelector(
    (state) => state.userPreferences.navigationPreferences,
  )
  const collapsed = useSelector((state) => state.userPreferences.navigationCollapsed)
  const { notificationPreferences } = useSelector((state) => state.userPreferences)
  // const { doNotDisturb } = useSelector(
  //   (state) => state.userPreferences.notificationPreferences,
  // )
  const { incomingCallMobile, incomingCallWeb, vonageClient } = useSelector(
    (state) => state.calls,
  )

  const {
    contacts: { connection },
  } = useSelector((state) => state.contacts)
  const dispatch = useDispatch()
  // const [detectIncomingCalls, setDetectIncomingCalls] = useState(false)

  /* Refs */
  const accordionRef = useRef(null)
  const scrollRef = useRef(null)
  const collapseRef = useRef(false)
  const wrapperRef = useRef(null)
  const editMenuWrapperRef = useRef(null)
  const editMenuRef = useRef(null)
  const centerEditingRef = useRef(null)

  /* Other Variables */
  const location = useLocation()
  const isMobile = useMediaQuery({ maxWidth: 835 })
  // const isMinimumScreenSize = useMediaQuery({ maxHeight: 1034 })
  const { user } = useAuth0()
  const navigate = useNavigate()

  const [updateUserPreferences, { loading }] = useMutation(UPDATE_USER_PREFERENCES)

  /* Sets section order on load */
  useEffect(() => {
    sideNavData.data.forEach((center) => {
      sectionOrder[center.id] = center.order
    })
    setSectionOrder(sectionOrder)
    setIsLoaded(true)
  }, [sideNavData])

  useEffect(() => {
    collapseRef.current = collapsed
  }, [collapsed])

  useEffect(() => {
    if (isMobile && incomingCallMobile) {
      setDisableIncomingCalMobileOrWeb(true)
    } else if (!isMobile && incomingCallWeb) {
      setDisableIncomingCalMobileOrWeb(true)
    } else {
      setDisableIncomingCalMobileOrWeb(false)
    }
  }, [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        editMenuWrapperRef.current &&
        !editMenuWrapperRef.current.contains(event.target)
      ) {
        setOpenEditMenuId(-1)
        setSectionIsEditing('')
        setEditedId('')
        setOutOfRangeNum(0)
        setIsEditing(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useLayoutEffect(() => {
    if (openEditMenuId > -1) {
      dispatch(setLeftNavigationCollapsed(false))
    }
  }, [collapsed])

  useEffect(() => {
    accordionRef.current = mobileSectionsOpen
  }, [mobileSectionsOpen])

  /* Handles Edit Menu Popout from Left Navigation Bar*/
  useEffect(() => {
    if (isEditing) {
      let editMenu = editMenuRef.current
      let editMenuWrapper = editMenuWrapperRef.current
      let centerEditing = centerEditingRef.current
      scrollRef.current.addEventListener('scroll', function () {
        let { y } = centerEditing.getBoundingClientRect()
        editMenuWrapper.style.top = y - 6 + 'px'
      })
      let { y } = centerEditing.getBoundingClientRect()
      editMenuWrapper.style.top = y - 6 + 'px'
      editMenuWrapper.append(editMenu)
    }
  }, [isEditing])

  /* Handlers And Other Functions */

  /* Handles reordering when drag event ends */
  const onDragEnd = (result) => {
    setEditedId('')
    /* Handles if destination is not in a droppable zone */
    const { destination, source, draggableId } = result
    if (!destination) {
      return
    }
    /*Handles if dragged item is dropped in original location */
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    /* Handles center reordering */
    if (result.type === 'CENTER') {
      const newCenterOrder = Array.from(sideNavData.order)
      newCenterOrder.splice(source.index, 1)
      newCenterOrder.splice(destination.index, 0, draggableId)
      dispatch(setNavigationPreferences({ ...sideNavData, order: newCenterOrder }))
      const updatedConfig = {
        navigationCollapsed: collapsed,
        notificationPreferences,
        navigationPreferences: {
          data: sideNavData.data,
          order: newCenterOrder,
        },
      }
      updateUserPreferences({
        variables: {
          userId: user.email,
          preferences: JSON.stringify({
            time_stamp: Date.now(),
            data: updatedConfig,
          }),
        },
      })
    } else if (result.type === 'SECTION') {
      const newSectionOrder = Array.from(sectionOrder[sectionIsEditing])
      newSectionOrder.splice(source.index, 1)
      newSectionOrder.splice(destination.index, 0, draggableId)
      //Reset state here
      const newOrder = sideNavData.data.map((item) => {
        if (item.id === sectionIsEditing) {
          let itemData = {
            title: item.title,
            id: item.id,
            order: newSectionOrder,
            items: item.items,
          }
          if (item?.link) itemData.link = item.link
          return itemData
        } else {
          return item
        }
      })
      const newSideNavOrder = {
        order: sideNavData.order,
        data: newOrder,
      }
      setSectionOrder({ ...sectionOrder, [sectionIsEditing]: newSectionOrder })
      dispatch(setNavigationPreferences(newSideNavOrder))

      animateDragId(draggableId)
      draggableId ? setAllowAnimate(true) : null

      setDestination(destination)
      setSource(source)
      setOutOfRangeNum(0)
      //
      const updatedConfig = {
        navigationCollapsed: collapsed,
        notificationPreferences,
        navigationPreferences: newSideNavOrder,
      }
      updateUserPreferences({
        variables: {
          userId: user.email,
          preferences: JSON.stringify({
            time_stamp: Date.now(),
            data: updatedConfig,
          }),
        },
      })

      /* If an item is dragged "out of bounds" within edit menu */
      if (lastCheckedIndex < destination.index) {
        const lengthOfChecked = lastCheckedIndex + 1
        const amountOutOfRange = destination.index + 1 - lengthOfChecked
        /*If the item is still dragged the same "out of range" amount, it won't trigger a rerender because the state 
        is technically the same, this is a stopgap solution to force the component to play the animation*/
        if (outOfRangeNum === amountOutOfRange) {
          setOutOfRangeNum(amountOutOfRange + 0.1)
        } else {
          setOutOfRangeNum(amountOutOfRange)
        }
        setEditedId(draggableId)

        const newEditedOrder = Array.from(sectionOrder[sectionIsEditing])
        newEditedOrder.splice(source.index, 1)
        newEditedOrder.splice(lastCheckedIndex, 0, draggableId)
        const newOrder = sideNavData.data.map((item) => {
          if (item.id === sectionIsEditing) {
            let itemData = {
              title: item.title,
              id: item.id,
              order: newEditedOrder,
              items: item.items,
            }
            if (item?.link) itemData.link = item.link
            return itemData
          } else {
            return item
          }
        })
        const newSideNavOrder = {
          order: sideNavData.order,
          data: newOrder,
        }
        setSectionOrder({ ...sectionOrder, [sectionIsEditing]: newEditedOrder })
        dispatch(setNavigationPreferences(newSideNavOrder))
        const updatedConfig = {
          navigationCollapsed: collapsed,
          notificationPreferences,
          navigationPreferences: newSideNavOrder,
        }
        updateUserPreferences({
          variables: {
            userId: user.email,
            preferences: JSON.stringify({
              time_stamp: Date.now(),
              data: updatedConfig,
            }),
          },
        })
        return
      }
    }
  }

  const handleCollapse = () => {
    dispatch(setLeftNavigationCollapsed(!collapsed))
    const updatedConfig = {
      navigationCollapsed: !collapsed,
      notificationPreferences,
      navigationPreferences: sideNavData,
    }
    updateUserPreferences({
      variables: {
        userId: user.email,
        preferences: JSON.stringify({
          time_stamp: Date.now(),
          data: updatedConfig,
        }),
      },
    })
  }

  const closeModal = () => {
    setIsOpen(!isOpen)
  }

  const getNewAppId = () => {
    let lastId = 'section-26'
    sideNavData.data.forEach((section) => {
      if (section.title === 'Custom Links' && section.order.length) {
        let id = [...section.order].slice(-1)
        let incrementedId = Number(id[0].split('-')[1]) + 1
        lastId = incrementedId
      }
    })
    return lastId
  }

  const getCustomLinksIndex = () => {
    let index = 7
    sideNavData.data.forEach((section, i) => {
      if (section.title === 'Custom Links') {
        index = i
      }
    })
    return index
  }

  const otherAppsAdded = (newApp) => {
    const newAppId = getNewAppId()
    const customLinksIndex = getCustomLinksIndex()

    let obj = {
      icon: newApp.link + '/favicon.ico',
      id: `section-${newAppId}`,
      link: newApp.link,
      title: newApp.nickname,
      visible: true,
      center: 'Custom Links',
    }
    let data = JSON.parse(JSON.stringify(sideNavData)).data
    data[customLinksIndex]['items'].push(obj)
    data[customLinksIndex]['order'].push(`section-${newAppId}`)
    dispatch(setNavigationPreferences({ ...sideNavData, data }))

    const updatedConfig = {
      navigationPreferences: { ...sideNavData, data },
      navigationCollapsed: collapsed,
      notificationPreferences,
    }
    updateUserPreferences({
      variables: {
        userId: user.email,
        preferences: JSON.stringify({
          time_stamp: Date.now(),
          data: updatedConfig,
        }),
      },
    })
    setOtherAppAdded(true)
    setIsOpen(false)
  }

  /* Sets The Accordion of Command Center Section to the correct position */
  useEffect(() => {
    if (isMobile) {
      sideNavData.data.map((center) => {
        if (center.title === 'Command Center') {
          setMobileSectionsOpen([...mobileSectionsOpen, 'Command Center'])
        }
      })
    }
  }, [sideNavData])

  /* Handles The Accordion Behavior of the Left Navigation in Mobile View*/
  const handleAccordionOpenClose = (event) => {
    if (
      event.target.innerText &&
      !Array.from(event.target.classList).includes('bXQYME')
    ) {
      const clickedSectionTitle = event.target.innerText
      if (clickedSectionTitle === 'Marketplace') {
        if (event.target.id.includes('marketplace')) {
          return
        }
      }
      if (mobileSectionsOpen.includes(clickedSectionTitle)) {
        let updatedMobileSectionsOpen = mobileSectionsOpen.filter((a) => {
          return a !== clickedSectionTitle
        })
        setMobileSectionsOpen(updatedMobileSectionsOpen)
      } else {
        setMobileSectionsOpen([...mobileSectionsOpen, clickedSectionTitle])
      }
    }
  }

  useEffect(() => {
    accordionRef.current = mobileSectionsOpen
  }, [mobileSectionsOpen])

  return (
    <div
      className={`h-full border-[#F0F0F0] border-t-[1px] sm:border-t-0 bg-white`}
      ref={wrapperRef}
      data-testid="left-nav"
    >
      {isLoaded ? (
        <div className={'h-full flex flex-col'} id="left-nav-bar">
          <DragDropContext onDragEnd={onDragEnd}>
            <div
              ref={scrollRef}
              className={`h-full z-10 flex flex-col relative border-[#F0F0F0] text-thryv-steel overflow-y-auto overflow-x-hidden`}
            >
              <Droppable droppableId={'1'} type={'CENTER'}>
                {(provided) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`${
                        collapsed
                          ? 'w-[52px] pl-1'
                          : 'w-[244px] md:w-[288px] pl-[3px]'
                      } rounded-[1px] border-[#E3E6E8] flex flex-col`}
                    >
                      <div className="flex pl-[50px] h-[76px] items-center md:hidden">
                        <div
                          data-testid="command-center-icon"
                          className="cursor-pointer"
                          onClick={() =>
                            location.pathname !== '/inbox' && navigate('/inbox')
                          }
                        >
                          {!collapsed && <ThryvCommandCenterIcon width={124} />}
                        </div>
                      </div>

                      <Marketplace
                        handleAccordionOpenClose={handleAccordionOpenClose}
                        mobileSectionsOpen={mobileSectionsOpen}
                        collapsed={isMobile ? false : collapsed}
                        sectionIsEditing={sectionIsEditing}
                        accordionRef={accordionRef.current[0]}
                      />

                      {sideNavData.order.map((id, index) => {
                        const navItem = sideNavData.data.find(
                          (item) => item.id === id,
                        )
                        const initPosition =
                          navItem.title === 'Command Center' ? true : false

                        return isMobile ? (
                          <div
                            key={id}
                            className="flex flex-row md:w-[280px] md:border-b-[2px] border-[#F0F0F0]"
                          >
                            <StyledAccordion
                              title={navItem.title}
                              defaultState={initPosition}
                              className={'z-20'}
                              onClick={handleAccordionOpenClose}
                            >
                              <MenuSection
                                isMobile={isMobile}
                                options={navItem}
                                sideNavData={sideNavData}
                                collapsed={false}
                                index={index}
                                id={id}
                                key={id}
                                setIsEditing={setIsEditing}
                                sectionIsEditing={sectionIsEditing}
                                setSectionIsEditing={setSectionIsEditing}
                                sectionsOrder={sectionOrder}
                                sectionOrder={sectionOrder[id]}
                                setSectionOrder={setSectionOrder}
                                draggableIdState={draggableIdState}
                                destination={destination}
                                source={source}
                                allowAnimate={allowAnimate}
                                setAllowAnimate={setAllowAnimate}
                                editedId={editedId}
                                setEditedId={setEditedId}
                                setLastCheckedIndex={setLastCheckedIndex}
                                lastCheckedIndex={lastCheckedIndex}
                                outOfRangeNum={outOfRangeNum}
                                openEditMenuId={openEditMenuId}
                                setOpenEditMenuId={setOpenEditMenuId}
                                setIsOpen={setIsOpen}
                                setOutOfRangeNum={setOutOfRangeNum}
                                isEditing={isEditing}
                              />
                            </StyledAccordion>
                            <FontAwesomeIcon
                              className={`
                                    ${
                                      mobileSectionsOpen.includes(navItem.title)
                                        ? 'animate-iconRotateOpen'
                                        : `${
                                            accordionRef.current[0] ===
                                              navItem.title &&
                                            'animate-iconRotateClosed'
                                          }`
                                    }
                                      absolute left-[240px] min-w-md:hidden text-center p-[15px] text-black`}
                              size="sm"
                              icon={faChevronDown}
                            />
                          </div>
                        ) : (
                          <MenuSection
                            isMobile={isMobile}
                            options={navItem}
                            allRefs={{ centerEditingRef, editMenuRef }}
                            sideNavData={sideNavData}
                            collapsed={collapsed}
                            index={index}
                            id={id}
                            key={id}
                            setIsEditing={setIsEditing}
                            sectionIsEditing={sectionIsEditing}
                            setSectionIsEditing={setSectionIsEditing}
                            sectionsOrder={sectionOrder}
                            sectionOrder={sectionOrder[id]}
                            setSectionOrder={setSectionOrder}
                            draggableIdState={draggableIdState}
                            destination={destination}
                            source={source}
                            allowAnimate={allowAnimate}
                            setAllowAnimate={setAllowAnimate}
                            editedId={editedId}
                            setEditedId={setEditedId}
                            setLastCheckedIndex={setLastCheckedIndex}
                            lastCheckedIndex={lastCheckedIndex}
                            outOfRangeNum={outOfRangeNum}
                            openEditMenuId={openEditMenuId}
                            setOpenEditMenuId={setOpenEditMenuId}
                            setIsOpen={setIsOpen}
                            setOutOfRangeNum={setOutOfRangeNum}
                            isEditing={isEditing}
                            isLoading={loading}
                          />
                        )
                      })}
                      {isMobile && (
                        <SupportCenterAndSettings
                          handleAccordionOpenClose={handleAccordionOpenClose}
                          mobileSectionsOpen={mobileSectionsOpen}
                          collapsed={false}
                          sectionIsEditing={sectionIsEditing}
                          accordionRef={accordionRef.current[0]}
                        />
                      )}
                      {provided.placeholder}
                    </div>
                  )
                }}
              </Droppable>
              <div className="group md:hidden">
                {collapsed ? (
                  <>
                    <FontAwesomeIcon
                      className="hover:cursor-pointer absolute top-0 ml-[15.5px] mt-[31px] group-hover:invisible"
                      size="lg"
                      icon={faBars}
                    />
                    <FontAwesomeIcon
                      className="hover:cursor-pointer absolute top-0 ml-[15.5px] mt-[31px] invisible group-hover:visible"
                      size="lg"
                      icon={faAnglesRight}
                      onClick={handleCollapse}
                    />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      className="hover:cursor-pointer absolute top-0 ml-[15.5px] mt-[31px] group-hover:invisible"
                      size="lg"
                      icon={faBars}
                    />
                    <FontAwesomeIcon
                      className="hover:cursor-pointer absolute top-0 ml-[15.5px] mt-[31px] invisible group-hover:visible"
                      size="lg"
                      icon={faAnglesLeft}
                      onClick={handleCollapse}
                    />
                  </>
                )}
              </div>
              <div className="sm:hidden flex grow items-end py-2">
                <SupportCenterAndSettings
                  handleAccordionOpenClose={handleAccordionOpenClose}
                  mobileSectionsOpen={mobileSectionsOpen}
                  collapsed={collapsed}
                  sectionIsEditing={sectionIsEditing}
                  accordionRef={accordionRef.current[0]}
                />
              </div>
            </div>
            {isEditing && (
              <div
                ref={editMenuWrapperRef}
                id="edit-menu-wrapper"
                style={{ top: null }}
                className={`absolute h-fit w-fit z-20 ${
                  collapsed ? 'left-[55px]' : 'left-[247px]'
                } `}
              />
            )}
            {!['/calls', '/calls/voicemail'].includes(location.pathname) &&
              vonageClient.isInit &&
              connection &&
              disableIncomingCalMobileOrWeb && (
                <div
                  id="incoming-call-wrapper"
                  style={
                    collapsed
                      ? { bottom: '10px', left: '55px' }
                      : { bottom: '10px', left: '265px' }
                  }
                  className={`absolute z-100 h-fit w-fit z-20`}
                >
                  <IncomingCallsWidget collapsed={collapsed} isMobile={isMobile} />
                </div>
              )}
          </DragDropContext>
        </div>
      ) : (
        <div
          className={`${
            collapsed ? 'w-[52px] pl-[4px]' : 'w-[244px] md:w-[288px] pl-[3px]'
          } rounded-[1px] border-[#E3E6E8] flex flex-col overflow-y-hidden`}
        >
          <div className="flex pl-[50px] h-[76px] items-center md:hidden">
            <ThryvCommandCenterIcon width={124} />
          </div>
          <div className="overflow-y-hidden" key={Math.random()}>
            {Array(30)
              .fill(true)
              .map((_, i) => (
                <div className="py-1.5 flex justify-center " key={i}>
                  <div className="w-5/6 bg-thryv-gray-medium-200 h-3 rounded-md mt-2" />
                </div>
              ))}
          </div>
        </div>
      )}
      <CustomModal
        isModalForm={true}
        heading={'Add Custom Link'}
        subHeading={
          'Speed up your productivity by entering a nickname and hyperlink below to add custom links in your left navigation'
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={closeModal}
        width={'500px'}
        showTitle={true}
      >
        <AddOtherApps onSuccess={otherAppsAdded} handleClose={closeModal} />
      </CustomModal>
    </div>
  )
}

export default LeftNavBar
