import { useEffect, useState } from 'react'
import Voicemail from './Voicemail'
import DefaultScreen from '../../recent-and-voicemail-components/default-screens/DefaultScreenMain'
import { determineShowDefaultScreen } from '../../helpers/recentAndVoicemailHelpers'
import { useSelector } from 'react-redux'
import { PaginatedList } from '@thryvlabs/maverick'

const Voicemails = ({ setDisplayInfoCard, isMobile, setContactInfoView }) => {
  const [callsCheckable, setCallsCheckable] = useState(false)
  const [checked, setChecked] = useState([])
  const voicemails = useSelector((state) => state.calls.voicemails)
  // const voicemailList = JSON.parse(JSON.stringify(voicemails))
  const tabSelected = 'voicemail'
  const [voicemailsData, setVoicemailsData] = useState([])
  // const dispatch = useDispatch()

  // const checkboxHandlers = {
  //   /*Selects All Checkboxes*/
  //   selectAll: function () {
  //     const checkboxes = document.getElementsByName('calls-deletion-list')
  //     const allChecked = []
  //     checkboxes.forEach((checkbox) => {
  //       checkbox.checked = true
  //       allChecked.push(checkbox.value)
  //     })
  //     setChecked(allChecked)
  //   },
  //   /* Deletes All Selected Checkboxes */
  //   /* For Now Deleted Items Are Only Removed From State */
  //   deleteAllSelected: async function () {
  //     setChecked([])
  //     const checkboxes = document.querySelectorAll(
  //       'input[name=calls-deletion-list]:checked',
  //     )
  //     const removedVoicemails = []
  //     checkboxes.forEach((checkbox) => {
  //       removedVoicemails.push(checkbox.value)
  //     })
  //     // const visibleVoicemails = voicemailList.filter((voicemail) => {
  //     //   return voicemail.voicemailDeleted === false
  //     // })

  //     // const deletedVoicemailsRemoved = visibleVoicemails.map((voicemail) => {
  //     //   if (removedVoicemails.includes(voicemail.id)) {
  //     //     return { ...voicemail, voicemailDeleted: true }
  //     //   }
  //     //   return voicemail
  //     // })
  //     setCallsCheckable(false)
  //     dispatch(setVoicemails(deletedVoicemailsRemoved))
  //   },
  // }

  // need to improve this bc if the user goes to the calls page they dont have the channels loaded so it doesnt return anything

  // console.log(phoneChannels)

  const hasVoiceMail = voicemails?.length > 0

  useEffect(() => {
    let refactoredVoicemails = voicemails.filter((data) => {
      if (JSON.parse(data.body).link) {
        return data
      }
    })
    setVoicemailsData([...refactoredVoicemails])
  }, [voicemails])

  return (
    <>
      {determineShowDefaultScreen(tabSelected, voicemails) ? (
        <DefaultScreen tabSelected={tabSelected} isMobile={isMobile} />
      ) : (
        <>
          <div className="h-full flex flex-col min-w-md:pt-[16px] p-[20px] md:p-[0px] md:bg-white font-montserrat">
            {/* <div className="flex flex-row items-center min-w-md:pb-[25px] mr-[17px]">
              {!isMobile && !callsCheckable && (
                <div className="w-full">
                  <RecordGreetingButton hasGreeting={hasGreeting} />
                </div>
              )}
              {/* <div
                id="edit-recent-calls"
                className={`${callsCheckable ? 'w-full' : 'content-end'}`}
              >
                <CallsEditorBar
                  checked={checked}
                  setChecked={setChecked}
                  active={callsCheckable}
                  onClickHandlers={{
                    edit: () => setCallsCheckable(true),
                    selectAll: checkboxHandlers.selectAll,
                    delete: checkboxHandlers.deleteAllSelected,
                    done: () => setCallsCheckable(false),
                  }}
                />
              </div>
            </div> */}
            {hasVoiceMail && voicemailsData ? (
              <PaginatedList list={voicemailsData}>
                <Voicemail
                  setContactInfoView={setContactInfoView}
                  isMobile={isMobile}
                  callsCheckable={callsCheckable}
                  setCallsCheckable={setCallsCheckable}
                  setDisplayInfoCard={setDisplayInfoCard}
                  checked={checked}
                  setChecked={setChecked}
                />
              </PaginatedList>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default Voicemails
