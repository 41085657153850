import { Button, Icon } from '@thryvlabs/maverick'
import { Oval } from 'react-loader-spinner'
import SharedPhotoButtons from './SharedPhotoButtons'
import { useMediaQuery } from 'react-responsive'

export const AllSharedPhotos = ({
  allAttachments,
  groupedAttachments,
  handleDownloadAllPhotos,
  imagePreviewPhotos,
  imageProcessing,
  handleClickImage,
  handlePhotoAttachmentDownload,
  imageLoaded,
  useImageLoader,
}) => {
  useImageLoader()

  const isMobile = useMediaQuery({ maxWidth: 640 })
  const size = isMobile ? '85px' : '136px'

  return (
    <>
      {allAttachments.length > 0 ? (
        Object.entries(groupedAttachments).map((attachments, index) => {
          return (
            <div key={index} className="text-sm open-sans font-normal py-2">
              <div className="flex justify-between">
                {attachments[0]}
                {!isMobile && (
                  <Button
                    variant="text"
                    level={1}
                    textVariant="light"
                    onClick={() => handleDownloadAllPhotos(index)}
                  >
                    Download All
                  </Button>
                )}
                {isMobile && (
                  <Button
                    variant="text"
                    level={1}
                    onClick={() => handleDownloadAllPhotos(index)}
                  >
                    <Icon
                      className="mr-2 inline-block"
                      variant="download"
                      type="regular"
                      height="15px"
                      width="20px"
                      color="#057AFF"
                    />
                    Download All
                  </Button>
                )}
              </div>
              <>
                {/* Image Grid */}
                <div className="grid grid-cols-2 gap-4 mb-[10px] mt-[10px] ml-[5px] px-[-10px] sm:grid-cols-3">
                  {Object.values(groupedAttachments)[index].map(
                    (attachment, index) => {
                      const url = imagePreviewPhotos.get(attachment.body)
                      return (
                        <div
                          key={`${attachment.pk1}-${index}`}
                          className="group relative flex justify-center items-center bg-thryv-gray-light-200 border border-thryv-gray-light-300 rounded-lg"
                          style={{ width: size, height: size, padding: '5px' }}
                        >
                          {!isMobile && (
                            <>
                              <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-40 transition-opacity duration-200 z-10"></div>
                              <div className="absolute inset-0 flex justify-center items-center p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-30">
                                {imageProcessing[index] ? (
                                  <Oval
                                    height={25}
                                    width={25}
                                    color="#FF5000"
                                    visible={true}
                                    ariaLabel="oval-loading"
                                  />
                                ) : (
                                  <SharedPhotoButtons
                                    handleClickImage={handleClickImage}
                                    handlePhotoAttachmentDownload={
                                      handlePhotoAttachmentDownload
                                    }
                                    attachment={attachment}
                                    index={index}
                                  />
                                )}
                              </div>
                            </>
                          )}
                          <div className="relative max-w-full max-h-full overflow-hidden z-0">
                            {imageLoaded[index] ? (
                              <img
                                src={url}
                                alt={`Image ${index + 1}`}
                                className="object-contain w-full h-full"
                                onClick={() => handleClickImage(index)}
                              />
                            ) : (
                              <Oval
                                height={25}
                                width={25}
                                color="#FF5000"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#E9E9E9"
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                              />
                            )}
                          </div>
                        </div>
                      )
                    },
                  )}
                </div>
              </>
            </div>
          )
        })
      ) : (
        <p className="self-start font-open-sans font-normal text-sm text-[#808080] my-5">
          Your shared photos will appear here.
        </p>
      )}
    </>
  )
}
