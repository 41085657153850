import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { GET_CONNECTED_CHANNELS_BY_LOCATION, GET_LOCATION_BY_ID } from '../graphql'
import useRedux from '../hooks/use-redux'
import {
  setUserPhoneNumber,
  setUserphoneChannelID,
  setIsLoadingVonageApp,
} from '../redux-toolkit/slices/calls/contacts/contacts-slice'
import { setCountryIso2 } from '../components/common/country-code-select/countryCodeSlice'
import { connectVonageChannel } from './vonageAPI'
import phone from 'phone'
import { useVonageClient } from './useVonageClient'
import getApolloLink from '../utils/apollo-link'
import { useApolloClient } from '@apollo/client'

export const useProvisionNumber = () => {
  const client = useApolloClient()
  const [isLoading, setIsLoading] = useState(false)
  const [number, setNumber] = useState(null)
  const [isRefetching, setIsRefetching] = useState(false)
  const [dispatch, useSelector] = useRedux()
  const { countryIso2 } = useSelector((state) => state.countryCode)
  const {
    contacts: { connection },
  } = useSelector((state) => state.contacts)
  const {
    user: { businessId, cc_id, dirCode, employee },
    getAccessTokenSilently,
  } = useAuth0()
  useVonageClient()
  const { data: locData } = useQuery(GET_LOCATION_BY_ID, {
    variables: { location_id: businessId },
  })
  const {
    data: chlData,
    // loading: loadingChls,
    refetch: refetchChls,
  } = useQuery(GET_CONNECTED_CHANNELS_BY_LOCATION, {
    variables: {
      loc_id: businessId,
    },
    onCompleted: (chlData) => {
      if (isRefetching && countryIso2) {
        const { queryConnectedChannelsByLocation: chls } = chlData
        setIsRefetching(false)
        setConnectedChannel(chls, countryIso2)
      }
    },
  })

  const isInExpectedCountry = (number, expectedCountry) => {
    const prefixObj = { NZ: '64', AU: '61', US: '1', CA: '1' }
    return number.startsWith(prefixObj[expectedCountry])
  }

  const setConnectedChannel = async (
    chls,
    CountryCode,
    isCheckingExistingChannels,
  ) => {
    const connectedChannel = chls
      .filter((chl) => chl.Provider === 'phone' && chl.Status === '1')
      .find((chl) => {
        let num = chl.AccountUserValue
        if (CountryCode === 'US' && dirCode === 1) {
          return phone('+' + num).countryIso2 === CountryCode
        }
        if (CountryCode === 'CA') {
          return phone('+' + num).countryIso2 === CountryCode
        }
        // for NZ/AU
        return isInExpectedCountry(num, CountryCode)
      })
    // if found one, let the app know vonage client can init
    if (connectedChannel) {
      const { AccountUserValue, ChannelID } = connectedChannel
      dispatch(setIsLoadingVonageApp('1'))
      dispatch(setUserPhoneNumber(AccountUserValue))
      setNumber(AccountUserValue)
      dispatch(setUserphoneChannelID(ChannelID))
      // if not found when provision number
    } else if (!isCheckingExistingChannels) {
      //   console.log('no available number or something happen🚨')
      setNumber('provision failed')
    }

    setIsLoading(false)
  }

  useEffect(() => {
    // only execute once when no connection
    if (locData && chlData && !connection) {
      let {
        queryLocationByID: { CountryCode },
      } = locData
      // if nothing coming back from location table, use "US" as countrycode by default
      // if (!CountryCode) CountryCode = 'US'
      // let CountryCode = 'CA'
      dispatch(setCountryIso2(CountryCode))
      const { queryConnectedChannelsByLocation: chls } = chlData
      setConnectedChannel(chls, CountryCode, true)
    }
  }, [locData, chlData])

  const hardReloadAuthToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({ cacheMode: 'off' })
      const link = getApolloLink(accessToken)
      client.setLink(link)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error calling hardReloadAuthToken function:', error)
    }
  }

  const provisionNumber = async (areacode) => {
    try {
      setIsLoading(true)
      // provision TFN for us thryv employee
      const accessToken = await getAccessTokenSilently()
      // TODO: Confirm which of the following area codes are allowed
      const validTFNList = ['800', '833', '844', '855', '866', '877', '888']
      // US thryv employee
      if (dirCode === 1 && countryIso2 === 'US' && employee === 'thryv') {
        if (validTFNList.includes(areacode)) {
          await connectVonageChannel(
            accessToken,
            cc_id,
            businessId,
            countryIso2,
            areacode,
            'landline-toll-free',
          )
        }
        // user is not a thryv employee
      } else {
        await connectVonageChannel(
          accessToken,
          cc_id,
          businessId,
          countryIso2,
          areacode,
        )
      }
      setIsRefetching(true)
      refetchChls()
      await hardReloadAuthToken()
    } catch (e) {
      setIsLoading(false)
      setNumber('provision failed')
    }
  }

  return [number, provisionNumber, isLoading]
}
