// reference1:https://en.wikipedia.org/wiki/National_conventions_for_writing_telephone_numbers#:~:text=New%20Zealand,-Almost%20all%20New&text=Recently%20with%208%20digit%20mobile,BBB%2DBBBB%20for%20mobile%20numbers.
// reference2(vonage API):https://developer.vonage.com/en/voice/voice-api/concepts/numbers
/**
 * Current Support List
 * Australia    +61 (4xx)   xxx-xxx
 * New Zealand  +64 (xx)    xxx-xxxx
 * US           +1  (xxx)   xxx-xxxx
 * Canada       +1  (xxx)   xxx-xxxx
 */

// this function turns phones(e.g. "xxxxxxxxxxx") following E.164 format(ref above)
// into a format like this "+[country] (area) [first N digits] [second N digits]"
const phoneFormatter = (phone = '') => {
  //   console.log(`input: ${phone}`)
  if (typeof phone === 'string') {
    let prefix = phone.slice(0, 2)
    let area, firstN, secondN

    // phone in US/Canada
    if (prefix[0] === '1') {
      prefix = '1'
      area = phone.slice(1, 4)
      firstN = phone.slice(4, 7)
      secondN = phone.slice(7)
    }
    //   Australia
    if (prefix === '61') {
      area = phone.slice(2, 4)
      firstN = phone.slice(4, 8)
      secondN = phone.slice(8)
    }
    // New Zealand
    if (prefix === '64') {
      area = phone.slice(2, 4)
      firstN = phone.slice(4, 7)
      secondN = phone.slice(7)
    }
    // US/CAN phone without 1
    if (
      prefix[0] !== '1' &&
      prefix !== '61' &&
      prefix !== '64' &&
      phone.length === 10
    ) {
      prefix = '1'
      area = phone.slice(0, 3)
      firstN = phone.slice(3, 6)
      secondN = phone.slice(6)
    }
    const res = `+${prefix} (${area}) ${firstN}-${secondN}`
    //   console.log(`result: ${res}`)
    return res
  }
  return ''
}

export default phoneFormatter
// console.log('US/Cananda')
// phoneFormatter('15167381633')
// console.log('AUS')
// phoneFormatter('61455232123')
// console.log('New Zealand')
// phoneFormatter('64221234567')
