import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery } from '@apollo/client'
import {
  UPDATE_STAFF,
  GET_STAFF,
  ADD_USER_CHANNEL_PERMISSIONS_BULK,
} from '../../../../../../../graphql'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import formSchema from './formSchema'
import { Input } from '@thryvlabs/maverick'
import { getRoleIntegerEquivalent } from '../../../../../../../utils/get-role-or-status-integer-equivalent'
import useDetectCountryCode from '../../../../../../../hooks/use-detect-country-code'
import { useConnectedChannels } from '../../../../../../../hooks/use-connected-channels'

// Components
import { ParagraphText, Button } from '@thryvlabs/maverick'
import {
  PhoneInput,
  FirstNameInput,
  LastNameInput,
  RoleInput,
  EmailInput,
} from '../../../../../../../components/common/add-staff-modal/inputs'

import { LoadingSpinner } from '../../../../../../../components/common/loading-spinner'
import { useCountryData } from '../../../../../../../hooks/use-country-data'
import { ConnectedChannelInputs } from '../../../../../../../components/common/add-staff-modal/inputs'

const formatPhone = (phoneNumber, countryISO) => {
  if (!phoneNumber) return ''

  if (countryISO === 'US') return phoneNumber.slice(1)
  else return phoneNumber.slice(2)
}

const EditStaffDetailsModal = ({
  setOpenEditStaffModal,
  staffMemberData,
  setStaffUpdatedName,
  setOpenUpdateStaffConfirmationModal,
  setUpdateStaffError,
  setUpdatedStaffData,
  setUpdatedStaffPermissionsData,
  newStaffPermissions,
  setNewStaffPermissions,
}) => {
  const { user } = useAuth0()
  const commandCenterId = user.cc_id
  const detectedCountry = useDetectCountryCode()

  const [isDuplicate, setIsDuplicate] = useState({ number: false, email: false })
  const [maskPattern, setMaskPattern] = useState('')
  const { countryISO, countryCode } = useCountryData()

  const { accessibleChannels } = useConnectedChannels(user.businessId, user.cc_uid)
  const [permittedChannels, setPermittedChannels] = useState([])
  const { data: singleStaffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: staffMemberData.cc_userId },
      },
    },
    onCompleted: () => {
      setPermittedChannels(singleStaffData?.staff[0].permittedChannels)
    },
  })

  const { data: staffData } = useQuery(GET_STAFF)

  const formattedPhone = formatPhone(staffMemberData?.phone, countryISO)

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema(countryISO)),
    defaultValues: {
      firstname: staffMemberData?.firstName,
      lastname: staffMemberData?.lastName,
      email: staffMemberData?.email,
      countryCode: countryCode,
      phonenumber: formattedPhone,
      role: getRoleIntegerEquivalent(staffMemberData?.role),
    },
  })

  const firstNameWatcher = watch('firstname')
  const lastNameWatcher = watch('lastname')
  const emailWatcher = watch('email')
  const phoneNumberWatcher = watch('phone')
  const roleWatcher = watch('role')

  const setMask = (countryCode) => {
    switch (countryCode) {
      case 'US':
        return setMaskPattern('(###) ###-####')
      case 'CA':
        return setMaskPattern('(###) ###-####')
      case 'AU':
        if (
          phoneNumberWatcher[1] === '4' ||
          phoneNumberWatcher[1] === '5'
          // checks if mobile numbber and formats accordingly
        ) {
          return setMaskPattern('#### ### ###')
        } else {
          return setMaskPattern('(##) #### ####')
        }

      case 'NZ':
        return setMaskPattern('(##) ###-####')
    }
    return '(###) ###-####'
  }

  useEffect(() => {
    setMask(countryISO)
  }, [countryISO])

  const [updateStaff, { loading, error }] = useMutation(UPDATE_STAFF, {
    onCompleted: () => {
      setOpenEditStaffModal(false)
      setOpenUpdateStaffConfirmationModal(true)
      setNewStaffPermissions([])
      setIsDuplicate({ number: false, email: false })
    },
    onError: () => {
      setOpenEditStaffModal(false)
      setUpdateStaffError({ error: true, msg: error })
      setOpenUpdateStaffConfirmationModal(true)
    },
    refetchQueries: [
      {
        query: GET_STAFF,
        variables: { comctrid: commandCenterId },
        fetchPolicy: 'network-only',
      },
    ],
  })

  const updateStaffToDatabase = async (formData) => {
    setStaffUpdatedName({
      firstName: formData.firstname,
      lastName: formData.lastname,
    })
    const duplicateEmail = staffData.staff.some(
      (staff) =>
        staff.Email === emailWatcher &&
        staff.CC_UserID !== singleStaffData.staff[0].CC_UserID,
    )

    const duplicateNumber = staffData.staff.filter(
      (staff) =>
        staff.Status !== 4 && staff.Phone === countryCode + phoneNumberWatcher,
    )

    if (duplicateNumber.length) {
      setIsDuplicate({ number: true, email: false })
    } else if (duplicateEmail) {
      setIsDuplicate({ number: false, email: true })
    }

    if (duplicateNumber.length === 0 && !duplicateEmail) {
      await updateStaff({
        variables: {
          CC_UserID: staffMemberData.cc_userId,
          LocationID: user.businessId,
          FirstName: formData.firstname,
          LastName: formData.lastname,
          Email: formData.email,
          Phone:
            formData.phone === undefined
              ? staffMemberData.phone
              : countryCode + formData.phone,
          // Status: active ? STAFF_STATUS.active : STAFF_STATUS.inactive, // don't need to pass in an active or inactive status when updating staff details
          Role: getRoleIntegerEquivalent(formData.role.value),
          Type: 'cinnamon roll', // FIXME: Figure out what Type refers to here. A placeholder is needed for this to work.
          CountryCode: formData.countryCode,
        },
      })
    }
    setUpdatedStaffData({
      CC_UserID: staffMemberData.cc_userId,
      LocationID: user.businessId,
      FirstName: formData.firstname,
      LastName: formData.lastname,
      Email: formData.email,
      Phone:
        formData.phone === undefined
          ? staffMemberData.phone
          : countryCode + formData.phone,
      Role: getRoleIntegerEquivalent(formData.role.value),
      Type: 'cinnamon roll',
      CountryCode: formData.countryCode,
    })

    updateChannelPermissionsInDb()
  }

  const [updateChannelPermissionsBulk, { loading: channelPermissionsLoading }] =
    useMutation(ADD_USER_CHANNEL_PERMISSIONS_BULK, {
      refetchQueries: [GET_STAFF],
    })

  const updateChannelPermissionsInDb = async () => {
    const updatedNewStaffPermissions = newStaffPermissions.map((x) => ({
      ...x,
      UserID: singleStaffData.staff[0].CC_UserID,
    }))

    if (!newStaffPermissions.length) return

    try {
      await updateChannelPermissionsBulk({
        variables: { input: updatedNewStaffPermissions },
      })
      setUpdatedStaffPermissionsData(updatedNewStaffPermissions)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      return
    }
  }

  return (
    <div className="edit-staff-modal sm:max-w-[300px]">
      <div className="flex px-[22px] justify-between items-center sm:max-w-[300px] mb-11">
        <h4 className="font-semibold font-[Montserrat] text-[22px] leading-[30px] text-thryv-black-500">
          Edit Staff
        </h4>
      </div>

      <form onSubmit={handleSubmit(updateStaffToDatabase)}>
        {/* ----- FORM ----- */}
        <div className="pr-[20px] mb-[42px] ml-2">
          <div className="flex justify-center gap-[56px] mb-[56px] sm:flex-col sm:gap-8 sm:mb-[32px]">
            <FirstNameInput
              className="sm:w-full w-[302px]"
              register={register}
              errors={errors}
              // disabled={user.cc_uid !== staffMemberData.cc_userId} leaving commented out in case we need to disable in the future
            />
            <LastNameInput
              className="sm:w-full w-[333px]"
              register={register}
              errors={errors}
              // disabled={user.cc_uid !== staffMemberData.cc_userId} leaving commented out in case we need to disable in the future
            />
          </div>

          <div className="flex pl-[20px] gap-[56px] mb-[48px] sm:flex-col sm:gap-8 sm:mb-[32px] sm:pl-0">
            {detectedCountry.length > 0 ? (
              <Input
                value={detectedCountry}
                tabindex="-1"
                disabled
                className="sm:w-full w-[110px]"
                type="tel"
                placeholder="Country Code"
                withLabel
                labelType="floating"
                variant="default"
              />
            ) : (
              <div className="h-[26px] w-[124px]">
                <LoadingSpinner widthAndHeight={24} />
              </div>
            )}
            <div className="flex flex-col">
              <PhoneInput
                className="sm:w-full w-[525px]"
                control={control}
                errors={errors}
                pattern={maskPattern}
                phoneNumber={staffMemberData ? formattedPhone : ''}
                // disabled={user.cc_uid !== staffMemberData.cc_userId}
              />
              {!loading && isDuplicate.number && (
                <p className="text-[#DF2A2A] font-open-sans text-xs">
                  Phone number is already in use.
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between px-5 mb-[42px] sm:flex-col sm:gap-8 sm:mb-[32px] sm:px-0">
            <div className="flex flex-col">
              <EmailInput
                register={register}
                errors={errors}
                className="w-[333px] sm:w-full"
              />
              {!loading && isDuplicate.email && (
                <p className="text-[#DF2A2A] font-open-sans text-xs">
                  Email is already in use.
                </p>
              )}
            </div>

            <RoleInput
              className="sm:w-[280px] w-[333px]"
              control={control}
              errors={errors}
              defaultRole={
                (staffMemberData.role === '1' && 'admin') ||
                (staffMemberData.role === '2' && 'staff') ||
                (staffMemberData.role === '3' && 'owner')
              }
            />
          </div>
        </div>

        {/* MANAGE CONNECTED CHANNELS */}

        <div className="flex flex-col px-[22px] gap-3 sm:max-w-[300px] mb-11">
          <h4 className="font-semibold font-[Montserrat] text-[16px] leading-[22px] text-thryv-black-500">
            Manage connected channels
          </h4>
          <ParagraphText variant="reg">
            Grand access to your channels here. You can change it later in Settings.
          </ParagraphText>

          {accessibleChannels.length > 0 && (
            <>
              <div
                className={`md:max-h-[120px] max-h-[220px] md:max-w-[720px] rounded-md p-4 ${
                  accessibleChannels.length <= 3
                    ? 'sm:overflow-y-auto overflow-visible'
                    : 'overflow-y-auto'
                } `}
              >
                {permittedChannels.length ? (
                  <ConnectedChannelInputs
                    currentRole={roleWatcher?.value}
                    connectedChannels={accessibleChannels}
                    control={control}
                    errors={errors}
                    isEdit={true}
                    permittedChannels={permittedChannels}
                    newStaffPermissions={newStaffPermissions}
                    setNewStaffPermissions={setNewStaffPermissions}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
        {/* ----- SUBMISSION BUTTONS ----- */}
        <div className="flex justify-end items-center gap-4 h-[33.69px] sm:max-w-[300px]">
          {loading || channelPermissionsLoading ? (
            <LoadingSpinner widthAndHeight={32} />
          ) : (
            <>
              <Button
                variant="text"
                level={2}
                textVariant="light"
                onClick={() => setOpenEditStaffModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={
                  !firstNameWatcher?.trim().length ||
                  !lastNameWatcher?.trim().length ||
                  !emailWatcher?.trim().length ||
                  !formattedPhone ||
                  (phoneNumberWatcher !== undefined &&
                    !phoneNumberWatcher?.trim().length)
                }
              >
                Save
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default EditStaffDetailsModal
