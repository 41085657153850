import { gql } from '@apollo/client'

export const UPDATE_USER_PREFERENCES = gql`
  mutation update_user_preferences_mutation(
    $userId: String!
    $preferences: String!
  ) {
    updateUserPreferences(userId: $userId, preferences: $preferences)
  }
`
