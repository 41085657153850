const { VITE_ENV } = import.meta.env
const MARKETING_CENTER_BASE_URL =
  VITE_ENV === 'prod'
    ? 'https://marketingcenter.labs.thryv.com'
    : 'https://mc-dev.sandbox.thryv.com'
const BUSINESS_CENTER_BASE_URL =
  VITE_ENV === 'prod' ? 'https://go.thryv.com' : 'https://emp.thryv.com'

const leftNavData = {
  Inbox: {
    title: 'Inbox',
    icon: 'inboxIcon',
    color: '#FF5000',
    activeColor: '#ffede5',
    link: '/inbox',
    center: 'Command Center',
  },
  TeamChat: {
    icon: 'teamChatIcon',
    color: '#FF5000',
    activeColor: '#ffede5',
    link: '/teamchat',
    center: 'Command Center',
  },
  Calls: {
    icon: 'callsIcon',
    color: '#FF5000',
    activeColor: '#ffede5',
    link: '/calls',
    center: 'Command Center',
  },
  Voicemail: {
    icon: 'leftNavVoicemailIcon',
    color: '#FF5000',
    activeColor: '#ffede5',
    link: '/calls/voicemail',
    center: 'Command Center',
  },
  Transactions: {
    icon: 'transactionsIcon',
    color: '#5378FC',
    activeColor: '#eef1ff',
    link: '/transactions',
    center: 'ThryvPay',
  },
  'Scheduled Payments': {
    icon: 'scheduledPaymentsIcon',
    color: '#5378FC',
    activeColor: '#eef1ff',
    link: '/scheduled-payments',
    center: 'ThryvPay',
  },
  Invoices: {
    icon: 'invoicesIcon',
    color: '#232323',
    activeColor: '#e9e9e9',
    link: 'https://go.thryv.com/app/payments/orders',
    center: 'Business Center',
    openInNewTab: true,
  },
  Documents: {
    icon: 'documentsIcon',
    color: '#232323',
    activeColor: '#e9e9e9',
    link: `${BUSINESS_CENTER_BASE_URL}/app/documents`,
    center: 'Business Center',
    openInNewTab: true,
  },
  Dashboard: {
    icon: 'dashboardIcon',
    color: '#232323',
    activeColor: '#e9e9e9',
    link: `${BUSINESS_CENTER_BASE_URL}/app/dashboard`,
    center: 'Business Center',
    openInNewTab: true,
  },
  Calendar: {
    icon: 'calendarIcon',
    color: '#232323',
    activeColor: '#e9e9e9',
    link: `${BUSINESS_CENTER_BASE_URL}/app/calendar`,
    center: 'Business Center',
    openInNewTab: true,
  },
  Clients: {
    icon: 'clientsIcon',
    color: '#232323',
    activeColor: '#e9e9e9',
    link: `${BUSINESS_CENTER_BASE_URL}/app/clients`,
    center: 'Business Center',
    openInNewTab: true,
  },
  Sales: {
    icon: 'salesIcon',
    color: '#232323',
    activeColor: '#e9e9e9',
    link: `${BUSINESS_CENTER_BASE_URL}/app/payments/orders`,
    center: 'Business Center',
    openInNewTab: true,
  },
  Marketing: {
    icon: 'marketingIcon',
    color: '#232323',
    activeColor: '#e9e9e9',
    link: `${BUSINESS_CENTER_BASE_URL}/app/campaigns/automatic`,
    center: 'Business Center',
    openInNewTab: true,
  },
  Reputation: {
    icon: 'reputationIcon',
    color: '#232323',
    activeColor: '#e9e9e9',
    link: `${BUSINESS_CENTER_BASE_URL}/app/competitor-watch`,
    center: 'Business Center',
    openInNewTab: true,
  },
  'Online Presence': {
    icon: 'onlinePresenceIcon',
    color: '#232323',
    activeColor: '#e9e9e9',
    link: `${BUSINESS_CENTER_BASE_URL}/app/my-livesite`,
    center: 'Business Center',
    openInNewTab: true,
  },
  'Social Content': {
    icon: 'socialContentIcon',
    color: '#232323',
    activeColor: '#e9e9e9',
    link: `${BUSINESS_CENTER_BASE_URL}/app/social`,
    center: 'Business Center',
    openInNewTab: true,
  },
  Overview: {
    icon: 'overviewIcon',
    color: '#3C5199',
    activeColor: '#ebeef5',
    link: `${MARKETING_CENTER_BASE_URL}/overview`,
    center: 'Marketing Center',
    openInNewTab: true,
  },
  Recommendations: {
    icon: 'recommendationsIcon',
    color: '#3C5199',
    activeColor: '#ebeef5',
    link: `${MARKETING_CENTER_BASE_URL}/recommendations`,
    center: 'Marketing Center',
    openInNewTab: true,
  },
  Analytics: {
    icon: 'analyticsIcon',
    color: '#3C5199',
    activeColor: '#ebeef5',
    link: `${MARKETING_CENTER_BASE_URL}/analytics`,
    center: 'Marketing Center',
    openInNewTab: true,
  },
  'Landing Pages': {
    icon: 'landingPagesIcon',
    color: '#3C5199',
    activeColor: '#ebeef5',
    link: `${MARKETING_CENTER_BASE_URL}/listings-management`,
    center: 'Marketing Center',
    openInNewTab: true,
  },
  Campaigns: {
    icon: 'campaignsIcon',
    color: '#3C5199',
    activeColor: '#ebeef5',
    link: `${MARKETING_CENTER_BASE_URL}/campaigns`,
    center: 'Marketing Center',
    openInNewTab: true,
  },
  Tools: {
    icon: 'toolsIcon',
    color: '#3C5199',
    activeColor: '#ebeef5',
    link: `${MARKETING_CENTER_BASE_URL}/tools`,
    center: 'Marketing Center',
    openInNewTab: true,
  },
  // Listing = standalone icon in Installed Apps
  'Listing Management': {
    icon: 'listingManagementIcon',
    color: '#7F9197',
    activeColor: '#f3f5f5',
    link: `/listings-management`,
    center: 'Installed Apps',
    openInNewTab: false,
  },
  // Listings = icon in Marketing
  'Listings Management': {
    icon: 'listingsManagementIcon',
    color: '#3C5199',
    activeColor: '#ebeef5',
    link: `${MARKETING_CENTER_BASE_URL}/listings-management`,
    center: 'Marketing Center',
    openInNewTab: true,
  },
  MyPay: {
    icon: 'myPayIcon',
    color: '#00717E',
    activeColor: '#e5f1f2',
    link: '/mypay',
    center: 'Workforce Center',
  },
  'Sales Dashboard': {
    icon: 'salesDashboardIcon',
    color: '#7F9197',
    activeColor: '#f2f4f5',
    link: '/sales-dashboard',
    visible: true,
    center: 'Reporting Center',
  },
  Signatures: {
    icon: 'signaturesIcon',
    color: '#7F9197',
    activeColor: '#f3f5f5',
    link: '/signatures',
    visible: true,
    center: 'Installed Apps',
  },
  'Website Builder': {
    icon: 'websiteBuilderIcon',
    color: '#7F9197',
    activeColor: '#f3f5f5',
    link: '/website-builder',
    visible: true,
    center: 'Installed Apps',
  },
  Leads: {
    icon: 'thryvLeadsIcon',
    color: '#7F9197',
    activeColor: '#f3f5f5',
    link: '/leads',
    visible: true,
    center: 'Installed Apps',
  },
  Reports: {
    icon: 'reportsIcon',
    color: '#3C5199',
    activeColor: '#f3f5f5',
    link: '/reporting-center/reports',
    visible: true,
    center: 'Reporting Center',
  },
  Favorites: {
    icon: 'favoritesIcon',
    color: '#3C5199',
    activeColor: '#f3f5f5',
    link: '/reporting-center/favorites',
    visible: true,
    center: 'Reporting Center',
  },
  ThryvAI: {
    icon: 'thryvAIIcon',
    color: '#3C5199',
    activeColor: '#f3f5f5',
    link: '/reporting-center/thryvAI',
    visible: true,
    center: 'Reporting Center',
  },
  Bin: {
    icon: 'binIcon',
    color: '#3C5199',
    activeColor: '#f3f5f5',
    link: '/reporting-center/bin',
    visible: true,
    center: 'Reporting Center',
  },
}

export default leftNavData
