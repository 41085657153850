import { useEffect } from 'react'
import { Header, Input, Button, Icon } from '@thryvlabs/maverick'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setBusinessLocation } from './slices/businessInfoSlice'
import { useMutation } from '@apollo/client'
import { UPDATE_BUSINESS_INFO_BY_LOCATION } from '../../graphql/mutations/business-info-mutations'
import { useAuth0 } from '@auth0/auth0-react'
import { GET_BUSINESS_INFO_BY_LOCATION } from '../../graphql/queries/business-info-queries'
import { convertAlphaToNumeric } from '../../modules/settings/MyAccount/BusinessCardForms/my-account-helpers'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const formSchema = yup
  .object()
  .shape({
    businessName: yup
      .string()
      .required('Business name is required')
      .min(3, 'Must be at least 3 characters'),
  })
  .required()

const BusinessNameModal = ({ setOpenBusinessModal }) => {
  const dispatch = useDispatch()
  const businessLocation = useSelector((state) => state.businessInfo.location)
  const { user } = useAuth0()
  const {
    control,
    setValue,
    getValues,
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      businessName: businessLocation.name,
    },
    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    reset({
      businessName: businessLocation.name,
    })
  }, [businessLocation])

  const [updateBusinessInfo] = useMutation(UPDATE_BUSINESS_INFO_BY_LOCATION, {
    variables: {
      name: getValues('businessName') || '',
      category: businessLocation.category || '',
      address: businessLocation.address || '',
      city: businessLocation.city || '',
      zip: businessLocation.zip || '',
      state: businessLocation.state || '',
      country: businessLocation.countryCode || '',
      phone:
        `${convertAlphaToNumeric(businessLocation.countryCode)}${
          businessLocation.phone
        }` || '',
      email: businessLocation.email || '',
      website: businessLocation.website || '',
      directoryCode: user.dirCode,
      locationId: user.businessId,
    },
    onCompleted: () => {},
    refetchQueries: [GET_BUSINESS_INFO_BY_LOCATION],
    onError: () => {},
  })

  const onSubmit = (data) => {
    const name = data.businessName || ''
    const category = businessLocation.category
    const address = businessLocation.address
    const city = businessLocation.city
    const zip = businessLocation.zip
    const state = businessLocation.state
    const phone = businessLocation.phone
    const email = businessLocation.email
    const website = businessLocation.website

    const updatedValues = {
      name,
      address,
      city,
      zip,
      state,
      country: businessLocation.country,
      countryCode: businessLocation.countryCode,
      phone,
      category,
      email,
      website,
    }
    dispatch(setBusinessLocation(updatedValues))
    setTimeout(updateBusinessInfo, 500)
    setOpenBusinessModal(false)
  }

  return (
    <div className=" flex flex-col gap-[40px] sm:w-[300px] sm:gap-[10px] sm:py-0 ">
      <div className="flex w-full items-center">
        <div className="flex justify-start w-2/3 sm:w-full sm:items-center sm:mb-6">
          <Header variant="h4" fontWeight="semibold">
            Add business name
          </Header>
        </div>
        <div className="flex w-1/3 justify-end sm:absolute sm:top-[25px] sm:right-[20px]">
          <Icon
            className="cursor-pointer"
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={() => {
              setOpenBusinessModal(false)
            }}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full sm:w-[300px]">
          <Controller
            {...register('businessName')}
            control={control}
            name="businessName"
            render={({ field }) => (
              <Input
                {...field}
                onChange={(e) => setValue('businessName', e.target.value)}
                error={errors.businessName}
                className="w-[500px] sm:w-[275px]"
                type="businessName"
                placeholder="Business name"
                name="businessName"
                withLabel
                labelType="floating"
                variant="default"
              />
            )}
          />
          <p className="text-notification-red text-xs absolute bottom-[85px] z-50">
            {errors.businessName?.message}
          </p>
        </div>
        <div className="flex w-full justify-end items-center gap-4 mt-[50px] sm:pr-4">
          <Button
            type="button"
            onClick={() => {
              setOpenBusinessModal(false)
            }}
            variant="text"
            level={2}
            textVariant="light"
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default BusinessNameModal
