import axios from 'axios'
const { VITE_VONAGE_API } = import.meta.env

/**
 * Generate jwt token for accessing vonage sdk
 * @param {String} ccid The cc_id from auth0 (is "" by default)
 * @return {String} The jwt token
 */
export const generateJwtToken = async (countrycode, subject, token) => {
  const params = subject ? { countrycode, subject } : { countrycode }
  const options = {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
    url: VITE_VONAGE_API + '/get_vonage_token',
    params,
  }
  const response = await axios.request(options)
  return response.data.jwt
}

export const getLegsApi = async (conversation_id, jwt2) => {
  const { data } = await axios({
    headers: {
      Authorization: `Bearer ${jwt2}`,
      // 'Content-Type': 'application/json',
    },
    url: 'https://api.nexmo.com/v0.3/legs',
    method: 'GET',
    params: { conversation_id },
  }).catch(() => {})
  // const leg = legs.find(({ type }) => type === phoneType)
  // if (!legs) return
  // const { uuid: rtc_id, status: reconnectCallStatus } = leg
  // return { rtc_id, reconnectCallStatus }
  return data._embedded.legs
}

export const connectVonageChannel = async (
  token,
  ccid,
  locid,
  countrycode,
  areacode,
  type,
) => {
  return await axios.post(
    VITE_VONAGE_API + '/connect_vonage_channel',
    {
      ccid,
      locid,
      countrycode,
      number: areacode,
      type,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  )
}

export const transferToVoiceMail = async ({
  token,
  clientUid,
  countryIso2,
  clientNumber,
}) => {
  const options = {
    url: VITE_VONAGE_API + '/transfer_to_voicemail',
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
    data: {
      uuid: clientUid,
      countrycode: countryIso2,
      cc_number: clientNumber,
    },
  }
  return axios(options).catch(() => {})
}
