import { faCreditCard, faCircleMinus } from '@fortawesome/pro-regular-svg-icons'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../components/command-center-icon/command-center-icon'

const AddPaymentMethodCard = ({
  label,
  cardHeader,
  isCardSelected = false,
  showIcon2 = false,
  supportIncreaseDecrease = false,
  increaseClick,
  decreaseClick,
  openAddPaymentModalFun,
  cardActionTypeLabel,
  monthlyMinuteSubscription,
  setMonthlyMinuteSubscription,
  isBorderDashed = true,
  activeCardType,
}) => {
  return (
    <div
      className={`flex m-[1rem] justify-between items-center sm:w-[92%]  h-[62px] border-[1px] border-[#CCCCCC] ${
        isBorderDashed ? 'border-dashed' : 'drop-shadow-md'
      }`}
    >
      <span className="ml-[1rem] flex">
        {isCardSelected && activeCardType && (
          <CommandCenterIcon variant="visa" type="solid" height="40" width="40" />
        )}
        {isCardSelected && !activeCardType && (
          <FontAwesomeIcon
            className="text-[14px] mr-[8px] text-[#CCCCCC] "
            icon={faCreditCard}
          />
        )}
        <span className="text-[14px] ml-[6px] flex flex-col justify-center">
          {cardHeader && (
            <span className="text-[#3C5199] open-sans font-semibold">
              {cardHeader}
            </span>
          )}
          <span
            className={`${isCardSelected ? 'text-[#231F20]' : 'text-[#808080]'}`}
          >
            {label}
          </span>
        </span>
      </span>

      {supportIncreaseDecrease && (
        <div className="inline-flex items-center ">
          <button>
            <FontAwesomeIcon
              onClick={decreaseClick}
              icon={faCircleMinus}
              className={`mr-[0.5rem] text-[${
                monthlyMinuteSubscription === 0 ? '#CCCCCC' : '#3D5199'
              }]`}
            />
          </button>

          <Input
            onChange={(e) => {
              if (e.target.value < 433) setMonthlyMinuteSubscription(e.target.value)
            }}
            value={monthlyMinuteSubscription}
            className="h-[32px] w-[40px] box-border bg-[#FFFFFF] border border-[#3D5199] rounded-[4px] text-center"
            type="text"
            name="counter"
            variant="default"
            customPlaceholder="0"
            maxLength={3}
          />

          <button>
            <FontAwesomeIcon
              onClick={increaseClick}
              className="text-[14px] ml-[0.5rem] mr-[0.5rem] text-[#3D5199]"
              icon={faCirclePlus}
            />
          </button>
        </div>
      )}

      {showIcon2 && (
        <FontAwesomeIcon
          className="text-[25px] mr-[1rem]"
          icon={faCirclePlus}
          onClick={openAddPaymentModalFun}
        />
      )}

      {cardActionTypeLabel && (
        <div>
          {isCardSelected && (
            <span
              className={`bg-[#18a085] font-semibold pl-[1rem] pr-[1rem] pt-[0.3rem] pb-[0.3rem] mr-[10px] rounded-[1rem] text-[12px] text-[#fff]`}
            >
              ACTIVE
            </span>
          )}
          <span
            className="text-[#057AFF] text-[12px] open-sans font-semibold mr-[1rem] cursor-pointer"
            onClick={openAddPaymentModalFun}
          >
            {cardActionTypeLabel}
          </span>
        </div>
      )}
    </div>
  )
}

export default AddPaymentMethodCard
