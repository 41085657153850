/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit'

const createNotif = ({ name, show, timeoutId, customText }) => {
  return {
    name: name || '',
    show: show || false,
    timeoutId: timeoutId || undefined,
    customText: customText || '',
  }
}

export const inboxNotificationsSlice = createSlice({
  name: 'inboxNotifications',
  initialState: {
    /* ----- Notification Objects Only ----- */
    /* ----- `name` field required for each notification ----- */
    contactCreateNotifError: createNotif({
      name: 'contactCreateNotifError',
    }),

    fileSizeWarningNotif: createNotif({
      name: 'fileSizeWarningNotif',
    }),

    credentialChangeNotif: createNotif({
      name: 'credentialChangeNotif',
    }),

    // NOTE: This notif object has another reference in another slice (remindMeSlice.js)
    reminderNotif: createNotif({
      name: 'reminderNotif',
    }),

    // NOTE: This notif object has another reference in another slice (remindMeSlice.js)
    customReminderNotif: createNotif({
      name: 'customReminderNotif',
    }),

    importedRegisterPhoneNotif: createNotif({
      name: 'importedRegisterPhoneNotif',
      show: true,
    }),

    importedRegisterBusinessErrorNotif: createNotif({
      name: 'importedRegisterBusinessErrorNotif',
      show: true,
    }),

    successfulAustraliaBusinessPhoneNotif: createNotif({
      name: 'successfulAustraliaBusinessPhoneNotif',
    }),

    mergeNotification: createNotif({
      name: 'mergeNotification',
    }),

    successMergeNotification: createNotif({
      name: 'successMergeNotification',
    }),
    unsendMessageNotification: createNotif({
      name: 'unsendMessageNotification',
    }),
  },
  reducers: {
    updateInboxNotif(state, action) {
      const notification = action.payload

      const updateContactCreateNotifError = () => {
        if (notification.show) state.contactCreateNotifError.show = true
        if (!notification.show) state.contactCreateNotifError.show = false
        if (notification.customText?.length) {
          state.contactCreateNotifError.customText = notification.customText
        } else {
          state.contactCreateNotifError.customText = ''
        }
      }

      const updateFileSizeWarningNotif = () => {
        if (notification.show) state.fileSizeWarningNotif.show = true
        if (!notification.show) state.fileSizeWarningNotif.show = false
      }

      const updateCredentialChangeNotif = () => {
        if (notification.show) state.credentialChangeNotif.show = true
        if (!notification.show) state.credentialChangeNotif.show = false
      }

      const updateReminderNotif = () => {
        if (notification.show) state.reminderNotif.show = true
        if (!notification.show) state.reminderNotif.show = false
      }

      const updateCustomReminderNotif = () => {
        if (notification.show) state.customReminderNotif.show = true
        if (!notification.show) state.customReminderNotif.show = false
      }

      const updateImportedRegisterPhoneNotif = () => {
        if (notification.show) state.importedRegisterPhoneNotif.show = true
        if (!notification.show) state.importedRegisterPhoneNotif.show = false
      }

      const updateImportedRegisterBusinessErrorNotif = () => {
        if (notification.show) state.importedRegisterBusinessErrorNotif.show = true
        if (!notification.show) state.importedRegisterBusinessErrorNotif.show = false
      }

      const updateSuccessfulAustraliaBusinessPhoneNotif = () => {
        if (notification.show)
          state.successfulAustraliaBusinessPhoneNotif.show = true
        if (!notification.show)
          state.successfulAustraliaBusinessPhoneNotif.show = false
      }

      const updateMergeNotification = () => {
        if (notification.show) state.mergeNotification.show = true
        if (!notification.show) state.mergeNotification.show = false
      }

      const updateSuccessMergeNotification = () => {
        if (notification.show) state.successMergeNotification.show = true
        if (!notification.show) state.successMergeNotification.show = false
      }

      const updateUnsendMessageNotification = () => {
        if (notification.show) state.unsendMessageNotification.show = true
        if (!notification.show) state.unsendMessageNotification.show = false
      }

      switch (notification.name) {
        case 'contactCreateNotifError':
          updateContactCreateNotifError()
          break
        case 'fileSizeWarningNotif':
          updateFileSizeWarningNotif()
          break
        case 'credentialChangeNotif':
          updateCredentialChangeNotif()
          break
        case 'reminderNotif':
          updateReminderNotif()
          break
        case 'customReminderNotif':
          updateCustomReminderNotif()
          break
        case 'importedRegisterPhoneNotif':
          updateImportedRegisterPhoneNotif()
          break
        case 'importedRegisterBusinessErrorNotif':
          updateImportedRegisterBusinessErrorNotif()
          break
        case 'successfulAustraliaBusinessPhoneNotif':
          updateSuccessfulAustraliaBusinessPhoneNotif()
          break
        case 'mergeNotification':
          updateMergeNotification()
          break
        case 'successMergeNotification':
          updateSuccessMergeNotification()
          break
        case 'unsendMessageNotification':
          updateUnsendMessageNotification()
          break
        default:
          console.error(
            `WARNING: Notification: "${notification.name}" does not exist.`,
          )
      }
    },

    clearInboxNotifTimeout(state, action) {
      const notificationType = action.payload
      const notification = state[notificationType]

      if (notification.timeoutId) clearTimeout(notification.timeoutId)
    },

    updateInboxNotifTimeoutId(state, action) {
      const { notifType, newTimeoutId } = action.payload

      if (newTimeoutId === undefined || notifType === undefined) {
        console.error('Please enter a valid timeoutId and Notification type.')
        return
      }

      const notification = state[notifType]
      if (notification.timeoutId) clearTimeout(notification.timeoutId)
      notification.timeoutId = newTimeoutId
    },
  },
})

export const {
  contactCreateNotifError,
  updateInboxNotif,
  clearInboxNotifTimeout,
  updateInboxNotifTimeoutId,

  // Notification Objects
  importedRegisterPhoneNotif,
} = inboxNotificationsSlice.actions

export default inboxNotificationsSlice.reducer
