import { gql } from '@apollo/client'

export const UPDATE_INBOX_DATA = gql`
  mutation update_inbox_data_mutation($input: UpdateMyInboxDataInput!) {
    updateMyInboxData(input: $input) {
      PK1
      SK1
      data
    }
  }
`

export const CREATE_MESSAGE = gql`
  mutation create_message_mutation(
    $channel_id: String!
    $thread_id: String!
    $body: String!
    $email_from: EmailParticipantInput
    $email_to: [EmailParticipantInput]
    $email_cc: [EmailParticipantInput]
    $email_bcc: [EmailParticipantInput]
    $email_subject: String
    $number_from: String
    $number_to: String
    $vonage_type: String
    $status: String
    $dm_from: String
    $dm_to: String
  ) {
    createMessage(
      input: {
        channel_id: $channel_id
        thread_id: $thread_id
        body: $body
        email_to: $email_to
        email_cc: $email_cc
        email_bcc: $email_bcc
        email_from: $email_from
        email_subject: $email_subject
        number_from: $number_from
        number_to: $number_to
        vonage_type: $vonage_type
        status: $status
        dm_from: $dm_from
        dm_to: $dm_to
      }
    ) {
      pk1
      sk1
      body
      msgpk
      com_ctr_id
    }
  }
`

export const DELETE_MESSAGE = gql`
  mutation delete_message_mutation(
    $pk1: String!
    $sk1: Long!
    $channel_id: String
    $thread_id: String
  ) {
    deleteMessage(
      pk1: $pk1
      sk1: $sk1
      channel_id: $channel_id
      thread_id: $thread_id
    )
  }
`
export const CREATE_MESSAGE_PIN = gql`
  mutation create_message_pin_mutation(
    $channelid: String!
    $threadid: String!
    $sk1: Long!
  ) {
    createMessagePin(channel_id: $channelid, thread_id: $threadid, sk1: $sk1) {
      threadpk
      msgpk
      agent_id
      body
      pinpk
      pk1
      email_subject
      email_snippet
      ts
    }
  }
`

export const DELETE_MESSAGE_PIN = gql`
  mutation delete_message_pin_mutation(
    $channelid: String!
    $threadid: String!
    $sk1: Long!
  ) {
    deleteMessagePin(channel_id: $channelid, thread_id: $threadid, sk1: $sk1) {
      threadpk
      msgpk
      agent_id
      body
      pinpk
      pk1
      email_subject
      email_snippet
      ts
    }
  }
`
export const DELETE_MESSAGES_WITH_MERGE = gql`
  mutation delete_messages(
    $VendorID: Int!
    $thread_id: String!
    $body: String
    $contactsk1: String
    $contactsk2: String
    $contactsk3: String
    $pk1: String!
    $sk1: Long!
  ) {
    deleteMessages(
      input: {
        VendorID: $VendorID
        thread_id: $thread_id
        mergeContact: {
          body: $body
          contactsk1: $contactsk1
          contactsk2: $contactsk2
          contactsk3: $contactsk3
          pk1: $pk1
          sk1: $sk1
        }
      }
    )
  }
`

export const DELETE_MESSAGES = gql`
  mutation delete_messages($VendorID: Int!, $thread_id: String!) {
    deleteMessages(input: { VendorID: $VendorID, thread_id: $thread_id })
  }
`
