import ccInboxImage from '../../assets/cc-inbox-signup-image.svg'
import ccTeamChatImage from '../../assets/cc-teamchat-signup-image.svg'
import ccCallsImage from '../../assets/cc-calls-signup-image.svg'
const images = [
  {
    id: 1,
    src: ccInboxImage,
    variant: 'inboxSignupImage',
    alt: 'Command Center Inbox',
    header: 'Command Center',
    headerOrange: 'Inbox',
    txt: 'Connect with all the prospects in your inbox',
    txtOrange: "no matter where they're coming from",
  },
  {
    id: 2,
    src: ccTeamChatImage,
    variant: 'teamchat',
    alt: 'Command Center TeamChat',
    header: 'Command Center',
    headerOrange: 'TeamChat',
    txt: "Conversations don't have to stop even when your team is on the go with ",
    txtOrange: "TeamChat's Mobile App",
  },
  {
    id: 3,
    src: ccCallsImage,
    variant: 'callsSignupImage',
    alt: 'Command Center Calls',
    header: 'Command Center',
    headerOrange: 'Calls',
    txt: 'Communicate directly with clients through',
    txtOrange: ' phone calls and video chats',
    txt2: ' with Calls',
  },
]

export default images
