import { useEffect, useState } from 'react'
import { Checkbox } from '@thryvlabs/maverick'
import VoicemailContent from './VoicemailContent'
import VoicemailContentMobile from './VoicemailContentMobile'
import { selectContact } from '../../../../../redux-toolkit/slices/calls/contacts/contacts-slice'
import { useDispatch } from 'react-redux'
import { LoadingSpinner } from '../../../../common/loading-spinner'

const Voicemail = ({
  // voicemail,
  isMobile,
  checked,
  setChecked,
  callsCheckable,
  setDisplayInfoCard,
  body,
  agent_id,
  item_type,
  number_from,
  sk1,
  pk1,
  number_to,
  threadpk,
  ts,
  setContactInfoView,
  msgpk,
  chanpk,
}) => {
  const [voiceMailOpen, setVoicemailOpen] = useState(false)
  const dispatch = useDispatch()

  const voicemail = {
    body,
    agent_id,
    item_type,
    number_from,
    sk1,
    pk1,
    number_to,
    threadpk,
    ts,
    msgpk,
    chanpk,
  }

  const transcript =
    typeof voicemail.body === 'string'
      ? JSON.parse(voicemail?.body).transcription
      : 'Voicemail unavailable'

  // const callback = React.useCallback(() => {
  //   setCallsCheckable(true)
  // }, [])

  useEffect(() => {
    setVoicemailOpen(false)
  }, [isMobile])

  // const bind = useLongPress(enabled ? callback : null, {
  //   threshold: 500,
  //   captureEvent: true,
  //   cancelOnMove: false,
  //   detect: LongPressDetectEvents.BOTH,
  // })

  function handleClick() {
    if (!callsCheckable) {
      dispatch(
        selectContact({
          recentCall: { ...voicemail, phone: voicemail?.number_from },
        }),
      )

      setContactInfoView('default')
    }
  }

  const handleChange = () => {
    const updatedChecked = checked.filter((id) => {
      return id !== voicemail.id
    })
    if (updatedChecked.length === checked.length) {
      setChecked([...checked, voicemail.id])
    } else {
      setChecked(updatedChecked)
    }
  }
  // ignore those duplicate voicemails without a link
  if (!JSON.parse(voicemail.body).link) return null
  return (
    <table className="w-full">
      <tbody className="w-full">
        <tr
          // Commenting out "long-press" to delete for now
          // {...(isMobile && { ...bind('test context') })}
          onClick={handleClick}
          className={`flex flex-col active:bg-thryv-cloud hover:bg-[#F2F4F6] hover:cursor-pointer group md:px-[5px] justify-center h-[74px] w-full rounded-[5px] font-open-sans mb-[5px]`}
        >
          <td
            className={`${
              callsCheckable && 'pl-[14px]'
            } flex items-center flex-wrap`}
          >
            {callsCheckable && (
              <Checkbox
                className="border-[#808080] mr-2"
                name="calls-deletion-list"
                value={voicemail.sk1}
                onChange={handleChange}
              />
            )}
            {isMobile ? (
              <VoicemailContentMobile
                voicemail={voicemail}
                voiceMailOpen={voiceMailOpen}
                setDisplayInfoCard={setDisplayInfoCard}
              />
            ) : (
              <VoicemailContent
                voicemail={voicemail}
                voiceMailOpen={voiceMailOpen}
                setVoicemailOpen={setVoicemailOpen}
              />
            )}
          </td>
        </tr>
        <tr
          className={`${
            voiceMailOpen ? '' : 'collapse'
          } text-thryv-night bg-[#f8f9fb]`}
        >
          <td className="flex flex-row p-[15px] rounded-[5px]">
            <p className="flex w-full px-[10px]">
              {transcript?.length > 0 ? transcript : <LoadingSpinner />}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default Voicemail
