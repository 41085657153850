import { useState, useEffect } from 'react'
import thryvcclogo from '../../assets/thryvcclogomedium.png'
import { Input, Button, Icon } from '@thryvlabs/maverick'
import { useForm, Controller } from 'react-hook-form'
import PasswordVerification from './PasswordVerification'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment-timezone'
import axios from 'axios'
const { VITE_SIGN_UP_SERVICE_URL } = import.meta.env
import { NotificationBar } from '@thryvlabs/maverick'
import { dataDogRumEndSession } from '../../utils/data-dog-rum'
import { sha1 } from 'js-sha1'

/*
  This component allows users to create a new password and confirm it. It includes a password
  verification feature that checks whether the entered password meets certain requirements
  for a secure password, such as a minimum length and the inclusion of uppercase letters,
  lowercase letters, numbers, and special characters. The component uses the react-hook-form
  library to manage form state, and the @thryvlabs/maverick library for UI components. When
  the user clicks the "Continue" button, the component calls a function passed as a prop to
  move to the next step of the sign-up process. The component also includes a "Back" button
  that allows the user to return to the previous step. 

  Props:
  - setStep: a function to move to the next step of the sign-up process

  State:
  - showPassword: a boolean indicating whether to show the password in plain text or not
  - showConfirmPassword: a boolean indicating whether to show the confirmed password in plain text or not
  - isLengthValid: a boolean indicating whether the entered password is at least 8 characters long
  - hasUpperCase: a boolean indicating whether the entered password contains at least one uppercase letter
  - hasLowerCase: a boolean indicating whether the entered password contains at least one lowercase letter
  - hasNumber: a boolean indicating whether the entered password contains at least one digit
  - hasSpecialChar: a boolean indicating whether the entered password contains at least one special character

*/

const CreatePassword = ({ setStep, userData }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [isLengthValid, setIsLengthValid] = useState(false)
  const [hasUpperCase, setHasUpperCase] = useState(false)
  const [hasLowerCase, setHasLowerCase] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [hasSpecialChar, setHasSpecialChar] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [errorContent, setErrorContent] = useState('')
  const [searchParams] = useSearchParams()
  const utm = window.location.href.split('?')[1]

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })
  const passwordPattern =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

  const handleShowPassword = (e) => {
    e.preventDefault()
    setShowPassword(!showPassword)
  }
  const handleShowConfirmPassword = (e) => {
    e.preventDefault()
    setShowConfirmPassword(!showConfirmPassword)
  }

  const password = watch('password')
  const confirmPassword = watch('confirmPassword')

  const doPasswordsMatch = () => {
    if (password === confirmPassword) {
      setPasswordsMatch(true)
    } else {
      setPasswordsMatch(false)
    }
  }

  const timezone = moment.tz.guess(true)

  const userInfo = {
    email: userData.email,
    first_name: userData.firstName,
    last_name: userData.lastName,
    phone: userData.countryCode + userData.phoneNumber.replace(/[^A-Za-z0-9]/g, ''),
    country: userData.country,
    time_zone: timezone,
    password,
    source: 'cc_signup',
  }

  const submitUserData = async () => {
    setIsLoading(true)
    if (searchParams.get('invite_id')) {
      userInfo.invite_id = searchParams.get('invite_id')
    }
    if (utm) {
      userInfo.utm = utm
    }
    try {
      const response = await axios.post(
        `${VITE_SIGN_UP_SERVICE_URL}/signup/create-new-user`,
        userInfo,
      )
      if (response) {
        // invoke impact conversion & identify function immediately after signup finish
        if (window.ire && !Array.isArray(window.ire)) {
          const {
            user_metadata: { cc_id },
            email,
          } = response.data
          if (email && cc_id) {
            // identify function
            window.ire('identify', {
              customerId: cc_id,
              customerEmail: sha1(email),
            })
            // conversion function
            window.ire(
              'trackConversion',
              44180,
              {
                orderId: 'IR_AN_64', // passing this value will create a UUID in impact
                customerId: cc_id,
                customerEmail: sha1(email),
              },
              {
                verifySiteDefinitionMatch: true,
              },
            )
          }
        }
        setIsLoading(false)
        dataDogRumEndSession()
        setStep('4')
      }
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
      if (error.response.data === 'Request failed with status code 400') {
        setErrorContent(`Email ${userData.email} Already In Use`)
      }
      throw error
    }
  }

  const ValidatePasswords = () => {
    if (
      isLengthValid &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar &&
      passwordsMatch
    ) {
      return (
        <Button
          variant="primary"
          type="submit"
          id="submit_password_enabled"
          onClick={async () => {
            await submitUserData()
          }}
        >
          Create Account
        </Button>
      )
    } else {
      return (
        <Button
          variant="primary"
          disabled
          type="submit"
          id="submit_password_disabled"
        >
          Create Account
        </Button>
      )
    }
  }

  // This `useEffect` is used to check whether the entered password meets certain requirements
  // for a secure password, such as a minimum length and the inclusion of uppercase letters,
  // lowercase letters, numbers, and special characters. It updates state values to reflect
  // whether each requirement has been met.

  useEffect(() => {
    setIsLengthValid(password.length >= 8)
    setHasUpperCase(/[A-Z]/.test(password))
    setHasLowerCase(/[a-z]/.test(password))
    setHasNumber(/\d/.test(password))
    setHasSpecialChar(/[!@#$%^&*]/.test(password))
    doPasswordsMatch()

    if (password === '' && confirmPassword === '') {
      setIsValid(true)
    }

    if (!passwordsMatch && confirmPassword !== '') {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [password, confirmPassword, passwordsMatch])

  return (
    <div className="flex flex-col items-center md:w-[329px] md:mb-[50px] md:mt-[85px]">
      <div className="h-[80px] gap-[20px] flex flex-col items-center mb-[24px] md:mb-0">
        <img
          src={thryvcclogo}
          alt="Thryv Command Center"
          className="w-[198px] h-[40px] md:hidden"
        />
        <p className='font-["Open_Sans"] text-[#4D4D4D] font-normal text-[14px] leading-[20px] text-center md:hidden'>
          Requiring secure passwords with multi-factor authentication is <br />
          one of many ways we protect your sensitive information.
        </p>
        <div className="hidden md:flex flex-col gap-2 justify-center text-center -mt-[45px]">
          <h1 className="hidden md:block font-primary text-[18px] leading-[24px] font-semibold text-[#4D4D4D]">
            Set Password
          </h1>
          <p className='font-["Open_Sans"] text-[#4D4D4D] font-normal text-[14px] leading-[20px] text-center'>
            We protect your sensitive information <br /> with multi-factor
            authentication.
          </p>
        </div>
      </div>
      <div className="w-[420px] md:w-[281px] md:my-0 md:mx-auto h-[206px] flex flex-col gap-[10px] mt-[32px]">
        <form>
          <div className="flex items-center">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: passwordPattern,
              }}
              render={({ field }) => (
                <Input
                  className="w-[420px] md:w-[273px]"
                  onChange={(e) => setValue('password', e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  name="password"
                  withLabel
                  labelType="floating"
                  variant="default"
                  ref={control}
                  {...field}
                />
              )}
            />
            {errors.password && <p>{errors.password.message}</p>}
            <button className="-ml-[25px] z-40" onClick={handleShowPassword}>
              {showPassword ? (
                <Icon type="regular" variant="eyeSlash" height="11px" width="16px" />
              ) : (
                <Icon type="regular" variant="eye" height="11px" width="16px" />
              )}
            </button>
          </div>
          <PasswordVerification
            isLengthValid={isLengthValid}
            hasUpperCase={hasUpperCase}
            hasLowerCase={hasLowerCase}
            hasNumber={hasNumber}
            hasSpecialChar={hasSpecialChar}
          />
          <div className="flex items-center">
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: passwordPattern,
              }}
              render={({ field }) => (
                <Input
                  className="w-[420px] md:w-[273px]"
                  onChange={(e) => setValue('confirmPassword', e.target.value)}
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  withLabel
                  labelType="floating"
                  variant="default"
                  ref={control}
                  isValid={isValid}
                  {...field}
                />
              )}
            />
            <button className="-ml-[25px] z-40" onClick={handleShowConfirmPassword}>
              {showConfirmPassword ? (
                <Icon type="regular" variant="eyeSlash" height="11px" width="16px" />
              ) : (
                <Icon type="regular" variant="eye" height="11px" width="16px" />
              )}
            </button>
          </div>
          {!passwordsMatch && confirmPassword !== '' ? (
            <div>
              <p className='font-["Open_Sans"] text-[#DF2A2A] font-normal text-[12px] leading-[16px] -mb-[16px]'>
                The passwords don't match
              </p>
            </div>
          ) : null}
          {isLoading ? (
            <div className="pr-[50px] pt-2">
              <span className="w-[58px] h-[58px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin relative left-1/2 top-5"></span>
            </div>
          ) : (
            <div className="flex mt-[70px] md:mt-[45px] items-center justify-center w-full">
              <button onClick={() => setStep('2')}>
                <p
                  className={`font-["Open_Sans"] font-[600] leading-[34px] text-[#808080] text-[13px] pr-[16px]`}
                >
                  BACK
                </p>
              </button>
              <ValidatePasswords />
            </div>
          )}
          {isError ? (
            <div className="pt-[15px]">
              <NotificationBar
                variant="error"
                content={
                  errorContent.length > 1 ? (
                    <>{errorContent}</>
                  ) : (
                    <>Something went wrong...please try again</>
                  )
                }
              />
            </div>
          ) : null}
        </form>
      </div>
    </div>
  )
}

export default CreatePassword
