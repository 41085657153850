/* eslint-disable no-console */
import { useDispatch, useSelector } from 'react-redux'
import {
  setShowModal,
  setModalContent,
} from '../../../../../components/common/modals/slices/connectChannelsSlice'
import { ButtonsData } from '../../../../../components/welcome-screen/buttonsData'
import { useQuery } from '@apollo/client'

// Components
import {
  SecurityStaffSectionPopover,
  SecurityStaffSectionTitle,
  ConnectedChannelsGroup,
} from '../../components'
import { ChannelsModalComponent } from '../../../../../components/common/modals/channels-modal-component/ChannelsModalComponent'
import BottomModalButton from '../../../../../components/welcome-screen/BottomModalButton'
import { Button, ParagraphText } from '@thryvlabs/maverick'
import { ChannelLinksContainer } from '../../../../../components/welcome-screen/WelcomeScreen.styles'
import { GET_PACKAGE_INFO } from '../../../../../graphql/queries'
import { setMaxChannelsAllowed } from '../../../../../components/common/modals/slices/connectChannelsSlice'
import { useActiveChannels } from '../../../../../hooks/use-active-channels'

const RoundedOrangeButton = ({ onClick, children }) => (
  <div className="inline-block">
    <Button
      variant="primary"
      onClick={onClick}
      className="text-sm !px-7 py-2.5 !rounded-[18px]"
    >
      {children}
    </Button>
  </div>
)

export const MyChannelsSection = ({ className, staffData }) => {
  const dispatch = useDispatch()
  const { showModal: showConnectModal } = useSelector(
    (state) => state.connectChannels,
  )
  const buttonsData = ButtonsData()

  const staffMembers = staffData?.staff || []

  const connectedChannels = useActiveChannels()

  const openChannelConnectModal = () => {
    dispatch(setShowModal(true))
    dispatch(setModalContent(1))
  }

  const {
    data: packageInfoData,
    loading: packageInfoLoading,
    error: packageInfoError,
  } = useQuery(GET_PACKAGE_INFO, {
    onCompleted: () => {
      dispatch(
        setMaxChannelsAllowed(packageInfoData.queryPackageInfoByCCID.InboxChannels),
      )
    },
    onError: () => {
      console.log('PACKAGE INFO ERROR:', packageInfoError)
    },
  })

  const maxChannelsAllowed = useSelector(
    (state) => state.connectChannels.maxChannelsAllowed,
  )

  return (
    <SecurityStaffSectionPopover
      className={`${className} ${
        connectedChannels.length > 0 && 'min-h-[303px] overflow-y-scroll'
      }`}
    >
      {showConnectModal && <ChannelsModalComponent />}

      {/* FIXME: Get the max number of channels that a user can have and display it here */}
      <SecurityStaffSectionTitle className="mb-6">
        {packageInfoLoading || maxChannelsAllowed === 'Unlimited'
          ? 'My Channels'
          : `My Channels (${connectedChannels?.length}/${maxChannelsAllowed})`}
      </SecurityStaffSectionTitle>

      {connectedChannels.length === 0 ? (
        <div>
          <div className="flex flex-col mb-8">
            <ParagraphText
              variant="reg"
              className="font-semibold leading-[22px] !font-[Montserrat]"
              color="thryv-orange-500"
            >
              Start using your inbox by connecting channels!
            </ParagraphText>

            <ParagraphText
              variant="reg"
              color="thryv-steel"
              className="leading-[22px] !font-[Montserrat] !font-medium mb-2"
            >
              12+ channels are available for both email and message
            </ParagraphText>

            <RoundedOrangeButton onClick={openChannelConnectModal}>
              CONNECT CHANNELS
            </RoundedOrangeButton>
          </div>

          <div className="flex flex-col space-y-3">
            <div>
              <ParagraphText
                variant="sm"
                className="pb-1 font-semibold tracking-[3px] !font-[Montserrat] mb-2"
                color="thryv-orange-500"
              >
                AVAILABLE CHANNELS
              </ParagraphText>

              <ChannelLinksContainer>
                {buttonsData.map((button, i) => {
                  return (
                    <BottomModalButton
                      key={i}
                      IconName={button.iconName}
                      ButtonText={button.ButtonText}
                      IconType={button.iconType}
                    />
                  )
                })}
              </ChannelLinksContainer>
            </div>
          </div>
        </div>
      ) : null}

      {connectedChannels.length > 0 ? (
        <ConnectedChannelsGroup
          channels={connectedChannels}
          staffMembers={staffMembers}
        />
      ) : null}
    </SecurityStaffSectionPopover>
  )
}
