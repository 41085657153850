import styled from 'styled-components'

export const ModalContainer = styled.div`
  padding: 5px;
  width: 1030px;
  height: 690px;

  font-family: 'Montserrat';
  font-weight: 400;

  @media (min-width: 360px) and (max-width: 389px) {
    width: 300px;
    height: unset;
    padding: 0px;
  }
  @media (min-width: 390px) and (max-width: 640px) {
    width: 350px;
    height: unset;
    padding: 0px;
  }
  @media (min-width: 768px) and (max-width: 820px) {
    width: 700px;
    height: unset;
    padding: 0px;
  }
`

export const ModalBody = styled.div`
  width: 100%;
  padding: 20px;
  @media (min-width: 380px) and (max-width: 640px) {
    padding: 5px;
  }
`

export const LeftSide = styled.div`
  width: 700px;
  height: 500px;
  display: flex;
  flex-direction: column;
  text-alig: left;
  @media (max-width: 640px) {
    height: unset;
  }
`

export const LargeRadioContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: Column;
  justify-content: space-between;
  gap: 12px;
  @media (min-width: 390px) and (max-width: 640px) {
    flex-direction: column;
    width: 300px;
  }
  @media (min-width: 375px) and (max-width: 380px) {
    flex-direction: column;
    width: 280px;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 05px;
  p {
    font-size: 12px;
    line-height: 18px;
    font-family: 'Open Sans', sans-serif;
    color: #a3a5a7;
  }
  @media (max-width: 640px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 10px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 640px) {
    margin: 0px 0px;
    gap: 0px;
  }
`

export const RightSide = styled.div`
  width: 330px;
  height: 500px;
  @media (max-width: 640px) {
    height: unset;
  }
`
export const RightSidePaymentForm = styled.div`
  width: 45%;
  height: 500px;
  @media (max-width: 640px) {
    height: unset;
  }
`

export const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
`
export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ButtonAlt = styled.div`
  color: #a3a5a7;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  padding: 7px;
  margin-right: 10px;
  border-radius: 3px;
  ${(props) => (props.hover ? `&:hover { cursor: pointer}` : '')};
`
