import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { CustomButtonGroup } from '../../../../components/common/CustomButtonGroupDropDown/CustomButtonGroup'
import { Entitlements } from './Entitlements'
import { StatusPill } from '../../../../components/common/status-pill/status-pill'
import CustomModal from '../../../../components/common/CustomModal/Modal'
import { CancelPlanModal } from '../../../../components/common/modals/cancel-plan-modal/CancelPlanModal'
import { useMediaQuery } from 'react-responsive'

export const SubscriptionMobile = (props) => {
  const {
    tableData,
    openMobileTableRow,
    setOpenMobileTableRow,
    selectedItem,
    setSelectedItem,
    loading,
    activeSubscriptions,
    handleClose,
    latestPayment,
    openCancelModal,
    setOpenCancelModal,
  } = props
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const renderPlanName = (plan) => {
    if (
      plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Basic' ||
      plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Plus' ||
      plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Pro'
    ) {
      return plan?.subscription?.SUBSCRIPTION_PLAN_NAME.substring(14)
    } else {
      return plan?.subscription?.SUBSCRIPTION_PLAN_NAME
    }
  }

  return (
    <>
      {loading ? (
        <div className="flex justify-center h-[60px]">
          <span className="w-[38px] h-[38px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] box-border animate-spin relative top-5"></span>
        </div>
      ) : (
        tableData.map((data, index) => (
          <div key={index}>
            {data.subscription.SUBSCRIPTION_PLAN_NAME !== 'Thryv Marketplace' &&
            data.subscription.SUBSCRIPTION_PLAN_NAME !== 'TeamChat' ? (
              <div className="w-full flex items-center h-[48px] bg-[#F8F9FB] mt-[1rem] mb-[1rem]">
                {openMobileTableRow === index + 1 ? (
                  <FontAwesomeIcon
                    className="ml-[8px] text-[12px] text-[#808080]"
                    icon={faChevronUp}
                    onClick={() => setOpenMobileTableRow(0)}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="ml-[8px] text-[12px] text-[#808080]"
                    icon={faChevronDown}
                    onClick={() => setOpenMobileTableRow(index + 1)}
                  />
                )}
                {/* <img
                className="w-[32px] h-[32px] rounded-[4px]"
                src={data?.center?.logo}
              /> */}
                {/* <div className='flex '> */}
                <div className="flex w-full justify-between items-center">
                  <span className="text-[12px] text-[#4D4D4D] pl-2 font-semibold">
                    {data?.subscription?.SUBSCRIPTION_PLAN_NAME}
                  </span>
                  <div className="pr-2">
                    <CustomButtonGroup
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                    />
                    <CustomModal
                      isModalForm={true}
                      heading=""
                      hasCrossIcon={false}
                      isOpen={openCancelModal}
                      setIsOpen={setOpenCancelModal}
                      close={handleClose}
                      width={isMobile ? '94%' : '500px'}
                    >
                      <CancelPlanModal
                        handleClose={handleClose}
                        activeSubscriptions={activeSubscriptions}
                        latestPayment={latestPayment}
                        planName={data.subscription.SUBSCRIPTION_PLAN_NAME}
                        subscription={data}
                        renderPlanName={renderPlanName}
                      />
                    </CustomModal>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full flex justify-start items-center h-[48px] bg-[#F8F9FB] mt-[1rem] mb-[1rem]">
                {openMobileTableRow === index + 1 ? (
                  <FontAwesomeIcon
                    className="ml-[8px] text-[12px] text-[#808080]"
                    icon={faChevronUp}
                    onClick={() => setOpenMobileTableRow(0)}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="ml-[8px] text-[12px] text-[#808080]"
                    icon={faChevronDown}
                    onClick={() => setOpenMobileTableRow(index + 1)}
                  />
                )}
                <div className="pl-2">
                  <span className="text-[12px] text-[#4D4D4D] font-semibold">
                    {data?.subscription?.SUBSCRIPTION_PLAN_NAME}
                  </span>
                </div>
              </div>
            )}

            {openMobileTableRow === index + 1 && (
              <div>
                <p className="flex justify-between p-[8px]">
                  <span className="text-[#808080] text-[14px]">Plan Name</span>
                  <span className="text-[#231F20] text-[14px]">
                    {renderPlanName(data)}
                  </span>
                </p>
                <p className="flex justify-between p-[8px]">
                  <span className="text-[#808080] text-[14px]">Status</span>
                  <span className="text-[#231F20] text-[14px]">
                    {data?.subscription?.__typename === 'ActiveSubscription' ? (
                      <StatusPill color="#16A085">ACTIVE</StatusPill>
                    ) : (
                      <StatusPill color="#a3a5a7">INACTIVE</StatusPill>
                    )}
                  </span>
                </p>
                <p className="flex justify-between p-[8px]">
                  <span className="text-[#808080] text-[14px]">Users</span>
                  <span className=" text-[#231F20] text-[14px]">
                    {data?.subscription?.SUBSCRIPTION_QUANTITY}
                  </span>
                </p>
                {data?.subscription?.QUANTITY_TIER_PRICE === null ? null : (
                  <p className="flex justify-between p-[8px]">
                    <span className="text-[#808080] text-[14px]">Monthly Price</span>
                    <span className="text-[#231F20] text-[14px]">
                      {' '}
                      ${data?.subscription?.QUANTITY_TIER_PRICE} per user/month
                    </span>
                  </p>
                )}

                {/* {data.entitlementsNo > 0 && <><p className='flex justify-between p-[8px]'>
            <span className='text-[#808080] text-[14px]'>Entitlements</span>
            <span className='text-[#231F20] text-[14px]'>{data.entitlementsNo}</span>
          </p>
            <div className=' p-[8px]'>
                {
                  data.entitlements.map((data, index) => (
                    <Entitlements key={index} entitlement={data}/>
                  ))
                }
            </div>
          </>} */}
                <div className="p-[8px]">
                  {data.addons.map((data, index) => (
                    <Entitlements key={index} entitlement={data.ADD_ON_NAME} />
                  ))}
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </>
  )
}
