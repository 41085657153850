import { useState, useEffect } from 'react'
import { Input, Button } from '@thryvlabs/maverick'
import { useForm, Controller } from 'react-hook-form'
import thryvcclogo from '../../assets/thryvcclogomedium.png'
const FormModal = ({ setOpenFormModal, userData, setCountryVerified }) => {
  const [, setUserInfo] = useState({})
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
    },
  })
  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const email = watch('email')

  useEffect(() => {
    setValue('firstName', userData.firstName)
    setValue('lastName', userData.lastName)
    setValue('email', userData.email)
  }, [])

  const ValidateForm = () => {
    if (firstName === '' || lastName === '' || email === '') {
      return (
        <Button variant="primary" disabled type="submit">
          Continue
        </Button>
      )
    } else {
      return (
        <Button
          onClick={() => setCountryVerified(false)}
          variant="primary"
          type="submit"
        >
          Continue
        </Button>
      )
    }
  }

  return (
    <div className="h-[450px] flex flex-col items-center py-[40px]">
      <div className="h-[100px] w-[330px] gap-[20px] text-center flex flex-col items-center sm:mb-[45px]">
        <img
          src={thryvcclogo}
          alt="Thryv Command Center"
          className="w-[198px] h-[40px] "
        />
        <p className='font-["Open_Sans"] font-normal text-[14px] leading-[20px]'>
          We will notify you when Command Center is ready in your region. Please fill
          out the form below.
        </p>
      </div>
      <form
        onSubmit={handleSubmit((data) => {
          setUserInfo({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
          })
        })}
      >
        <div className="w-[420px] h-[134px] sm:w-[281px] sm:my-0 sm:mx-auto  flex flex-col gap-[10px] mt-[32px]">
          <div className="w-[420px] max-w-[420px] sm:w-[281px] h-[62px] flex gap-[16px]">
            <Controller
              control={control}
              rules={{ required: 'First Name required' }}
              name="firstName"
              render={({ field }) => (
                <Input
                  className="w-[198px] sm:w-[132.5px]"
                  onChange={(e) => setValue('firstName', e.target.value)}
                  type="text"
                  placeholder="First Name"
                  withLabel
                  labelType="floating"
                  variant="default"
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              rules={{ required: 'Last Name required' }}
              name="lastName"
              render={({ field }) => (
                <Input
                  className="w-[198px]  sm:w-[132.5px]"
                  onChange={(e) => setValue('lastName', e.target.value)}
                  type="text"
                  placeholder="Last Name"
                  withLabel
                  labelType="floating"
                  variant="default"
                  {...field}
                />
              )}
            />
          </div>
          <div className="sm:w-[281px] sm:ml-0">
            <Controller
              control={control}
              rules={{
                required: 'Please enter a valid email',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email',
                },
              }}
              name="email"
              render={({ field }) => (
                <Input
                  className="w-[420px]"
                  onChange={(e) => setValue('email', e.target.value)}
                  type="email"
                  placeholder="Email"
                  withLabel
                  labelType="floating"
                  variant="default"
                  {...field}
                />
              )}
            />
          </div>
        </div>

        <div className="w-[420px] sm:w-[329px] flex justify-center gap-[16px] mt-[40px]">
          <Button
            onClick={() => setOpenFormModal(false)}
            variant="text"
            level={2}
            textVariant="light"
          >
            Cancel
          </Button>
          <ValidateForm />
        </div>
      </form>
    </div>
  )
}

export default FormModal
