import EmojiPicker, { EmojiStyle } from 'emoji-picker-react'
import { forwardRef } from 'react'
import { TransitionContainer } from '../../../../common/transition-container'
import StyledEmojiMenuWrapper from './styled-emoji-menu-wrapper'

const EmojiMenu = forwardRef(
  ({ show, transitionProps, menuProps = {}, onEmojiClick = () => {} }, ref) => {
    const handleEmojiClick = (emojiData) => {
      onEmojiClick(emojiData)
    }

    return (
      <TransitionContainer
        show={show}
        {...transitionProps}
        ref={ref}
        unmount={false}
      >
        {/* ----- Styles used here are applied to the Emoji Menu ----- */}
        {/* ----- Using className prop will NOT work ----- */}
        <StyledEmojiMenuWrapper styles={menuProps.styles || {}}>
          <EmojiPicker
            onEmojiClick={handleEmojiClick}
            emojiStyle={EmojiStyle.NATIVE}
            lazyLoadEmojis={true}
            autoFocusSearch={true}
            skinTonePickerLocation={'PREVIEW'}
            width={menuProps.styles.width || 280}
            height={menuProps.styles.height || 320}
            categories={[
              'smileys_people',
              'animals_nature',
              'food_drink',
              'activities',
              'travel_places',
              'objects',
              'symbols',
              'flags',
            ]}
          />
        </StyledEmojiMenuWrapper>
      </TransitionContainer>
    )
  },
)

export default EmojiMenu
