import { Header } from '@thryvlabs/maverick'
import { useEffect, useState } from 'react'
import ErrorAlert from '../../../components/common/errorAlert/ErrorAlert'
import { PaymentCards } from './ProductAndServices/PaymentCards'
import { Subscriptions } from './ProductAndServices/Subscriptions'
import { useMediaQuery } from 'react-responsive'
import { useLazyQuery } from '@apollo/client'
import {
  FETCH_LATEST_PAYMENT,
  FETCH_ACTIVE_SUBSCRIPTIONS,
} from '../../../graphql/queries/settings-queries'
import moment from 'moment'
import { useAuth0 } from '@auth0/auth0-react'
import { CC_RECURLY_PLAN_CODES_LIST_EXCLUDE } from '../../../utils/recurly-constants'
import { useSelector, useDispatch } from 'react-redux'
import { setSubscriptionUuid } from './ProductAndServices/subscription-slices'
import { getCountryCurrency } from '../../../utils/get-country-currency'
import { Notification } from '../../../components/common/notification'
import { setPaymentProcessed } from '../../../components/common/modals/upgrade-plan-modal/slices/planInfoSlice'

const ProductServices = () => {
  const dispatch = useDispatch()
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const errMsg =
    'The last payment didn’t go through. You owe $XXX.XX. Please click here or go to the ‘Settings - Payment Methods’ page to update your payment information.'
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const [latestPayment, setLatestPayment] = useState([{}, {}])
  const [activeSubscriptions, setActiveSubscriptions] = useState([])
  const [notificationClosed, setNotificationClosed] = useState(true)
  const { user } = useAuth0()
  const { currentPlan, recentlyUpgraded } = useSelector((state) => state.planInfo)
  const [getLatestPayment, { loading: loadingPayment }] =
    useLazyQuery(FETCH_LATEST_PAYMENT)
  const [getActiveSubscriptions, { loading: loadingSubscriptions }] = useLazyQuery(
    FETCH_ACTIVE_SUBSCRIPTIONS,
  )
  const countryCode = useSelector((state) => state.countryCode.countryIso2)

  function fetchSubscriptionsAndPayment() {
    // getting info of active subscriptions
    getActiveSubscriptions({
      variables: {
        loc_id: user.businessId,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { data: subscriptions } = data.queryActiveSubscriptionsByLocation
        // Just showing CC subscriptions and others with addOns
        const ccSubscriptions = subscriptions.filter(
          (subscription) =>
            !CC_RECURLY_PLAN_CODES_LIST_EXCLUDE.includes(
              subscription.subscription.SUBSCRIPTION_PLAN_CODE,
            ) || subscription.addons.length > 0,
        )
        const commandCenterSubscriptionUuid = subscriptions.filter((sub) =>
          sub.subscription.SUBSCRIPTION_PLAN_NAME.includes('Command Center'),
        )
        dispatch(
          setSubscriptionUuid(
            commandCenterSubscriptionUuid[0].subscription.SUBSCRIPTION_UUID,
          ),
        )
        setActiveSubscriptions(ccSubscriptions)
      },
    })
    // getting info of latest payment
    getLatestPayment({
      variables: {
        loc_id: user.businessId,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setLatestPayment([
          {
            paymentType: 'Last Payment',
            paymentDate:
              data.queryLatestPaymentInfoByLocation.data.last_payment_amount &&
              data.queryLatestPaymentInfoByLocation.data.last_payment_date
                ? data.queryLatestPaymentInfoByLocation.data.last_payment_date
                : null,
            paymentAmount: data.queryLatestPaymentInfoByLocation.data
              .last_payment_amount
              ? `$${
                  data.queryLatestPaymentInfoByLocation.data.last_payment_amount
                } ${getCountryCurrency(countryCode)}`
              : '-',
          },
          {
            paymentType: 'Next Payment',
            paymentDate:
              data.queryLatestPaymentInfoByLocation.data.last_payment_amount &&
              data.queryLatestPaymentInfoByLocation.data.next_payment_date
                ? data.queryLatestPaymentInfoByLocation.data.next_payment_date
                : null,
            paymentAmount: data.queryLatestPaymentInfoByLocation.data
              .last_payment_amount
              ? `$${
                  data.queryLatestPaymentInfoByLocation.data.last_payment_amount
                } ${getCountryCurrency(countryCode)}`
              : '-',
          },
        ])
      },
    })
  }

  useEffect(() => {
    fetchSubscriptionsAndPayment()

    if (recentlyUpgraded) {
      setNotificationClosed(false)
      setTimeout(() => {
        fetchSubscriptionsAndPayment()
        setNotificationClosed(true)
      }, 60000)
    }
  }, [currentPlan, recentlyUpgraded])

  const handleCloseNotification = () => {
    setNotificationClosed(true)
    dispatch(setPaymentProcessed(false))
  }

  return (
    <>
      {showErrorAlert && (
        <ErrorAlert message={errMsg} setShowErrorAlert={setShowErrorAlert} />
      )}

      {/* ----- PAYMENT CARDS ----- */}
      <div className="grid gap-5 sm:gap-1 grid-cols-2 mb-[1rem] sm:mb-[0px] sm:bg-[#fff]">
        {latestPayment.map((data, idx) => (
          <PaymentCards
            className="sm:col-span-5 sm:m-[0.6rem]"
            key={idx}
            loading={loadingPayment}
            paymentType={data.paymentType}
            paymentDate={
              data.paymentDate
                ? moment(data.paymentDate).format('MMMM Do, YYYY')
                : null
            }
            paymentAmount={data.paymentAmount}
          />
        ))}
      </div>

      {/* ----- ACTIVE SUBSCRIPTIONS ----- */}
      <div className="bg-[white] rounded-[4px] p-6 border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A] sm:w-[95%] sm:ml-2">
        {!isMobile && (
          <>
            <div className="pl-[1rem] pr-[1rem] flex sm:flex-col items-center justify-between">
              <Header
                fontWeight="semibold"
                variant="h6"
                className="sm:w-[100%] mt-5 mb-5"
              >
                Services and Plans
              </Header>
            </div>
          </>
        )}
        <Subscriptions
          loading={loadingSubscriptions}
          activeSubscriptions={activeSubscriptions}
          latestPayment={latestPayment}
        />
        {recentlyUpgraded && !notificationClosed ? (
          <Notification
            variant="success"
            iconType="solid"
            iconVariant="circleExclamation"
            className="text-left gap-x-3 gap-y-6 sm:w-full sm:text-center"
            textToRender={`Thanks, ${user.name}! You have successfully ${
              currentPlan?.header == 'Professional' ? 'upgraded' : 'changed'
            } your plan to ${
              currentPlan?.header
            }! It can take up to 60 seconds for your updated plan to be reflected in your active subscriptions`}
            close={handleCloseNotification}
          />
        ) : null}
      </div>
    </>
  )
}
export default ProductServices
