import { Button } from '@thryvlabs/maverick'
import { useState } from 'react'
import { faLaptop, faX } from '@fortawesome/pro-regular-svg-icons'
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const AddImage = ({ setImageUrl, handleClose }) => {
  const [, setUploadedImage] = useState()
  const [activeTab, setActiveTab] = useState(0)

  function uploadSingleFile(e) {
    setUploadedImage(e.target.files[0])
    setImageUrl(URL.createObjectURL(e.target.files[0]))
    handleClose()
  }

  function tabChange(tabIndex) {
    setActiveTab(tabIndex)
  }

  return (
    <div>
      <div className="flex w-full border-b-[2px] border-[#939fb1]">
        <div
          onClick={() => tabChange(0)}
          className={`flex flex-col ml-2 mr-2 w-[120px] items-center ${
            activeTab == 0 ? 'border-b-[3px] border-[#364a65]' : ''
          }`}
        >
          <FontAwesomeIcon
            className={`text-[2rem] ${
              activeTab == 0 ? 'text-[#3376f6]' : 'text-[#182e57]'
            }`}
            icon={faLaptop}
            size={'lg'}
          />
          <p className="mt-2 mb-2"> My Files </p>
        </div>

        <div className="absolute top-[25px] right-[20px]">
          <FontAwesomeIcon
            onClick={handleClose}
            icon={faX}
            className="w-[18px] h-[18px] text-[#A3A5A7] cursor-pointer"
          />
        </div>
      </div>

      <div className="w-full p-[10px]">
        <div className="flex flex-col justify-center items-center m-auto border-[3px] w-full h-[500px] border-[#939fb1] border-dashed">
          <input
            className="h-[500px]"
            type="file"
            onClick={(event) => {
              event.target.value = null
            }}
            onChange={uploadSingleFile}
            style={{
              backgroundColor: 'transperant',
              opacity: '0',
              width: '100%',
              position: 'absolute',
            }}
          />
          <FontAwesomeIcon
            className="text-[6rem] text-[#939fb1] "
            icon={faCloudArrowUp}
            size={'lg'}
          />
          <p>Drag and Drop Here </p>
          <p> or </p>
          <Button variant="primary" type="submit">
            Browse
          </Button>
        </div>
      </div>

      <hr />
    </div>
  )
}
