const Pill = ({ notifications, collapsed, isActive }) => {
  return (
    <div>
      {!collapsed ? (
        <div
          data-testid="expanded-notification-pill"
          className={`${
            isActive && 'bg-thryv-night text-white'
          } w-[22px] h-[16px] text-[10px] text-dark-gray font-bold text-center align-middle bg-barely-gray group-hover:bg-thryv-night group-hover:text-white font-primary pt-[1px] rounded-[26px]`}
        >
          {notifications > 9 ? '9+' : notifications}
        </div>
      ) : (
        <div
          data-testid="collapsed-notification-pill"
          className={`absolute w-[10px] bottom-[21.5px] left-[24px] h-[10px] border-2 border-white rounded-2xl font-bold text-center align-middle bg-notification-red`}
        ></div>
      )}
    </div>
  )
}

export default Pill
