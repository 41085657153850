import { createSlice } from '@reduxjs/toolkit'
import { uuidv4 } from '../../../utils/uuidGenerator'

export const messageContentSlice = createSlice({
  name: 'messageContent',
  initialState: {
    labelOptions: [],
    labels: [
      {
        id: '82a17096-9dcf-11ed-a8fc-0242ac120002',
        value: 'Important',
        checked: false,
      },
      {
        id: '82a16b96-9dcf-11ed-a8fc-0242ac120002',
        value: 'Updates',
        checked: false,
      },
      {
        id: '82a165ba-9dcf-11ed-a8fc-0242ac120002',
        value: 'Custom 1',
        checked: false,
      },
    ],
    matters: {
      matter: undefined,
      labels: [{ label: 'Maxwell' }, { label: 'Arthur' }, { label: 'Gabriel' }],
    },
    selectedMatter: 'All',
    messages: [],
    showCreateLabelModal: false,
    selectedMessageForNewLabel: {
      channel_id: '',
      thread_id: '',
      theadpk: '',
      message_sk1: '',
    },
  },
  reducers: {
    setMessages(state, action) {
      state.messages = action.payload
    },
    setLabelOptions(state, action) {
      state.labels = action.payload
    },
    addLabel(state, action) {
      const newName = action.payload

      const newLabel = {
        LabelID: uuidv4(),
        LabelName: newName,
      }

      state.labels.push(newLabel)
    },
    toggleLabelChecked(state, action) {
      const label = state.labels.find((label) => label.id === action.payload)

      // FIXME: Make sure to update newName and newCheckedStatus in database
      // as well!!! May need to create a async function. Or the redux equivalent.
      if (label) label.checked = !label.checked
    },
    addNewLabel(state, action) {
      state.labels.push(action.payload)
    },
    editLabel(state, action) {
      const { id, value } = action.payload
      const label = state.labels.find((label) => label.id === id)
      if (label) {
        label.value = value
      }
    },
    deleteLabel(state, action) {
      const id = action.payload
      const index = state.labels.findIndex((label) => label.LabelID === id)
      if (index > -1) {
        state.labels.splice(index, 1)
      }
    },
    setMatters(state, action) {
      state.matters = action.payload
    },
    setSelectedMatter(state, action) {
      state.selectedMatter = action.payload
    },
    setShowCreateLabelModal(state, action) {
      state.showCreateLabelModal = action.payload
    },
    setSelectedMessageForNewLabel(state, action) {
      if (Object.keys(action.payload).length === 0) {
        state.selectedMessageForNewLabel = {
          channel_id: '',
          thread_id: '',
          theadpk: '',
          message_sk1: '',
        }
      } else {
        state.selectedMessageForNewLabel = action.payload
      }
    },
  },
})

export const {
  setMessages,
  addLabel,
  deleteLabel,
  setSelectedMatter,
  toggleLabelChecked,
  setLabelOptions,
  setShowCreateLabelModal,
  setSelectedMessageForNewLabel,
} = messageContentSlice.actions

export default messageContentSlice.reducer
