import { useState, useRef, forwardRef, useEffect, useContext } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import DOMPurify from 'dompurify'
import '../message-component.css'
import { ParagraphText } from '@thryvlabs/maverick'
import { MessageFooter } from './message-footer'
import { MessageSocialIcon } from '../message-social-icon'
import ActionButtonsMenu from '../action-buttons-menu/action-buttons-menu'
import { CommandCenterIcon } from '../../../../command-center-icon'
import { useClickOutside } from '../../../../../hooks/use-click-outside'
import { setMessages } from '../../../slices/messageContentSlice'
import { UploadedFile as Attachment } from '../../message-compose/attachments/uploaded-files'
import { BccOrRecipientsSection } from '../../common/message-recipient-section/message-recipient-section'
// import { BccOrRecipientsSection } from '../incoming-message/sections/message-section/message-section'
import './outgoing-message.css'
import { OutgoingMessageContext } from '../../../../../context/message-context'
import { ShowMoreButton } from '../../message-compose/compose-header/ccSection'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'
import phoneFormatter from '../../../../../utils/phoneFormatter'
import findYouTubeVideoId from '../../../common/youtube-embeds/findYoutubeVideoId'
import YouTubeEmbed from '../../../common/youtube-embeds/YoutubeEmbeded'

import {
  setMessageInModal,
  setViewFullMessageOpen,
} from '../../../slices/inboxSlice'

const MessageBoxContainer = forwardRef(({ children, className, ...rest }, ref) => {
  return (
    <div
      className={`${className} relative max-w-[465px] md:max-w-[300px] bg-thryv-sapphire-500 bg-opacity-[0.96] border-solid border border-[#EFEFEF] rounded-bl-xl rounded-br-xl duration-300 transition-all`}
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  )
})

export const OutgoingMessage = ({
  outgoingId,
  className,
  message,
  user,
  actionButtons,
  messages,
  selectedThreadId,
  setDeleteMsgClicked,
  refetchPins,
  refetchMessages,
  body,
  messageContentRef,
  lastMessage,
}) => {
  const dispatch = useDispatch()
  const messageBoxRef = useRef(null)
  const [showActionButtons, setShowActionButtons] = useState(false)
  const [willClamp, setWillClamp] = useState(false)
  const visibleMenuRef = useRef('')
  const msgRef = useRef(null)
  const [showMore, setShowMore] = useState(false)
  const outgoingMessage = useContext(OutgoingMessageContext)
  const [isDeletedMessage, setIsDeletedMessage] = useState(
    body === 'Message deleted.',
  )

  const msgText =
    body ||
    outgoingMessage?.email_snippet === null ||
    outgoingMessage?.email_snippet === undefined
      ? outgoingMessage?.body
      : outgoingMessage?.email_snippet
  const [messageText, setMessageText] = useState(msgText)
  const [messageTitle, setMessageTitle] = useState(message.title)

  let youtubeEmbedComponent = null
  if (message.text) {
    const youTubeVideoId = findYouTubeVideoId(message.text)
    if (youTubeVideoId) {
      // Make sure youTubeVideoId is not null or undefined
      youtubeEmbedComponent = <YouTubeEmbed videoId={youTubeVideoId} />
    }
  }

  const handleDeleteLabel = (label) => {
    const newMessages = messages.map((message) => {
      const selectedMessage = { ...message }

      if (message.id === outgoingId) {
        selectedMessage.labels = selectedMessage.labels.filter((labelItem) => {
          return labelItem.id !== label
        })
      }
      return selectedMessage
    })
    dispatch(setMessages(newMessages))
  }

  useClickOutside(() => {
    setShowActionButtons(false)
  }, messageBoxRef)

  const handleMouseEnter = () => {
    if (isDeletedMessage) return
    setShowActionButtons(true)
  }

  const handleShowMoreClick = () => {
    setShowMore(!showMore)
  }

  const handleMouseLeave = () => {
    if (visibleMenuRef.current === '') setShowActionButtons(false)
  }

  const { selectedThread } = useSelector((state) => state.inboxThreads)

  const getAttachmentLink = (file_id) => {
    const channelCCID = outgoingMessage?.pk1.split('#')[0]
    const channelID = outgoingMessage?.chanpk

    return `${channelCCID}/${channelID}/${selectedThread.id}/${file_id}`
  }

  const renderCallContent = () => {
    let call = {}

    switch (message.variant) {
      case 'CALL':
        call = {
          icon: {
            type: 'solid',
            variant: 'outgoingCall',
          },
          title: 'Outgoing Call',
        }
        break
    }
    return (
      <>
        <div className="p-4">
          <div className="flex gap-3 px-2">
            <div className="flex justify-center items-center">
              <CommandCenterIcon
                type={call.icon.type}
                variant={call.icon.variant}
                color="white"
                width="16"
                height="16"
              />
            </div>
            <div className="flex flex-col">
              <ParagraphText
                variant="reg"
                className="font-['Montserrat'] !font-semibold"
                color="white"
              >
                {call.title}
              </ParagraphText>

              {message.variant === 'CALL' && (
                <ParagraphText variant="reg" color="thryv-gray-light-500">
                  {message.text.toLowerCase() === 'missed call.'
                    ? 'Unanswered'
                    : message.text}
                </ParagraphText>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  useEffect(() => {
    setWillClamp(
      msgRef.current?.scrollHeight > msgRef.current?.clientHeight ||
        outgoingMessage?.body?.length > 150,
    )
  }, [msgRef])

  const attachmentsToShow = message?.attachments?.filter(
    (file) => file.messageID === message.sk1,
  )

  const firstTwoAttachments = attachmentsToShow.slice(0, 2)

  const useHTML = message.text.startsWith('<') || body.startsWith('<')
  const underlineLink = (htmlText) => {
    return htmlText.replace(
      /<a.*?href="([^"]*)".*?>(.*?)<\/a>/gi,
      '<a href="$1" target="_blank" rel="noopener noreferrer"><span class="underline">$2</span></a>',
    )
  }

  const formattedHTML = underlineLink(messageText || body)

  useEffect(() => {
    if (messageText && messageText === 'Message deleted.') {
      setMessageTitle('')
      setIsDeletedMessage(true)
      return messageText
    }
  }, [messageText, isDeletedMessage])

  const renderMessageContent = (
    <>
      <div className="m-4">
        <div ref={msgRef} className="mb-2 line-clamp-3">
          <ParagraphText
            variant="reg"
            className="font-['Montserrat'] !font-semibold mb-2"
            color="white"
          >
            {messageTitle}
          </ParagraphText>

          {!useHTML ? (
            <ParagraphText variant="sm" color="white">
              {messageText}
            </ParagraphText>
          ) : (
            <ParagraphText
              variant="sm"
              color="white"
              className="outgoing-message-inner-html"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formattedHTML) }}
            />
          )}
        </div>
        {youtubeEmbedComponent}

        {!isDeletedMessage && willClamp && message?.type !== 'CC' && (
          <button
            className="text-sm font-open-sans font-semibold text-white underline pt-[5px]"
            onClick={() => {
              batch(() => {
                dispatch(setViewFullMessageOpen(true))
                dispatch(setMessageInModal(outgoingMessage.body))
              })
            }}
          >
            View entire message
          </button>
        )}
      </div>

      {!isDeletedMessage &&
      (message.emailRecipients?.cc?.length > 0 ||
        message.emailRecipients?.bcc?.length > 0 ||
        attachmentsToShow?.length > 0) ? (
        <div className="bg-white flex flex-col p-2 pb-0 items-left rounded-b-[10px]">
          <div className="pl-3">
            {message?.emailRecipients.cc &&
            message?.emailRecipients.cc?.length >= 1 ? ( //checking that there's something to render inside cc array
              <BccOrRecipientsSection
                emailRecipient={message.emailRecipients.cc}
                type="cc"
              />
            ) : null}
          </div>
          <div className="pl-3">
            {message?.emailRecipients.bcc &&
            message?.emailRecipients.bcc?.length >= 1 ? (
              <BccOrRecipientsSection
                emailRecipient={message.emailRecipients.bcc}
                type="bcc"
              />
            ) : null}
          </div>

          {attachmentsToShow?.length > 2 && !showMore ? (
            <div className=" flex flex-row flex-wrap bg-white p-3 rounded-b-[10px] border-t border-[#eceef1]">
              {firstTwoAttachments?.map((file, i) => (
                <Attachment
                  file={JSON.parse(file.body)}
                  getAttachment={getAttachmentLink}
                  thumbnailUrl={file?.thumbnailUrl}
                  fileId={file.sk1}
                  key={i}
                />
              ))}
              <ShowMoreButton
                className="ml-1"
                number={attachmentsToShow.length - 2}
                onClick={() => handleShowMoreClick()}
                showMore={showMore}
              />
            </div>
          ) : attachmentsToShow?.length >= 2 ? (
            <div className=" flex flex-row flex-wrap bg-white p-3 rounded-b-[10px] border-t border-[#eceef1]">
              {attachmentsToShow.map((file, i) => (
                <Attachment
                  file={JSON.parse(file.body)}
                  getAttachment={getAttachmentLink}
                  thumbnailUrl={file?.thumbnailUrl}
                  fileId={file.sk1}
                  key={i}
                />
              ))}
            </div>
          ) : attachmentsToShow?.length === 1 ? (
            <div className=" flex flex-row flex-wrap bg-white p-3 rounded-b-[10px] border-t border-[#eceef1]">
              {attachmentsToShow.map((file, i) => (
                <Attachment
                  file={JSON.parse(file.body)}
                  getAttachment={getAttachmentLink}
                  thumbnailUrl={file?.thumbnailUrl}
                  fileId={file.sk1}
                  key={i}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )

  return (
    <>
      <div
        id={outgoingId}
        className={`message-container 
    ${className} 
    ${message.variant === 'CALL' && 'min-w-fit'}`}
      >
        <div className="px-2 pb-1 pt-2">
          <MessageBoxContainer
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isPinned={message.isPinned}
            ref={messageBoxRef}
          >
            <div className="flex absolute right-2.5 gap-2.5 -top-4 items-center h-9 w-full">
              {message?.type === 'CC' ? (
                <div className="h-6 w-6 ">
                  <CommandCenterIcon type="solid" variant="imap" />
                </div>
              ) : (
                <TooltipWithCenteredArrow
                  variant="top"
                  width="max-content"
                  title={
                    message.type === 'phone'
                      ? phoneFormatter(user.channelAddress)
                      : user?.channelAddress || ''
                  }
                >
                  <MessageSocialIcon variant={message?.type || 'gmail'} />
                </TooltipWithCenteredArrow>
              )}

              <ActionButtonsMenu
                setMessageText={setMessageText}
                show={
                  showActionButtons && actionButtons.clickHandlers ? true : false
                }
                variant={message?.variant || 'gmail'}
                type={message.type}
                disableButtons={actionButtons?.disableButtons || false}
                showPin={message.isPinned}
                labels={message.labels}
                threadpk={message.threadpk}
                sk1={message.sk1}
                pk1={message?.pk1}
                chanpk={message.chanpk}
                refetchMessages={refetchMessages}
                refetchPins={refetchPins}
                clickHandlers={actionButtons?.clickHandlers}
                allDropdownOrientations="left"
                hideRemindMeBtn={true}
                onVisibleMenuChange={(visibleMenu) =>
                  (visibleMenuRef.current = visibleMenu)
                }
                outgoingId={outgoingId}
                messageSk1={message.sk1}
                selectedThreadId={selectedThreadId}
                setDeleteMsgClicked={setDeleteMsgClicked}
                messageContentRef={messageContentRef}
              />
            </div>

            {(message.variant === 'MSG' || message.variant === 'SMS') &&
              renderMessageContent}
            {message.variant === 'CALL' && renderCallContent()}
          </MessageBoxContainer>
        </div>

        <MessageFooter
          channelType={message.type} //using this to know if its type = phone to format phone number
          fullName={user.fullName}
          timestamp={message.timestamp}
          showDoubleCheck={message.showSendReciept}
          labels={message.labels}
          handleDeleteLabel={handleDeleteLabel}
          messages={messages}
          isDeletedMessage={isDeletedMessage}
          outgoingId={outgoingId}
          lastMessage={lastMessage}
        />
      </div>
    </>
  )
}
