import { useEffect, useState, useRef, useLayoutEffect } from 'react'
import MenuItem from './MenuItem'
import EditMenu from '../edit-menu/EditMenu'
import { Icon } from '@thryvlabs/maverick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripDotsVertical, faPencil } from '@fortawesome/pro-regular-svg-icons'
import { Draggable } from 'react-beautiful-dnd'
import { StyledDiv } from './MenuSection.styles'
import useDisplayCenter from '../../../hooks/use-display-center'
import { useDispatch } from 'react-redux'
import { setNavigationPreferences } from '../../../redux-toolkit/slices/user-preferences/user-preferences'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectNotificationsCount } from '../../../redux-toolkit/slices/notifications/notifications-slice'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_PREFERENCES } from '../../../graphql'
import { useAuth0 } from '@auth0/auth0-react'

const MenuSection = ({
  options,
  sideNavData,
  collapsed,
  index,
  id,
  setIsEditing,
  sectionIsEditing,
  setSectionIsEditing,
  sectionOrder,
  sectionsOrder,
  setSectionOrder,
  openEditMenuId,
  setOpenEditMenuId,
  setIsOpen,
  draggableIdState,
  destination,
  source,
  allowAnimate,
  setAllowAnimate,
  editedId,
  setEditedId,
  setLastCheckedIndex,
  outOfRangeNum,
  setOutOfRangeNum,
  isEditing,
  isMobile,
  isLoading,
  allRefs,
}) => {
  const collapseRef = useRef(false)
  const [sideMenuItem, setSideMenuItem] = useState([])
  const [checkedItems, setCheckedItems] = useState(sectionOrder)
  const [unCheckedItems, setUnCheckedItems] = useState([])
  const [previousSectionOrder, setPreviousSectionOrder] = useState(sectionOrder)
  const [showEditMenu, setShowEditMenu] = useState(false)
  const dispatch = useDispatch()
  const { user } = useAuth0()

  const { hideNavBarSection } = useDisplayCenter(options)
  const ishideNavBarSection = hideNavBarSection()
  const notifications = useSelector(selectNotificationsCount)
  const userPreferences = useSelector((state) => state.userPreferences)
  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES)

  useEffect(() => {
    collapseRef.current = collapsed
  }, [collapsed])

  useEffect(() => {
    if (showEditMenu) {
      let checkedCount = 0
      options?.items.forEach((item) => {
        if (item.visible) {
          checkedCount += 1
        }
      })
      setLastCheckedIndex(checkedCount - 1)
    }
  }, [showEditMenu])

  useLayoutEffect(() => {
    let sortedSideMenuItems = sortSideMenuItems(sideMenuItem)
    setSideMenuItem(sortedSideMenuItems)
  }, [sectionOrder])

  const handleClickSectionLink = (e) => {
    if (
      e.target.tagName.toLowerCase() === 'svg' ||
      e.target.tagName.toLowerCase() === 'path'
    ) {
      e.preventDefault()
    }
  }

  const handleEditMenuOpen = () => {
    if (openEditMenuId === index) {
      setOpenEditMenuId(-1)
      setIsEditing(false)
      setShowEditMenu(false)
    } else {
      setOpenEditMenuId(index)
      setIsEditing(true)
      setShowEditMenu(true)
    }
    // setIsEditing(!isEditing)
    // setShowEditMenu(!showEditMenu)
    setEditedId('')
    setOutOfRangeNum(0)
    setLastCheckedIndex(checkedItems.length - 1)
    setAllowAnimate((current) => !current)
    draggableIdState = ''
    draggableIdState == '' ? setAllowAnimate(false) : setAllowAnimate('')
    setSectionIsEditing(options?.id)
  }

  const handleOnChange = (id) => {
    setPreviousSectionOrder(sectionOrder)
    setOutOfRangeNum(0)
    const changedItemId = sideMenuItem[id].id
    setEditedId(changedItemId)
    const itemVisible = sideMenuItem[id].visible
    let unchecked = []
    let checked = []
    draggableIdState = ''
    draggableIdState == '' ? setAllowAnimate(false) : null

    // const sideMenuItemCopy = structuredClone(sideMenuItem)
    const sideMenuItemUpdatedVisibility = structuredClone(sideMenuItem).map(
      (item) => {
        if (item.id === changedItemId) {
          return { ...item, visible: !item.visible }
        } else {
          item.visible ? checked.push(item.id) : unchecked.push(item.id)
          return item
        }
      },
    )
    itemVisible ? unchecked.unshift(changedItemId) : checked.push(changedItemId)
    setSideMenuItem(sideMenuItemUpdatedVisibility)
    setCheckedItems(checked)
    setUnCheckedItems(unchecked)
    const newOrder = sideNavData.data.map((item) => {
      if (item.id === options?.id) {
        let itemData = {
          id: item.id,
          title: item.title,
          order: [...checked, ...unchecked],
          items: item.items.map((subitem) => {
            if (subitem.id === changedItemId) {
              return { ...subitem, visible: !subitem.visible }
            } else {
              return subitem
            }
          }),
        }
        if (item?.link) itemData.link = item.link
        return itemData
      } else {
        return item
      }
    })
    const newSideNavOrder = {
      order: sideNavData.order,
      data: newOrder,
    }
    setSectionOrder({
      ...sectionsOrder,
      [sectionIsEditing]: [...checked, ...unchecked],
    })
    setLastCheckedIndex(checked.length - 1)

    dispatch(setNavigationPreferences(newSideNavOrder))

    const newUserPreferences = {
      navigationPreferences: userPreferences.navigationPreferences,
      navigationCollapsed: userPreferences.navigationCollapsed,
      notificationPreferences: userPreferences.notificationPreferences,
    }
    newUserPreferences.navigationPreferences = newSideNavOrder

    updateUserPreferences({
      variables: {
        userId: user.email,
        preferences: JSON.stringify({
          time_stamp: Date.now(),
          data: newUserPreferences,
        }),
      },
    })
  }

  const sortSideMenuItems = (items) => {
    let newOrder = []
    // eslint-disable-next-line no-unused-vars
    let sorted = sectionOrder?.forEach((id) => {
      // eslint-disable-next-line no-unused-vars
      let test = items?.forEach((item) => {
        if (id === item.id) {
          newOrder.push(item)
        }
      })
    })
    return newOrder
  }

  useEffect(() => {
    const sortedItems = sortSideMenuItems(options?.items)
    setSideMenuItem(sortedItems)
  }, [sectionOrder, options])

  // useEffect(() => {
  //   setSideMenuItem(options?.items)
  // }, [options])

  return (
    <div id={options?.id} className="relative">
      {!ishideNavBarSection && (
        <Draggable
          draggableId={options?.id}
          key={id}
          index={index}
          isDragDisabled={openEditMenuId > -1}
        >
          {(provided, snapshot) => {
            return (
              <StyledDiv
                {...provided.draggableProps}
                ref={provided.innerRef}
                isDragging={snapshot.isDragging}
                className={`${
                  collapsed
                    ? `${
                        !collapseRef.current && sectionIsEditing.length === 0
                          ? 'animate-slideup'
                          : ''
                      } w-[36px]`
                    : `${
                        collapseRef.current && sectionIsEditing.length === 0
                          ? 'animate-slidedown'
                          : ''
                      } w-[231px]`
                }`}
              >
                {!collapsed ? (
                  options?.link ? (
                    <NavLink
                      style={{ textDecoration: 'none' }}
                      to={options?.link}
                      target="_blank"
                      onClick={handleClickSectionLink}
                    >
                      <div
                        ref={
                          sectionIsEditing === options?.id
                            ? allRefs.centerEditingRef
                            : null
                        }
                        className="md:hidden group flex w-[231px] gap-[8px] py-[6px] pl-[8px] rounded-[4px] hover:bg-[#ECEEF1] hover:text-black hover:cursor-pointer"
                      >
                        <h6 className=" text-dark-gray text-[12px] font-semibold leading-[18px] font-primary">
                          {options?.title}
                        </h6>
                        <div {...provided.dragHandleProps}>
                          {!isEditing && (
                            <FontAwesomeIcon
                              icon={faGripDotsVertical}
                              size={'lg'}
                              className="h-[18px] absolute left-[195px] invisible group-hover:visible hover:cursor-grab"
                            />
                          )}
                        </div>
                        {options?.title !== 'Custom Links' && !isEditing && (
                          <FontAwesomeIcon
                            id={options?.id}
                            icon={faPencil}
                            className="absolute left-[212px] h-[16px] w-[16px] invisible group-hover:visible"
                            onClick={handleEditMenuOpen}
                          />
                        )}
                        {options?.title === 'Custom Links' && (
                          <Icon
                            onClick={() => setIsOpen(true)}
                            className="absolute left-[212px] h-[16px] w-[16px] invisible group-hover:visible"
                            type="regular"
                            variant="circlePlus"
                          />
                        )}
                      </div>
                    </NavLink>
                  ) : (
                    <div
                      ref={
                        sectionIsEditing === options?.id
                          ? allRefs.centerEditingRef
                          : null
                      }
                      className="md:hidden group flex w-[231px] gap-[8px] py-[6px] pl-[8px] rounded-[4px] hover:bg-[#ECEEF1] hover:text-black hover:cursor-pointer"
                    >
                      <h6 className=" text-dark-gray text-[12px] font-semibold leading-[18px] font-primary">
                        {options?.title}
                      </h6>
                      <div {...provided.dragHandleProps}>
                        {!isEditing && (
                          <FontAwesomeIcon
                            icon={faGripDotsVertical}
                            size={'lg'}
                            className="h-[18px] absolute left-[195px] invisible group-hover:visible hover:cursor-grab"
                          />
                        )}
                      </div>
                      {options?.title !== 'Custom Links' && !isEditing && (
                        <FontAwesomeIcon
                          id={options?.id}
                          icon={faPencil}
                          className="absolute left-[212px] h-[16px] w-[16px] invisible group-hover:visible"
                          onClick={handleEditMenuOpen}
                        />
                      )}
                      {options?.title === 'Custom Links' && (
                        <Icon
                          onClick={() => setIsOpen(true)}
                          className="absolute left-[212px] h-[16px] w-[16px] invisible group-hover:visible"
                          type="regular"
                          variant="circlePlus"
                        />
                      )}
                    </div>
                  )
                ) : (
                  <div className="hidden" {...provided.dragHandleProps}></div>
                )}
                <ul>
                  {sideMenuItem?.map((option, idx) => {
                    /* ---- Hide social platform for now ---- */
                    if (
                      option.title === 'Social Content' ||
                      option.title === 'Social'
                    ) {
                      return
                    }
                    return (
                      option.visible && (
                        <MenuItem
                          options={option}
                          collapsed={collapsed}
                          isMobile={isMobile}
                          id={option.id}
                          index={index}
                          itr={idx}
                          key={option.id}
                          title={options?.title}
                          notifications={notifications[option.title]}
                          draggableIdState={draggableIdState}
                          sectionOrder={sectionOrder}
                          destination={destination}
                          source={source}
                          showEditMenu={showEditMenu}
                          allowAnimate={allowAnimate}
                          outOfRangeNum={outOfRangeNum}
                          checkedItems={checkedItems}
                          openEditMenuId={openEditMenuId}
                          isLoading={isLoading}
                        />
                      )
                    )
                  })}
                </ul>
              </StyledDiv>
            )
          }}
        </Draggable>
      )}
      {openEditMenuId === index && (
        <EditMenu
          ref={allRefs.editMenuRef}
          title={options?.title}
          sideMenuItem={sideMenuItem}
          handleChange={handleOnChange}
          setOpenEditMenuId={setOpenEditMenuId}
          setSectionIsEditing={setSectionIsEditing}
          editedId={editedId}
          setEditedId={setEditedId}
          setIsEditing={setIsEditing}
          checkedItems={checkedItems}
          unCheckedItems={unCheckedItems}
          setAllowAnimate={setAllowAnimate}
          draggableIdState={draggableIdState}
          outOfRangeNum={outOfRangeNum}
          setOutOfRangeNum={setOutOfRangeNum}
          previousSectionOrder={previousSectionOrder}
        />
      )}
    </div>
  )
}

export default MenuSection
