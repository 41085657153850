import styled from 'styled-components'
import { Button } from '@thryvlabs/maverick'

export const borderColors = {
  red: '#DF2A2A',
  yellow: '#FAC02B',
}

export const ChannelButton = styled(Button)`
  height: 36px;
  width: 36px;
  border-radius: 5px;
  padding: 7px;

  font-size: 15px;
  background-color: ${(props) => (props.active == 'false' ? '#979797' : '#FFFFFF')};
  border: ${(props) =>
    props.$Status == '99' ? '1.5px solid #DF2A2A' : '1px #eceef1 solid'};

  ${({ $borderColor }) =>
    $borderColor && `border: 1.5px solid ${borderColors[$borderColor]};`}

  &:hover {
    background-color: ${(props) => (props.active == 'true' ? '#ECEEF1' : '#979797')};

    color: #000;
  }
`

// border-[#EF9595]
