import 'react-awesome-lightbox/build/style.css'
import UserProfileAndActivity from './user-profile-and-activity/UserProfileAndActivity'
import SharedMedia from './shared-media/SharedMedia'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { useEffect, forwardRef } from 'react'

const RightPanel = forwardRef(
  (
    {
      setShowFiles,
      setShowProfile,
      setShowPanel,
      showFiles,
      showProfile,
      showPanel,
    },
    ref,
  ) => {
    const selectedThreadId = useSelector(
      (state) => state.inboxThreads.selectedThread.id,
    )
    useEffect(() => {
      setShowPanel(false)
      setShowFiles(false)
      setShowProfile(false)
    }, [selectedThreadId])
    return (
      <div
        ref={ref}
        className={
          showPanel
            ? 'absolute right-0 bg-white h-[calc(100%-138px)] w-[350px] shadow-thryv rounded-[4px] py-[24px] px-[22px] font-semibold font-montserrat text-thryv-black-500 border border-thryv-gray-light-400 overflow-auto no-scrollbar transition duration-200 ease-in-out transform  animate-slideInFromRight z-30'
            : ''
        }
      >
        {showPanel && showFiles ? (
          <SharedMedia />
        ) : showPanel && showProfile ? (
          <UserProfileAndActivity />
        ) : (
          <></>
        )}
      </div>
    )
  },
)

export default RightPanel
