import { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import images from './CarouselImages'

const Carousel = ({ step }) => {
  const currentStep = step - 1

  const sliderRef = useRef(null)

  useEffect(() => {
    if (sliderRef.current && currentStep >= 0 && currentStep < images.length) {
      sliderRef.current.slickGoTo(currentStep)
    }
  }, [currentStep])

  const settings = {
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    initialSlide: currentStep >= 0 && currentStep < images.length ? currentStep : 0,
  }

  return (
    <div className="mt-[125px]">
      <Slider {...settings} ref={sliderRef}>
        {images.map((item) => (
          <div key={item.id}>
            <div>
              <img src={item.src} alt={item.alt} className="m-auto" />
            </div>
            <div className="flex gap-1 justify-center mb-[30px]">
              <h1 className="text-black mt-4 font-['Open_Sans'] font-semibold text-[18px] leading-[20px]">
                {item.header}
              </h1>
              <h1 className="text-[#FF5000] mt-4 font-['Open_Sans'] font-semibold text-[18px] leading-[20px]">
                {item.headerOrange}
              </h1>
            </div>
            <div className="w-[348px] flex wrap text-center flex-col my-0 mx-auto items-center h-[140px] max-h-[140px] pb-[80px]">
              <span className="text-black mt-3 font-['Open_Sans'] font-normal text-[14px] leading-[20px]">
                {item.txt}{' '}
                <span className="text-[#FF5000] font-['Open_Sans'] font-normal text-[14px] leading-[20px]">
                  {item.txtOrange}
                </span>
                <span className="text-black font-['Open_Sans'] font-normal text-[14px] leading-[20px]">
                  {item.txt2}
                </span>
              </span>{' '}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Carousel
