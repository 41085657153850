import { Avatar } from '@thryvlabs/maverick'

export const CommandCenterAvatar = ({
  className,
  widthAndHeight,
  firstName,
  lastName,
  imageUrl,
  avatarProps,
}) => {
  const renderImageAvatar = () => (
    <Avatar
      className="w-full h-full"
      imageUrl={imageUrl}
      variant="image"
      size={avatarProps?.size || 'small'}
    />
  )

  const renderNameAvatar = () => (
    <Avatar
      className="w-full h-full"
      variant="name"
      size={avatarProps?.size || 'small'}
      name={{
        firstName,
        lastName,
      }}
      staticBackground
      backgroundColor={'#9CAFF7'}
    />
  )

  const renderUnknownContactAvatar = () => (
    <Avatar
      variant="unidentified"
      name={{
        firstName: firstName,
        lastName: lastName,
      }}
      className="w-full h-full object-cover"
      size={avatarProps?.size || 'small'}
    />
  )

  return (
    <div
      className={className}
      style={{
        width: widthAndHeight || '24px',
        height: widthAndHeight || '24px',
      }}
    >
      <div
        className="flex items-center justify-center rounded-full overflow-hidden"
        style={{
          width: widthAndHeight || '24px',
          height: widthAndHeight || '24px',
        }}
      >
        {imageUrl ? renderImageAvatar() : null}

        {!imageUrl && firstName && lastName ? renderNameAvatar() : null}

        {!imageUrl && (!firstName || !lastName)
          ? renderUnknownContactAvatar()
          : null}
      </div>
    </div>
  )
}
