import { useTranslations, formatTimestamp } from '@knocklabs/react'
import { useRef, useState, useEffect } from 'react'
import formatContactData from '../../inbox/hooks/formatContactData'
import RoundedActionButton from '../../inbox/common/rounded-action-button'
import { TransitionContainer } from '../../common/transition-container'
import { MenuButton } from '../../inbox/message-content/message-header/header-menu/buttons/tri-dot-button-with-menu/menu-button'
import { CommandCenterIcon } from '../../command-center-icon'
import { createPortal } from 'react-dom'

const NotificationFeed = ({
  notification,
  markAsRead,
  viewMoreClicked,
  clearSingleNotification,
  markAsUnread,
  openNotification,
  index,
  items,
  allRefs,
}) => {
  const { locale } = useTranslations()
  const triDotRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 })

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        triDotRef.current &&
        !allRefs.menuRef.current.contains(event.target) &&
        !triDotRef.current.contains(event.target)
      ) {
        setShowMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [triDotRef])

  useEffect(() => {
    if (showMenu && allRefs.notificationRef.current) {
      const rect = allRefs.notificationRef.current.getBoundingClientRect()
      if (index === items.length - 1) {
        const menuWidth = 219
        const offsetLeft = -40
        const offsetTop = -20

        let leftPosition = rect.right - menuWidth + offsetLeft + window.scrollX

        const viewportWidth = window.innerWidth
        if (leftPosition + menuWidth > viewportWidth) {
          leftPosition = viewportWidth - menuWidth
        }

        const topPosition = rect.bottom + offsetTop + window.scrollY

        setMenuPosition({
          top: topPosition,
          left: leftPosition,
        })
      }

      if (index === items.length - 2) {
        const menuWidth = 219
        const offsetLeft = -40
        const offsetTop = -90

        let leftPosition = rect.right - menuWidth + offsetLeft + window.scrollX

        const viewportWidth = window.innerWidth
        if (leftPosition + menuWidth > viewportWidth) {
          leftPosition = viewportWidth - menuWidth
        }

        const topPosition = rect.bottom + offsetTop + window.scrollY

        setMenuPosition({
          top: topPosition,
          left: leftPosition,
        })
      }
    }
  }, [showMenu])

  const handleScroll = () => {
    setShowMenu(false)
  }

  const keys = { 37: 1, 38: 1, 39: 1, 40: 1 }

  function preventDefault(e) {
    e.preventDefault()
  }

  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e)
      return false
    }
  }

  let supportsPassive = false
  try {
    const options = Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get: function () {
        supportsPassive = true
      },
    })
    window.addEventListener('test', null, options)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Passive event listener not supported:', e)
  }

  const wheelOpt = supportsPassive ? { passive: false } : false
  const wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

  function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false) // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt) // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt) // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false)
  }

  function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault)
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt)
    window.removeEventListener('touchmove', preventDefault, wheelOpt)
    window.removeEventListener('keydown', preventDefaultForScrollKeys)
  }

  useEffect(() => {
    const menuElement = allRefs.menuRef.current

    if (menuElement) {
      const handleMouseEnter = () => {
        disableScroll()
      }

      const handleMouseLeave = () => {
        enableScroll()
      }

      menuElement.addEventListener('mouseenter', handleMouseEnter)
      menuElement.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        menuElement.removeEventListener('mouseenter', handleMouseEnter)
        menuElement.removeEventListener('mouseleave', handleMouseLeave)
        enableScroll()
      }
    }
  }, [allRefs.menuRef])

  useEffect(() => {
    allRefs.notificationRef.current?.addEventListener('scroll', handleScroll)
    return () => {
      allRefs.notificationRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [allRefs.notificationRef])

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(false)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [showMenu])

  function truncateMessage(str) {
    let plainText = str.replace(/<[^>]*>/g, '').replace(/\n/g, ' ')

    if (plainText.length > 35) {
      return plainText.slice(0, 35) + '...'
    } else {
      return plainText
    }
  }

  function truncateSubject(str) {
    let plainText = str.replace(/<[^>]*>/g, '').replace(/\n/g, ' ')

    if (plainText.length > 30) {
      return plainText.slice(0, 30) + '...'
    } else {
      return plainText
    }
  }

  return (
    <div className="px-[16px]" data-testid="notification-feed">
      <div className="w-full max-w-[396px] h-[74px] border-b border-[#e3e6e8] items-center justify-stretch flex gap-[12px] ">
        <div className="relative">
          {notification.read_at ? null : (
            <div
              data-testid="unread-notification-dot"
              className="rounded-full h-[15px] w-[15px] bg-white flex items-center justify-center absolute z-[1000] -left-2 -top-1"
            >
              <div className="rounded-full h-[10px] w-[10px] bg-[#ff5000]" />
            </div>
          )}
          <CommandCenterIcon
            variant={'cc-subscription'}
            type={'solid'}
            width={40}
            height={40}
          />
        </div>

        <div className="flex flex-col w-[270px]">
          <h6 className="font-[montserrat] font-semibold text-[14px] leading-5 text-[#231f20]">
            {truncateSubject(formatContactData(notification.data.subject))}
          </h6>
          <h6 className="font-open-sans font-normal text-[14px] leading-5 text-[#4d4d4d]">
            {truncateMessage(notification.data.message)}
          </h6>
          <h6 className="font-open-sans font-normal text-[12px] leading-[18px] text-[#a3a5a7]">
            {formatTimestamp(notification.inserted_at, { locale })}
          </h6>
        </div>
        <div className={`flex justify-end relative`}>
          <RoundedActionButton
            className="!w-[30px] !h-[30px] rotate-90 bg-white"
            type="regular"
            dataTestId="notification-menu-button"
            icon={{
              variant: 'horizontalEllipsis',
              type: 'regular',
              width: '16',
              height: '4',
              color: '#808080',
              hoverColor: '#4d4d4d',
            }}
            active={true}
            onClick={() => setShowMenu(!showMenu)}
            isCCIcon
            ref={triDotRef}
          />

          {(index === items.length - 1 || index === items.length - 2) &&
          showMenu &&
          viewMoreClicked ? (
            createPortal(
              <div
                ref={allRefs.menuRef}
                data-testid="notification-menu-portal"
                className="absolute z-[1000]"
                style={{
                  top: `${menuPosition.top}px`,
                  left: `${menuPosition.left}px`,
                }}
              >
                <TransitionContainer
                  show={showMenu}
                  className={`flex flex-col p-2 !w-[219px]`}
                  as="ul"
                  transformOrigin="top right"
                  unmount={false}
                  ref={allRefs.menuRef}
                >
                  <div
                    className="border-[#e3e6e8] border-b h-[42px] mb-2"
                    data-testid="notification-menu-portal-open"
                  >
                    <MenuButton
                      noIcon={true}
                      onClick={() => {
                        markAsRead(notification)
                        openNotification(notification)
                      }}
                    >
                      Open
                    </MenuButton>
                  </div>
                  {notification.read_at ? (
                    <MenuButton
                      noIcon={true}
                      onClick={() => {
                        markAsUnread(notification)
                        setShowMenu(false)
                      }}
                    >
                      Mark as unread
                    </MenuButton>
                  ) : (
                    <MenuButton
                      noIcon={true}
                      onClick={() => {
                        markAsRead(notification)
                        setShowMenu(false)
                      }}
                    >
                      Mark as read
                    </MenuButton>
                  )}

                  <MenuButton
                    noIcon={true}
                    onClick={() => {
                      clearSingleNotification(notification)
                      setShowMenu(false)
                    }}
                  >
                    Clear
                  </MenuButton>
                </TransitionContainer>
              </div>,
              document.body,
            )
          ) : (
            <div
              ref={allRefs.menuRef}
              className="absolute bottom-0 right-[225px]"
              data-testid="notification-menu-normal"
            >
              <TransitionContainer
                show={showMenu}
                className={`flex flex-col p-2 !w-[219px]`}
                as="ul"
                transformOrigin="top right"
                unmount={false}
                ref={allRefs.menuRef}
              >
                <div className="border-[#e3e6e8] border-b h-[42px] mb-2">
                  <MenuButton
                    noIcon={true}
                    onClick={() => {
                      markAsRead(notification)
                      openNotification(notification)
                    }}
                  >
                    Open
                  </MenuButton>
                </div>
                {notification.read_at ? (
                  <MenuButton
                    noIcon={true}
                    onClick={() => {
                      markAsUnread(notification)
                      setShowMenu(false)
                    }}
                  >
                    Mark as unread
                  </MenuButton>
                ) : (
                  <MenuButton
                    noIcon={true}
                    onClick={() => {
                      markAsRead(notification)
                      setShowMenu(false)
                    }}
                  >
                    Mark as read
                  </MenuButton>
                )}
                <MenuButton
                  noIcon={true}
                  onClick={() => {
                    clearSingleNotification(notification)
                    setShowMenu(false)
                  }}
                >
                  Clear
                </MenuButton>
              </TransitionContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NotificationFeed
