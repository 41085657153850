import './filter-menu.css'
import { useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import {
  setInboxAdvancedFilters,
  setInboxFilter,
  clearInboxThreadIds,
} from '../../../../../slices/inboxThreadsSlice'
import { FETCH_THREADS } from '../../../../../../../graphql'
import { useActiveChannels } from '../../../../../../../hooks/use-active-channels'
import { useInboxMenuConversations } from '../../../../hooks/use-inbox-menu-conversations'

// Components
import {
  SearchInOption,
  // LabelOption,
  ChannelOption,
  DateOption,
  // AttachmentOption,
} from '../../../sections/filter-section'
import { FilterItemGroup } from '../../../sections/filter-section/common'
import { TransitionContainer } from '../../../../../../common/transition-container'
import { Button } from '@thryvlabs/maverick'

export const FilterMenu = ({ className, show, onSearchClick }) => {
  const dispatch = useDispatch()
  const { inboxAdvancedFilters, inboxFilter } = useSelector(
    (state) => state.inboxThreads,
  )

  /*
    COMMENTING THIS OUT BECAUSE ITS NOT BEING USED AND ITS JUST RUNNING FOR NO REASON
    const { data: labelData } = useQuery(GET_LABEL_OPTIONS_BY_LOCATION, {
      variables: {
        locationid: auth0User.businessId,
      },
    })
  
    eslint-disable-next-line no-unused-vars
    const labels = inboxAdvancedFilters.labels.length
    ? inboxAdvancedFilters.labels
    : labelData?.queryLabelOptionsByLocation?.map((label) => {
        return { id: label.LabelID, value: label.LabelName, checked: false }
      })

  */
  const activeChannels = useActiveChannels()
  const channelProviders = activeChannels.map((channel) => channel.Provider)

  const getFormData = (form) => {
    const formData = new FormData(form)
    const formDataObject = {}

    for (const pair of formData.entries()) {
      const key = pair[0]
      const val = pair[1]

      if (key === 'search in') formDataObject[key] = val
      if (key === 'date') formDataObject[key] = JSON.parse(val)
      if (key === 'channel') formDataObject[key] = JSON.parse(val)
      if (key === 'label') formDataObject[key] = JSON.parse(val)
      if (key === 'attachment') formDataObject[key] = JSON.parse(val)
    }

    return formDataObject
  }

  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const startSearch = (e) => {
    e.preventDefault()
    const formData = getFormData(e.target)

    const refactoredFormData = {
      folder: formData['search in'],
      channels: formData.channel,
      labels: formData.label,
      date: formData.date,
      attachment: formData.attachment,
    }
    dispatch(setInboxAdvancedFilters(refactoredFormData))
    dispatch(setInboxFilter(refactoredFormData.folder))
    dispatch(clearInboxThreadIds([]))
    getThreads()
  }

  return (
    <div className="absolute min-w-[100%] z-20">
      <TransitionContainer
        show={show}
        className={`${className} border border-thryv-gray-light-400`}
        transformOrigin={'top center'}
        positioning="relative"
      >
        <form className="p-[15px]" onSubmit={(e) => startSearch(e)}>
          <FilterItemGroup className="w-[190px] max-w-[200px]">
            <SearchInOption defaultFilter={inboxFilter} />
            {/* REVISITING LABELS. */}
            {/* <LabelOption initialLabels={labels} /> */}
            <ChannelOption
              defaultChannels={channelProviders}
              defaultSelected={inboxAdvancedFilters.channels}
            />
            <DateOption
              type={inboxAdvancedFilters.date.type}
              defaultDate={inboxAdvancedFilters.date.dates}
            />
            {/* REVISITING ATTACHMENTS POST-LAUNCH */}
            {/* <AttachmentOption defaultValue={inboxAdvancedFilters.attachment} /> */}
          </FilterItemGroup>

          <div className="flex justify-end mt-6">
            <Button
              variant="primary"
              type="submit"
              data-testid="apply-search"
              onClick={onSearchClick}
            >
              Apply
            </Button>
          </div>
        </form>
      </TransitionContainer>
    </div>
  )
}
