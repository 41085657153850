import { useState, useEffect } from 'react'
import { Select, Header, Button, DatePicker } from '@thryvlabs/maverick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/pro-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import {
  setShowCustomNotification,
  setCustomReminderNotification,
  setNotifications,
} from '../../../slices/remindMeSlice'
import { options } from './modalSelectOptions'
import moment from 'moment'
import { CREATE_NOTIFICATION_MUTATION } from '../../../../../graphql/mutations/inbox-notification-mutations'
import { FETCH_MESSAGES_BY_THREAD_ID } from '../../../../../graphql/queries/inbox-queries'

const CustomReminderModal = ({
  setOpenReminderModal,
  incomingMessageId,
  customReminderTimeInMilliseconds,
  setCustomReminderTimeInMilliseconds,
  customReminderPk,
  customReminderSk,
  selectedThreadId,
}) => {
  const [selectedOption, setSelectedOption] = useState({
    name: '',
    value: '',
  })
  const [currentDate, setCurrentDate] = useState(null)
  const originalDate = currentDate
  const momentObj = moment(originalDate)
  const trimmedDate = momentObj.format('ddd, MMM D')
  const [checkIfToday, setCheckIfToday] = useState(null)
  const dispatch = useDispatch()

  const calculateCustomReminderToMillisecconds = () => {
    const localDateTime = moment() // gets the current date and time in the local timezone
    const currentUtcInMilliseconds = localDateTime.utc().valueOf() // converts local date and time to UTC milliseconds
    const newMoment = momentObj // defines new moment object
    let [hours, minutes, seconds] = selectedOption.value.split(':') // grabs time from value of select component
    newMoment.set({ hour: hours, minute: minutes, second: seconds }) // sets moment object time from the date picker to value from time select component
    const customReminderInMilliseconds = newMoment.utc().valueOf() // converts custom reminder date and time to UTC milliseconds

    // subjtract custom reminder date/time with current date / time
    const differenceInMilliseconds =
      customReminderInMilliseconds - currentUtcInMilliseconds

    //return difference in milliseconds

    setCustomReminderTimeInMilliseconds(differenceInMilliseconds)
    return differenceInMilliseconds
  }

  const currentTime = moment().format('HH:mm:ss')

  const handleSubmit = () => {
    calculateCustomReminderToMillisecconds()
    dispatch(
      setCustomReminderNotification({
        customDate: trimmedDate,
        customTime: selectedOption.name,
      }),
    )
    dispatch(
      setNotifications({
        id: incomingMessageId,
        customDate: trimmedDate,
        customTime: selectedOption.name,
      }),
    )
    setOpenReminderModal(false)
    dispatch(setShowCustomNotification(true))
  }

  const [createNotificationReminder] = useMutation(CREATE_NOTIFICATION_MUTATION)

  useEffect(() => {
    if (customReminderTimeInMilliseconds !== 0) {
      createNotificationReminder({
        variables: {
          PK1: customReminderPk,
          SK1: customReminderSk,
          agent_id: '86',
          REMINDSK: customReminderTimeInMilliseconds,
        },
        refetchQueries: [
          {
            query: FETCH_MESSAGES_BY_THREAD_ID,
            variables: { threadid: selectedThreadId },
            fetchPolicy: 'network-only',
          },
        ],
      })
    }
  }, [customReminderTimeInMilliseconds])

  const today = moment()

  useEffect(() => {
    const isToday = today.isSame(currentDate, 'day')
    if (isToday) {
      setCheckIfToday(true)
    } else {
      setCheckIfToday(false)
    }
  }, [checkIfToday, today, currentDate])

  const CreateButton = () => {
    if (
      (selectedOption.name === '' && selectedOption.value === '') ||
      (checkIfToday && selectedOption.value < currentTime) ||
      currentDate === null
    ) {
      return (
        <Button variant="primary" disabled>
          Create
        </Button>
      )
    } else {
      return (
        <Button variant="primary" onClick={() => handleSubmit()}>
          Create
        </Button>
      )
    }
  }
  return (
    <div className="pl-[15px]">
      <div className="w-[436px] h-[34px] flex items-center mb-[50px]">
        <div className="w-[386px] flex items-start">
          <Header variant="h4" fontWeight="semibold">
            Create a reminder
          </Header>
        </div>
        <div className="w-[50px] flex justify-end items-center">
          <FontAwesomeIcon
            onClick={() => setOpenReminderModal(false)}
            icon={faX}
            className="w-[18px] h-[18px] text-[#A3A5A7] cursor-pointer"
          />
        </div>
      </div>
      <div className="w-[436px] gap-[24px] flex items-center">
        <div className="w-[284px]">
          <DatePicker
            currentDate={currentDate}
            handleOnChange={setCurrentDate}
            selectableDates={'current/future'}
            name="date"
            label="date"
          />
        </div>
        <div className="w-[128px] flex items-end h-[44px]">
          <Select
            options={options}
            selectLabel="Time"
            width="full"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            withLabel
            largeHeight
          />
        </div>
      </div>
      <div className="mt-[52px] flex w-[436px] justify-end gap-[16px]">
        <Button
          variant="text"
          level={1}
          textVariant="light"
          onClick={() => setOpenReminderModal(false)}
        >
          Cancel
        </Button>
        <CreateButton />
      </div>
    </div>
  )
}

export default CustomReminderModal
