import { useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import {
  DELETE_LABEL_OPTIONS_BY_LOCATION,
  UPDATE_LABEL_OPTIONS_BY_LOCATION,
} from '../../../graphql/mutations/label-options-mutations'
import { Modal, Input } from '@thryvlabs/maverick'
import { GET_LABEL_OPTIONS_BY_LOCATION } from '../../../graphql/queries'

export default function Label({ label }) {
  const [newLabel, setNewLabel] = useState('')
  const handleNewLabel = (str) => {
    return /^\s*$/.test(str)
  }
  /* Commenting this out for now since we don't have a way to access number of messages per label */
  // const messages = useSelector((state) => state.messageContent.messages)
  // const numberOfMessages = (label) => {
  //   let count = 0
  //   const messageLabels = messages.map((message) => message.labels)
  //   messageLabels.forEach((messageLabel) => {
  //     if (messageLabel) {
  //       messageLabel.forEach((object) => {
  //         if (object.value === label) {
  //           count++
  //         }
  //       })
  //     }
  //   })
  //   return count
  // }

  const [updateLabel] = useMutation(UPDATE_LABEL_OPTIONS_BY_LOCATION, {
    variables: { label_id: label.LabelID, label_name: newLabel },
    refetchQueries: [GET_LABEL_OPTIONS_BY_LOCATION],
    onError: () => {},
  })

  const handleEditLabel = (e) => {
    e.preventDefault()
    updateLabel()
  }

  const [deleteLabel] = useMutation(DELETE_LABEL_OPTIONS_BY_LOCATION, {
    variables: { label_id: label.LabelID },
    refetchQueries: [GET_LABEL_OPTIONS_BY_LOCATION],
    onError: () => {},
  })

  const handleDeleteLabel = (e) => {
    e.preventDefault()
    deleteLabel()
  }

  return (
    <div
      className="min-w-[250px] py-3 px-4 rounded-md mb-3 flex flex-row justify-between"
      style={{ height: '56px', backgroundColor: '#F8F9FB' }}
    >
      <div className="flex flex-col justify-center">
        <p className="text-sm">{label.LabelName}</p>
        {/* Commenting this out for now since we don't have a way to access number of messages per label */}
        {/* <p className="text-sm text-thryv-gray-medium-400">
          {numberOfMessages(label.value)} messages
        </p> */}
      </div>
      <div className="flex flex-row items-center">
        <Modal
          variant="default"
          id="hyperlink-modal"
          title="Edit label"
          action
          actionClose
          footer
          btnActionText="Save"
          btnAction={handleEditLabel}
          btnActionDisabled={handleNewLabel(newLabel)}
          footerCancel
          modalTestId="edit-label-btn"
          altBtn={<div>Edit</div>}
        >
          <div className="py-5">
            <Input
              className="w-full"
              type="text"
              value={newLabel}
              name="newlabel"
              withLabel
              placeholder="New Label"
              labelType="static"
              variant="default"
              onChange={(e) => setNewLabel(e.target.value)}
            />
          </div>
        </Modal>

        <span className="px-2 text-[#4D4D4D]">|</span>
        <Modal
          variant="default"
          id="hyperlink-modal"
          title="Delete label"
          action
          actionClose
          footer
          btnActionText="Delete"
          btnAction={handleDeleteLabel}
          footerCancel
          modalTestId="delete-label-btn"
          altBtn={<div>Delete</div>}
        >
          <div className="py-5">
            Are you sure you want to delete this label? Once a label is deleted, you
            will lose all assigned labels to the messages on Command Center Inbox.
          </div>
        </Modal>
      </div>
    </div>
  )
}
