import { useEffect, useState } from 'react'
import { ConnectedChannelRow } from '../connected-channel-row'
import { useController } from 'react-hook-form'

const ACCESS_TYPES = {
  'No Access': 0,
  'Read only': 1,
  'Read / Write': 2,
}

export const ConnectedChannelInputs = ({
  className,
  connectedChannels,
  control,
  currentRole,
  isEdit,
  permittedChannels,
  newStaffPermissions,
  setNewStaffPermissions,
}) => {
  const { field } = useController({ name: 'connectedChannels', control })
  const [channelAccessSelections, setChannelAccessSelections] = useState([])

  const updateChannelAccessType = (newAccessType, channelId) => {
    const newChannels = [...channelAccessSelections]

    const indexOfChannelToChange = channelAccessSelections.findIndex(
      (channel) => channel.ChannelID === channelId,
    )

    newChannels[indexOfChannelToChange].AccessType = newAccessType

    setChannelAccessSelections(newChannels)
  }

  const initChannelAccessSelections = () => {
    if (connectedChannels.length) {
      const newChannels = connectedChannels.map((channel) => {
        return {
          AccessType: ACCESS_TYPES['Read / Write'],
          ChannelID: channel.ChannelID,
        }
      })

      setChannelAccessSelections(newChannels)
    }
  }

  useEffect(() => {
    initChannelAccessSelections()
  }, [])

  useEffect(() => {
    const stringifiedChannelAccessSelections = JSON.stringify(
      channelAccessSelections,
    )
    field.onChange(stringifiedChannelAccessSelections)
  }, [channelAccessSelections])

  const getAccessType = (channelId) => {
    if (isEdit) {
      const filteredChannels = permittedChannels.filter(
        (chan) => chan.ChannelID === channelId,
      )
      return filteredChannels[0].AccessType
    }
    return
  }

  return (
    <>
      <ul className={`${className} flex flex-col gap-[36px] sm:gap-6`}>
        {connectedChannels.map((channel, i) => (
          <li key={i}>
            <ConnectedChannelRow
              newStaffPermissions={newStaffPermissions}
              setNewStaffPermissions={setNewStaffPermissions}
              staffAccessType={getAccessType(channel.ChannelID)}
              isEdit={isEdit}
              permittedChannels={permittedChannels}
              currentRole={currentRole}
              variant={channel.Provider}
              channel={{
                row: i,
                accessType: channel.AccessType,
                nickname: channel.Nickname,
                credential:
                  channel.Provider === 'video'
                    ? 'Video Channel'
                    : channel.AccountUserValue,
                channelId: channel.ChannelID,
              }}
              className="sm:flex-col sm:gap-4"
              onChange={(newAccessType) => {
                if (!channelAccessSelections?.length) return

                updateChannelAccessType(
                  ACCESS_TYPES[newAccessType],
                  channel.ChannelID,
                )
              }}
            />
          </li>
        ))}
      </ul>
    </>
  )
}
