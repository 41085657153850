import { gql } from '@apollo/client'

export const GET_CLOUDSEARCH_CONTACTS = gql`
  query get_cloudsearch_contacts(
    $search: String!
    $limit: Int
    $field_filters: CloudSearchFieldsInput
  ) {
    cloudsearchContacts: queryCloudsearchContacts(
      input: { search: $search, limit: $limit, field_filters: $field_filters }
    ) {
      pk1
      sk1
      contactsk1
      contactsk2
      contactsk3
      emailAddresses
      fullName
      phoneNumbers
    }
  }
`


/* Please make sure to use IETF RFC3339 format for dates in time_filter for it to work. */
/* Resource: https://docs.aws.amazon.com/cloudsearch/latest/developerguide/searching-dates.html */
export const GET_CLOUDSEARCH_MESSAGES = gql`
  query get_cloudsearch_messages(
    $search: String! 
    $limit: Int
    $channel_types: [String]
    $time_filter: CloudsearchTableLongSortInput
  ) {
    cloudsearchMessages: queryCloudsearchMessages(
      input: { 
        search: $search
        limit: $limit
        channel_types: $channel_types
        time_filter: $time_filter
      }
    ) {
      threadpk
      body
      chan_type
      sk1
      pk1
      email_subject
      timestamp
      acct_id
      conv_id
      email_snippet
      item_type
      label
      labelsk
      msgpk
      pinpk
      pinsk
      email_from {
        email
        name
      }
      email_to {
        email
        name
      }
      chanpk
    }
  }
`

export const GET_CLOUDSEARCH_CONTACTS_SUGGESTIONS = gql`
  query get_cloudsearch_contacts_suggestions(
    $search: String!
    $suggester: String
    $limit: Int
    $ranks: CloudsearchContactSuggestionRank
  ) {
    contactSuggestions: queryCloudsearchContactSuggestions(
      input: { search: $search, ranks: $ranks, limit: $limit, suggester: $suggester }
    ) {
      pk1
      sk1
      full_name
      contactsk1
      contactsk2
      contactsk3
      email_addresses
      phone_numbers
    }
  }
`
