import { CardTitle, Header } from '@thryvlabs/maverick'
import { useEffect, useRef, useState } from 'react'
import Toggle from '../../../components/common/toggle/Toggle'
import { NotificationInfo } from './NotificationInfo'
import { SelectField } from './SelectField'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { UPDATE_USER_PREFERENCES } from '../../../graphql/mutations/user-preferences-mutation'
import {
  setDesktopNotificationPreferences,
  setMobileNotificationPreferences,
} from '../../../redux-toolkit/slices/user-preferences/user-preferences'
import { setUserPreferences } from '../../../redux-toolkit/slices/user-preferences/user-preferences'
import { GET_USER_PREFERENCES } from '../../../graphql'

export const Notifications = () => {
  const preferenceData = useSelector((state) => state.userPreferences)
  const [inboxNotificationData, setInboxNotificationData] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [teamchatNotificationData, setTeamchatNotificationData] = useState([])
  const [callsNotificationData, setCallsNotificationData] = useState([])
  const [desktopNotifications, setDirectDesktopNotificationData] = useState(
    preferenceData.notificationPreferences.desktop,
  )
  const [mobileNotifications, setDirectMobileNotificationData] = useState(
    preferenceData.notificationPreferences.mobile,
  )

  const { doNotDisturb } = useSelector(
    (state) => state.userPreferences.notificationPreferences,
  )
  const { user } = useAuth0()
  const dispatch = useDispatch()
  const isMounted = useRef(false)
  const isRendered = useRef(false)

  const { data: userPreferences } = useQuery(GET_USER_PREFERENCES, {
    fetchPolicy: 'no-cache',
    variables: { userId: user?.email },
    onCompleted: () => {
      setDirectDesktopNotificationData(
        JSON.parse(userPreferences?.queryUserPreferences?.[0]?.Values).data
          .notificationPreferences.desktop,
      )
      setDirectMobileNotificationData(
        JSON.parse(userPreferences?.queryUserPreferences?.[0]?.Values).data
          .notificationPreferences.mobile,
      )
    },
  })

  useEffect(() => {
    if (isRendered.current) {
      if (doNotDisturb) {
        setDirectDesktopNotificationData([
          { label: 'Inbox', value: false },
          { label: 'TeamChat', value: false },
          { label: 'Calls', value: false },
        ])
        setDirectMobileNotificationData([
          { label: 'Inbox', value: false },
          { label: 'TeamChat', value: false },
          { label: 'Calls', value: false },
        ])
      } else {
        setDirectDesktopNotificationData([
          { label: 'Inbox', value: true },
          { label: 'TeamChat', value: true },
          { label: 'Calls', value: true },
        ])
        setDirectMobileNotificationData([
          { label: 'Inbox', value: true },
          { label: 'TeamChat', value: true },
          { label: 'Calls', value: true },
        ])
      }
    }
    isRendered.current = true
  }, [doNotDisturb])

  const newTeamChatTypeList = [
    { name: 'All', value: 'All' },
    { name: 'Tagged Only', value: 'Tagged Only' },
  ]

  const changeDesktopToggle = (index) => {
    let notificationsList = JSON.parse(JSON.stringify(desktopNotifications))

    notificationsList[index].value = !notificationsList[index].value
    setDirectDesktopNotificationData([...notificationsList])
    dispatch(setDesktopNotificationPreferences([...notificationsList]))
  }

  const changeMobileToggle = (index) => {
    let notificationsList = JSON.parse(JSON.stringify(mobileNotifications))
    notificationsList[index].value = !notificationsList[index].value
    setDirectMobileNotificationData([...notificationsList])
    dispatch(setMobileNotificationPreferences([...notificationsList]))
  }

  const [selectedConversationDesktop] = useState(
    preferenceData.notificationPreferences.selectedConversationDesktop,
  )

  const [navigationPreferences] = useState(preferenceData.navigationPreferences)

  const [navigationCollapsed] = useState(preferenceData.navigationCollapsed)

  const [selectedTeamChatDesktop, setSelectedTeamChatDesktop] = useState(
    preferenceData.notificationPreferences.selectedTeamChatDesktop,
  )
  const [selectedConversationMobile] = useState(
    preferenceData.notificationPreferences.selectedConversationMobile,
  )
  // eslint-disable-next-line no-unused-vars
  const [selectedTeamChatMobile, setSelectedTeamChatMobile] = useState(
    preferenceData.notificationPreferences.selectedTeamChatMobile,
  )

  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES)
  const updatePreferences = async (updatedPreferences) => {
    try {
      await updateUserPreferences({
        variables: {
          userId: user.email,
          preferences: JSON.stringify({
            time_stamp: Date.now(),
            data: updatedPreferences,
          }),
        },
      })
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    const processNotification = (label, container) => {
      const desktopNotification = desktopNotifications?.find(
        (x) => x.label === label,
      )
      const mobileNotification = mobileNotifications?.find((x) => x.label === label)

      if (desktopNotification) {
        container.push({
          device: 'Desktop',
          label: desktopNotification.label,
          value: desktopNotification.value,
        })
      }

      if (mobileNotification) {
        container.push({
          device: 'Mobile',
          label: mobileNotification.label,
          value: mobileNotification.value,
        })
      }
    }

    const inbox = []
    processNotification('Inbox', inbox)

    const teamchat = []
    processNotification('TeamChat', teamchat)

    const calls = []
    processNotification('Calls', calls)

    setInboxNotificationData(inbox)
    setTeamchatNotificationData(teamchat)
    setCallsNotificationData(calls)
  }, [preferenceData])

  useEffect(() => {
    if (isMounted.current) {
      const updatedConfig = {
        navigationPreferences,
        navigationCollapsed,
        notificationPreferences: {
          doNotDisturb: doNotDisturb,
          desktop: desktopNotifications,
          mobile: mobileNotifications,
          selectedConversationMobile,
          selectedConversationDesktop,
          selectedTeamChatDesktop,
          selectedTeamChatMobile,
        },
      }
      updatePreferences(updatedConfig)
      dispatch(setUserPreferences(updatedConfig))
    }
    isMounted.current = true
  }, [
    doNotDisturb,
    desktopNotifications,
    mobileNotifications,
    selectedConversationDesktop,
    selectedTeamChatDesktop,
    selectedConversationMobile,
    selectedTeamChatMobile,
    navigationCollapsed,
  ])

  return (
    <div className="bg-[white] rounded p-6 border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A]">
      <div className="mt-1 mb-6">
        <Header fontWeight="semibold" variant="h6">
          Notifications
        </Header>
      </div>

      <Toggle label="Do Not Disturb" />

      <hr className="border-[#E3E6E8] border-[1px] mt-[1rem]" />

      <div className="mt-3 mb-6">
        <CardTitle size="md" variant="subtitle" className="text-sm">
          Inbox
        </CardTitle>
      </div>

      {inboxNotificationData.map((notification, index) => (
        <div
          key={index}
          className="grid grid-cols-2 gap-3 sm:flex-col mt-5 mb-5 w-full"
        >
          <div>
            <Toggle
              key={index}
              index={0}
              isToggleList={true}
              toggleStatus={notification.value}
              setToggleStatus={
                notification.device === 'Desktop'
                  ? changeDesktopToggle
                  : changeMobileToggle
              }
              label={notification.device}
            />

            {notification.value === true &&
              notification.label === 'New TeamChat' && (
                <NotificationInfo
                  label="Please select conversation type"
                  tooltipDes="Get notifications for all incoming TeamChat messages, or just the ones you're tagged in."
                />
              )}
          </div>

          {notification.value === true && notification.label === 'New TeamChat' && (
            <div className="w-[22%] sm:w-[130px]  mt-[20px]">
              {notification.label === 'New TeamChat' && (
                <SelectField
                  className="[&>div>div:last-child>ul]:h-auto"
                  options={newTeamChatTypeList}
                  selectedOption={selectedTeamChatDesktop}
                  setSelectedOption={setSelectedTeamChatDesktop}
                  withLabel
                />
              )}
            </div>
          )}
        </div>
      ))}

      {/* <hr className="border-[#E3E6E8] border-[1px] mt-[1rem]" />

      <div className="mt-3 mb-6">
        <CardTitle size="md" variant="subtitle" className="text-sm">
          TeamChat
        </CardTitle>
      </div>
      {teamchatNotificationData.map((notification, index) => (
        <div
          key={index}
          className="grid grid-cols-2 gap-3 sm:flex-col mt-5 mb-5 w-full"
        >
          <div>
            <Toggle
              key={index}
              index={1}
              isToggleList={true}
              toggleStatus={notification.value}
              setToggleStatus={
                notification.device === 'Desktop'
                  ? changeDesktopToggle
                  : changeMobileToggle
              }
              label={notification.device}
            />

            {notification.value === true &&
              notification.label === 'New TeamChat' && (
                <NotificationInfo
                  label="Please select conversation type"
                  tooltipDes="Get notifications for all incoming TeamChat messages, or just the ones you're tagged in."
                />
              )}
          </div>

          {notification.value === true && notification.label === 'New TeamChat' && (
            <div className="w-[22%] sm:w-[130px]  mt-[20px]">
              {notification.label === 'New TeamChat' && (
                <SelectField
                  className="[&>div>div:last-child>ul]:h-auto"
                  options={newTeamChatTypeList}
                  selectedOption={selectedTeamChatDesktop}
                  setSelectedOption={setSelectedTeamChatDesktop}
                  withLabel
                />
              )}
            </div>
          )}
        </div>
      ))} */}

      <hr className="border-[#E3E6E8] border-[1px] mt-[1rem]" />
      <div className="mt-3 mb-6">
        <CardTitle size="md" variant="subtitle" className="text-sm">
          Calls
        </CardTitle>
      </div>
      {callsNotificationData.map((notification, index) => (
        <div
          key={index}
          className="grid grid-cols-2 gap-3 sm:flex-col mt-5 mb-5 w-full"
        >
          <div>
            <Toggle
              key={index}
              index={2}
              isToggleList={true}
              toggleStatus={notification.value}
              setToggleStatus={
                notification.device === 'Desktop'
                  ? changeDesktopToggle
                  : changeMobileToggle
              }
              label={notification.device}
            />

            {notification.value === true &&
              notification.label === 'New TeamChat' && (
                <NotificationInfo
                  label="Please select conversation type"
                  tooltipDes="Get notifications for all incoming TeamChat messages, or just the ones you're tagged in."
                />
              )}
          </div>

          {notification.value === true && notification.label === 'New TeamChat' && (
            <div className="w-[22%] sm:w-[130px]  mt-[20px]">
              {notification.label === 'New TeamChat' && (
                <SelectField
                  className="[&>div>div:last-child>ul]:h-auto"
                  options={newTeamChatTypeList}
                  selectedOption={selectedTeamChatDesktop}
                  setSelectedOption={setSelectedTeamChatDesktop}
                  withLabel
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
