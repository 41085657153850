import { Icon, ParagraphText } from '@thryvlabs/maverick'

export const ConversationHeader = ({ variant, title, timestamp, isViewed }) => (
  <div className="flex justify-between items-center">
    <span className="grow flex gap-2 items-center max-w-full w-0">
      <ParagraphText
        variant="reg"
        color={isViewed ? 'thryv-steel' : 'thryv-black-500 font-semibold'}
        className="truncate"
      >
        {title}
      </ParagraphText>
      {variant === 'draft' && (
        <div>
          <Icon
            variant="pen"
            type="regular"
            color="#808080"
            height="16"
            width="16"
          />
        </div>
      )}
    </span>

    <ParagraphText
      className='font-["Montserrat"] ml-4'
      variant="sm"
      color={isViewed ? 'thryv-steel' : 'thryv-black-500 font-semibold'}
    >
      {timestamp}
    </ParagraphText>
  </div>
)
