import { Button, Icon, ParagraphText } from '@thryvlabs/maverick'
import { useRef, useState } from 'react'
import { CommandCenterIcon } from '../../../../command-center-icon'
import { CCSection } from './ccSection'
import {
  setEditorView,
  setShowFormattingToolbar,
  setSubjectText,
} from '../../../slices/messageComposeSlice'
import { useSelector, useDispatch } from 'react-redux'
import { BccSection } from './bccSection'
import { setReplying, setMessage } from '../../../slices/messageComposeSlice'
import { useEffect } from 'react'

const SubjectSection = ({ selectedChannel }) => {
  const { subjectText } = useSelector((s) => s.messageCompose)

  const dispatch = useDispatch()
  const subjectRef = useRef()

  const handleInput = (evt) => {
    dispatch(setSubjectText(evt.target.value))
  }

  const handleSubmitSubject = (evt) => {
    evt.preventDefault()
    // eslint-disable-next-line no-undef
    setSubjectResolved(true)
  }

  return (
    <div
      id="subject-section"
      className="flex w-10/12 items-center py-4"
      ref={subjectRef}
    >
      {selectedChannel?.VendorID === '1' &&
        selectedChannel?.Provider !== 'phone' && (
          <>
            <ParagraphText variant="reg" color="thryv-steel" className="pr-2">
              Subject:
            </ParagraphText>
            <form className="w-full" onSubmit={(evt) => handleSubmitSubject(evt)}>
              <input
                type="text"
                name="subject"
                value={subjectText}
                autoFocus
                className="focus:outline-none w-full rounded text-sm font-snow"
                onChange={(evt) => handleInput(evt)}
              />
            </form>
          </>
        )}
    </div>
  )
}

export const UnderlineBorder = ({ className }) => {
  return <span className={`w-[100%] h-[1px] bg-[#e3e6e8] ${className} `} />
}

export const ComposeHeader = ({ limitedOptions, selectedChannel }) => {
  const { editorView, message, subjectText } = useSelector((s) => s.messageCompose)
  const dispatch = useDispatch()
  const [showCC, setShowCC] = useState(false)
  const [showBCC, setShowBCC] = useState(false)
  const selectedThread = useSelector((s) => s.inboxThreads.selectedThread)

  useEffect(() => {
    if (message.emailRecipients?.cc?.length > 0) {
      setShowCC(true)
    }
    if (message.emailRecipients?.bcc?.length > 0) {
      setShowBCC(true)
    }
  }, [message])

  useEffect(() => {
    dispatch(setEditorView('closed'))
    dispatch(setReplying({ replying: false, subject: null }))
    dispatch(
      setMessage({
        text: '',
        title: '',
        emailRecipients: {
          cc: [],
          bcc: [],
        },
        labels: [],
      }),
    )
  }, [selectedThread])

  const handleClose = () => {
    dispatch(setShowFormattingToolbar(false))
    if (subjectText) {
      dispatch(setEditorView('closed'))
    } else {
      dispatch(setReplying({ replying: false, subject: null }))
      dispatch(setEditorView('closed'))
      dispatch(
        setMessage({
          text: '',
          title: '',
          emailRecipients: {
            cc: [],
            bcc: [],
          },
          labels: [],
        }),
      )
    }
  }

  return (
    <div id="compose-header" className="w-full flex flex-col">
      <div className="w-full flex justify-between">
        <SubjectSection selectedChannel={selectedChannel} />
        <div
          id="compose-header-right"
          className="flex items-center justify-evenly gap-2 gap-x-3 py-4 mr-2"
        >
          {!limitedOptions && !showCC && (
            <Button
              variant="text"
              level={1}
              className="z-10"
              onClick={() => setShowCC(true)}
              tabIndex={-1}
            >
              Cc
            </Button>
          )}
          {!limitedOptions && !showBCC && (
            <Button
              variant="text"
              level={1}
              className="z-10"
              onClick={() => setShowBCC(true)}
              tabIndex={-1}
            >
              Bcc
            </Button>
          )}
          {selectedChannel?.Provider !== 'phone' && (
            <>
              <button
                type="button"
                className="ml-2 z-10"
                onClick={() =>
                  editorView === 'expanded'
                    ? dispatch(setEditorView('open'))
                    : dispatch(setEditorView('expanded'))
                }
                tabIndex={-1}
              >
                <CommandCenterIcon
                  type="solid"
                  variant={
                    editorView === 'expanded'
                      ? 'collapseDiagonalArrows'
                      : 'expandDiagonalArrows'
                  }
                />
              </button>
              <button
                type="button"
                onClick={() => handleClose()}
                className="z-10"
                tabIndex={-1}
              >
                <Icon
                  variant="x"
                  type="regular"
                  width="12"
                  height="12"
                  fill="#808080"
                />
              </button>
            </>
          )}
        </div>
      </div>
      {selectedChannel?.Provider !== 'phone' && (
        <UnderlineBorder className="w-full mb-2 " />
      )}
      {showCC && <CCSection setShowCC={setShowCC} />}
      {showBCC && <BccSection setShowBCC={setShowBCC} />}
    </div>
  )
}
