const RecordGreetingButton = () => {
  return (
    // <Button
    //   onClick={() => {
    //     alert('Open Voicemail Recorder In Settings')
    //   }}
    //   variant={hasGreeting ? 'secondary' : 'primary'}
    // >
    //   Record Voicemail Greeting
    // </Button>
    <></>
  )
}

export default RecordGreetingButton
