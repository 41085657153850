import { ChannelButton, borderColors } from './ManageChannelButton.styles'
import './ManageChannel.css'
import { Oval } from 'react-loader-spinner'
import ChannelsIcon from '../channels-icon/channels-icon'
import { Icon } from '@thryvlabs/maverick'
import useCardData from '../inbox/hooks/useCardData'

const getBorderColorForPhone = (provider) => {
  if (provider !== 'phone') return null

  const { getPhoneCard } = useCardData()
  const [currentPhoneCard] = getPhoneCard()

  if (currentPhoneCard?.connectError) {
    return {
      color: 'red',
      icon: (
        <Icon
          variant={'circleExclamation'}
          width={16}
          height={16}
          type="solid"
          color={borderColors['red']}
          className={`absolute left-[-5px] top-[-7px] bg-white rounded-full`}
        />
      ),
    }
  }

  if (currentPhoneCard?.connectWarning) {
    return {
      color: 'yellow',
      icon: (
        <>
          <div
            className={`w-[5px] h-[10px] absolute left-[0px] top-[-2px] bg-white`}
          />
          <Icon
            variant={'triangleExclamation'}
            width={16}
            height={16}
            type="solid"
            color={borderColors['yellow']}
            className={`absolute left-[-5px] top-[-7px]`}
          />
        </>
      ),
    }
  }

  return null
}

function ManageChannelButton({
  onClick,
  iconType = '',
  iconName = '',
  showSpinner = false,
  channel,
}) {
  const color = getBorderColorForPhone(channel?.Provider)

  return (
    <>
      {showSpinner ? (
        <ChannelButton
          variant="secondary"
          buttonType="outlined"
          onClick={onClick}
          active={'false'}
          data-testid="connect-channel-loading"
        >
          <div className="rounded-full absolute inset-0 inline-flex items-center justify-center ">
            <Oval
              height={25}
              width={25}
              color="#FFF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#E9E9E9"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
          </div>

          {iconType && iconName ? (
            <ChannelsIcon
              variant={iconName}
              height="20px"
              width="20px"
              className="z-0 opacity-100"
            />
          ) : (
            <span className="!text-[1.5rem]">+</span>
          )}
        </ChannelButton>
      ) : (
        <ChannelButton
          variant="secondary"
          buttonType="outlined"
          onClick={onClick}
          active={'true'}
          $Status={channel?.Status}
          data-testid={`${channel?.Provider}-channel`}
          $borderColor={channel?.Provider === 'phone' && color?.color}
        >
          {channel?.Provider === 'phone' && color?.icon}

          {iconType && iconName ? (
            <ChannelsIcon
              variant={iconName}
              height="20px"
              width="20px"
              className="z-0 opacity-100"
            />
          ) : (
            <span className="!text-[1.5rem]">+</span>
          )}
        </ChannelButton>
      )}
    </>
  )
}

export default ManageChannelButton
