import { useState } from 'react'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Accordion } from '@thryvlabs/maverick'
import { SubscriptionMobile } from './SubscriptionMobile'
import { SubscriptionWeb } from './SubscriptionWeb'
import { useDispatch } from 'react-redux'
import {
  setDisableBackdropClick,
  setModalContent,
  setShowModal,
} from '../../../../components/common/modals/slices/connectChannelsSlice'
import { ChannelsModalComponent } from '../../../../components/common/modals/channels-modal-component/ChannelsModalComponent'

export const Subscriptions = ({ loading, activeSubscriptions, latestPayment }) => {
  const [selectedItem, setSelectedItem] = useState('')
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [openMobileTableRow, setOpenMobileTableRow] = useState(0)
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const dispatch = useDispatch()

  const handleClose = () => {
    if (selectedItem === 'Cancel') setOpenCancelModal(false)
    setSelectedItem('')
  }

  useEffect(() => {
    if (selectedItem === 'Upgrade') openUpgradePlanModal()
    else if (selectedItem === 'Cancel') setOpenCancelModal(true)
  }, [selectedItem])

  const openUpgradePlanModal = () => {
    dispatch(setModalContent(4))
    dispatch(setDisableBackdropClick(false))
    dispatch(setShowModal(true))
  }

  return (
    <div className="bg-[#FFFFFF] bg-[!red]">
      {!isMobile && (
        <SubscriptionWeb
          tableData={activeSubscriptions}
          loading={loading}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          handleClose={handleClose}
          activeSubscriptions={activeSubscriptions}
          latestPayment={latestPayment}
          openCancelModal={openCancelModal}
          setOpenCancelModal={setOpenCancelModal}
        />
      )}

      {isMobile && (
        <Accordion section={true} title="Active Subscription" defaultState={true}>
          <SubscriptionMobile
            tableData={activeSubscriptions}
            loading={loading}
            openMobileTableRow={openMobileTableRow}
            setOpenMobileTableRow={setOpenMobileTableRow}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            handleClose={handleClose}
            activeSubscriptions={activeSubscriptions}
            latestPayment={latestPayment}
            openCancelModal={openCancelModal}
            setOpenCancelModal={setOpenCancelModal}
          />
        </Accordion>
      )}
      <ChannelsModalComponent onClose={() => setSelectedItem('')} />
    </div>
  )
}

export const TableRowInfo = ({ label, value, fontStyle, mr }) => {
  return (
    <div className="px-[15px] py-[15px] w-full bg-thryv-white-200 border-b border-thryv-gray-light-400">
      <div className="flex justify-between items-center ">
        <h6 className={` ${fontStyle} font-montserrat text-[12px]`}>{label}</h6>
        <h6 className={` ${fontStyle} font-montserrat text-[12px] ${mr}`}>
          {value}
        </h6>
      </div>
    </div>
  )
}
