import { useRef, useEffect } from 'react'
import RoundedActionButton from '../../../../../common/rounded-action-button'
import LabelsMenu from '../../../../labels-menu/labels-menu'

export const LabelsButton = ({
  disabled,
  labels,
  sk1,
  pk1,
  chanpk,
  menu,
  setVisibleMenu,
  threadpk,
  variant,
}) => {
  const { isVisible, orientation = 'right' } = menu
  const labelsMenuRef = useRef()
  // const labelsModalRef = useRef()
  const actionButton = useRef()

  const toggleMenu = () => {
    isVisible ? setVisibleMenu('') : setVisibleMenu('labels')
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        labelsMenuRef.current &&
        !labelsMenuRef.current.contains(event.target) &&
        !actionButton.current.contains(event.target)
      ) {
        setVisibleMenu('')
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [labelsMenuRef])

  return (
    <div className="relative">
      <RoundedActionButton
        dataTestId="label-btn"
        icon={{
          type: 'regular',
          variant: 'label',
          color: menu.isVisible && '#808080',
        }}
        tooltipTitle="Label"
        disable={disabled}
        onClick={toggleMenu}
        active={isVisible}
        ref={actionButton}
        isCCIcon
      />
      <div className="relative">
        <LabelsMenu
          ref={labelsMenuRef}
          className={`${orientation === 'left' && 'right-0'} mt-1`}
          show={isVisible}
          messageLabels={labels}
          sk1={sk1}
          pk1={pk1}
          chanpk={chanpk}
          threadpk={threadpk}
          variant={variant}
          transformOrigin={orientation === 'left' ? 'top right' : 'top left'}
        />
      </div>
    </div>
  )
}
