import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
	name: 'modal',
	initialState: {
		isAttachmentsModalOpen: false,
	},
	reducers: {
		toggleAttachmentsModal: (state) => {
			state.isAttachmentsModalOpen = !state.isAttachmentsModalOpen
		},
	}
})

export const { toggleAttachmentsModal } = modalSlice.actions
export default modalSlice.reducer
