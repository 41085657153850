import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { InvoiceCouponsMobile } from './InvoiceCoupons/InvoiceCouponsMobile'
import { InvoiceCouponsWeb } from './InvoiceCoupons/InvoiceCouponsWeb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { FETCH_PAYMENT_HISTORY } from '../../../graphql/queries/settings-queries'

export const InvoicesCoupons = () => {
  const [isAccordianOpen, setIsAccordianOpen] = useState(true)
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const { user } = useAuth0()
  const [tableData, setTableData] = useState([])

  const { data, loading } = useQuery(FETCH_PAYMENT_HISTORY, {
    variables: {
      loc_id: user.businessId,
    },
    onCompleted: () => {
      setTableData(data.queryPaymentHistoryByLocation.data)
    },
  })

  const toggleAccordian = function () {
    setIsAccordianOpen(!isAccordianOpen)
  }

  return (
    <div className="bg-[#FFFFFF] mt-[1rem]">
      {!isMobile && <InvoiceCouponsWeb tableData={tableData} loading={loading} />}

      {isMobile && (
        <>
          <div className="w-[95%] flex flex-col mx-auto bg-[white] rounded-[4px] p-6 border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A]">
            <div className="flex justify-between">
              <p className="text-[18px] font-semibold">
                Payment History <span className="font-light">(Invoices)</span>
              </p>
              {isAccordianOpen ? (
                <FontAwesomeIcon
                  icon={faCaretUp}
                  size={'lg'}
                  onClick={toggleAccordian}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCaretDown}
                  size={'lg'}
                  onClick={toggleAccordian}
                />
              )}
            </div>

            {isAccordianOpen && (
              <InvoiceCouponsMobile tableData={tableData} loading={loading} />
            )}
          </div>
        </>
      )}
    </div>
  )
}
