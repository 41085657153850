import { ParagraphText } from '@thryvlabs/maverick'

export const PaymentCards = ({
  className,
  paymentType,
  paymentDate,
  loading,
  paymentAmount,
}) => {
  const renderLastPaymentInfo = () => (
    <ParagraphText
      variant="lg"
      className="text-[18px] leading-[24px] !font-semibold !font-[Montserrat]"
    >
      {paymentAmount}
    </ParagraphText>
  )

  const renderNextPaymentInfo = () => (
    <ParagraphText
      variant="reg"
      className="text-[18px] leading-[24px] !font-semibold !font-[Montserrat]"
    >
      {paymentAmount}
    </ParagraphText>
  )

  return (
    <div
      className={`${className} border border-[#E3E6E8] shadow-[0px_4px_4px_0px_#0000000A] rounded-[4px] bg-[white] px-4 py-3`}
    >
      {loading ? (
        <span className="w-[38px] h-[38px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin relative"></span>
      ) : (
        <>
          <div className="flex justify-between mb-1.5">
            <ParagraphText variant="reg">{paymentType}</ParagraphText>
            <ParagraphText variant="reg" color="[#A3A5A7]">
              {paymentDate}
            </ParagraphText>
          </div>

          {paymentType === 'Last Payment' ? renderLastPaymentInfo() : null}
          {paymentType === 'Next Payment' ? renderNextPaymentInfo() : null}
        </>
      )}
    </div>
  )
}
