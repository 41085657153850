import { ProfileImage, PlaceholderImage } from '../profile-image'

export const ProfileAvatar = ({
  variant,
  user,
  firstName = '',
  lastName = '',
  isViewed,
}) => {
  if (variant === 'thryv-support') {
    return (
      <ProfileImage
        src={null}
        variant={!isViewed && 'active'}
        isThryvSupport={true}
      />
    )
  }

  if (variant === 'user-with-image') {
    return <ProfileImage src={user.imageUrl} variant={!isViewed && 'active'} />
  }

  const isValidName = () => {
    return (
      !firstName.includes('Unknown') &&
      !firstName.includes('(') &&
      !lastName.includes('Unknown') &&
      !lastName.includes('(') &&
      isNaN(firstName)
    )
  }

  return (
    <>
      {isValidName() ? (
        <PlaceholderImage
          firstName={firstName}
          lastName={lastName}
          variant={!isViewed && 'active'}
        />
      ) : (
        <PlaceholderImage variant={!isViewed && 'active'} />
      )}
    </>
  )
}
