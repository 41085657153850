import { useMutation, useLazyQuery } from '@apollo/client'
import { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CREATE_MESSAGE_PIN,
  DELETE_MESSAGE_PIN,
} from '../../../../../graphql/mutations/inbox-message-mutations'
import {
  FETCH_MESSAGES_BY_THREAD_ID,
  FETCH_THREADS,
} from '../../../../../graphql/queries'
import { TransitionContainer } from '../../../../common/transition-container'
import { PinButton, LabelsButton, RemoveButton } from './action-buttons'
import { DELETE_MESSAGE } from '../../../../../graphql/mutations/inbox-message-mutations'
import { setThreadData, setIncomingContactName } from '../../../slices/inboxSlice'
import {
  IncomingMessageContext,
  OutgoingMessageContext,
} from '../../../../../context/message-context'
import { useInboxMenuConversations } from '../../../inbox-menu/hooks/use-inbox-menu-conversations'

/* ################################################ */
/* #              AVAILABLE VISIBLE MENUS         # */
/* ################################################ */
/*
/*          emojis | labels | ''
/*
/* ################################################ */

const ActionButtonsMenu = ({
  variant,
  disableButtons,
  showPin,
  // clickHandlers = {
  //   onRemoveClick: () => {},
  //   onPinClick: () => {},
  // },
  allDropdownOrientations = 'right',
  // eslint-disable-next-line no-console
  onVisibleMenuChange = (activeMenu) => console.log(activeMenu),
  labels,
  sk1,
  pk1,
  chanpk,
  show,
  // id,
  // setOpenReminderModal,
  // setIncomingMessageId,
  // reminderTimeInMilliseconds,
  // setReminderTimeInMilliseconds,
  // customReminderTimeInMilliseconds,
  // setCustomReminderPk,
  // setCustomReminderSk,
  // selectedThreadId,
  messageSk1,

  threadpk,
  messageContentRef,
  refetchMessages,
  refetchPins,
}) => {
  const [visibleMenu, setVisibleMenu] = useState('')
  const dispatch = useDispatch()
  const incomingMessage = useContext(IncomingMessageContext)
  const outgoingMessage = useContext(OutgoingMessageContext)
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )

  const messagePK1 = incomingMessage?.pk1 || outgoingMessage?.pk1

  const recordScrollPosition = () => {
    if (messageContentRef?.current) {
      const scrollTop = messageContentRef?.current.scrollTop
      sessionStorage.setItem('scrollPosition', scrollTop)
    }
  }

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition')
    if (scrollPosition && messageContentRef?.current) {
      messageContentRef.current.scrollTop = parseInt(scrollPosition)
    }
  }

  const { conversationLimit } = useSelector((state) => state.inbox)
  const selectedThreadName = useSelector(
    (state) => state.inboxThreads.selectedThread.name,
  )

  const getIncomingContactName = (data) => {
    if (data.items.length === 0) {
      dispatch(setIncomingContactName(selectedThreadName))
      return
    } else {
      data.items.forEach((message) => {
        if (message?.email_from?.email === selectedThreadName) {
          dispatch(setIncomingContactName(message.email_from.name))
          return
        } else if (message?.dm_from) {
          dispatch(setIncomingContactName(message.dm_from))
          return
        } else if (message?.number_from) {
          dispatch(setIncomingContactName(message.number_from))
          return
        } else {
          dispatch(setIncomingContactName(selectedThreadName))
          return
        }
      })
    }
  }

  const [create_message_pin] = useMutation(CREATE_MESSAGE_PIN)

  const [delete_message_pin] = useMutation(DELETE_MESSAGE_PIN)

  const handlePinButton = async (messagePk1, messageSk1) => {
    recordScrollPosition()
    if (!showPin) {
      create_message_pin({
        variables: {
          channelid: messagePk1?.split('#')[1],
          threadid: selectedThreadId,
          sk1: messageSk1,
        },
      }).then(() => {
        refetchPins({ threadid: selectedThreadId })
        refetchMessages()
        handleScrollPosition()
      })
    } else {
      delete_message_pin({
        variables: {
          channelid: messagePk1?.split('#')[1],
          threadid: selectedThreadId,
          sk1: messageSk1,
        },
      }).then(() => {
        refetchPins({ threadid: selectedThreadId })
        refetchMessages()
        handleScrollPosition()
      })
    }
  }

  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const [getMessages, { data: messagesData }] = useLazyQuery(
    FETCH_MESSAGES_BY_THREAD_ID,
    {
      variables: {
        threadid: selectedThreadId,
        first: conversationLimit,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
      onCompleted: () => {
        handleScrollPosition()
        getIncomingContactName(messagesData.queryMessagesByThread)
        const messages = messagesData.queryMessagesByThread.items.map((message) => {
          return {
            ...message,
            visible: true,
          }
        })
        dispatch(setThreadData(messages?.reverse()))
      },
    },
  )

  const [deleteMessage, { loading }] = useMutation(DELETE_MESSAGE, {
    onCompleted: () => {
      getMessages()
      getThreads()
    },
  })

  const handleDelete = () => {
    recordScrollPosition()

    const pk1 = incomingMessage?.pk1 || outgoingMessage?.pk1
    const sk1 = incomingMessage?.sk1 || outgoingMessage?.sk1
    const channelId = incomingMessage?.chanpk || outgoingMessage?.chanpk
    deleteMessage({
      variables: {
        pk1: pk1,
        sk1: sk1,
        channel_id: channelId,
        thread_id: selectedThreadId,
      },
    })
  }
  const renderVoicemailBtns = (
    <>
      <PinButton
        variant={
          (disableButtons && 'disabled') ||
          (showPin && 'active') ||
          (!showPin && 'inactive')
        }
        onClick={() => handlePinButton(messagePK1, messageSk1)}
      />
      <LabelsButton
        disabled={disableButtons}
        setVisibleMenu={setVisibleMenu}
        labels={labels}
        sk1={sk1}
        pk1={pk1}
        threadpk={threadpk}
        variant={variant}
        chanpk={chanpk}
        menu={{
          isVisible: visibleMenu === 'labels',
          orientation: allDropdownOrientations,
        }}
      />
    </>
  )

  const renderMessageBtns = (
    <>
      {/* FIXME: Removed temporarily: https://app.asana.com/0/0/1204162713457317/f */}
      {/* {type === 'facebook' && (
        <EmojisButton
          disabled={disableButtons}
          setVisibleMenu={setVisibleMenu}
          menu={{
            isVisible: visibleMenu === 'emojis',
            orientation: allDropdownOrientations,
          }}
        />
      )} */}
      <PinButton
        variant={
          (disableButtons && 'disabled') ||
          (showPin && 'active') ||
          (!showPin && 'inactive')
        }
        onClick={() => handlePinButton(messagePK1, messageSk1)}
      />
      <LabelsButton
        disabled={disableButtons}
        setVisibleMenu={setVisibleMenu}
        labels={labels}
        sk1={sk1}
        pk1={pk1}
        threadpk={threadpk}
        variant={variant}
        chanpk={chanpk}
        menu={{
          isVisible: visibleMenu === 'labels',
          orientation: allDropdownOrientations,
        }}
      />
      {loading ? (
        <div className=" bg-[#fcfcff] px-4 py-3 ">
          <div className="dot-flashing "></div>{' '}
        </div>
      ) : (
        <RemoveButton
          disabled={disableButtons}
          onClick={() => {
            handleDelete()
          }}
        />
      )}
    </>
  )

  useEffect(() => {
    onVisibleMenuChange(visibleMenu)
  }, [visibleMenu])

  useEffect(() => {
    return () => {
      //commenting this out until bug is fixed
      // setVisibleMenu('')
    }
  }, [show])

  return (
    variant !== 'CALL' && (
      <TransitionContainer
        show={show}
        as="div"
        className="relative flex rounded bg-thryv-white-50 border border-[#EFEFEF] p-px shadow-[0_2px_3px_0px_#00000040] duration-[301ms]"
        positioning="relative"
        width="fit-content"
        transformOrigin="center"
        unmount={false} // Allows menu to transition with ease in out
      >
        {variant === 'VOICEMAIL' && renderVoicemailBtns}
        {(variant === 'MSG' || variant === 'SMS') && renderMessageBtns}
      </TransitionContainer>
    )
  )
}

export default ActionButtonsMenu
