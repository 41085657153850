import { Button, Header, RegularX } from '@thryvlabs/maverick'
import { HeaderDiv } from '../connect-channels-modal/ModalComponent.styles'
import styled from 'styled-components'
import { useState } from 'react'
import { Notification } from '../../notification/notification'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setModalContent } from '../slices/connectChannelsSlice'
import capitalizeFirstLetter from '../../../inbox/utils/capitalizeFirstLetter'
import { useMediaQuery } from 'react-responsive'
const DeleteChannelModal = ({ handleDelete, clickedID, handleClose }) => {
  const { channelInfo } = useSelector((state) => state.connectChannels)
  const isMobile = useMediaQuery({ maxWidth: 640 })
  // const indexClicked = cardData.findIndex(
  //   (item) => item.ButtonText === channelInfo[clickedID]?.Provider,
  // )

  const dispatch = useDispatch()
  const [channel] = useState(channelInfo[clickedID])

  return (
    <CardContainer>
      <CardTop>
        <HeaderDiv>
          <Header
            fontWeight="semibold"
            variant={isMobile ? 'h6' : 'h4'}
            className="px-2 x-sm:px-0"
          >
            Disconnect Channel
          </Header>
          <Button
            className="ml-20"
            variant="text"
            level={2}
            onClick={() => handleClose(close)}
          >
            <RegularX width="18" height="18" fill="#A3A5A7" onClick={close} />
          </Button>
        </HeaderDiv>
        <TextContainer>
          <StyleModalTitle size="md" variant="subtitle">
            Are you sure you want to disconnect this channel?
          </StyleModalTitle>
          <Notification
            variant="error"
            iconType="solid"
            iconVariant="circleExclamation"
            textToRender={`Once a channel is deleted, you and your staff will lose
            all access and content related to this ${
              channel?.Provider ? capitalizeFirstLetter(channel?.Provider) : null
            } channel on Command Center Inbox after 3 days.`}
            className="w-[432px] text-left sm:w-full sm:text-center sm:p-3"
            textSize="reg"
          />
        </TextContainer>
      </CardTop>

      <CardBottom>
        <Button
          variant="text"
          level={2}
          textVariant="light"
          onClick={() => dispatch(setModalContent(2))}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="w-7/12"
          onClick={() => handleDelete(clickedID)}
        >
          Disconnect
        </Button>
      </CardBottom>
    </CardContainer>
  )
}

export default DeleteChannelModal

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 3.5rem;
  padding: 20px;
  @media (max-width: 640px) {
    width: 100%;
    align-items: center;
  }
`
const CardTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  @media (max-width: 640px) {
    width: 100%;
  }
`

const CardBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 220px;
`
const TextContainer = styled.div`
  width: 436px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  @media (max-width: 640px) {
    width: 100%;
    margin: 0 auto;
  }
`

const StyleModalTitle = styled.h6`
  text-transform: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #231f20;
  padding-top: 1rem;
  width: 100%;
`
