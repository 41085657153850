import { datadogRum } from '@datadog/browser-rum'

const {
  VITE_ENV,
  VITE_DATA_DOG_APPLICATION_ID,
  VITE_DATA_DOG_CLIENT_TOKEN,
  VITE_DATA_DOG_SERVICE,
} = import.meta.env

export const dataDogRumStartSession = (userData) => {
  datadogRum.init({
    applicationId: VITE_DATA_DOG_APPLICATION_ID,
    clientToken: VITE_DATA_DOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    service: VITE_DATA_DOG_SERVICE,
    env: VITE_ENV,
    version: '0.1.0',
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.setUser(userData)
  datadogRum.startSessionReplayRecording()
}

export const dataDogRumEndSession = () => {
  datadogRum.stopSession()
  // setTimeout(() => {
  //   datadogRum.stopSession()
  // }, [10000])
}
