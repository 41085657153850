/* eslint-disable no-useless-escape */
import { ParagraphText } from '@thryvlabs/maverick'
import { Icon } from '@thryvlabs/maverick'
import { useEffect, useState } from 'react'
import 'react-awesome-lightbox/build/style.css'
import { batch } from 'react-redux'
import { useDispatch } from 'react-redux'
import ColoredFileIcon from '../../../../common/colored-file-icon/colored-file-icon'
import {
  handleClickAttachment,
  setFileName,
  setFileType,
} from '../../../slices/messageComposeSlice'
import { formatS3DownloadLink } from './format-S3-dowload-link'
import './uploaded-files-styles.css'
import useUploadedFiles from './useUploadedFiles'

export const UploadedFiles = ({
  uploadedFiles,
  handleRemoveAttachment,
  loadingState,
  openLocalFiles,
}) => {
  return (
    <div
      id="uploaded-files-container"
      className={` w-full flex flex-row gap-x-1 overflow-x-auto absolute`}
    >
      {uploadedFiles?.map((file, index) => {
        let reloadedFileBody
        // need to find a way to make sure we get the file link from s3 for when the user clicks on the reloaded file while in the response bar
        if (file.body !== undefined)
          reloadedFileBody = JSON.parse(file?.body).filename
        return (
          <UploadedFile
            key={index}
            file={file}
            index={index}
            removable={true}
            handleRemoveAttachment={handleRemoveAttachment}
            openLocalFile={openLocalFiles}
            loadingState={loadingState}
            reloadedFileBody={reloadedFileBody}
            uploadedFiles={uploadedFiles}
          />
        )
      })}
    </div>
  )
}

export const UploadedFile = ({
  file,
  index = 0,
  removable = false,
  fileId,
  getAttachment = () => {},
  handleRemoveAttachment = () => {},
  openLocalFile,
  loadingState = false,
  thumbnailUrl,
  reloadedFileBody,
}) => {
  const [thumbnailPicture, setThumbnailPicture] = useState(thumbnailUrl)

  useEffect(() => {
    const image = new Image()
    image.src = thumbnailPicture
    image.onerror = () => {
      setThumbnailPicture('loading') //setting img link to loding to show file icon instead of broken image
    }
  }, [thumbnailUrl])

  return (
    <div
      data-testid="attachmentContainer"
      className="flex flex-row items-center justify-start bg-[#F8F9FB] color-grayscale/thryv-night border border-[#ECEEF1] w-[203px] h-[35px] rounded gap-1 m-1"
    >
      {thumbnailPicture && thumbnailPicture !== 'loading' ? (
        <img
          src={thumbnailPicture}
          className="aspect-square ml-2"
          width={24}
          height={32}
        />
      ) : (
        <ColoredFileIcon
          variant={
            file.name ? file.name : file.filename ? file.filename : reloadedFileBody
          }
          text={
            reloadedFileBody
              ? reloadedFileBody?.substr(reloadedFileBody?.indexOf('.'))?.slice('1')
              : file?.contentType
          }
          height={'24px'}
          width={'18px'}
          className="aspect-square ml-1"
        />
      )}

      <UploadedFileName
        name={
          file.name ? file.name : file.filename ? file.filename : reloadedFileBody
        }
        file_id={fileId ? fileId : file.lastModified} //the file id is used for the query to download the attchment, if its not being passed it means that is a local file thats being uploaded and for that one the sk1 is something different called lastModified
        previewUrl={file.previewUrl}
        openLocalFile={openLocalFile}
        getAttachment={getAttachment}
        loadingState={loadingState}
        file={file}
      />
      {removable ? (
        <button
          type="button"
          onClick={() => {
            handleRemoveAttachment(file, index)
          }}
          className="ml-auto mr-2"
        >
          <Icon
            variant="x"
            type="regular"
            color="#4d4d4d"
            width={8}
            height={8}
            className="min-w-[8px] min-h-[8px]"
          />
        </button>
      ) : null}
    </div>
  )
}

export const UploadedFileName = ({
  name = 'Attachment',
  previewUrl,
  file_id,
  openLocalFile,
  getAttachment,
  loadingState,
  file,
}) => {
  const dispatch = useDispatch()
  const { getDownloadLink } = useUploadedFiles()

  const handleDownload = () => {
    if (!openLocalFile) {
      const attachmentVars = getAttachment(file_id)
      getDownloadLink({
        variables: {
          filename: attachmentVars,
        },
      }).then((res) => {
        const data = res.data
        const formattedUrl = formatS3DownloadLink(data)
        batch(() => {
          dispatch(setFileType(file?.contentType))
          dispatch(setFileName(file?.filename))
          dispatch(handleClickAttachment(formattedUrl))
        })
      })
    } else {
      //files in the response bar go through here
      if (file.previewUrl) {
        batch(() => {
          dispatch(setFileType(file.type))
          dispatch(handleClickAttachment(file.previewUrl))
        })
      } else {
        const attachmentVars = openLocalFile(file.type, file?.sk1)
        getDownloadLink({
          variables: {
            filename: attachmentVars,
          },
        }).then((res) => {
          const data = res.data
          const formattedUrl = formatS3DownloadLink(data)
          const parsedBody = JSON.parse(file.body)

          batch(() => {
            dispatch(setFileType(parsedBody.contentType))
            dispatch(setFileName(parsedBody?.filename))
            dispatch(handleClickAttachment(formattedUrl))
          })
        })
      }
    }
  }

  if (!name) {
    return (
      <ParagraphText
        color="thryv-night"
        variant="sm"
        className="!font-semibold whitespace-nowrap overflow-hidden text-ellipsis pl-1 hover:text-[#057aff] hover:underline hover:cursor-pointer"
        onClick={() => {
          dispatch(handleClickAttachment(previewUrl))
        }}
      ></ParagraphText>
    )
  }
  if (loadingState && file.uploading) {
    return (
      <div className="indeterminate-progress-bar">
        <div className="indeterminate-progress-bar__progress"></div>
      </div>
    )
  }

  const fileTypeRegex = /\.([0-9a-z]+)(?:[\?#]|$)/gi
  const truncatedName =
    name.length > 12 ? `${name.substring(0, 12).trim()}...` : name
  const fileType = name.match(fileTypeRegex)

  return (
    <ParagraphText
      color="thryv-night"
      variant="sm"
      className="!font-semibold whitespace-nowrap overflow-hidden text-ellipsis pl-1 hover:text-[#057aff] hover:underline hover:cursor-pointer"
      onClick={() => {
        handleDownload()
      }}
      data-testid="fileName"
    >
      <span>{truncatedName}</span>
      {/* show this when the content type is not on the name */}
      <span>{truncatedName.includes(fileType) ? null : fileType} </span>

      {/* REMOVING THE FILE SIZE FOR NOW AS IT IS NOT COMMING BACK FROM THE BE SO WE THOUGHT IT WOULD JUST BE BETTER TO REMOVE IT ALTOGETHER  */}
      {/*  <span className="text-thryv-steel">{formattedSize}</span> */}
    </ParagraphText>
  )
}
