import { createContext, useContext, useEffect, useState } from 'react'
import { io } from 'socket.io-client'

const TC_BACKEND_DEV_API_URL = "https://teamchatbackend-staging.thryv.com/"
const TC_BACKEND_PROD_API_URL = "https://teamchatproduction.thryv.com/"

const TEAMCHAT_API_URL =
  import.meta.env.VITE_ENV === 'prod'
    ? TC_BACKEND_PROD_API_URL
    : TC_BACKEND_DEV_API_URL

const SocketContext = createContext()

export const useSocket = () => useContext(SocketContext)

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    const newSocket = io(`${TEAMCHAT_API_URL}`, {
      transports: ['websocket'],
    })
    setSocket(newSocket)

    return () => {
      newSocket.close()
    }
  }, [])

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
}
