/* eslint-disable no-console */
import axios from 'axios'
import { useEffect, useState } from 'react'
import camera from '../../assets/camera.png'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_BUSINESS_ICON_UPLOAD_URL } from '../../graphql/queries/business-info-queries'
import { GET_S3_DOWNLOAD_LINK } from '../../graphql/queries/inbox-queries'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector, useDispatch } from 'react-redux'
import { setBusinessLogoPreview } from './slices/businessInfoSlice'
import { AvatarSkeleton } from './components/avatar-skeleton'

const UploadImage = () => {
  // All logic from this article -> https://medium.com/@ibamibrhm/custom-upload-button-image-preview-and-image-upload-with-react-hooks-a7977618ee8c

  const [image, setImage] = useState({ preview: '', raw: '' })
  const { user } = useAuth0()
  const dispatch = useDispatch()
  const businessLogo = useSelector((state) => state.businessInfo.logo)

  useEffect(() => {
    setImage({
      preview: businessLogo,
    })
  }, [businessLogo])

  const handleChange = (e) => {
    if (e.target.files.length) {
      dispatch(setBusinessLogoPreview(URL.createObjectURL(e.target.files[0])))
      handleUpload(e.target.files[0])
    }
  }

  const [getBusinessLogoUploadURL] = useLazyQuery(GET_BUSINESS_ICON_UPLOAD_URL, {
    fetchPolicy: 'no-cache',
  })

  const { loading } = useQuery(GET_S3_DOWNLOAD_LINK, {
    variables: {
      filename: `${user.cc_id}/logo`,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.getSafeS3DownloadURL) {
        const formattedUrl = data.getSafeS3DownloadURL.substring(
          data.getSafeS3DownloadURL.indexOf('=') + 1,
          data.getSafeS3DownloadURL.length - 1,
        )
        setImage({ preview: formattedUrl, raw: formattedUrl })
        dispatch(setBusinessLogoPreview(formattedUrl))
      }
    },
  })

  const uploadBusinessLogoToS3 = async (uploadUrl, fileToUpload, contentType) => {
    let options = {
      headers: { 'Content-Type': contentType },
    }
    await axios.put(uploadUrl, fileToUpload, options).catch((err) => {
      console.log('Error uploading file to s3', err)
    })
  }

  const handleUpload = async (file) => {
    const uploadURL = await getBusinessLogoUploadURL({
      variables: {
        content_type: file.type,
      },
    })

    try {
      uploadBusinessLogoToS3(
        uploadURL.data.getBusinessIconUploadURL,
        file,
        file.type,
      )
    } catch (err) {
      console.log('Error uploading business logo to s3 bucket', err)
    }
  }

  return loading ? (
    <AvatarSkeleton />
  ) : (
    <div>
      <label htmlFor="upload-button">
        {image.preview ? (
          <img
            className="h-[40px] w-[40px] cursor-pointer object-cover"
            src={image.preview}
            alt="client logo"
            onError={() => {
              setImage({
                preview: camera,
              })
            }}
          />
        ) : (
          <>
            <img
              src={camera}
              alt="placeholder logo"
              className="h-[40px] w-[40px] cursor-pointer"
            />
          </>
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </div>
  )
}
export default UploadImage
