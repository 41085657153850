import { Avatar } from '@thryvlabs/maverick'
import {
  AvatarPlaceHolder,
  OutgoingCallHangupIcon,
  OutgoingCallMutedIcon,
  OutgoingCallUnmutedIcon,
  OutgoingCallPauseCallIcon,
  CircleMessageIcon,
  CircleDialPadIcon,
} from '@icons'
import { useAudioPermissionModal } from './audioPermissionModal'
import { useEffect, useState } from 'react'
import { useRedux } from '../../hooks'
import { setCallDirection } from './slices/callSlice'
import { resetSearch } from '../../redux-toolkit/slices/calls/contacts/contacts-slice'
import avatarColorPicker from '../../utils/avatarColorPicker'
import {
  setCallStatus,
  setCallMuted,
  setCallOnHold,
  clearCallEvent,
  setCallDuration,
  setClientNumber,
  setUserUid,
} from './slices/callSlice'
import phoneFormatter from '../../utils/phoneFormatter'
import { setMinutesRemaining } from '../../redux-toolkit'
import {
  roundToTwoDecimalPlaces,
  secondsToMinutesConverter,
} from './add-minutes/SecondsToMinutesConverter'
import useContactName from './hooks/useContactName'
import { ThryvGuyIcon } from '../command-center-icon/icons'

const outgoingCallActiveButtonStyles =
  'active:outline-0 active:ring-8 active:ring-opacity-5 active:ring-thryv-black-500 rounded-full transition-all duration-200 ease-in-out'

const outgoingCallWidgetStyles =
  'w-full bg-white flex flex-col justify-start items-center mt-10'

const OutgoingCall = ({ phoneValue, isMobile, setShowKeypad, showKeypad }) => {
  const [dispatch, useSelector] = useRedux()
  const { countryIso2 } = useSelector((state) => state.countryCode)
  const {
    audioPermission,
    callStatus,
    callMuted,
    callOnHold,
    dtmf,
    callDuration: { secs, mins },
    clientNumber,
    userUid,
    vonageClient,
  } = useSelector((state) => state.calls)

  const [minutes, setMinutes] = useState(mins)
  const [seconds, setSeconds] = useState(secs)
  const [{ minutesRemaining }] = useRedux('meetings')
  const contactName = useContactName()
  const [isOpen, , PermissionModal] = useAudioPermissionModal(isMobile)

  // Function that clear call event&status after call ends
  const resetCallEvent = (isAudioIssue = false) => {
    // notified user call is end if they open up keypad
    if (setShowKeypad) setShowKeypad(false)
    dispatch(resetSearch())
    if (!isAudioIssue) {
      setTimeout(() => {
        setMinutes(0)
        setSeconds(0)
        dispatch(clearCallEvent())
      }, 3000)
    } else {
      setMinutes(0)
      setSeconds(0)
      dispatch(clearCallEvent())
    }
  }

  // Make outgoing call
  useEffect(() => {
    if (audioPermission && !callStatus && vonageClient.app) {
      let prefix = '1'
      let cleanPhoneValue = phoneValue.match(/\d+/g).join('')
      if (countryIso2 === 'CA' || countryIso2 === 'US') prefix = '1'
      if (countryIso2 === 'NZ') prefix = '64'
      if (countryIso2 === 'AU') {
        prefix = '61'
        if (cleanPhoneValue[0] === '0') {
          //Remove the 0 before australian phone numbers
          cleanPhoneValue = cleanPhoneValue.substring(1)
        }
      }
      let destination = cleanPhoneValue
      if (destination.length != 11) {
        destination = prefix + cleanPhoneValue
      }
      dispatch(setCallDirection('Outgoing'))
      dispatch(setClientNumber(destination))
      vonageClient.app.serverCall({ to: destination })
    }
  }, [audioPermission, vonageClient.app])

  // When microphone have no permission, clear the call event
  useEffect(() => {
    if (!audioPermission && !isOpen) {
      resetCallEvent(true)
    }
  }, [isOpen, audioPermission])

  // counting call minutes
  useEffect(() => {
    let timer
    if (callStatus === 'answered') {
      timer = setTimeout(() => {
        if (seconds + 1 === 60) {
          setSeconds(0)
          setMinutes(minutes + 1)
        } else {
          setSeconds(seconds + 1)
        }
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
      if (callStatus !== 'completed') {
        dispatch(setCallDuration({ minutes, seconds }))
      }
    }
  }, [seconds, callStatus])

  // Keep track of call status
  useEffect(() => {
    if (vonageClient.app) {
      const symbol = vonageClient.app.on(
        'legStatusUpdate',
        (callId, legId, status) => {
          dispatch(setUserUid(callId))
          dispatch(setCallStatus(status.y7_1.toLocaleLowerCase()))
        },
      )
      // clear call listener when component unmount
      return () => {
        if (vonageClient.app) {
          vonageClient.app.off('legStatusUpdate', symbol)
        }
      }
    }
  }, [vonageClient.app])

  useEffect(() => {
    if (callStatus === 'completed') {
      dispatch(
        setMinutesRemaining(
          roundToTwoDecimalPlaces(
            minutesRemaining - (mins + secondsToMinutesConverter(secs)),
          ),
        ),
      )
    }

    if (
      [
        'cancelled',
        'busy',
        'timeout',
        'failed',
        'rejectCall',
        'completed',
        'unanswered',
      ].includes(callStatus)
    ) {
      resetCallEvent()
    }
  }, [callStatus])

  // handle IVR(Interactive Voice Response) call
  useEffect(() => {
    if (callStatus === 'answered' && dtmf) {
      vonageClient.app.sendDTMF(userUid, dtmf[0])
    }
  }, [callStatus, dtmf, vonageClient.app])

  // handle hangup
  const handleClickOnHangUp = async () => {
    await vonageClient.app.hangup(userUid).then(() => resetCallEvent())

    dispatch(setCallStatus('completed'))
  }
  // Onhold call
  const handleClickOnHold = async () => {
    if (callOnHold) {
      await vonageClient.app.unmute(userUid)
      await vonageClient.app.disableEarmuff(userUid)
    } else {
      await vonageClient.app.mute(userUid)
      await vonageClient.app.enableEarmuff(userUid)
    }
    dispatch(setCallMuted(!callOnHold))
    dispatch(setCallOnHold(!callOnHold))
  }

  // mute call
  const handleClickOnMute = async () => {
    if (!callOnHold) {
      if (callMuted) await vonageClient.app.unmute(userUid)
      else await vonageClient.app.mute(userUid)
      dispatch(setCallMuted(!callMuted))
    }
  }
  const handleClickOnMsg = () => {
    alert('show message')
  }
  const displayDuration = () => {
    let sec = seconds
    let min = minutes
    if (sec < 10) {
      sec = '0' + sec
    }
    if (min < 10) {
      min = '0' + min
    }
    return min + ':' + sec
  }
  const renderMutedStatus = callMuted ? (
    <OutgoingCallMutedIcon fill="red" />
  ) : (
    <OutgoingCallUnmutedIcon />
  )

  const renderOutgoingCallAvatar = (size) => {
    if (contactName === 'Thryv Support Center') {
      return <ThryvGuyIcon className={``} />
    } else if (contactName) {
      return (
        <Avatar
          variant="name"
          staticBackground
          backgroundColor={avatarColorPicker(contactName?.split(' ')[1])}
          name={{
            firstName: contactName?.split(' ')[0] || '',
            lastName: contactName?.split(' ')[1] || '',
          }}
          size={size || 'default'}
        />
      )
    }

    return <AvatarPlaceHolder />
  }

  const renderOutGoinCallWithPermissions =
    // audioPermissionStatus === 'granted' &&
    ['ringing', 'started', 'answered', null].includes(callStatus) ? (
      <div className={outgoingCallWidgetStyles}>
        {renderOutgoingCallAvatar()}
        {contactName && (
          <div className="font-montserrat font-semibold text-base text-black mt-2">
            {contactName}
          </div>
        )}
        <span
          className={`${
            contactName
              ? 'font-montserrat text-sm font-normal'
              : 'font-montserrat font-semibold text-base text-black mt-2'
          }`}
        >
          {/* FixMe when international code need to be specified */}
          {phoneFormatter(clientNumber)}
        </span>
        <span className="font-open-sans font-normal text-base text-black">
          {callStatus === 'answered' ? displayDuration() : 'Calling...'}
        </span>

        <div className="w-full flex gap-8 px-[59px] mt-6">
          <button
            className={`${outgoingCallActiveButtonStyles}`}
            onClick={handleClickOnMute}
          >
            {renderMutedStatus}
          </button>
          <button
            className={`${outgoingCallActiveButtonStyles}`}
            onClick={handleClickOnHangUp}
          >
            <OutgoingCallHangupIcon />
          </button>
          <button
            onClick={handleClickOnHold}
            className={`${outgoingCallActiveButtonStyles}`}
          >
            <OutgoingCallPauseCallIcon fill={callOnHold ? 'red' : ''} />
          </button>
        </div>
      </div>
    ) : null

  const renderOutGoinCallEnded = () =>
    // audioPermissionStatus === 'granted' &&
    callStatus === 'completed' ? (
      <div className={outgoingCallWidgetStyles}>
        {renderOutgoingCallAvatar()}
        <span className="font-montserrat font-semibold text-base text-black mt-2">
          Call Ended
        </span>
        <span className="font-open-sans font-normal text-base text-black">
          {displayDuration()}
        </span>

        <div className="w-full flex gap-8 px-[59px] mt-6">
          <button className="cursor-not-allowed active:outline-0 active:ring-0">
            <OutgoingCallMutedIcon />
          </button>
          <button
            className={`${outgoingCallActiveButtonStyles}`}
            // onClick={handleClickOnHangUp}
          >
            <OutgoingCallHangupIcon />
          </button>
          <button className="cursor-not-allowed active:outline-0 active:ring-0">
            <OutgoingCallPauseCallIcon />
          </button>
        </div>
      </div>
    ) : null

  const renderOutgoinCallMobile = () => (
    <div className="flex flex-col items-center">
      {renderOutgoingCallAvatar('large')}
      {contactName && (
        <div className="font-montserrat font-semibold text-base text-black mt-2">
          {contactName}
        </div>
      )}
      <span
        className={`${
          contactName
            ? 'font-montserrat text-sm font-normal'
            : 'font-montserrat font-semibold text-base text-black mt-2'
        }`}
      >
        {phoneFormatter(clientNumber)}
      </span>
      <span className="font-open-sans font-normal text-sm text-black mt-2">
        {['ringing', 'started'].includes(callStatus) && 'Outgoing Call'}
        {callStatus === 'answered' && displayDuration()}
        {callStatus === 'completed' && 'Call Ended'}
      </span>
      <div className="flex flex-row gap-8 justify-between mt-12">
        <button className="w-16 h-16" onClick={handleClickOnMsg} disabled>
          <CircleMessageIcon width={'64'} height={'64'} />
        </button>
        <button className="w-16 h-16" onClick={handleClickOnMute}>
          <OutgoingCallMutedIcon
            width={'64'}
            height={'64'}
            fill={callMuted && 'red'}
          />
        </button>
      </div>
      <div className="flex flex-row gap-8 mt-9">
        <button onClick={handleClickOnHold}>
          <OutgoingCallPauseCallIcon
            width={'64'}
            height={'64'}
            fill={callOnHold && 'red'}
          />
        </button>
        <button onClick={() => setShowKeypad(true)}>
          <CircleDialPadIcon width={'64'} height={'64'} />
        </button>
      </div>
      <button className="mt-16" onClick={handleClickOnHangUp}>
        <OutgoingCallHangupIcon />
      </button>
    </div>
  )

  if (!vonageClient.app) return null
  if (!audioPermission) return <>{PermissionModal}</>
  return (
    <>
      {/* {renderOutgoingCallWithNoPermissions} */}
      {!isMobile ? renderOutGoinCallWithPermissions : null}
      {!isMobile ? renderOutGoinCallEnded() : null}
      {isMobile && !showKeypad ? renderOutgoinCallMobile() : null}
    </>
  )
}

export default OutgoingCall
