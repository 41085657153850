import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import AddPaymentMethodCard from '../../../modules/settings/MyAccount/PaymentMethod/AddPaymentMethodCard'

const Step1 = ({
  cardInfo,
  cardStatus,
  setFormPart,
  timeRemaining,
  monthlyMinuteSubscription,
  isCardSelected,
  setMonthlyMinuteSubscription,
}) => {
  return (
    <>
      <div>
        <p className="h-[36px] m-[1rem] w-[95%] text-[14px] font-normal open-sans bg-[#FFF9EA] flex items-center">
          <FontAwesomeIcon
            className="ml-[1rem] mr-[8px] text-[#FAC02B] "
            icon={faTriangleExclamation}
          />
          <span>{`You have ${timeRemaining} minutes remaining in your plan.`}</span>
        </p>

        <p className="text-[#808080] text-[14px] font-normal ml-[1rem] mt-[1rem] mb-[1rem]">
          Your Plan
        </p>
        <AddPaymentMethodCard
          label="Renews on the 15th of every month, 60 mins included"
          cardHeader="Basic"
          isCardSelected={false}
          openAddPaymentModalFun={() => setFormPart('Payment')}
          isBorderDashed={false}
        />
        <p className="text-[#808080] text-[14px] font-normal ml-[1rem] mt-[1rem] mb-[1rem]">
          Add monthly minutes to your subscription
        </p>
        <AddPaymentMethodCard
          label={[
            'Minutes don’t roll over. Learn more about how minutes work',
            <a
              key={Math.random()}
              target="_blank"
              rel="noopener noreferrer"
              href="https://learn.thryv.com/hc/en-us/articles/16511823137677-Calls-Minute-Counter"
              className="text-[#057AFF]"
            >
              {' '}
              here
            </a>,
          ]}
          cardHeader="100 monthly minutes"
          isCardSelected={false}
          supportIncreaseDecrease={true}
          monthlyMinuteSubscription={monthlyMinuteSubscription}
          setMonthlyMinuteSubscription={setMonthlyMinuteSubscription}
          decreaseClick={() => {
            if (monthlyMinuteSubscription > 0)
              setMonthlyMinuteSubscription(Number(monthlyMinuteSubscription) - 1)
          }}
          increaseClick={() =>
            setMonthlyMinuteSubscription((prev) => Number(prev) + 1)
          }
          openAddPaymentModalFun={() => setFormPart('Payment')}
          isBorderDashed={false}
        />
        <p className="text-[#808080] text-[14px] font-normal ml-[1rem] mt-[1rem] mb-[1rem]">
          Payment Method
        </p>
        <AddPaymentMethodCard
          label={
            isCardSelected
              ? `${cardInfo?.cardType} ending in ${cardInfo?.lastFour}`
              : 'No Card Selected'
          }
          isCardSelected={isCardSelected}
          cardActionTypeLabel={isCardSelected ? 'Change' : 'Add'}
          openAddPaymentModalFun={() => setFormPart('Payment')}
          isBorderDashed={false}
          cardStatus={cardStatus} //for active statsus should be ACTIVE
          activeCardType={cardInfo?.cardType} //incase any card are present on the basic of the we can use i.e try using visa
        />
      </div>
    </>
  )
}

export default Step1
