import { useLazyQuery, useSubscription } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { useInboxMenuConversations } from '../inbox/inbox-menu/hooks/use-inbox-menu-conversations'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  SUBSCRIPTION_FOR_MESSAGE_CREATED,
  FETCH_MESSAGES_BY_THREAD_ID,
  FETCH_THREADS,
  SUBSCRIPTION_FOR_SUCCESSFUL_CONTACT_MERGE,
} from '../../graphql'
import { GET_LABEL_OPTIONS_BY_LOCATION } from '../../graphql'
import { GET_MESSAGES_BY_PHONE_CHANNEL } from '../../graphql'
import { setThreadData, setIncomingContactName } from '../inbox/slices/inboxSlice'
import { setMostRecentThread } from '../inbox/slices/inboxThreadsSlice'
import {
  setInboxNotifications,
  setCallsNotifications,
} from '../../redux-toolkit/slices/notifications/notifications-slice'
import { setIsLoading } from '../inbox/slices/inboxSlice'
import { setLabelOptions } from '../inbox/slices/messageContentSlice'

import { Buffer } from 'buffer'
window.Buffer = Buffer

export function InboxSubscriptionListener() {
  const { initMessageThreads, initPinnedMessageThreads, createVariables } =
    useInboxMenuConversations()
  const dispatch = useDispatch()
  const [sk1, setSk1] = useState(0)
  const { conversationLimit } = useSelector((state) => state.inbox)
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )
  const selectedThreadName = useSelector(
    (state) => state.inboxThreads.selectedThread.name,
  )

  const getIncomingContactName = (data) => {
    if (data.items.length === 0) {
      dispatch(setIncomingContactName(selectedThreadName))
      return
    } else {
      data.items.forEach((message) => {
        if (message?.email_from?.email === selectedThreadName) {
          dispatch(setIncomingContactName(message.email_from.name))
          return
        } else if (message?.dm_from) {
          dispatch(setIncomingContactName(message.dm_from))
          return
        } else if (message?.number_from) {
          dispatch(setIncomingContactName(message.number_from))
          return
        } else {
          dispatch(setIncomingContactName(selectedThreadName))
          return
        }
      })
    }
  }

  const { user } = useAuth0()

  const { data: subscriptionAlert } = useSubscription(
    SUBSCRIPTION_FOR_MESSAGE_CREATED,
  )

  const { data: mergeAlert } = useSubscription(
    SUBSCRIPTION_FOR_SUCCESSFUL_CONTACT_MERGE,
  )

  const [getMessages, { data: messagesData, loading: messagesLoading }] =
    useLazyQuery(FETCH_MESSAGES_BY_THREAD_ID, {
      variables: {
        threadid: selectedThreadId,
        first: conversationLimit,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
      onCompleted: () => {
        getIncomingContactName(messagesData.queryMessagesByThread)
        const messages = messagesData.queryMessagesByThread.items.map((message) => {
          return {
            ...message,
            visible: true,
          }
        })
        dispatch(setThreadData(messages?.reverse()))
      },
    })

  const [getLabelOptions, { data: labelOptions }] = useLazyQuery(
    GET_LABEL_OPTIONS_BY_LOCATION,
    {
      variables: {
        locationid: user?.businessId || '',
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
      onCompleted: () => {
        const updatedLabelOptions = labelOptions.queryLabelOptionsByLocation.map(
          (option) => {
            return { ...option, checked: false }
          },
        )
        dispatch(setLabelOptions(updatedLabelOptions))
      },
    },
  )

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      getLabelOptions()
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
      dispatch(
        setMostRecentThread([
          {
            id: threads?.at(0)?.thread_id,
            name: threads?.at(0)?.thread_name,
          },
          {
            id: threads?.at(1)?.thread_id,
            name: threads?.at(1)?.thread_name,
          },
        ]),
      )
    },
  })

  const [getUnreadThreads, { data: unreadThreadsData }] = useLazyQuery(
    FETCH_THREADS,
    {
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache',
      variables: {
        folder: 'unread',
      },
      onCompleted: () => {
        const threads = unreadThreadsData?.data?.queryThreads?.items?.filter(
          (x) => x?.messages?.items?.length > 0,
        )
        const pinned = unreadThreadsData?.data?.pinned?.items?.filter(
          (x) => x?.messages?.items?.length > 0 && x?.read === false,
        )
        dispatch(setInboxNotifications(threads?.length + pinned?.length || 0))
      },
    },
  )

  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)

  const [getRecentCalls, { data: recentCallsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const unreadCalls = recentCallsData.queryMessagesByPhoneChannel.filter(
          (x) => !x.is_read,
        ).length

        dispatch(
          setCallsNotifications({
            calls: unreadCalls || 0,
          }),
        )
      },
    },
  )

  const [getRecentVoicemails, { data: recentVoicemailsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const unreadVoicemails =
          recentVoicemailsData.queryMessagesByPhoneChannel.filter(
            (x) => !x.is_read,
          ).length

        dispatch(
          setCallsNotifications({
            voicemails: unreadVoicemails || 0,
          }),
        )
      },
    },
  )

  const fetchVoicemailsAndCalls = () => {
    getRecentVoicemails({
      variables: {
        chanpk: phoneChannelID,
        item_type: 'VOICEMAIL',
      },
    })
    getRecentCalls({
      variables: {
        chanpk: phoneChannelID,
        item_type: 'CALL',
      },
    })
  }

  useEffect(() => {
    if (subscriptionAlert) {
      if (sk1 !== subscriptionAlert?.onMessageCreated?.sk1) {
        setSk1(subscriptionAlert?.onMessageCreated?.sk1)
        const threadpk = subscriptionAlert?.onMessageCreated?.pk1?.split('#')[2]
        if (threadpk === selectedThreadId) {
          getMessages()
        }
        getThreads()
        getUnreadThreads()
        if (phoneChannelID) {
          fetchVoicemailsAndCalls()
        }
      }
    }
  }, [subscriptionAlert, phoneChannelID])

  useEffect(() => {
    if (mergeAlert && JSON.parse(mergeAlert?.onGenericUpdate?.body)?.channelId) {
      getMessages()
      getThreads()
      getUnreadThreads()
      if (phoneChannelID) {
        fetchVoicemailsAndCalls()
      }
    }
  }, [mergeAlert, phoneChannelID])

  useEffect(() => {
    if (messagesLoading === false) {
      dispatch(setIsLoading(false))
    }
  }, [messagesLoading])

  return <></>
}
