import { gql } from '@apollo/client'

export const GET_LEFT_NAV_INACTIVE_CENTERS = gql`
  query get_left_nav_inactive_centers($locationid: String!) {
    queryLeftNavOptionsByLocation(location_id: $locationid) {
      BusinessCenter
      MarketingCenter
      Signatures
      WebsiteBuilder
      ThryvLeads
      Listings
    }
  }
`

export const GET_LEFT_NAV_INBOX_CALLS_NOTIFICATIONS = gql`
  query get_left_nav_inbox_calls_notifications {
    queryUnreadPills {
      numThreads
      numCalls
      numVoicemails
    }
  }
`
