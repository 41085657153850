import { gql } from '@apollo/client'

export const FETCH_ACTIVE_USER_DATA = gql`
  query fetchActiveUserData {
    allUsers {
      connection
      phoneNumber
    }
  }
`
