import { InformationIcon } from '../../../../command-center-icon/icons'
import CallAvatar from '../../recent-and-voicemail-components/CallAvatar'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { FETCH_CONTACT_BY_THREAD } from '../../../../../graphql'
import formatContactData from '../../../../inbox/hooks/formatContactData'

const VoicemailContentMobile = ({ voicemail, setDisplayInfoCard }) => {
  const getVoicemailDate = (voicemail) => {
    return moment(voicemail.ts).local().format('ll')
  }

  const { data: contact } = useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: { threadid: voicemail?.threadpk?.split('#')?.at(0) },
  })

  const body =
    typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)?.body
        ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body).body
        : JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)
      : null

  const firstName = body ? body.given_name : ''
  const lastName = body ? body.surname : ''

  const fullName = firstName + ' ' + lastName

  const formattedPhoneNumber = contact
    ? contact?.queryContactsByThread?.items?.at(0)?.contactsk3 === 'Anonymous'
      ? 'Unknown Caller'
      : formatContactData(body?.phone_numbers?.at(0)?.number)
    : null

  return (
    <>
      <div className="flex pr-[10px] pl-[13px]">
        <CallAvatar call={voicemail} firstName={firstName} lastName={lastName} />
      </div>
      <div className={`flex flex-col grow justify-center`}>
        <div
          className={`${
            !voicemail.voiceMailPlayed ? '' : ''
          } block max-w-[175px] truncate font-semibold`}
        >
          {contact && fullName.length > 1 ? fullName : formattedPhoneNumber}
        </div>
        <div className="flex flex-row text-thryv-black pt-[5px]">
          <div className="flex text-[14px]">{voicemail.location}</div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex flex-row text-thryv-steel text-[14px] pr-[8px] items-center">
          <div className="flex flex-col pr-[16px]">
            <span className="flex pb-[2px]">{getVoicemailDate(voicemail)}</span>
            {/* Commenting out voicemail length for now */}
            {/* <span className="flex pt-[2px] justify-end">00:50</span> */}
          </div>
          <div
            onClick={() => {
              setDisplayInfoCard(true)
            }}
            className="flex pr-[6px] items-center"
          >
            <InformationIcon height="36px" width="36px" fill="#CCCCCC" />
          </div>
        </div>
      </div>
    </>
  )
}

export default VoicemailContentMobile
