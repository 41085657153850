import { useRef, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledAccordion } from '../LeftNavBar.styles'
import { NavLink } from 'react-router-dom'
import CustomStyledTooltip from '../CustomStyledTooltip.jsx'
import { faStore, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { useDispatch } from 'react-redux'
import { setMobileNavOpen } from '../slices/left-nav-slice'
const MarketplaceSection = ({
  collapsed,
  sectionIsEditing,
  handleAccordionOpenClose,
  mobileSectionsOpen,
  accordionRef,
}) => {
  const [parentCoords, setParentCoords] = useState({ x: 0, y: 0 })
  const [isHovering, setIsHovering] = useState(false)
  const [isLoaded, setisLoaded] = useState(false)
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const container = useRef(null)
  const collapseRef = useRef(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setisLoaded(true)
  }, [])

  useEffect(() => {
    if (isLoaded) {
      setShouldAnimate(true)
    }
  }, [collapsed])

  const handleMouseEnter = (e) => {
    if (collapsed) {
      setIsHovering(container.current.contains(e.target))
      if (parentCoords.x === 0) {
        const el = document.getElementById(`menu-item-marketplace`)
        let { x, y } = el.getBoundingClientRect()
        x = x + 5
        setParentCoords({ x, y })
      }
    }
  }

  const handleHover = ({ target }) => {
    if (container.current && container.current.contains(target)) {
      return
    }
    setParentCoords({ x: 0, y: 0 })
    return setIsHovering(false)
  }

  useEffect(() => {
    if (collapsed) {
      document.addEventListener('mouseover', handleHover)

      return () => {
        document.removeEventListener('mouseover', handleHover)
      }
    }
  }, [collapsed])

  return (
    <div
      onClick={handleAccordionOpenClose}
      className="flex md:w-[280px] md:border-b-[2px] border-[#F0F0F0]"
    >
      <StyledAccordion title="Marketplace" className="z-20 min-w-md:hidden">
        <div
          id="marketplace-wrapper"
          className={`text-thryv-steel inline-block my-0 mx-auto gap-[8px] ${
            collapsed
              ? `${
                  !collapseRef.current && sectionIsEditing.length === 0
                    ? 'animate-slideup'
                    : ''
                } w-[36px]`
              : `${
                  collapseRef.current && sectionIsEditing.length === 0
                    ? 'animate-slidedown'
                    : ''
                } w-[228px] md:w-[275]`
          } ml-[3px] md:border-hidden border-b-[1px] border-[#F0F0F0]`}
        >
          <NavLink
            to="/marketplace"
            onClick={() => {
              dispatch(setMobileNavOpen(false))
            }}
          >
            {({ isActive }) => (
              <div
                style={isActive ? { backgroundColor: '#ECEEF1' } : {}}
                id="marketplace-icon-wrapper"
                className={`flex ${
                  collapsed
                    ? 'w-[36px] h-[36px]'
                    : 'w-[231px] md:w-[275px] pl-[15px]'
                } min-w-md:grow min-w-md:justify-center  py-[6px] align-center gap-[8px] rounded-[4px] hover:bg-[#ECEEF1] hover:text-dark-gray hover:cursor-pointer`}
              >
                <FontAwesomeIcon
                  icon={faStore}
                  className={`${
                    collapsed
                      ? 'h-[22px] w-[23px] pt-[2px]'
                      : 'pl-0 h-[16px] w-[17px]'
                  } text-[#056E30]`}
                />
                {!collapsed ? (
                  <span
                    id="marketplace-title"
                    className="text-[12px] leading-[18px] font-semibold font-primary text-thryv-steel"
                  >
                    Marketplace
                  </span>
                ) : null}
              </div>
            )}
          </NavLink>
        </div>
      </StyledAccordion>
      <div
        ref={container}
        onMouseEnter={handleMouseEnter}
        data-testid={`menu-item-marketplace`}
        id={`menu-item-marketplace`}
      >
        <NavLink to="/marketplace">
          {({ isActive }) => (
            <div
              className={`text-thryv-steel inline-block my-0 mx-auto gap-[8px] md:hidden ${
                collapsed
                  ? `${shouldAnimate ? 'animate-slideup' : ''} w-[36px]`
                  : `${shouldAnimate ? 'animate-slidedown' : ''} w-[228px]`
              } ml-[3px] pb-[4px] md:border-hidden border-b-[1px] border-[#F0F0F0]`}
            >
              <div
                style={isActive ? { backgroundColor: '#ECEEF1' } : {}}
                className={`flex group relative ${
                  collapsed
                    ? 'w-[36px] h-[36px] flex justify-center'
                    : 'w-[231px] pl-[13px]'
                } py-[6px] bg-white gap-[8px] rounded-[4px] hover:cursor-pointer hover:bg-[#ECEEF1] items-center text-thryv-steel hover:text-dark-gray`}
              >
                <FontAwesomeIcon
                  icon={faStore}
                  className={`${
                    collapsed
                      ? 'h-[22px] w-[23px] pt-[2px]'
                      : 'pl-0 h-[16px] w-[17px]'
                  } text-[#056E30]`}
                />
                {!collapsed ? (
                  <span
                    id="marketplace"
                    className="text-[12px] leading-[18px] font-semibold font-primary"
                  >
                    Marketplace
                  </span>
                ) : null}
              </div>
            </div>
          )}
        </NavLink>
        <FontAwesomeIcon
          className={`
              ${
                mobileSectionsOpen.includes('Marketplace')
                  ? 'animate-iconRotateOpen'
                  : `${accordionRef === 'Marketplace' && 'animate-iconRotateClosed'}`
              }
                absolute left-[240px] min-w-md:hidden text-center p-[15px] text-black`}
          size="sm"
          icon={faChevronDown}
          onAnimationEnd={() => {}}
        />
        {isHovering && (
          <CustomStyledTooltip
            options={{ title: 'Marketplace' }}
            collapsed={collapsed}
            coords={parentCoords}
          />
        )}
      </div>
    </div>
  )
}

export default MarketplaceSection
