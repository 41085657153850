import { useQuery } from '@apollo/client'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../graphql'
import { useState, useEffect } from 'react'

export const useConnectedChannels = (businessId, cc_uid) => {
  const [allChannels, setAllChannels] = useState([])
  const [inaccessibleChannels, setInaccessibleChannels] = useState([])
  const [accessibleChannels, setAccessibleChannels] = useState([])
  const [readOnlyChannels, setReadOnlyChannels] = useState([])
  const [readWriteChannels, setReadWriteChannels] = useState([])

  const { data: connectedChannelsData } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: { loc_id: businessId, user_id: cc_uid },
  })

  const allChannelsData = connectedChannelsData?.queryConnectedChannelsByUser

  const initChannels = () => {
    const tempInaccessibleChannels = []
    const tempAccessibleChannels = []
    const tempReadOnlyChannels = []
    const tempReadWriteChannels = []

    allChannelsData.forEach((channel) => {
      if (channel.Provider === 'CC') return

      if (channel.Status === '1' || channel.Status === '2') {
        tempAccessibleChannels.push(channel)
      }

      if (channel.Status === '0') tempInaccessibleChannels.push(channel)

      if (channel.Status === '1') tempReadOnlyChannels.push(channel)

      if (channel.Status === '2') tempReadWriteChannels.push(channel)
    })

    setInaccessibleChannels(tempInaccessibleChannels)
    setAccessibleChannels(tempAccessibleChannels)
    setReadOnlyChannels(tempReadOnlyChannels)
    setReadWriteChannels(tempReadWriteChannels)
    setAllChannels(allChannelsData)
  }

  useEffect(() => {
    if (allChannelsData) {
      initChannels()
    }
  }, [])

  return {
    inaccessibleChannels: inaccessibleChannels,
    accessibleChannels: accessibleChannels,
    readOnlyChannels: readOnlyChannels,
    readWriteChannels: readWriteChannels,
    allChannels: allChannels,
  }
}
