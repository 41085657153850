import { ModalWrapper } from '@thryvlabs/maverick'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDeleteChannel } from '../../../../../components/common/modals/channels-modal-component/ChannelsModalComponent'
import { ModalContainer } from '../../../../../components/common/modals/connect-channels-modal/ModalComponent.styles'
import DeleteChannelModal from '../../../../../components/common/modals/delete-channel-modal/DeleteChannelModal'
import ChannelContainer from '../../../../../components/common/modals/manage-channels-modal/channel-container'
import { setModalContent } from '../../../../../components/common/modals/slices/connectChannelsSlice'

export const ConnectedChannelsGroup = ({ className, channels, staffMembers }) => {
  const dispatch = useDispatch()
  const { modalContent } = useSelector((state) => state.connectChannels)
  const [clickedId, setClickedId] = useState(null)

  const { handleDelete } = useDeleteChannel(clickedId)

  const openDeleteModal = (id) => {
    dispatch(setModalContent(3))
    setClickedId(id)
  }

  const handleClose = () => {
    setClickedId(null)
  }

  const isModalVisible = typeof clickedId === 'number' && modalContent === 3

  return (
    <>
      {isModalVisible && (
        <ModalWrapper
          show
          close={handleClose}
          disableBackdropClick
          className="inline-block transform overflow-hidden align-middle bg-white p-6 shadow-xl rounded-lg undefined md:m-auto md:w-[100%] md:my-[10px] "
        >
          <ModalContainer>
            <DeleteChannelModal
              handleClose={handleClose}
              handleDelete={handleDelete}
              clickedID={clickedId}
            />
          </ModalContainer>
        </ModalWrapper>
      )}
      <div className={`${className} flex flex-col gap-8`}>
        {channels.map((channel, i) => {
          return (
            <ChannelContainer
              key={i}
              channel={{
                provider: channel.Provider,
                id: channel.ChannelID,
                iconName: channel.Provider,
                nickname: channel.Nickname ? channel.Nickname : '',
                email:
                  channel.Provider === 'video'
                    ? 'Video Channel'
                    : channel.AccountUserValue,
              }}
              connectError={channel.Status === '99'}
              disableDelete={channel.syncState === '1'}
              reconnectButtonStyles={'ml-[45%]'}
              channelToDeleteIndex={i}
              openDeleteModal={openDeleteModal}
              staffMembers={staffMembers}
            />
          )
        })}
      </div>
    </>
  )
}
