import { useEffect, useRef, useState, Fragment } from 'react'
import { Transition } from '@headlessui/react'

import './tooltip.css'

/* ################################################ */
/* #####      Modified Maverick Tooltip       ##### */
/* ################################################ */
/* ##                                            ## */
/* ##        Includes all Tooltip props from     ## */
/* ##        Maverick's Tooltip except for       ## */
/* ##        flipBubbleOrientation               ## */
/* ##                                            ## */
/* ##        https://tinyurl.com/29snkycf        ## */
/* ##                                            ## */
/* ################################################ */

/* ################################################ */
/* #####                 TIP                  ##### */
/* ################################################ */
/* ##                                            ## */
/* ##        Use width="max-content" to have     ## */
/* ##        the text within the tooltip         ## */
/* ##        ALWAYS BE CENTERED.                 ## */
/* ##                                            ## */
/* ################################################ */

export const TooltipWithCenteredArrow = ({
  variant,
  title,
  description,
  children,
  width,
  className = '',
  positioning = { offsetX: '0px', offsetY: '0px' },
  backgroundColor = '#231F20',
  fontColor = 'white',
  titleFont,
  descriptionFont,
  disableVisibleOnClick,
}) => {
  /* Checking for missing required props */
  if (!variant)
    throw new Error(
      `You need to pass a prop called "variant" of type string to the TooltipWithCenteredArrow component. To learn more about the list of required props for the
      Tooltip component go to Maverick documentation at: https://main.d1vpc2eiy8nx58.amplifyapp.com/?path=/docs/ui-tooltips--tooltip-top`,
    )
  /* Checking for missing required props */

  const node = useRef(null)
  const [isVisible, setVisible] = useState(false)
  // clicked is used to display the tooltip if the tooltip is clicked on, rather than just when hovered
  const [clicked, setClicked] = useState(false)

  const handleHover = ({ target }) => {
    if (target instanceof Node && node.current?.contains(target)) {
      /** hover over
       * isVisible is set on the onMouseOver event on the tooltip in the JSX
       */
      return
    }
    // hover out
    return setVisible(false)
  }

  useEffect(() => {
    document.addEventListener('mouseover', handleHover)

    return () => {
      document.removeEventListener('mouseover', handleHover)
    }
  }, [])

  // handleClickOutside will close the tooltip if anywhere other than the tooltip is clicked
  const handleClickOutside = (e) => {
    const tooltipNode = node.current
    if (tooltipNode?.contains(e.target)) {
      return setClicked(true)
    }
    return setClicked(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  /* ----- using these hooks to position the top tooltip based on the size of the tooltip -----*/
  // const topTip = useRef<any | null>(null);
  // const [topHeight, setTopHeight] = useState<number | undefined>(0);
  // const [nodeHeight, setNodeHeight] = useState<number | undefined>(0);

  // useEffect(() => {
  //   setNodeHeight(node?.current?.clientHeight);
  //   setTopHeight((topTip?.current?.clientHeight + nodeHeight) * -1);
  // });
  /* ----- ----- */

  const textProps = {
    title,
    titleFont,
    fontColor,
    description,
    descriptionFont,
  }

  const { offsetX = '0px', offsetY = '0px' } = positioning

  const isInverted = variant === 'top' || variant === 'bottom'
  const parallel = isInverted ? offsetY : offsetX
  const perpendicular = isInverted ? offsetX : offsetY

  const stylingData = {
    bottom: {
      center: 'left',
      opposite: 'top',
      offset: `calc(50% + ${perpendicular})`,
      modifier: -1,
      translate: 'translateX(-50%)',
    },
    top: {
      center: 'left',
      opposite: 'bottom',
      offset: `calc(50% + ${perpendicular})`,
      modifier: 1,
      translate: 'translateX(-50%)',
    },
    left: {
      center: 'top',
      opposite: 'right',
      offset: `calc(50% - ${perpendicular})`,
      modifier: -1,
      translate: 'translateY(-50%)',
    },
    right: {
      center: 'top',
      opposite: 'left',
      offset: `calc(50% - ${perpendicular})`,
      modifier: 1,
      translate: 'translateY(-50%)',
    },
  }

  const { center, opposite, offset, modifier, translate } = stylingData[variant]

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const arrowStyling = {
    [`${center}`]: '50%',
    [`${opposite}`]: '0',
    [`border${capitalize(variant)}Color`]: backgroundColor,
    [`border${capitalize(opposite)}`]: '0',
    [`margin${capitalize(opposite)}`]: '-8px',
    [`margin${capitalize(center)}`]: '-8px',
  }

  return (
    <div>
      <div
        className="relative"
        ref={node}
        onMouseEnter={() => setVisible(!isVisible)}
        onFocus={() => setVisible(true)}
        onBlur={() => setVisible(false)}
      >
        <div>{children}</div>
        {(disableVisibleOnClick ? isVisible : isVisible || clicked) && (
          <Transition
            as={Fragment}
            show={disableVisibleOnClick ? isVisible : isVisible || clicked}
            enter="transition-all duration-75 z-[200]"
            enterTo="scale-100 opacity-100"
            leave="transition-all duration-500 z-[200]"
            leaveFrom="scale-100 opacity-100"
            leaveTo="opacity-0 scale-0"
          >
            <div
              className={`absolute ${className} z-[200]`}
              style={{
                [`${opposite}`]: `calc(100% + 20px + (${parallel} * ${modifier}))`,
                [`${center}`]: `${offset}`,
                transform: translate,
                width,
              }}
            >
              <div
                className={`bubble ${description && 'min-w-[206px]'}`}
                style={{
                  backgroundColor: backgroundColor,
                }}
              >
                <TooltipText {...textProps} />
              </div>
              <span className="bubble-arrow" style={arrowStyling} />
            </div>
          </Transition>
        )}
      </div>
    </div>
  )
}

const TooltipText = ({
  title,
  titleFont,
  fontColor,
  description,
  descriptionFont,
}) => {
  return (
    <>
      {title && (
        <div
          style={{ font: titleFont, color: fontColor }}
          className={`text-thryv-white-200 text-h9 font-montserrat font-semibold text-center ${
            description && 'pb-[10px]'
          }`}
        >
          {title}
        </div>
      )}
      {description && (
        <div
          style={{ font: descriptionFont, color: fontColor }}
          className="text-thryv-white-200 text-range-min font-open-sans font-normal text-left"
        >
          {description}
        </div>
      )}
    </>
  )
}
