import { useEffect, useState } from 'react'
import DefaultScreen from '../../recent-and-voicemail-components/default-screens/DefaultScreenMain'
import Recent from './Recent'
import { useSelector } from 'react-redux'
import { determineShowDefaultScreen } from '../../helpers/recentAndVoicemailHelpers'
import { PaginatedList } from '@thryvlabs/maverick'

const Recents = ({
  setDisplayInfoCard,
  isMobile,
  contactInfoView,
  setContactInfoView,
  setShowMobileContact,
}) => {
  const [callsReceivedTab, setCallsReceivedTab] = useState('all')
  const calls = useSelector((state) => state.calls.calls)
  const tabSelected = 'recent'
  const showCall = calls?.length > 0

  // need to improve this bc if the user goes to the calls page they dont have the channels loaded so it doesnt return anything

  useEffect(() => {
    setCallsReceivedTab('all')
  }, [tabSelected])

  useEffect(() => {
    setCallsReceivedTab('all')
  }, [isMobile])

  return (
    <>
      {determineShowDefaultScreen(tabSelected, calls) ? (
        <DefaultScreen tabSelected="recent" isMobile={isMobile} />
      ) : (
        <div className="h-full min-w-md:pt-[13px] p-[20px] md:p-[0px] md:bg-white">
          {/* Remove Received Calls Navigation */}
          {/* <ReceivedCallsNavigation
            callsReceivedTab={callsReceivedTab}
            setCallsReceivedTab={setCallsReceivedTab}
          /> */}
          {/* NEED TO GET THE DIVIDER IN THE APPROPIATE PLACES */}

          {/* <CallHistoryDivider
            date={moment(calls[0].timestamp).format('DD MMM yy')}
          /> */}

          {/* Add the 3 loading dots while its loading  */}
          {showCall && (
            <PaginatedList list={calls}>
              <Recent
                contactInfoView={contactInfoView}
                setContactInfoView={setContactInfoView}
                setDisplayInfoCard={setDisplayInfoCard}
                isMobile={isMobile}
                callsReceivedTab={callsReceivedTab}
                setShowMobileContact={setShowMobileContact}
              />
            </PaginatedList>
          )}
        </div>
      )}
    </>
  )
}

export default Recents
