/* ----- command-center-icon.jsx ----- */
import {
  ThryvCommandCenterIcon,
  ThumbtackIcon,
  FilterIcon,
  CirclePhoneIcon,
  GmailIcon,
  YahooIcon,
  OutlookIcon,
  FacebookMessengerIcon,
  GoogleMessengerIcon,
  WebchatIcon,
  IcloudIcon,
  HotmailIcon,
  AolIcon,
  IMAPIcon,
  RegularAltThumbtackIcon,
  SolidAltThumbtackIcon,
  TowerBroadcastRegularIcon,
  FontFormatIcon,
  PaperClipIcon,
  HyperlinkIcon,
  HappyEmojiIcon,
  GifIcon,
  VerticalLineIcon,
  PaperPlaneCircleIcon,
  CloudUploadIcon,
  GmailRoundedSquare,
  AlarmClockIcon,
  HorizontalLineIcon,
  ExpandDiagonalArrowsIcon,
  LabelIcon,
  CommentCheckRegularIcon,
  HorizontalEllipsisIcon,
  NewAppointmentIcon,
  RegisterEventIcon,
  RequestPaymentIcon,
  NewInvoiceIcon,
  ChargeCardIcon,
  InviteClientIcon,
  InviteScheduleIcon,
  IncomingCallIcon,
  OutgoingCallIcon,
  MissedCallIcon,
  SolidIncomingCallIcon,
  RegularReplyAllIcon,
  VideoIcon,
  CirclePenIcon,
  RegularThumbtackIcon,
  RegularUploadFolderIcon,
  SolidOutgoingCallIcon,
  SolidRoundedPauseButtonIcon,
  SolidPlayButtonIcon,
  SolidCalendarIcon,
  RegularAltPhoneIcon,
  FailedCallIcon,
  AvatarPlaceHolder,
  RecordAudioIcon,
  CircleMinusIcon,
  CollapseDiagonalArrowsIcon,
  RegularPrinterIcon,
  RegularDownloadIcon,
  RegularCsvFileIcon,
  RegularPdfFileIcon,
  VisaCardIcon,
  ThryvCCOrangeLogoIcon,
  SolidMessagesIcon,
  TicketBlueIcon,
  TicketGrayIcon,
  CCPaymentFailIcon,
  VideoCallChannelIcon,
  SignaturesIcon,
  WebsiteBuilderIcon,
  ThryvLeadsIcon,
  InboxIcon,
  TeamChatIcon,
  CallsIcon,
  LeftNavVoicemailIcon,
  TransactionsIcon,
  ScheduledPaymentsIcon,
  RecommendationsIcon,
  AnalyticsIcon,
  ToolsIcon,
  OverviewIcon,
  LandingPagesIcon,
  CampaignsIcon,
  InvoicesIcon,
  DocumentsIcon,
  CalendarIcon,
  ClientsIcon,
  SalesIcon,
  SocialContentIcon,
  MarketingIcon,
  OnlinePresenceIcon,
  MyPayIcon,
  SalesDashboardIcon,
  DashboardIcon,
  ReputationIcon,
  MasterCardIcon,
  NoSearchResultsIcon,
  Microsoft365,
  VoicemailIcon,
  ListingManagementIcon,
  ListingsManagementIcon,
  ThryvAIIcon,
  ThryvCcSubscriptionIcon,
  ThryvCcBusinessCenterSubscriptionIcon,
  ThryvCcMarketingCenterSubscriptionIcon,
  ThryvCcPaySubscriptionIcon,
  ThryvMarketplaceSubscriptionIcon,
  ThryvMagnifyIcon,
} from './icons'
import { Icon } from '@thryvlabs/maverick'
import { InstagramLogo } from '@thryvlabs/maverick'

const validateType = (type) => {
  if (type !== 'solid' && type !== 'regular') {
    return `Type "${type}" is not valid.`
  }
}

/* ----- CommandCenterIcon inspired by Maverick's "Icon" ----- */
/* ----- https://tinyurl.com/bdzmvuhr ----- */

/* ################################################ */
/* #####               IMPORTANT              ##### */
/* ################################################ */
/* ##   Please be conscience of duplication      ## */
/* ##   if importing a Maverick component here.  ## */
/* ##                                            ## */
/* ##   Importing less Icons from Maverick here  ## */
/* ##   will prevent duplication.                ## */
/* ################################################ */

/* ################################################ */
/* #####                GUIDE                 ##### */
/* ################################################ */
/* ##                                            ## */
/* ##        https://tinyurl.com/273cfe7s        ## */
/* ##                                            ## */
/* ################################################ */

export const CommandCenterIcon = ({
  variant, // Look at case values below
  type, // (string): solid | regular
  height, // (number)
  width, // (number)
  color, // (string): hex value
  testid, //(string): adds testID to component
  ...rest
}) => {
  validateType(type)

  if (type === 'solid') {
    switch (variant) {
      case 'thryvCommandCenterIcon':
        return <ThryvCommandCenterIcon {...rest} width={width} height={height} />
      case 'thumbtack':
        return <ThumbtackIcon fill={color} {...rest} width={width} height={height} />
      case 'filter':
        return <FilterIcon fill={color} {...rest} width={width} height={height} />
      case 'towerBroadcast':
        return (
          <TowerBroadcastRegularIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'ttl': // Also available on Maverick as phoneOffice
        return (
          <Icon
            variant="phoneOffice"
            type="solid"
            fill={color || '#3D5199'}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'gmail':
        return <GmailIcon {...rest} width={width} height={height} />
      case 'outlook':
        return <OutlookIcon {...rest} width={width} height={height} />
      case 'microsoft365':
        return <Microsoft365 {...rest} width={width} height={height} />
      case 'facebookMessenger':
        return <FacebookMessengerIcon {...rest} width={width} height={height} />
      case 'yahoo':
        return <YahooIcon {...rest} width={width} height={height} />
      case 'instagram': // Also available on Maverick as import InstagramLogo
        return <InstagramLogo {...rest} width={width} height={height} />
      case 'googleMessenger':
        return <GoogleMessengerIcon {...rest} width={width} height={height} />
      case 'webchat':
        return <WebchatIcon {...rest} width={width} height={height} />
      case 'icloud':
        return <IcloudIcon {...rest} width={width} height={height} />
      case 'hotmail':
        return <HotmailIcon {...rest} width={width} height={height} />
      case 'aol':
        return <AolIcon {...rest} width={width} height={height} />
      case 'imap':
        return <IMAPIcon {...rest} width={width} height={height} />
      case 'gmailRoundedSquare':
        return (
          <GmailRoundedSquare fill={color} {...rest} width={width} height={height} />
        )
      case 'altThumbtack':
        return (
          <SolidAltThumbtackIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'horizontalLine':
        return (
          <HorizontalLineIcon {...rest} width={width} height={height} fill={color} />
        )
      case 'expandDiagonalArrows':
        return (
          <ExpandDiagonalArrowsIcon
            {...rest}
            width={width}
            height={height}
            fill={color}
          />
        )
      case 'collapseDiagonalArrows':
        return (
          <CollapseDiagonalArrowsIcon
            {...rest}
            width={width}
            height={height}
            fill={color}
          />
        )
      case 'incomingCall':
        return (
          <SolidIncomingCallIcon
            {...rest}
            width={width}
            height={height}
            fill={color}
          />
        )
      case 'circlePen':
        return (
          <CirclePenIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
            testid={testid}
          />
        )
      case 'outgoingCall':
        return (
          <SolidOutgoingCallIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'roundedPauseButton':
        return (
          <SolidRoundedPauseButtonIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'playButton':
        return (
          <SolidPlayButtonIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'calendar':
        return (
          <SolidCalendarIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'circleMinus':
        return (
          <CircleMinusIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'visa':
        return <VisaCardIcon width={width} height={height} {...rest} />
      case 'masterCard':
        return <MasterCardIcon width={width} height={height} {...rest} />
      case 'thryvCCLogoOrange':
        return <ThryvCCOrangeLogoIcon width={width} height={height} {...rest} />
      case 'messages':
        return (
          <SolidMessagesIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'ticketBlue':
        return <TicketBlueIcon width={width} height={height} {...rest} />
      case 'ticketGray':
        return <TicketGrayIcon width={width} height={height} {...rest} />

      case 'CCPaymentFail':
        return (
          <CCPaymentFailIcon width={width} height={height} fill={color} {...rest} />
        )
      case 'video':
        return (
          <VideoCallChannelIcon
            width={width}
            height={height}
            fill={color}
            {...rest}
          />
        )
      case 'noSearchResultsIcon':
        return <NoSearchResultsIcon width={width} height={height} {...rest} />

      case 'thryvMagnify':
        return (
          <ThryvMagnifyIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'cc-subscription':
        return <ThryvCcSubscriptionIcon width={width} height={height} {...rest} />

      case 'businessCenterSubscription':
        return (
          <ThryvCcBusinessCenterSubscriptionIcon
            width={width}
            height={height}
            {...rest}
          />
        )

      case 'marketingCenterSubscription':
        return (
          <ThryvCcMarketingCenterSubscriptionIcon
            width={width}
            height={height}
            {...rest}
          />
        )

      case 'thryvPaySubscription':
        return <ThryvCcPaySubscriptionIcon width={width} height={height} {...rest} />

      case 'thryvMarketplaceSubscription':
        return (
          <ThryvMarketplaceSubscriptionIcon
            width={width}
            height={height}
            {...rest}
          />
        )

      default:
        return `Variant "${variant}" is not valid.`
    }
  } else if (type === 'regular') {
    switch (variant) {
      case 'thumbtack':
        return (
          <RegularThumbtackIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'voicemail':
        return <VoicemailIcon fill={color} {...rest} width={width} height={height} />
      case 'uploadFolder':
        return (
          <RegularUploadFolderIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'calls':
        return (
          <CirclePhoneIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'ttl': // Also available on Maverick as phoneOffice
        return (
          <Icon
            variant="phoneOffice"
            type="regular"
            fill={color || '#3D5199'}
            // color={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'fontFormat':
        return (
          <FontFormatIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'paperClip':
        return <PaperClipIcon fill={color} {...rest} width={width} height={height} />
      case 'hyperlink':
        return <HyperlinkIcon fill={color} {...rest} width={width} height={height} />
      case 'happyEmoji':
        return (
          <HappyEmojiIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'gif':
        return <GifIcon fill={color} {...rest} width={width} height={height} />
      case 'ellipsis': // Also available on Maverick as verticalEllipsis
        return (
          <Icon
            variant="verticalEllipsis"
            type="regular"
            color="#cccccc"
            width="8"
            height="20"
          />
        )
      /* ----- Available on Maverick as horizontalEllipsis            ----- */
      /* -----                                                        ----- */
      /* ----- Maverick's horizontalEllipsis breaks at small sizes... ----- */
      /* ----- ... this is why it's added here.                       ----- */
      /* ----- https://tinyurl.com/yjyeutdh                           ----- */
      case 'horizontalEllipsis':
        return (
          <HorizontalEllipsisIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'verticalLine':
        return <VerticalLineIcon {...rest} width={width} height={height} />
      case 'paperPlaneCircle':
        return (
          <PaperPlaneCircleIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'altThumbtack':
        return (
          <RegularAltThumbtackIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'cloudUploadIcon':
        return (
          <CloudUploadIcon {...rest} fill={color} width={width} height={height} />
        )
      case 'alarmClock':
        return (
          <AlarmClockIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'commentCheck':
        return (
          <CommentCheckRegularIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'label':
        return <LabelIcon fill={color} {...rest} width={width} height={height} />
      case 'newAppointmentAction':
        return (
          <NewAppointmentIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'newInvoiceAction':
        return (
          <NewInvoiceIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'registerAction':
        return (
          <RegisterEventIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'chargeCardAction':
        return (
          <ChargeCardIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'inviteClientAction':
        return (
          <InviteClientIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'inviteScheduleAction':
        return (
          <InviteScheduleIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'paymentAction':
        return (
          <RequestPaymentIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'incomingCall':
        return <IncomingCallIcon {...rest} width={width} height={height} />
      case 'outgoingCall':
        return <OutgoingCallIcon {...rest} width={width} height={height} />
      case 'missedCall':
        return <MissedCallIcon {...rest} width={width} height={height} />
      case 'failedCall':
        return <FailedCallIcon {...rest} width={width} height={height} />
      case 'recordAudio':
        return <RecordAudioIcon {...rest} width={width} height={height} />
      case 'replyAll':
        return (
          <RegularReplyAllIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'altPhone':
        /* Also available on Maverick */
        /* Added here because this version looks better at smaller sizes */
        return (
          <RegularAltPhoneIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'videoCall':
        return <VideoIcon {...rest} width={width} height={height} />
      case 'avatarPlaceHolder':
        return <AvatarPlaceHolder {...rest} width={width} height={height} />
      case 'printer':
        return (
          <RegularPrinterIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'download':
        return (
          <RegularDownloadIcon
            fill={color}
            {...rest}
            width={width}
            height={height}
          />
        )
      case 'csvFile':
        return (
          <RegularCsvFileIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'pdfFile':
        return (
          <RegularPdfFileIcon fill={color} {...rest} width={width} height={height} />
        )
      case 'salesDashboardIcon':
        return <SalesDashboardIcon width={width} height={height} />
      case 'signaturesIcon':
        return <SignaturesIcon width={width} height={height} />
      case 'websiteBuilderIcon':
        return <WebsiteBuilderIcon width={width} height={height} />
      case 'thryvLeadsIcon':
        return <ThryvLeadsIcon width={width} height={height} />
      case 'inboxIcon':
        return <InboxIcon width={width} height={height} />
      case 'teamChatIcon':
        return <TeamChatIcon width={width} height={height} />
      case 'callsIcon':
        return <CallsIcon width={width} height={height} />
      case 'leftNavVoicemailIcon':
        return <LeftNavVoicemailIcon width={width} height={height} />
      case 'transactionsIcon':
        return <TransactionsIcon width={width} height={height} />
      case 'scheduledPaymentsIcon':
        return <ScheduledPaymentsIcon width={width} height={height} />
      case 'recommendationsIcon':
        return <RecommendationsIcon width={width} height={height} />
      case 'analyticsIcon':
        return <AnalyticsIcon width={width} height={height} />
      case 'toolsIcon':
        return <ToolsIcon width={width} height={height} />
      case 'overviewIcon':
        return <OverviewIcon width={width} height={height} />
      case 'landingPagesIcon':
        return <LandingPagesIcon width={width} height={height} />
      case 'campaignsIcon':
        return <CampaignsIcon width={width} height={height} />
      case 'listingManagementIcon':
        return <ListingManagementIcon width={width} height={height} />
      case 'listingsManagementIcon':
        return <ListingsManagementIcon width={width} height={height} />
      case 'reportsIcon':
        return (
          <Icon
            type="regular"
            variant="chartLineUp"
            color="#3C5199"
            width="16"
            height="16"
          />
        )
      case 'favoritesIcon':
        return (
          <Icon
            type="regular"
            variant="heart"
            color="#3C5199"
            width="16"
            height="16"
          />
        )
      case 'thryvAIIcon':
        return <ThryvAIIcon />
      case 'binIcon':
        return (
          <Icon
            type="regular"
            variant="altTrash"
            color="#3C5199"
            width="16"
            height="16"
          />
        )
      case 'invoicesIcon':
        return <InvoicesIcon width={width} height={height} />
      case 'documentsIcon':
        return <DocumentsIcon width={width} height={height} />
      case 'calendarIcon':
        return <CalendarIcon width={width} height={height} />
      case 'clientsIcon':
        return <ClientsIcon width={width} height={height} />
      case 'salesIcon':
        return <SalesIcon width={width} height={height} />
      case 'socialContentIcon':
        return <SocialContentIcon width={width} height={height} />
      case 'marketingIcon':
        return <MarketingIcon width={width} height={height} />
      case 'onlinePresenceIcon':
        return <OnlinePresenceIcon width={width} height={height} />
      case 'myPayIcon':
        return <MyPayIcon width={width} height={height} />
      case 'dashboardIcon':
        return <DashboardIcon width={width} height={height} />
      case 'reputationIcon':
        return <ReputationIcon width={width} height={height} />
      default:
        return `Variant "${variant}" is not valid.`
    }
  }
}
