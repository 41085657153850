import { CC_RECURLY_PLAN_CODES } from '../../../../utils/recurly-constants'
import { TIERS_DATA } from './constants'

export const syncCCPlanPrices = (data, activePlanCode) => (
  TIERS_DATA.map(tier => ({
    ...tier,
    // If user has freePlus account, tier will still be "free"
    isCurrent: (tier.code === activePlanCode) ||  (tier.name === 'free' && activePlanCode === CC_RECURLY_PLAN_CODES.freePlus),
    selected: false,
    price: data.find(d => d.code === tier.code).price
  }))
)

export default {
  syncCCPlanPrices
}
