/*
----- SQL Database reference: command_center_qa -----
----- Meaning of integers in Role column in Users table in SQL ----- 

1. admin
2. staff
3. Owner

*/

export const getRoleIntegerEquivalent = (inputRoleName) => {
  const roleName = inputRoleName.toLowerCase()
  switch (roleName) {
    case 'admin':
      return 1
    case 'staff':
      return 2
    case 'owner':
      return 3
  }
}

/*
----- Database reference: command_center_qa -----
----- Meaning of integers in Status column in Users table in SQL ----- 

0. Initialized (Being Migrated)
1. Active
2. Locked (Business Cancelled)
3. Inactive (Individual Account Blocked)
4. Deleted (Individual Account Deleted)
5. Reset Password
6. Pending (OOE Sign Up - Before PW Setup)

*/

// FIXME: Might have to update switch statment and check if status is contained in a string
export const getStatusIntegerEquivalent = (inputStatus) => {
  const status = inputStatus.toLowerCase()

  switch (status) {
    case 'initialized (being migrated)':
      return 1
    case 'active':
      return 1
    case 'locked (business cancelled)':
      return 2
    case 'inactive (individual account deleted)':
      return 3
    case 'deleted (individual account deleted)':
      return 4
    case 'reset password':
      return 5
    case 'pending (ooe) sign up - before pw setup':
      return 6
  }
}
