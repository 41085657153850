export const options = [
    {
      name: '12:00 AM',
      value: '00:00:00',
      disabled: true
    },
    {
      name: '12:30 AM',
      value: '00:30:00',
    },
    {
      name: '1:00 AM',
      value: '01:00:00',
    },
    {
      name: '1:30 AM',
      value: '01:30:00',
    },
    {
      name: '2:00 AM',
      value: '02:00:00',
    },
    {
      name: '2:30 AM',
      value: '02:30:00',
    },
    {
      name: '3:00 AM',
      value: '03:00:00',
    },
    {
      name: '3:30 AM',
      value: '03:30:00',
    },
    {
      name: '4:00 AM',
      value: '04:00:00',
    },
    {
      name: '4:30 AM',
      value: '04:30:00',
    },
    {
      name: '5:00 AM',
      value: '05:00:00',
    },
    {
      name: '5:30 AM',
      value: '05:30:00',
    },
    {
      name: '6:00 AM',
      value: '06:00:00',
    },
    {
      name: '6:30 AM',
      value: '06:30:00',
    },
    {
      name: '7:00 AM',
      value: '07:00:00',
    },
    {
      name: '7:30 AM',
      value: '07:30:00',
    },
    {
      name: '8:00 AM',
      value: '08:00:00',
    },
    {
      name: '8:30 AM',
      value: '08:30:00',
    },
    {
      name: '9:00 AM',
      value: '09:00:00',
    },
    {
      name: '9:30 AM',
      value: '09:30:00',
    },
    {
      name: '10:00 AM',
      value: '10:00:00',
    },
    {
      name: '10:30 AM',
      value: '10:30:00',
    },
    {
      name: '11:00 AM',
      value: '11:00:00',
    },
    {
      name: '11:30 AM',
      value: '11:30:00',
    },
    {
      name: '12:00 PM',
      value: '12:00:00',
    },
    {
      name: '12:30 PM',
      value: '12:30:00',
    },
    {
      name: '1:00 PM',
      value: '13:00:00',
    },
    {
      name: '1:30 PM',
      value: '13:30:00',
    },
    {
      name: '2:00 PM',
      value: '14:00:00',
    },
    {
      name: '2:30 PM',
      value: '14:30:00',
    },
    {
      name: '3:00 PM',
      value: '15:00:00',
    },
    {
      name: '3:30 PM',
      value: '15:30:00',
    },
    {
      name: '4:00 PM',
      value: '16:00:00',
    },
    {
      name: '4:30 PM',
      value: '16:30:00',
    },
    {
      name: '5:00 PM',
      value: '17:00:00',
    },
    {
      name: '5:30 PM',
      value: '17:30:00',
    },
    {
      name: '6:00 PM',
      value: '18:00:00',
    },
    {
      name: '6:30 PM',
      value: '18:30:00',
    },
    {
      name: '7:00 PM',
      value: '19:00:00',
    },
    {
      name: '7:30 PM',
      value: '19:30:00',
    },
    {
      name: '8:00 PM',
      value: '20:00:00',
    },
    {
      name: '8:30 PM',
      value: '20:30:00',
    },
    {
      name: '9:00 PM',
      value: '21:00:00',
    },
    {
      name: '9:30 PM',
      value: '21:30:00',
    },
    {
      name: '10:00 PM',
      value: '22:00:00',
    },
    {
      name: '10:30 PM',
      value: '22:30:00',
    },
    {
      name: '11:00 PM',
      value: '23:00:00',
    },
    {
      name: '11:30 PM',
      value: '23:30:00',
    },
  ]