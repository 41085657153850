import { useEffect } from 'react'
import { ParagraphText, Icon } from '@thryvlabs/maverick'
import { LabelOnMessage } from '../../../labels/label-on-message'
import { GET_STAFF } from '../../../../../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { useState, useContext } from 'react'
import { OutgoingMessageContext } from '../../../../../../context/message-context'
import { useSelector, useDispatch } from 'react-redux'
import { setJustNowTimer } from '../../../../slices/inboxSlice'
export const MessageFooter = ({
  timestamp,
  labels,
  isDeletedMessage,
  outgoingId,
  lastMessage,
}) => {
  // const renderNameOrPhone = (name) => {
  //   //add logic to check if there is a contact associated to the number
  //   if (channelType === 'phone') return phoneFormatter(name).substring(1)
  //   return name
  // }
  const outgoingMessage = useContext(OutgoingMessageContext)
  const { user } = useAuth0()
  const commandCenterId = user.cc_id
  const [staffAgentId, setStaffAgentId] = useState([])
  const dispatch = useDispatch()
  const { data } = useQuery(GET_STAFF, {
    variables: {
      comctrid: commandCenterId,
    },
    onCompleted: () => {
      const matchAgentIds = data.staff.filter(
        (staff) => staff.CC_UserID === outgoingMessage.agent_id,
      )
      setStaffAgentId(matchAgentIds)
    },
  })

  const { justNowTimer } = useSelector((state) => state.inbox)

  useEffect(() => {
    if (justNowTimer === true) {
      const timer = setTimeout(() => {
        dispatch(setJustNowTimer(false))
      }, 60000)
      return () => clearTimeout(timer)
    } else {
      return
    }
  }, [justNowTimer])

  const handleTimestamp = () => {
    if (
      justNowTimer &&
      lastMessage?.sk1 === outgoingId &&
      outgoingMessage?.agent_id !== null
    ) {
      return 'Just now'
    } else {
      return timestamp
    }
  }

  useEffect(() => {
    handleTimestamp()
  }, [lastMessage])

  return (
    <div className="mx-6 flex flex-wrap justify-end pb-[15px max-w-[465px]">
      <div className="flex gap-1 items-center text-center min-h-[20px] pr-[5px]">
        <ParagraphText
          variant="sm"
          className="font-['Montserrat'] text-[10px] font-medium"
          color="thryv-night"
        >
          {outgoingMessage?.agent_id === null
            ? `[${outgoingMessage?.email_from.email}]`
            : `[${staffAgentId.map(
                (name) => name.FirstName + ' ' + name.LastName,
              )}]`}{' '}
        </ParagraphText>
        <ParagraphText
          variant="sm"
          className="font-['Montserrat'] text-[10px] font-medium"
          color="thryv-night"
        >
          {handleTimestamp()}
        </ParagraphText>

        {/* <Icon
          variant="doubleCheck"
          type="regular"
          color="#057AFF"
          height="13"
          width="13"
        /> */}

        <Icon
          variant="check"
          type="regular"
          color="#808080"
          height="13"
          width="13"
        />
      </div>
      <div className="flex flex-row flex-wrap space-x-1">
        {!isDeletedMessage &&
          labels &&
          labels.map((label, i) => {
            return <LabelOnMessage label={label} key={i} />
          })}
      </div>
    </div>
  )
}
