import { useState, useEffect } from 'react'
import axios from 'axios'
import thryvcclogo from '../../assets/thryvcclogomedium.png'
import { Header, TableFormAlert } from '@thryvlabs/maverick'
const { VITE_SIGN_UP_SERVICE_URL } = import.meta.env
import formatContactData from '../inbox/hooks/formatContactData'
import './SignUpStyles.css'

const VerifyStep = ({ setStep, userData }) => {
  const [firstNumber, setFirstNumber] = useState('')
  const [secondNumber, setSecondNumber] = useState('')
  const [thirdNumber, setThirdNumber] = useState('')
  const [fourthNumber, setFourthNumber] = useState('')
  const [fifthNumber, setFifthNumber] = useState('')
  const [sixthNumber, setSixthNumber] = useState('')
  const [fullNumber, setFullNumber] = useState('')
  const [showWarning, setShowWarning] = useState(false)
  const [showResentMessage, setShowResentMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  const displayPhoneNumber = `${userData.countryCode}${userData.phoneNumber}`
  const formatedDisplayPhoneNumber = formatContactData(displayPhoneNumber)

  const re = /^[0-9\b]+$/

  const requestCode = () => {
    axios.post(`${VITE_SIGN_UP_SERVICE_URL}/signup/get-code`, {
      phone: displayPhoneNumber,
    })
  }

  const compareCodes = async () => {
    setLoading(true)
    axios
      .post(`${VITE_SIGN_UP_SERVICE_URL}/signup/verify-code`, {
        phone: displayPhoneNumber,
        code: fullNumber,
      })
      .then((res) => {
        if (res.data.status === 'approved') {
          window.utag.link({
            tealium_event: 'cc-signup-track',
            event_name: 'cc-verified-lead',
            event_type: 'Lead',
          })
          setShowWarning(false)
          setStep('3')
        } else {
          setShowWarning(true)
          setLoading(false)
        }
      })
  }

  const handleNextInput = (e) => {
    if (re.test(e.target.value) === true) {
      const fieldName = e.target.id.split('-')[1]
      const nextSibiling = document.getElementById(
        `input-${parseInt(fieldName) + 1}`,
      )
      if (nextSibiling !== null) {
        nextSibiling.focus()
      }
    }
  }

  const handlePreviousInput = (e) => {
    const fieldName = e.target.id.split('-')[1]
    const previousSibiling = document.getElementById(
      `input-${parseInt(fieldName) - 1}`,
    )
    if (previousSibiling !== null) {
      previousSibiling.focus()
      previousSibiling.value = ''
    }
  }

  const handleClick = () => {
    if (fullNumber.length === 0) {
      document.getElementById('input-1').focus()
    } else if (fullNumber.length !== 0 && fullNumber.length !== 6) {
      for (var i = 6; i > 0; i--) {
        if (document.getElementById(`input-${i}`).value !== '') {
          continue
        } else {
          document.getElementById(`input-${i}`).focus()
        }
      }
    }
  }

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value[0]
    const numbersOnly = input?.replace(/\D/g, '')
    return numbersOnly
  }

  const handleInputChange = (e) => {
    if (e.key === 'Backspace') {
      handlePreviousInput(e)
    } else {
      handleNextInput(e)
    }
  }

  useEffect(() => {
    handleClick()
  }, [])

  useEffect(() => {
    setFullNumber(
      firstNumber +
        secondNumber +
        thirdNumber +
        fourthNumber +
        fifthNumber +
        sixthNumber,
    )
    if (fullNumber.length === 6) {
      compareCodes()
    }
  }, [
    firstNumber,
    secondNumber,
    thirdNumber,
    fourthNumber,
    fifthNumber,
    sixthNumber,
    fullNumber,
  ])

  const handleAutoFill = (event) => {
    if (event.target.value.length === 6) {
      const digits = event.target.value.split('')
      document.getElementById('input-1').value = digits[0]
      document.getElementById('input-2').value = digits[1]
      document.getElementById('input-3').value = digits[2]
      document.getElementById('input-4').value = digits[3]
      document.getElementById('input-5').value = digits[4]
      document.getElementById('input-6').value = digits[5]
      setFirstNumber(digits[0])
      setSecondNumber(digits[1])
      setThirdNumber(digits[2])
      setFourthNumber(digits[3])
      setFifthNumber(digits[4])
      setSixthNumber(digits[5])
      document.getElementById(`input-6`).focus()
    }
  }

  return (
    <>
      <div className="mb-[30px] md:mb-[0px] md:mt-[50px]">
        <img
          src={thryvcclogo}
          alt="Thryv Command Center"
          className="w-[198px] h-[40px] md:hidden"
        />
      </div>

      <div className="h-[80px] md:mt-[35px] gap-[20px] flex flex-col items-center ">
        <Header fontWeight="semibold" variant="h4" className="min-w-md:hidden">
          <p>Verifying Your Identity</p>
        </Header>
        <p className='font-["Open_Sans"] font-normal w-[408px] text-sm md:w-[303px] md:h-[588px]'>
          We will not be sending marketing messages. A text message will be sent to
          this number only to verify your identity. Please enter 6-digit code sent to{' '}
          <b>{formatedDisplayPhoneNumber}</b> to continue.
        </p>
        <div className="grid grid-flow-col pt-[30px] gap-x-[20px] md:w-[303px] md:gap-x-[10px] z-40">
          <input
            id="input-1"
            className="h-[44px] w-[50px] md:w-[40px] flex text-center rounded border border-thryv-gray-light-500 focus:outline-none focus:border-thryv-orange"
            type="tel"
            placeholder="0"
            onClick={() => handleClick()}
            onChange={(e) => {
              e.target.value = handlePhoneNumberChange(e)
              setFirstNumber(e.target.value)
            }}
            onKeyUp={(e) => {
              handleInputChange(e)
            }}
            onInput={handleAutoFill}
            autoComplete="one-time-code"
          />
          <input
            id="input-2"
            className="h-[44px] w-[50px] md:w-[40px] flex text-center rounded border border-thryv-gray-light-500 focus:outline-none focus:border-thryv-orange"
            type="tel"
            placeholder="0"
            onClick={() => handleClick()}
            onChange={(e) => {
              e.target.value = handlePhoneNumberChange(e)
              setSecondNumber(e.target.value)
            }}
            onKeyUp={(e) => handleInputChange(e)}
            onInput={handleAutoFill}
            autoComplete="one-time-code"
          />
          <input
            id="input-3"
            className="h-[44px] w-[50px] md:w-[40px] flex text-center rounded border border-thryv-gray-light-500 focus:outline-none focus:border-thryv-orange"
            type="tel"
            placeholder="0"
            onClick={() => handleClick()}
            onChange={(e) => {
              e.target.value = handlePhoneNumberChange(e)
              setThirdNumber(e.target.value)
            }}
            onKeyUp={(e) => handleInputChange(e)}
            onInput={handleAutoFill}
            autoComplete="one-time-code"
          />
          <input
            id="input-4"
            className="h-[44px] w-[50px] md:w-[40px] flex text-center rounded border border-thryv-gray-light-500 focus:outline-none focus:border-thryv-orange"
            type="tel"
            placeholder="0"
            onClick={() => handleClick()}
            onChange={(e) => {
              e.target.value = handlePhoneNumberChange(e)
              setFourthNumber(e.target.value)
            }}
            onKeyUp={(e) => handleInputChange(e)}
            onInput={handleAutoFill}
            autoComplete="one-time-code"
          />
          <input
            id="input-5"
            className="h-[44px] w-[50px] md:w-[40px] flex text-center rounded border border-thryv-gray-light-500 focus:outline-none focus:border-thryv-orange"
            type="tel"
            placeholder="0"
            onClick={() => handleClick()}
            onChange={(e) => {
              e.target.value = handlePhoneNumberChange(e)
              setFifthNumber(e.target.value)
            }}
            onKeyUp={(e) => handleInputChange(e)}
            onInput={handleAutoFill}
            autoComplete="one-time-code"
          />
          <input
            id="input-6"
            className="h-[44px] w-[50px] md:w-[40px] flex text-center rounded border border-thryv-gray-light-500 focus:outline-none focus:border-thryv-orange"
            type="tel"
            placeholder="0"
            onClick={() => handleClick()}
            onChange={(e) => {
              e.target.value = handlePhoneNumberChange(e)
              setSixthNumber(e.target.value)
            }}
            onKeyUp={(e) => handleInputChange(e)}
            onInput={handleAutoFill}
            autoComplete="one-time-code"
          />
        </div>
      </div>
      <div className="w-[230px] h-[150px] flex flex-col gap-[10px] mt-[32px]" />
      <div className="flex gap-[8px] w-[300px] mb-[40px] md:pt-[60px]">
        <span className='font-["Open_Sans"] text-[#808080] md:mt-[-50px]'>
          Didn't receive the code?{' '}
          <a
            href="#"
            onClick={() => {
              requestCode()
              setShowResentMessage(true)
              setTimeout(() => {
                setShowResentMessage(false)
              }, 6000)
            }}
            className="font-medium text-[#057AFF] dark:text-[#057AFF] hover:underline"
          >
            Click to resend
          </a>{' '}
        </span>
      </div>
      {loading ? (
        <div>
          <span className="w-[38px] h-[38px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin"></span>
        </div>
      ) : (
        <button onClick={() => setStep('1')}>
          <p
            className={`font-["Open_Sans"] font-[600] leading-[34px] text-[#808080] text-[13px] pr-[16px] md:mt-[-40px] mt-[-20px]`}
          >
            BACK
          </p>
        </button>
      )}
      {showResentMessage ? (
        <div className="mb-[25px] md:mb-4">
          <TableFormAlert
            variant="success"
            alertText="Another SMS code has been sent to your phone."
            allowClose
            onClick={() => setShowResentMessage(false)}
          />
        </div>
      ) : (
        <></>
      )}
      {showWarning ? (
        <div className="mb-[25px] md:mb-4">
          <TableFormAlert
            variant="danger"
            alertText="Invalid verification code. Please try again."
            allowClose
            onClick={() => setShowWarning(false)}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default VerifyStep
