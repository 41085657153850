import { useEffect, useState } from 'react'
import { Button } from '@thryvlabs/maverick'
import { OrderRow, OrderTotal, SummaryBox, TotalLine } from './OrderSummaryBox.style'
import { GET_RECURLY_SUBSCRIPTION_TAX } from '../../../../../graphql'
import { useQuery } from '@apollo/client'
import { LoadingSpinner } from '../../../loading-spinner'

const PROMO_INITIAL_STATE = {
  value: '',
  price: 0,
  validated: false,
  error: false,
}

// const SuccessFailIcon = ({ className, show, variant }) => {
//   let iconVariant = ''
//   if (variant === 'success') iconVariant = 'circleCheck'
//   else if (variant === 'fail') iconVariant = 'circleExclamation'

//   let iconColor = ''
//   if (variant === 'success') iconColor = '#16A085'
//   else if (variant === 'fail') iconColor = '#DF2A2A'

//   return (
//     <div
//       className={`flex items-center justify-center transition-all duration-300 h-4 w-4 rounded-full
//       ${show ? 'opacity-100' : 'opacity-0'}
//       ${className}
//     `}
//     >
//       <Icon
//         type="solid"
//         variant={iconVariant}
//         width={16}
//         height={16}
//         fill={iconColor}
//       />
//     </div>
//   )
// }

// const SuccessFailMessage = ({ className, show, variant }) => {
//   let textColor = ''
//   if (variant === 'success') textColor = '!text-[#16A085]'
//   if (variant === 'fail') textColor = '!text-[#DF2A2A]'

//   return (
//     <div
//       className={`transition-all duration-300 h-4 w-full text-start
//       ${show ? 'opacity-100' : 'opacity-0'}
//       ${className}
//     `}
//     >
//       <ParagraphText variant="reg" className={`${textColor}`}>
//         {variant === 'success' ? 'Code applied successfully' : ''}
//         {variant === 'fail' ? 'Invalid promo code' : ''}
//       </ParagraphText>
//     </div>
//   )
// }

// const InputWithResponses = ({ error, validated, value, onChange }) => {
//   return (
//     <div className="relative">
//       <div className="h-[28px]">
//         <Input
//           type="text"
//           placeholder="Enter Promo Code"
//           customPlaceholder="Enter Promo Code"
//           name="zipCode"
//           labelType="floating"
//           variant="default"
//           className="pr-[18px]"
//           value={value}
//           onChange={onChange}
//           withLabel
//         />
//       </div>
//       {validated && (
//         <>
//           <SuccessFailIcon
//             className="absolute top-[50%] right-0 -translate-y-2/4"
//             show={validated}
//             variant={error ? 'fail' : 'success'}
//           />
//           <SuccessFailMessage
//             variant={error ? 'fail' : 'success'}
//             className="absolute top-[calc(100%+2px)] right-0"
//             show={validated}
//           />
//         </>
//       )}
//     </div>
//   )
// }

export default function OrderSummaryBox({
  selectedPlan,
  setCanSubmit,
  accountInfo,
  canApplyPromoCode = false,
  seats,
  setErrorMessage,
}) {
  const [applyPromoCode, setApplyPromoCode] = useState(false)
  const [promoCodeState, setPromoCodeState] = useState(PROMO_INITIAL_STATE)
  const [loadingTaxes, setLoadingTaxes] = useState(true)
  const [taxPrice, setTaxPrice] = useState(null)
  const { price } = selectedPlan || { price: 0 }
  const billingInfo = accountInfo?.billingInfo || null
  const billingInfoId = billingInfo?.id || null

  const { data, error } = useQuery(GET_RECURLY_SUBSCRIPTION_TAX, {
    variables: {
      CCID: localStorage.getItem('cc_id'),
      billingInfoId: billingInfoId,
      planCode: selectedPlan?.code,
      NumberofSeats: seats,
    },
    skip: !billingInfo,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (data) {
        const {
          queryRecurlySubscriptionTaxes: { amount },
        } = data
        if (amount !== null && amount !== undefined) {
          setTaxPrice(amount)
          setLoadingTaxes(false)
        }
      }
    },
    onError: () => {
      setTaxPrice(0)
      setLoadingTaxes(false)
      setCanSubmit(false)
    },
  })

  const taxError = error?.graphQLErrors
  useEffect(() => {
    if (taxError && taxError.length > 0) {
      const errorMsg = taxError[0].errorType
      setErrorMessage(errorMsg)
    }
  }, [taxError])

  useEffect(() => {
    if (!canApplyPromoCode && applyPromoCode) {
      setApplyPromoCode(false)
    }
    return () => {
      setPromoCodeState(PROMO_INITIAL_STATE)
    }
  }, [canApplyPromoCode])

  useEffect(() => {
    if (setCanSubmit && price !== 0 && taxPrice !== null) {
      setCanSubmit(true)
    } else if (setCanSubmit && selectedPlan?.code == 'cc_basic-5-thryv-m2m') {
      setCanSubmit(true)
    }
  }, [price, taxPrice, billingInfoId])

  // const ticketIcon = canApplyPromoCode ? 'ticketBlue' : 'ticketGray'

  // const onApplyPromoCode = () => {
  //   if (promoCodeState.value.trim() === '') return
  //   setPromoCodeState((prev) => ({
  //     ...prev,
  //     price: 5,
  //     validated: true,
  //     error: false,
  //   }))
  // }

  // const onChangePromoCode = ({ target }) => {
  //   const { value } = target
  //   setPromoCodeState((prev) => ({
  //     ...prev,
  //     value,
  //   }))
  // }

  const calculateOrderTotal = () => {
    const taxes =
      taxPrice === null || taxPrice === undefined ? 0 : parseFloat(taxPrice)
    let total = parseFloat(price) * seats + taxes

    if (promoCodeState.price !== 0) {
      total = total - promoCodeState.price
    }
    return Number(total).toFixed(2)
  }

  const calculatePlanPricing = () => {
    let total = parseFloat(price) * seats
    return Number(total).toFixed(2)
  }

  return (
    <SummaryBox>
      <div className="header">Order Summary</div>
      <div className="order-details">
        <OrderRow>
          <div className="title">
            {selectedPlan?.header}{' '}
            <span className="text-[#4D4D4D]">
              (
              {seats ? (
                `x ${seats} ${seats > 1 ? 'users' : 'user'}`
              ) : (
                <LoadingSpinner widthAndHeight={24} />
              )}
              )
            </span>
          </div>
          <div className="prices">
            {' '}
            {calculatePlanPricing() !== 'NaN' ? (
              <div className="prices">{`$${calculatePlanPricing()}`}</div>
            ) : (
              <LoadingSpinner widthAndHeight={24} />
            )}
          </div>
        </OrderRow>
        {billingInfo === null || price === '0' ? (
          <OrderRow>
            <div className="title">Estimated Taxes</div>
            <div className="prices">{`$${Number(taxPrice).toFixed(2)}`}</div>
          </OrderRow>
        ) : (
          <OrderRow>
            <div className="title">Estimated Taxes</div>
            {loadingTaxes ? (
              <LoadingSpinner widthAndHeight={24} />
            ) : (
              <div className="prices">{`$${Number(taxPrice).toFixed(2)}`}</div>
            )}
          </OrderRow>
        )}

        {promoCodeState.validated && promoCodeState.price !== 0 && (
          <OrderRow>
            <div className="title">
              Discount{' '}
              <Button
                className="ml-[08px]"
                variant="text"
                level={1}
                onClick={() => setPromoCodeState(PROMO_INITIAL_STATE)}
              >
                [Remove]
              </Button>
            </div>
            <div className="prices text-[#FF5000]">{`-$${promoCodeState.price}.00`}</div>
          </OrderRow>
        )}

        <TotalLine />
        {billingInfo == null || price == '0' ? (
          <OrderTotal>
            <div className="title">Total</div>
            <div className="prices">{`$${calculateOrderTotal()}/month`}</div>
          </OrderTotal>
        ) : (
          <OrderTotal>
            <div className="title">Total</div>
            {calculateOrderTotal() !== 'NaN' && taxPrice !== null ? (
              <div className="prices">{`$${calculateOrderTotal()}/month`}</div>
            ) : (
              <LoadingSpinner widthAndHeight={24} />
            )}
          </OrderTotal>
        )}
      </div>
      {/* 
      <PromoSection>
        {!applyPromoCode ? (
          <Button
            variant="text"
            level={1}
            onClick={setApplyPromoCode}
            disabled={!canApplyPromoCode}
          >
            {' '}
            <div className="flex">
              <CommandCenterIcon
                type={'solid'}
                variant={ticketIcon}
                width={20}
                height={13}
                className={'mt-[3px]'}
                fill={'#808080'}
              />
              <span className="ml-[05px]"> Apply Promo Code</span>
            </div>
          </Button>
        ) : (
          <>
            <div className="flex w-full">
              <div className="pr-2">
                <InputWithResponses
                  value={promoCodeState.value}
                  onChange={onChangePromoCode}
                  validated={promoCodeState.validated}
                  error={promoCodeState.error}
                />
              </div>
              <Button
                onClick={onApplyPromoCode}
                variant="secondary"
                buttonType="outlined"
              >
                Apply
              </Button>{' '}
            </div>
          </>
        )}
      </PromoSection> */}
    </SummaryBox>
  )
}
