import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Checkbox,
  Header,
  Input,
  MaskedInput,
  Select,
} from '@thryvlabs/maverick'
import { UPGRADE_FLOW_STEPS } from './constants'
import StepsContainer from './steps-container/StepsContainer'
import {
  ButtonAlt,
  ButtonContainer,
  ContentContainer,
  FlexEnd,
  FlexStart,
  LeftSide,
  RightSidePaymentForm,
} from './UpgradePlanModal.styles'
import OrderSummaryBox from './order-summary-box/OrderSummaryBox'
import { useMutation } from '@apollo/client'
import {
  SET_ADD_RECURLY_BILLING_INFO,
  SET_UPDATE_RECURLY_BILLING_INFO,
} from '../../../../graphql'
import { useSelector } from 'react-redux'
import { getCountryStates } from '../../../top-navigation-bar/StateProvinceSelectDropdown'
import { LoadingSpinner } from '../../loading-spinner'
import { getRecurlyErrorMessage } from '../../../../utils/recurly-constant'
import { postalCodeValidator } from '../../../../utils/postalCodeValidator'
import { PaymetnInfoContainer } from './PaymentForm.styles'

export default function PaymentForm({
  currentStep,
  setStep,
  isNewPayment,
  moveToPreviousStep,
  accountInfo,
  refetchAccountInfo,
  selectedPlan,
  seats,
}) {
  // const [, setCanSubmit] = useState(false)
  const [primaryPaymentMethod, setPrimaryPaymentMethod] = useState(
    isNewPayment ? false : accountInfo.billingInfo.primaryPaymentMethod,
  )
  const cc_id = localStorage.getItem('cc_id')
  const [error, setError] = useState(null)
  const countryCode = useSelector((state) => state.countryCode.countryIso2)
  const [states] = useState(
    getCountryStates(useSelector((state) => state.countryCode.countryIso2)),
  )
  const [loading, setLoading] = useState()

  const getSelectedState = function (selectedState) {
    return states.find((state) => state.value === selectedState)
  }

  const getDoubleDigitDate = function (date) {
    if (date.toString().length == 1) {
      return `0${date}`
    }
    return date
  }

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      cardNumber: '',
      expiresOn: isNewPayment
        ? ''
        : `${getDoubleDigitDate(
            accountInfo.billingInfo.paymentMethod.expMonth,
          )}${accountInfo.billingInfo.paymentMethod.expYear
            .toString()
            .substring(2, 4)}`,
      cvv: '',
      fname: isNewPayment ? '' : accountInfo.firstName,
      lname: isNewPayment ? '' : accountInfo.lastName,
      street1: isNewPayment ? '' : accountInfo.billingInfo.address.street1,
      street2: isNewPayment ? '' : accountInfo.billingInfo.address.street2,
      city: isNewPayment ? '' : accountInfo.billingInfo.address.city,
      state: isNewPayment
        ? { name: '', value: '' }
        : getSelectedState(accountInfo.billingInfo.address.region),
      postalCode: isNewPayment ? '' : accountInfo.billingInfo.address.postalCode,
    },
  })

  const [
    createBilling,
    { loading: loadingNewBillingInfo, error: errorAddingBillingInfo },
  ] = useMutation(SET_ADD_RECURLY_BILLING_INFO, {
    onCompleted: (data) => {
      // eslint-disable-next-line no-console
      console.log('query completed', data)
    },
  })

  const [
    updateBilling,
    { loading: loadingeditBillingInfo, error: errorUpdatingBillingInfo },
  ] = useMutation(SET_UPDATE_RECURLY_BILLING_INFO, {
    onCompleted: (data) => {
      // eslint-disable-next-line no-console
      console.log('query completed', data)
    },
  })

  useEffect(() => {
    if (errorAddingBillingInfo || errorUpdatingBillingInfo) {
      let errorMessage
      try {
        const addBillingInfoError = JSON.parse(
          errorAddingBillingInfo?.message || '{}',
        )?.params?.transactionError?.message
        const updateBillingInfoError = JSON.parse(
          errorUpdatingBillingInfo?.message || '{}',
        )?.params?.transactionError?.message
        errorMessage = addBillingInfoError || updateBillingInfoError
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error parsing error message', err)
      }

      setError(errorMessage || 'Error adding payment method')
    }
  }, [errorAddingBillingInfo, errorUpdatingBillingInfo])

  function updateBillingInfo(data) {
    const client = window.recurly
    const { billingInfo } = accountInfo
    const cc_id = localStorage.getItem('cc_id')

    client.configure({
      publicKey: import.meta.env.VITE_RECURLY_PUBLIC_KEY,
      parent: false,
      cors: true,
    })

    client.token(
      {
        first_name: data.fname,
        last_name: data.lname,
        number: data.cardNumber,
        year: `20${Number(data.expiresOn.substring(2, 4))}`,
        month: Number(data.expiresOn.substring(0, 2)),
        cvv: data.cvv,
        address1: data.street1,
        address2: data.street2,
        country: countryCode,
        city: data.city,
        state: data.state.value,
        postal_code: data.postalCode,
      },
      (err, token) => {
        if (token) {
          try {
            updateBilling({
              variables: {
                CCID: cc_id,
                token: token.id,
                billingInfoId: billingInfo.id,
                isPrimary: primaryPaymentMethod,
              },
            })
              .then(() => {
                refetchAccountInfo({ CCID: cc_id })
                setStep(UPGRADE_FLOW_STEPS.ORDER_REVIEW)
                setLoading(false)
              })
              .catch(() => setError('There is an error with your Credit Card'))
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Service error:', error)
          }
        } else if (err) {
          setError(getRecurlyErrorMessage(err))
          // setLoading(false)
        }
        setLoading(false)
      },
    )
  }

  async function createBillingInfo(data) {
    const client = window.recurly
    client.configure({
      publicKey: import.meta.env.VITE_RECURLY_PUBLIC_KEY,
      parent: false,
      cors: true,
    })
    client.token(
      {
        first_name: data.fname,
        last_name: data.lname,
        number: data.cardNumber,
        year: `20${Number(data.expiresOn.substring(2, 4))}`,
        month: Number(data.expiresOn.substring(0, 2)),
        cvv: data.cvv,
        address1: data.street1,
        address2: data.street2,
        country: countryCode,
        city: data.city,
        state: data.state.value,
        postal_code: data.postalCode,
        primary_payment_method: primaryPaymentMethod,
      },
      (err, token) => {
        if (token) {
          createBilling({
            variables: { CCID: localStorage.getItem('cc_id'), token: token.id },
          }).then(
            () => {
              refetchAccountInfo({ CCID: cc_id })
              setStep(UPGRADE_FLOW_STEPS.ORDER_REVIEW)
              setLoading(false)
            },
            () => {
              setLoading(false)
            },
          )
        } else if (err) {
          setError(getRecurlyErrorMessage(err))
          setLoading(false)
        }
      },
    )
  }

  const customValidationPincode = (value) => {
    if (postalCodeValidator(countryCode, value) === 'valid') {
      return undefined
    } else {
      return postalCodeValidator(countryCode, value)
    }
  }

  const onSubmitFormHandler = async (data) => {
    setLoading(true)
    try {
      if (!isNewPayment) {
        await updateBillingInfo(data)
      } else {
        await createBillingInfo(data)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  function isLoadingData() {
    return loadingNewBillingInfo || loadingeditBillingInfo || loading
  }

  return (
    <>
      <StepsContainer step={'payment'} />
      <ContentContainer>
        <LeftSide>
          <PaymetnInfoContainer>
            <div className="mt-[15px] mb-[5px] text-[15px] text-[#808080] flex justify-start">
              Payment Info
            </div>
            <form
              id="payment-form"
              className="w-[100%]  border border-[#ECEEF1] p-[1rem]"
              onSubmit={handleSubmit(onSubmitFormHandler)}
            >
              <Header
                fontWeight="medium"
                variant="h3"
                className="mb-3 text-sm text-left open-sans text-[#231F20]"
              >
                Credit Card Info*
              </Header>
              <div className="grid grid-cols-6 gap-3">
                <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
                  <>
                    <Controller
                      control={control}
                      rules={{
                        required: 'Card Number is required',
                        minLength: {
                          value: 15,
                          message: 'Card Number must be 15/16 digits',
                        },
                        maxLength: {
                          value: 16,
                          message: 'Card Number must be  15/16 digits',
                        },
                      }}
                      name="cardNumber"
                      render={({ field }) => (
                        <MaskedInput
                          onChange={(e) => {
                            setValue('cardNumber', e.target.value)
                          }}
                          className="w-[100%] mt-[7px]"
                          type="tel"
                          {...field}
                          name="cardNumber"
                          withLabel
                          placeholder="Card Number"
                          labelType="floating"
                          variant="default"
                          mask="#### #### #### ####"
                          maxLength={20}
                        />
                      )}
                    />
                    <p className="!text-notification-red text-xs ">
                      {errors.cardNumber?.message}
                      {error?.includes('number') && 'Invalid Card Number'}
                    </p>
                  </>
                </div>

                <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
                  <>
                    <Controller
                      control={control}
                      rules={{
                        required: 'Expiry date is required',
                        minLength: {
                          value: 4,
                          message: 'Invalid Expiration',
                        },
                      }}
                      name="expiresOn"
                      render={({ field }) => (
                        <MaskedInput
                          onChange={(e) => setValue('expiresOn', e.target.value)}
                          className="w-[100%] mt-[7px]"
                          type="tel"
                          {...field}
                          name="expiresOn"
                          withLabel
                          placeholder="Expires On (MM/YY)"
                          labelType="floating"
                          variant="default"
                          mask="##/##"
                          maxLength={5}
                        />
                      )}
                    />
                    <p className="!text-notification-red text-xs ">
                      {errors.expiresOn?.message}
                      {error?.includes('month' || 'year') &&
                        'Invalid expiration date'}
                    </p>
                  </>
                </div>

                <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
                  <>
                    <Controller
                      control={control}
                      rules={{
                        required: 'CVV is required',
                        minLength: {
                          value: 3,
                          message: 'CVV must be 3/4 digits',
                        },
                        maxLength: {
                          value: 4,
                          message: 'CVV must be 3/4 digits',
                        },
                      }}
                      name="cvv"
                      render={({ field }) => (
                        <Input
                          onChange={(e) => setValue('cvv', e.target.value)}
                          className="w-[100%] mt-[7px]"
                          type="tel"
                          {...field}
                          name="cvv"
                          withLabel
                          placeholder="CVV"
                          labelType="floating"
                          variant="default"
                          maxLength={4}
                        />
                      )}
                    />
                    <p className="!text-notification-red text-xs ">
                      {errors.cvv?.message}
                    </p>
                  </>
                </div>
              </div>
              <Header
                fontWeight="medium"
                variant="h3"
                className="mt-5 mb-3 text-sm text-left open-sans text-[#231F20]"
              >
                Billing Address
              </Header>
              <div className="grid grid-cols-6 gap-3">
                <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
                  <>
                    <Controller
                      control={control}
                      rules={{ required: 'First Name is required' }}
                      name="fname"
                      render={({ field }) => (
                        <Input
                          onChange={(e) => setValue('fname', e.target.value)}
                          className="w-[100%] mt-[7px]"
                          type="text"
                          {...field}
                          name="fname"
                          placeholder="First Name"
                          withLabel
                          labelType="floating"
                          variant="default"
                        />
                      )}
                    />
                    <p className="!text-notification-red text-xs ">
                      {errors.fname?.message}
                    </p>
                  </>
                </div>

                <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
                  <>
                    <Controller
                      control={control}
                      rules={{ required: 'Last Name is required' }}
                      name="lname"
                      render={({ field }) => (
                        <Input
                          onChange={(e) => setValue('lname', e.target.value)}
                          className="w-[100%] mt-[7px]"
                          type="text"
                          {...field}
                          name="lname"
                          withLabel
                          placeholder="Last Name"
                          labelType="floating"
                          variant="default"
                        />
                      )}
                    />
                    <p className="!text-notification-red text-xs ">
                      {errors.lname?.message}
                    </p>
                  </>
                </div>

                <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
                  <>
                    <Controller
                      control={control}
                      rules={{ required: 'Street Address is required' }}
                      name="street1"
                      render={({ field }) => (
                        <Input
                          onChange={(e) => setValue('street1', e.target.value)}
                          className="w-[100%] mt-[7px]"
                          type="text"
                          {...field}
                          name="street1"
                          withLabel
                          labelType="floating"
                          placeholder="Street Address"
                          variant="default"
                        />
                      )}
                    />
                    <p className="!text-notification-red text-xs ">
                      {errors.street1?.message}
                    </p>
                  </>
                </div>

                <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
                  <>
                    <Controller
                      control={control}
                      name="street2"
                      render={({ field }) => (
                        <Input
                          onChange={(e) => setValue('street2', e.target.value)}
                          className="w-[100%] mt-[7px]"
                          type="text"
                          {...field}
                          name="street2"
                          withLabel
                          labelType="floating"
                          placeholder="Street Address 2 (optional)"
                          variant="default"
                        />
                      )}
                    />
                  </>
                </div>

                <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
                  <>
                    <Controller
                      control={control}
                      rules={{ required: 'City is required' }}
                      name="city"
                      render={({ field }) => (
                        <Input
                          onChange={(e) => setValue('city', e.target.value)}
                          className="w-[100%] mt-[7px]"
                          type="text"
                          {...field}
                          name="city"
                          placeholder="City"
                          withLabel
                          labelType="floating"
                          variant="default"
                        />
                      )}
                    />
                    <p className="!text-notification-red text-xs ">
                      {errors.city?.message}
                    </p>
                  </>
                </div>

                <div className="sm:col-span-6 col-span-2 mt-4 mb-5">
                  <>
                    <Controller
                      control={control}
                      rules={{ required: 'State is required' }}
                      name="state"
                      render={({ field }) => (
                        <Select
                          options={states}
                          name="state"
                          width="full"
                          selectLabel="State/Region"
                          labelType="floating"
                          selectedOption={getValues('state')}
                          {...field}
                          setSelectedOption={(e) => setValue('state', e)}
                        />
                      )}
                    />
                    <p className="!text-notification-red text-xs ">
                      {errors.state?.message}
                    </p>
                  </>
                </div>

                <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
                  <>
                    <Controller
                      control={control}
                      rules={{
                        required: 'Postal Code is required',
                        validate: customValidationPincode,
                      }}
                      name="postalCode"
                      render={({ field }) => (
                        <Input
                          onChange={(e) => setValue('postalCode', e.target.value)}
                          className="w-[100%] mt-[7px]"
                          type="tel"
                          {...field}
                          name="postalCode"
                          placeholder="Postal code"
                          withLabel
                          labelType="floating"
                          variant="default"
                        />
                      )}
                    />
                    <p className="!text-notification-red text-xs ">
                      {errors.postalCode?.message}
                    </p>
                  </>
                </div>
              </div>
            </form>

            <div className="flex text-left text-[14px] mt-[15px]">
              <Checkbox
                checked={primaryPaymentMethod}
                onChange={() => setPrimaryPaymentMethod((prev) => !prev)}
                label="Make this my default credit card"
                hasLabel
              />
            </div>

            <div className="flex text-left">
              {error ? (
                <p className="!text-[14px] !text-[#B22222]">{error}</p>
              ) : null}
            </div>
          </PaymetnInfoContainer>
        </LeftSide>
        <RightSidePaymentForm>
          <OrderSummaryBox
            selectedPlan={selectedPlan}
            canApplyPromoCode={currentStep === UPGRADE_FLOW_STEPS.ORDER_REVIEW}
            accountInfo={accountInfo}
            // setCanSubmit={setCanSubmit}
            seats={seats}
            setErrorMessage={setError}
          />
        </RightSidePaymentForm>
      </ContentContainer>
      <ButtonContainer>
        <FlexStart>
          <ButtonAlt onClick={moveToPreviousStep} variant="primary" hover>
            BACK
          </ButtonAlt>
        </FlexStart>
        <FlexEnd>
          {isLoadingData() ? (
            <LoadingSpinner />
          ) : (
            <Button form="payment-form" type="submit" variant="primary">
              {isNewPayment ? 'Add new' : 'Save'}
            </Button>
          )}
        </FlexEnd>
      </ButtonContainer>
    </>
  )
}
