const { VITE_ENV } = import.meta.env
const MARKETING_CENTER_BASE_URL =
  VITE_ENV === 'prod'
    ? 'https://marketingcenter.labs.thryv.com'
    : 'https://mc-dev.sandbox.thryv.com'

export const createNewMarketingCenter = (id) => {
  return {
    title: 'Marketing Center',
    id: id,
    order: [
      'section-16',
      'section-17',
      'section-18',
      'section-19',
      'section-20',
      'section-21',
    ],
    items: [
      {
        id: 'section-16',
        title: 'Overview',
        visible: true,
        center: 'Marketing Center',
      },
      {
        id: 'section-17',
        title: 'Recommendations',
        visible: true,
        center: 'Marketing Center',
      },
      {
        id: 'section-18',
        title: 'Analytics',
        visible: true,
        center: 'Marketing Center',
      },
      // {
      //   id: 'section-19',
      //   title: 'Landing Pages',
      //   visible: true,
      //   center: 'Marketing Center',
      // },
      {
        id: 'section-19',
        title: 'Campaigns',
        visible: true,
        center: 'Marketing Center',
      },
      {
        id: 'section-20',
        title: 'Tools',
        visible: true,
        center: 'Marketing Center',
      },
      {
        id: 'section-21',
        title: 'Listings Management',
        visible: true,
        center: 'Marketing Center',
      },
    ],
    link: `${MARKETING_CENTER_BASE_URL}/overview`,
  }
}

export const createNewBusinessCenter = (id) => {
  return {
    title: 'Business Center',
    id: id,
    order: [
      'section-8',
      'section-9',
      'section-10',
      'section-11',
      'section-12',
      'section-13',
      'section-14',
      'section-15',
    ],
    items: [
      {
        id: 'section-8',
        title: 'Dashboard',
        visible: true,
        center: 'Business Center',
      },
      {
        id: 'section-9',
        title: 'Calendar',
        visible: true,
        center: 'Business Center',
      },
      {
        id: 'section-10',
        title: 'Clients',
        visible: true,
        center: 'Business Center',
      },
      {
        id: 'section-11',
        title: 'Sales',
        visible: true,
        center: 'Business Center',
      },
      {
        id: 'section-12',
        title: 'Documents',
        visible: true,
        center: 'Business Center',
      },
      {
        id: 'section-13',
        title: 'Marketing',
        visible: true,
        center: 'Business Center',
      },
      {
        id: 'section-14',
        title: 'Online Presence',
        visible: true,
        center: 'Business Center',
      },
      {
        id: 'section-15',
        title: 'Social Content',
        visible: true,
        center: 'Business Center',
      },
    ],
    link: 'https://go.thryv.com/app/dashboard',
  }
}

export const createNewReportingCenter = (id) => {
  return {
    title: 'Reporting Center',
    id: id || 6,
    order: [
      'section-30',
      //  'section-31', 'section-32', 'section-33'
    ],
    items: [
      {
        id: 'section-30',
        title: 'Reports',
        visible: true,
        center: 'Reporting Center',
      },
      // {
      //   id: 'section-31',
      //   title: 'Favorites',
      //   visible: true,
      //   center: 'Reporting Center',
      // },
      // {
      //   id: 'section-32',
      //   title: 'ThryvAI',
      //   visible: true,
      //   center: 'Reporting Center',
      // },
      // {
      //   id: 'section-33',
      //   title: 'Bin',
      //   visible: true,
      //   center: 'Reporting Center',
      // },
    ],
  }
}

export const filterUserPreferencesByCenter = (
  preferencesObject,
  hasMarketingCenter,
  hasListingsManagement,
  hasBusinessCenter,
  hasSignatures,
  hasWebsiteBuilder,
  hasThryvLeads,
  hasReportingCenter,
) => {
  // Show/hide Marketing Center & Business Center
  if (!hasMarketingCenter)
    preferencesObject = removeCenterFromUserPreferences(
      preferencesObject,
      'Marketing Center',
    )
  if (!hasBusinessCenter)
    preferencesObject = removeCenterFromUserPreferences(
      preferencesObject,
      'Business Center',
    )
  if (
    hasMarketingCenter &&
    !preferencesObject?.data?.navigationPreferences?.data?.some(
      (center) => center.title === 'Marketing Center',
    )
  ) {
    preferencesObject = addCenterToUserPreferences(
      preferencesObject,
      'Marketing Center',
    )
  }
  if (
    hasBusinessCenter &&
    !preferencesObject?.data?.navigationPreferences?.data?.some(
      (center) => center.title === 'Business Center',
    )
  ) {
    preferencesObject = addCenterToUserPreferences(
      preferencesObject,
      'Business Center',
    )
  }

  // Show/hide Signatures & Website Builder
  preferencesObject = updateInstalledApps(
    preferencesObject,
    hasSignatures,
    hasWebsiteBuilder,
    hasThryvLeads,
    hasListingsManagement,
  )

  // Fix marketing center nav items if old items (Landing Pages) are stored in auth0 or localstorage
  if (hasMarketingCenter) {
    const marketingCenterIsOutdated =
      preferencesObject?.data?.navigationPreferences?.data
        .find((section) => section.title === 'Marketing Center')
        ?.items?.find((item) => item.title === 'Landing Pages') !== undefined

    if (marketingCenterIsOutdated) {
      preferencesObject = removeCenterFromUserPreferences(
        preferencesObject,
        'Marketing Center',
      )
      preferencesObject = addCenterToUserPreferences(
        preferencesObject,
        'Marketing Center',
      )
    }
  }

  if (!hasReportingCenter)
    preferencesObject = removeCenterFromUserPreferences(
      preferencesObject,
      'Reporting Center',
    )

  if (hasReportingCenter) {
    if (
      !preferencesObject?.data?.navigationPreferences?.data?.some(
        (center) => center.title === 'Reporting Center',
      )
    ) {
      preferencesObject = addCenterToUserPreferences(
        preferencesObject,
        'Reporting Center',
      )
      // Check if reporting center added but order not correct
    } else if (
      !preferencesObject?.data?.navigationPreferences?.order?.includes('6')
    ) {
      preferencesObject?.data?.navigationPreferences?.order?.splice(-1, 0, '6')
    }
  }

  return preferencesObject
}

export const removeCenterFromUserPreferences = (preferencesObject, centerName) => {
  const navigationPrefs = preferencesObject?.data?.navigationPreferences
  const centerIndex = navigationPrefs?.data?.findIndex(
    (center) => center.title === centerName,
  )
  if (centerIndex !== -1) {
    const centerId = navigationPrefs?.data[centerIndex]?.id
    navigationPrefs?.data?.splice(centerIndex, 1)

    const orderIdIndex = navigationPrefs?.order?.indexOf(centerId)
    if (orderIdIndex !== -1) {
      navigationPrefs?.order?.splice(orderIdIndex, 1)
    }
  }
  return preferencesObject
}

export const addCenterToUserPreferences = (preferencesObject, centerName) => {
  const navigationPrefs = preferencesObject?.data?.navigationPreferences
  const largestCurrentCenterId = Math.max(
    // eslint-disable-next-line no-unsafe-optional-chaining
    ...navigationPrefs?.data?.map((center) => parseInt(center.id)),
  )
  const newCenterId = (largestCurrentCenterId + 1).toString()
  const newCenter =
    centerName === 'Marketing Center'
      ? createNewMarketingCenter(newCenterId)
      : centerName === 'Reporting Center'
        ? createNewReportingCenter(newCenterId)
        : createNewBusinessCenter(newCenterId)
  navigationPrefs?.data?.push(newCenter)
  navigationPrefs?.order?.push(newCenterId)
  return preferencesObject
}

const buildInstalledAppConfig = (appName) => {
  if (
    !['Signatures', 'Website Builder', 'Leads', 'Listing Management'].includes(
      appName,
    )
  )
    return {}

  const installedAppSections = {
    Signatures: 'section-24',
    'Website Builder': 'section-25',
    Leads: 'section-26',
    'Listing Management': 'section-27',
  }

  return {
    id: installedAppSections[appName],
    title: appName,
    visible: true,
    center: 'Installed Apps',
  }
}

const updateInstalledApps = (
  userPreferences,
  hasSignatures,
  hasWebsiteBuilder,
  hasThryvLeads,
  hasListingsManagement,
) => {
  const installedApps = userPreferences?.data?.navigationPreferences?.data?.filter(
    (section) => section.title === 'Installed Apps',
  )[0]

  // Remove duplicates of any item and remove old version of Listings Management
  const uniqueApps = installedApps?.items?.filter(
    (section, index, self) =>
      index === self.findIndex((t) => t.id === section.id) &&
      section.title !== 'Listings Management',
  )
  const uniqueOrder = [...new Set(installedApps?.order)]

  if (uniqueApps?.length !== installedApps?.items?.length) {
    installedApps.items = uniqueApps
    installedApps.order = uniqueOrder
  }

  const isSignaturesVisible = installedApps?.items?.some(
    (item) => item.title === 'Signatures',
  )
  const isWebsiteBuilderVisible = installedApps?.items?.some(
    (item) => item.title === 'Website Builder',
  )
  const isThryvLeadsVisible = installedApps?.items?.some(
    (item) => item.title === 'Leads',
  )
  const isListingsManagementVisible = installedApps?.items?.some(
    (item) => item.title === 'Listing Management',
  )

  if (!isSignaturesVisible && hasSignatures) {
    addInstalledAppToUserPreferences(userPreferences, 'Signatures')
  } else if (isSignaturesVisible && !hasSignatures) {
    removeInstalledAppFromUserPreferences(userPreferences, 'Signatures')
  }

  if (!isWebsiteBuilderVisible && hasWebsiteBuilder) {
    addInstalledAppToUserPreferences(userPreferences, 'Website Builder')
  } else if (isWebsiteBuilderVisible && !hasWebsiteBuilder) {
    removeInstalledAppFromUserPreferences(userPreferences, 'Website Builder')
  }

  if (!isThryvLeadsVisible && hasThryvLeads) {
    addInstalledAppToUserPreferences(userPreferences, 'Leads')
  } else if (isThryvLeadsVisible && !hasThryvLeads) {
    removeInstalledAppFromUserPreferences(userPreferences, 'Leads')
  }

  if (!isListingsManagementVisible && hasListingsManagement) {
    addInstalledAppToUserPreferences(userPreferences, 'Listing Management')
  } else if (isListingsManagementVisible && !hasListingsManagement) {
    removeInstalledAppFromUserPreferences(userPreferences, 'Listing Management')
  }

  return userPreferences
}

const removeInstalledAppFromUserPreferences = (userPreferences, appName) => {
  const navigationData = userPreferences?.data?.navigationPreferences?.data
  navigationData?.forEach((section) => {
    if (section.title === 'Installed Apps') {
      section.items = section.items.filter((item) => item.title !== appName)
      section.order = section?.order?.filter((id) => {
        const item = section.items.find((item) => item.id === id)
        return item !== undefined
      })
    }
  })
  return userPreferences
}

const addInstalledAppToUserPreferences = (userPreferences, appName) => {
  const appToAdd = buildInstalledAppConfig(appName)
  const installedApps = userPreferences?.data?.navigationPreferences?.data?.filter(
    (section) => section.title === 'Installed Apps',
  )[0]
  installedApps?.items?.push(appToAdd)
  installedApps?.order?.push(appToAdd.id)
  return userPreferences
}
