import { useSelector, useDispatch } from 'react-redux'
import {
  setInboxThreadIds,
  setPinnedInboxThreadIds,
  setInboxThreadSk1s,
  setPinnedInboxThreadSk1s,
  setSelectedThread,
} from '../../slices/inboxThreadsSlice'
import { DATE_THREADS_SORTS } from '../utils/date-threads-sorts'
import { DATE_THREADS_FILTERS } from '../utils/date-threads-filters'
import {
  midnight,
  yesterdayMidnightTime,
  startOfLastWeekMidnightTime,
  startOfLastMonthMidnightTime,
  startOfLastYearMidnightTime,
} from '../helper-functions/getTimeThreads'
import { setPinnedThreads, setTimeThreads } from '../../slices/inboxSlice'
import { ascSort, descSort } from './use-inbox-menu-conversation/utils'

export const useInboxMenuConversations = () => {
  const dispatch = useDispatch()

  const { inboxSort, inboxFilter, inboxAdvancedFilters } = useSelector(
    (state) => state.inboxThreads,
  )
  const selectedThread = useSelector((state) => state.inboxThreads.selectedThread)

  const dateRange = inboxAdvancedFilters.date.type

  const sortProperties =
    DATE_THREADS_SORTS[inboxSort || 'Date received: newest first']
  const filter = DATE_THREADS_FILTERS[inboxFilter]

  const initMessageThreads = (threads) => {
    const today = []
    const yesterday = []
    const lastWeek = []
    const lastMonth = []
    const lastYear = []
    const overOneYear = []

    const populateThreads = () => {
      threads.forEach((thread) => {
        const compareTime = thread?.last_message_time

        const latestChannel = thread.messages.items[0]?.chan_type

        const isIncluded =
          inboxAdvancedFilters.channels.includes(latestChannel) ||
          inboxAdvancedFilters.channels.length === 0

        const newThread = { ...thread, compareTime: compareTime }

        const createDate = {
          isToday: compareTime > midnight,
          isYesterday: yesterdayMidnightTime < compareTime && compareTime < midnight,
          isLastWeek:
            startOfLastWeekMidnightTime < compareTime &&
            compareTime < yesterdayMidnightTime,
          isLastMonth:
            startOfLastMonthMidnightTime < compareTime &&
            compareTime < startOfLastWeekMidnightTime,
          isLastYear:
            startOfLastYearMidnightTime < compareTime &&
            compareTime < startOfLastMonthMidnightTime,
          isOverOneYear: compareTime < startOfLastYearMidnightTime,
        }

        if (createDate?.isToday && isIncluded) today?.push(newThread)
        else if (createDate?.isYesterday && isIncluded) yesterday?.push(newThread)
        else if (createDate?.isLastWeek && isIncluded) lastWeek?.push(newThread)
        else if (createDate?.isLastMonth && isIncluded) lastMonth?.push(newThread)
        else if (createDate?.isLastYear && isIncluded) lastYear?.push(newThread)
        else if (createDate?.isOverOneYear && isIncluded)
          overOneYear?.push(newThread)
        if (
          selectedThread.id === thread.thread_id &&
          selectedThread.name !== thread.thread_name
        ) {
          dispatch(
            setSelectedThread({ id: thread.thread_id, name: thread.thread_name }),
          )
        }
      })

      const filterThreadIds = (threads) =>
        threads.filter((t) => t.messages.items.length > 0).map((t) => t.thread_id)

      const filterThreadSk1AndId = (threads) =>
        threads
          .filter((t) => t.messages.items.length > 0)
          .map((x) => ({ id: x.thread_id, sk1: x.sk1 }))

      switch (dateRange) {
        case 'Any':
          dispatch(setInboxThreadIds(filterThreadIds(threads)))
          dispatch(setInboxThreadSk1s(filterThreadSk1AndId(threads)))

          break
        case 'Yesterday':
          dispatch(setInboxThreadIds(filterThreadIds(yesterday)))
          dispatch(setInboxThreadSk1s(filterThreadSk1AndId(yesterday)))

          break
        case 'Last week':
          dispatch(setInboxThreadIds(filterThreadIds(lastWeek)))
          dispatch(setInboxThreadSk1s(filterThreadSk1AndId(lastWeek)))
          break
        case 'Last month':
          dispatch(setInboxThreadIds(filterThreadIds(lastMonth)))
          dispatch(setInboxThreadSk1s(filterThreadSk1AndId(lastMonth)))
          break
        case 'Last year':
          dispatch(setInboxThreadIds(filterThreadIds(lastYear)))
          dispatch(setInboxThreadSk1s(filterThreadSk1AndId(lastYear)))
          break
        default:
          break
      }
    }

    const sortThreads = () => {
      const sort_order = sortProperties?.sort_order
      const sort_field = sortProperties?.field

      const allThreads = [
        ...today,
        ...yesterday,
        ...lastWeek,
        ...lastMonth,
        ...lastYear,
        ...overOneYear,
      ]

      const sortThreadsAsc = (fieldType) => {
        dispatch(
          setTimeThreads({
            todayMessageThreads:
              fieldType === 'thread_name'
                ? ascSort(fieldType, allThreads)
                : ascSort(fieldType, overOneYear),
            yesterdayMessageThreads: ascSort(fieldType, lastYear),
            lastWeekMessageThreads: ascSort(fieldType, lastMonth),
            lastMonthMessageThreads: ascSort(fieldType, lastWeek),
            lastYearMessageThreads: ascSort(fieldType, yesterday),
            overOneYearMessageThreads: ascSort(fieldType, today),
          }),
        )
      }

      const sortThreadsDesc = (fieldType) => {
        dispatch(
          setTimeThreads({
            todayMessageThreads:
              fieldType === 'thread_name'
                ? descSort(fieldType, allThreads)
                : descSort(fieldType, today),
            yesterdayMessageThreads: descSort(fieldType, yesterday),
            lastWeekMessageThreads: descSort(fieldType, lastWeek),
            lastMonthMessageThreads: descSort(fieldType, lastMonth),
            lastYearMessageThreads: descSort(fieldType, lastYear),
            overOneYearMessageThreads: descSort(fieldType, overOneYear),
          }),
        )
      }

      switch (sort_order) {
        case 'asc':
          if (sort_field === 'message_time') sortThreadsAsc('message_time')
          else if (sort_field === 'thread_name') sortThreadsAsc('thread_name')
          break
        case 'desc':
          if (sort_field === 'message_time') sortThreadsDesc('message_time')
          else if (sort_field === 'thread_name') sortThreadsDesc('thread_name')
          break
      }
    }
    populateThreads()
    sortThreads()
  }

  const initPinnedMessageThreads = (pinnedThreads) => {
    const pinned = []

    const populateThreads = () => {
      pinnedThreads.forEach((thread) => {
        const compareTime = thread?.last_message_time

        const newThread = { ...thread, compareTime }

        pinned.push(newThread)
      })
      const filterThreadIds = (pinnedThreads) =>
        pinnedThreads
          .filter((t) => t.messages.items.length > 0)
          .map((t) => t.thread_id)

      const filterThreadSk1AndId = (threads) =>
        threads
          .filter((t) => t.messages.items.length > 0)
          .map((x) => ({ id: x.thread_id, sk1: x.sk1 }))

      switch (inboxFilter) {
        case 'Inbox':
          dispatch(setPinnedInboxThreadIds(filterThreadIds(pinned)))
          dispatch(setPinnedInboxThreadSk1s(filterThreadSk1AndId(pinned)))

          break
        case 'Unread':
          dispatch(setPinnedInboxThreadIds(filterThreadIds(pinned)))
          dispatch(setPinnedInboxThreadSk1s(filterThreadSk1AndId(pinned)))
          break
        case 'Completed':
          dispatch(setPinnedInboxThreadIds(filterThreadIds(pinned)))
          dispatch(setPinnedInboxThreadSk1s(filterThreadSk1AndId(pinned)))
          break
        default:
          break
      }
    }

    const sortThreads = () => {
      const sort_order = sortProperties?.sort_order
      const sort_field = sortProperties?.field

      const sortThreadsAsc = (fieldType) => {
        dispatch(setPinnedThreads(ascSort(fieldType, pinned)))
      }

      const sortThreadsDesc = (fieldType) => {
        dispatch(setPinnedThreads(descSort(fieldType, pinned)))
      }

      switch (sort_order) {
        case 'asc':
          if (sort_field === 'message_time') sortThreadsAsc('message_time')
          else if (sort_field === 'thread_name') sortThreadsAsc('thread_name')
          break
        case 'desc':
          if (sort_field === 'message_time') sortThreadsDesc('message_time')
          else if (sort_field === 'thread_name') sortThreadsDesc('thread_name')
          break
      }
    }

    populateThreads()
    sortThreads()
  }

  const createVariables = (nextToken, limit = 200) => {
    return {
      folder: filter,
      limit: limit,
      order: sortProperties?.sort_order,
      sort: sortProperties?.field,
      nextToken: nextToken,
    }
  }

  const createPinnedVariables = (nextToken) => {
    return {
      folder: 'pinned',
      order: sortProperties?.sort_order,
      sort: sortProperties?.field,
      nextToken: nextToken,
    }
  }

  return {
    createVariables,
    initMessageThreads,
    initPinnedMessageThreads,
    createPinnedVariables,
  }
}
