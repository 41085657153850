import { useState, useEffect } from 'react'
import { Select } from '@thryvlabs/maverick'
import { useController } from 'react-hook-form'
import styled from 'styled-components'

const styleInputText = () => {
  const textElem = document.querySelector(
    ".add-staff-role-input span[data-testid='select-option']",
  )

  const text = textElem.innerText

  if (text === 'Role') textElem.style.color = '#a3a5a7'
  else textElem.style.color = 'black'
}

const getRoleOption = (defaultRole) => {
  if (defaultRole === 'staff') return { name: 'Staff', value: 'staff' }
  else if (defaultRole === 'admin') return { name: 'Admin', value: 'admin' }
  else if (defaultRole === 'owner') return { name: 'Owner', value: 'owner' }
  else return { name: '', value: '' }
}

export const RoleInput = ({ className, control, errors, onChange, defaultRole }) => {
  const { field } = useController({ name: 'role', control })
  let options = [
    { name: 'Admin', value: 'admin' },
    { name: 'Staff', value: 'staff' },
  ]

  const CustomSelect = styled(Select)`
  ul {
    height: 70px !important;
  }
`;

  const [selectedOption, setSelectedOption] = useState(getRoleOption(defaultRole))

  useEffect(() => {
    styleInputText()
  }, [selectedOption])

  useEffect(() => {
    field.onChange(selectedOption)
    onChange && onChange(selectedOption.name)
  }, [selectedOption])

  return (
    <>
      <div className={`add-staff-role-input h-[27px] text-start ${className}`}>
        <CustomSelect
          errors={errors}
          className={`bottom-[7px] w-full`}
          name="role"
          options={options}
          selectLabel="Role"
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          disabled={defaultRole === 'owner'}
        />
      </div>
    </>
  )
}
