export const sortCalls = (calls) => {
  let batchDate = calls[0].date
  let batchCalls = []
  const sortedCalls = calls.map((call, i) => {
    if (i === calls.length - 1) {
      batchCalls.push(call)
      return {
        date: batchDate,
        calls: batchCalls,
      }
    } else if (call.date === batchDate) {
      batchCalls.push(call)
    } else {
      const tempCalls = batchCalls
      const tempDate = batchDate
      batchCalls = [call]
      batchDate = call.date
      return {
        date: tempDate,
        calls: tempCalls,
      }
    }
  })
  return sortedCalls.filter((el) => {
    return el !== undefined
  })
}

export const hasAtLeastOneVoicemail = (calls) => {
  // const filteredCalls = calls.filter((call) => {
  //   return call.voicemailDeleted === false && call.voicemail
  // })
  return calls?.length > 0
}

export const hasAtLeastOneCall = (calls) => {
  // const filteredCalls = calls.filter((call) => {
  //   return !call.callDeleted
  // })
  return calls?.length > 0
}

export const determineShowDefaultScreen = (tabSelected, callList) => {
  if (tabSelected === 'voicemail') {
    return !hasAtLeastOneVoicemail(callList)
  } else {
    return !hasAtLeastOneCall(callList)
  }
}

export const determineShowCall = (tabSelected, call, callsReceivedTab) => {
  if (callsReceivedTab === 'missed' && call.type !== 'missed') {
    return
  }
  if (tabSelected === 'voicemail' && (!call.voicemail || call.voicemailDeleted)) {
    return false
  }
  if (tabSelected === 'recent' && call.callDeleted) {
    return false
  }
  return true
}
