import { useState } from 'react'
import { Header, ParagraphText } from '@thryvlabs/maverick'
import SignUpTimeline from './SignUpTimeline'
import CreateProfile from './CreateProfile'
import VerifyStep from './VerifyStep'
import CreatePassword from './CreatePassword'
import Carousel from './Carousel'
import thryvcclogowhite from '../../assets/thryv-cc-white.png'
import checkmark from '../../assets/checkmark.png'
import AlmostDone from './AlmostDone'

const SignUp = () => {
  const [userData, setUserData] = useState({})
  const [response, setResponse] = useState({})
  const [step, setStep] = useState('1')
  const [countryVerified, setCountryVerified] = useState(true)
  const [userEmail, setUserEmail] = useState('')

  return (
    <div className="bg-sign-up-bg bg-cover bg-center bg-no-repeat h-[100vh] w-[100vw] flex items-center justify-center">
      {step !== '4' && (
        <>
          <img
            src={thryvcclogowhite}
            alt="thryv logo white"
            className="hidden md:flex md:absolute md:top-[45px]"
          />

          {countryVerified ? (
            <div className="w-[1188px] max-h-[752px] h-[752px] md:w-[329px] md:h-[588px] md:max-h-[588px] m bg-white z-10 rounded-[12px] border border-[#ECEEF1] drop-shadow-md flex">
              <div className="w-1/2 h-full flex flex-col items-center md:w-[329px]">
                <SignUpTimeline step={step} />
                {step === '1' ? (
                  <CreateProfile
                    userData={userData}
                    setUserData={setUserData}
                    setStep={setStep}
                    setCountryVerified={setCountryVerified}
                    setUserEmail={setUserEmail}
                  />
                ) : step === '2' ? (
                  <VerifyStep userData={userData} setStep={setStep} />
                ) : step === '3' ? (
                  <CreatePassword
                    setStep={setStep}
                    userData={userData}
                    response={response}
                    setResponse={setResponse}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="h-full w-[1px] bg-[#ECEEF1] md:hidden " />
              <div className="w-1/2 h-full  md:hidden">
                <Carousel step={step} />
              </div>
            </div>
          ) : (
            <div className="w-[540px] max-h-[450px] h-[392px] md:w-[329px] md:h-[450px] bg-white z-10 rounded-[12px] border border-[#ECEEF1] drop-shadow-md flex flex-col items-center">
              <img
                src={checkmark}
                alt="checkmark icon"
                className="h-[110px] w-[110px] mt-[60px] mb-[20px] mx-auto"
              />
              <div className="flex flex-col gap-[32px] w-[331px] md:w-[300px] md:text-center items-center mb-[50px]">
                <Header fontWeight="semibold" variant="h1">
                  Awesome!
                </Header>
                <ParagraphText variant="reg" color="thryv-steel">
                  We will send a notification email when Command Center is ready to
                  use in your region. Thank you!
                </ParagraphText>
              </div>
            </div>
          )}
        </>
      )}
      {step === '4' && <AlmostDone userEmail={userEmail} />}
    </div>
  )
}

export default SignUp
