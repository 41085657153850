import { useEffect, useState } from 'react'
import {
  CirclePauseIcon,
  PlayButtonIcon,
} from '../../../../command-center-icon/icons'
import CallAvatar from '../../recent-and-voicemail-components/CallAvatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { FETCH_CONTACT_BY_THREAD } from '../../../../../graphql'
import formatContactData from '../../../../inbox/hooks/formatContactData'
import { GET_S3_DOWNLOAD_LINK } from '../../../../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { LoadingSpinner } from '../../../../common/loading-spinner'

const VoicemailDate = ({ voicemail }) => {
  const voicemailDate = moment(voicemail?.ts).local().format('ll')

  return (
    <div className="flex items-center pr-[16px] pl-[10px] ">
      <span className="flex pb-[2px]">{voicemailDate}</span>
    </div>
  )
}
const VoicemailContent = ({ voicemail, voiceMailOpen, setVoicemailOpen }) => {
  const [voicemailPlayed] = useState(false)
  const [disableVoicemail, setDisableVoicemail] = useState(false)
  const [VmExpirationDate, setVmExpirationDate] = useState(null)

  const voicemailLink = voicemail.body
  const voicemailUUID = voicemailLink.substring(
    voicemailLink.indexOf('files/') + 6,
    voicemailLink.length - 2,
  )

  const { data: contact } = useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: { threadid: voicemail?.threadpk?.split('#')?.at(0) },
  })

  const body =
    typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)?.body
        ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body).body
        : JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)
      : null

  const firstName = body ? body.given_name : ''
  const lastName = body ? body.surname : ''

  const fullName = firstName + ' ' + lastName

  const formattedPhoneNumber = contact
    ? contact?.queryContactsByThread?.items?.at(0)?.contactsk3 === 'Anonymous'
      ? 'Unknown Caller'
      : formatContactData(body?.phone_numbers?.at(0)?.number)
    : null

  const getExpiresDate = () => {
    //returns the days from the time the voicemail was made
    const today = Date.now()
    const oneDay = 24 * 60 * 60 * 1000
    const diffDays = Math.round(Math.abs((today - voicemail.sk1) / oneDay))

    setVmExpirationDate(30 - diffDays)

    if (diffDays >= 30) {
      setDisableVoicemail(true)
    }
    return diffDays
  }

  const init = () => {
    getExpiresDate()
  }

  useEffect(() => {
    init()
  }, [])

  const linkToFetchAudio = voicemailUUID

  return (
    <div className="flex w-full pr-[20px]">
      <div className="flex pr-[8.5px] pl-[20px] items-center">
        <CallAvatar call={voicemail} firstName={firstName} lastName={lastName} />
      </div>
      <div className={`flex ml-[10px] pr-[20px] items-center `}>
        <div
          className={`${
            !voicemailPlayed ? '' : ''
          } block w-[150px] max-w-[1352px] truncate font-semibold`}
          // DISABLING THE VOICEMAIL PLAYED STYLES BECAUSE WE HAVE TO WAY TO PERSIST IT RIGHT NOW
        >
          {contact && fullName.length > 1 && !/^\d/.test(firstName)
            ? fullName
            : formattedPhoneNumber}
        </div>
        {/* REMOVING LOCATION FOR NOW */}
        {/* <div className="flex text-[#A3A5A7]">{voicemail.location}</div> */}
      </div>
      <div className="flex items-center w-full justify-end ">
        <div className="flex text-thryv-steel text-[14px] py-1">
          <div className="flex items-center">
            {voicemail?.ts ? (
              <span className="flex pr-[16px]">
                {VmExpirationDate >= 1
                  ? `Expires in ${VmExpirationDate} Days`
                  : `Expired`}
              </span>
            ) : null}
          </div>
          <div className="border-r-[1px] border-[#ccc]" />
          <VoicemailDate voicemail={voicemail} />
          <div className="flex flex-row items-center">
            {/* <button
              disabled={true}
              onClick={() => {
                alert('Download Voicemail From Cloud')
              }}
              className="flex pl-[4px] pr-[20px]"
            >
              <DownloadFromCloudIcon />
            </button> */}
            <AudioPlayer
              url={linkToFetchAudio}
              expiredVoicemail={disableVoicemail}
              voicemail={voicemail}
            />
            <button
              onClick={() => {
                setVoicemailOpen(!voiceMailOpen)
              }}
            >
              <FontAwesomeIcon
                className={`${
                  voiceMailOpen
                    ? 'transform rotate-180 duration-300'
                    : 'transform duration-300'
                } flex hover:cursor-pointer`}
                size="lg"
                icon={faChevronDown}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const AudioPlayer = ({ url, voicemail, expiredVoicemail }) => {
  // const [playing, toggle] = useAudio(url)
  const [audio, setAudio] = useState()
  const [playingAudio, setPlayingAudio] = useState(false)
  const [audioLoaded, setAudioLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  // need to add loading animation for when the audio is loading
  const { VITE_NYLAS_HOST } = import.meta.env
  const { user } = useAuth0()

  const playAudio = (audioBLOB) => {
    const audioURL = URL.createObjectURL(audioBLOB)
    const audio = new Audio(audioURL)
    setLoading(false)
    setAudio(audio)
    audio.play()
    audio.addEventListener('play', () => setPlayingAudio(true))
    audio.addEventListener('ended', () => setPlayingAudio(false))
  }

  const filename = `${user.cc_id}/${voicemail.chanpk}/${
    voicemail.threadpk.split('#')[0]
  }/${voicemail.msgpk}.mp3`

  const { data } = useQuery(GET_S3_DOWNLOAD_LINK, {
    variables: {
      filename,
    },
  })

  const s3DownloadLink = data?.getSafeS3DownloadURL.substring(
    data?.getSafeS3DownloadURL.indexOf('=') + 1,
    data?.getSafeS3DownloadURL.length - 1,
  )

  const loadVM = async () => {
    const myHeaders = new Headers()
    setLoading(true)
    myHeaders.append('Accept', 'audio/mpeg')
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const expectedResponse = await fetch(s3DownloadLink, requestOptions)

    if (expectedResponse.ok) {
      const audioBLOB = await expectedResponse.blob()
      setAudioLoaded(true)
      playAudio(audioBLOB)
    } else {
      const vonageResponse = await fetch(
        `${VITE_NYLAS_HOST}/voicemail/${url}`,
        requestOptions,
      )
      const audioBLOB = await vonageResponse.blob()
      setAudioLoaded(true)
      playAudio(audioBLOB)
    }
  }

  return (
    <div className="flex pr-[16px]">
      {loading ? (
        <LoadingSpinner widthAndHeight={'24px'} />
      ) : !audioLoaded || !playingAudio ? ( //play btn
        <button
          disabled={expiredVoicemail}
          onClick={() => loadVM()}
          className={`${
            expiredVoicemail ? 'opacity-40 pointer-events-none' : 'opacity-100'
          }`}
        >
          <PlayButtonIcon />
        </button>
      ) : (
        //pause btn
        <button
          onClick={() => {
            audio.pause()
            setPlayingAudio(false)
          }}
        >
          <CirclePauseIcon width={24} height={24} active={true} />
        </button>
      )}
    </div>
  )
}

export default VoicemailContent
